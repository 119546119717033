import { vamDACRequestList_actionType } from "../../../actionTypes/adminAction-actionTypes/role-actionTypes/vamDACRequestList-actionType";

const initialState = {
  loading: false,
  data: [],
  error: undefined
};

const VAM_DAC_ListReducer = (state = initialState, action) => {
  switch (action.type) {
    case vamDACRequestList_actionType.VAM_DAC_REQUEST_LIST_START:
      return {
        ...state,
        loading: true
      };
    case vamDACRequestList_actionType.VAM_DAC_REQUEST_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload
      };
    case vamDACRequestList_actionType.VAM_DAC_REQUEST_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default VAM_DAC_ListReducer;
