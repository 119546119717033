import { axiosApigateway } from "../../../api";
import { group_actionType } from "../../actionTypes/adminAction-actionTypes/group-actionType";


const createGroupData = (responeData) => {
    const groupData = responeData.map((o) => {
        return {
        id : o?.id_pk,
        name : o?.name,
        description : o?.description,
        isDynamicCountry : o?.isDynamicCountry,
        isDynamicProduct : o?.isDynamicProduct
    };
  });
  return groupData;
};

export function getGroupAllRequest(){
   
    return async(dispatch)=> {
        try{
            // calling API
            const response = await axiosApigateway.get("/vam-groups");
            const data = createGroupData(response?.data?.data || []);
            if(response){
                dispatch({
                    type : group_actionType.GET_GROUP_SUCCESS,
                    data : data //response.data.data
                });
            }
        }catch(error){
            dispatch({
                type : group_actionType.GET_GROUP_FAILURE,
                data : error
            });
        }
    };
}

export function initiliseGroupReduerState(){
    return(dispatch) => {
        dispatch({
            type: group_actionType.EDIT_REQUEST_START
        });
    };
}

const  getErrorMessage =(error, isEditRequest) => {
    let errorMessage;
    if(error?.response?.data && isEditRequest){
        errorMessage = error.response.data;
    }else if(!isEditRequest && error?.response?.data){
        errorMessage = error.response.data;
    }else{
        errorMessage = "Something went Wrong, Please try after some time!";
    }
   return errorMessage;
};

export function saveGroupRequest(data, isEditRequest, groupId){
    return async(dispatch) => {
        dispatch(initiliseGroupReduerState());
        try{
            let response;
            if(isEditRequest){
                response = await axiosApigateway.put(`/vam-groups/${groupId}`, data);
            }else{
                response = await axiosApigateway.post("/vam-groups", data);
            }
            if(response){
                dispatch({
                    type : group_actionType.EDIT_REQUEST_SUCCESS,
                    data : response?.data?.message || isEditRequest ? "VAM Group successfully updated." : "VAM Group successfully added."
                });
            }
            dispatch(getGroupAllRequest());
        }catch(error){
            const errorMsg = getErrorMessage(error, isEditRequest);
            dispatch({
                type: group_actionType.EDIT_REQUEST_FAILURE,
                data : errorMsg
            });
        }
    };
}

export function deleteGroupRequest(groupId){
    return async(dispatch) => {
        dispatch(initiliseGroupReduerState());
        try{
            const response  = await axiosApigateway.delete(`/vam-groups/${groupId}`);
            console.log("respone", response);
            if(response){
                dispatch({
                    type : group_actionType.EDIT_REQUEST_SUCCESS,
                    data : response?.data?.message
                });
                dispatch(getGroupAllRequest());
            }
            
        }catch(error){
            dispatch({
                type: group_actionType.EDIT_REQUEST_FAILURE
            });
        }
    };
}
