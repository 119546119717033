import React, { useState } from "react";
import "./styles/ReassignAndTerminateTableHeader.css";
import { TableSortLabel } from "@mui/material";

const ReassignAndTerminateTableHeader = ({ columns, sortHandler }) => {
  const [reassignAndTerminateTableColumns] = useState(columns);
  const [sortField, setSortField] = useState("");
  const [sortBy, setSortBy] = useState("asc");

  const sortChangeHandler = (accessor) => {
    const sortOrder =
      accessor === sortField && sortBy === "asc" ? "desc" : "asc";
    setSortField(accessor);
    setSortBy(sortOrder);
    sortHandler(accessor, sortOrder);
  };

  return (
    <thead className="reassignAndTerminate_tableHeader">
      <tr>
        {reassignAndTerminateTableColumns.map((column) => {
          return (
            <th
              key={column.accessor}
              className="reassignAndTerminate_tableHeadRow"
              onClick={
                column.sortable
                  ? () => sortChangeHandler(column.accessor)
                  : null
              }
            >
                <TableSortLabel
                  active={ sortField === column.accessor}
                  hideSortIcon={!column.sortable}
                  direction={
                    column.sortable ?
                      sortField === column.accessor && sortBy === "asc"
                        ? "asc"
                        : sortField === column.accessor && sortBy === "desc"
                        ? "desc"
                        : undefined
                    :undefined
                  }
                >
                  {column.name}
                </TableSortLabel>
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

export default ReassignAndTerminateTableHeader;
