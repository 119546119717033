import { axiosApigateway } from "../../../../api";
import { reassignAndTerminateRequestTasks_actionType } from "../../../actionTypes/adminAction-actionTypes/reassignAndTerminate/reassignAndTermianteRequestTasks-actionType";

export function reassignAndTerminateRequestTasks() {
  return {
    type: reassignAndTerminateRequestTasks_actionType.REASSIGN_AND_TERMINATE_REQUEST_TASKS
  };
}

export function reassignAndTerminateRequestTasksSuccess(data) {
  return {
    type: reassignAndTerminateRequestTasks_actionType.REASSIGN_AND_TERMINATE_REQUEST_TASKS_SUCCESS,
    payload: data
  };
}

export function reassignAndTerminateRequestTasksError(error) {
  return {
    type: reassignAndTerminateRequestTasks_actionType.REASSIGN_AND_TERMINATE_REQUEST_TASKS_ERROR,
    payload: error
  };
}

function getReassignAndTerminateRequestTasks(data) {
  let dataList = [];
  data.forEach((dataObject) => {
    const formattedDataObject = {
      taskId: dataObject?.taskId ?? "",
      taskType: dataObject?.taskType ?? "",
      taskStatus: dataObject?.taskStatus ?? "",
      viewerADGroup: dataObject?.viewerADGroup ?? "",
      initialTaskAudience: dataObject?.initialTaskAudience ?? "",
      taskAssignedTo: dataObject?.taskAssignedTo ?? "",
      taskAssignedBy: dataObject?.taskAssignedBy ?? "",
      isTaskReassignable: dataObject.isTaskReassignable ?? "",
      vamRequestId: dataObject?.vam_request?.request_id ?? "",
      requestedBy: dataObject?.vam_request?.requested_by,
      requestedFor: dataObject?.vam_request?.requested_for,
      requestedByName: dataObject?.vam_request?.requested_by_name,
      requestedForName:dataObject?.vam_request?.requested_for_name
    };
    dataList.push(formattedDataObject);
  });

  return dataList;
}

export function reassignAndTerminateRequestTasksCall(data) {
  return async (dispatch) => {
    dispatch(reassignAndTerminateRequestTasks());

    try {
      const response = await axiosApigateway.get(`/approval-tasks?vamRequestRequestId=${data}`);
      if (response) {
        const responseData = response.data.data;
        const data = getReassignAndTerminateRequestTasks(responseData);
        dispatch(reassignAndTerminateRequestTasksSuccess(data));
      }
    } catch (error) {
      dispatch(reassignAndTerminateRequestTasksError(error));
    }
  };
}
