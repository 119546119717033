import { React, useState } from 'react';
import "./styles/AdminPortal.css";

import { adminPortalPageNavigation_actionType } from './utils/adminPortalPageNavigation_actionType';

import NavigationBar from './NavigationBar';
import AdminMain from "./AdminMain/AdminMain";
import AdminFooter from './AdminFooter';

function AdminPortal() {
  const [pageNavigationList, setPageNavigationList] = useState([]);
  const [activeSection, setActiveSection] = useState(adminPortalPageNavigation_actionType.ADMIN_DASHBOARD);
  return (
    <div className='adminPortalContainer'>
      <div className='adminNavigationContainer'>
        <NavigationBar 
          pageNavigationList={pageNavigationList} 
          setPageNavigationList={setPageNavigationList}
          setActiveSection={setActiveSection}
        />
      </div>
      <div className='adminMainContainer'>
        <AdminMain 
          setPageNavigationList={setPageNavigationList}
          activeSection={activeSection}
          setActiveSection={setActiveSection}
        />
      </div>
      <div className='adminFooterContainer'>
        <AdminFooter />
      </div>
    </div>
  );
}

export default AdminPortal;