import { axiosApigateway } from "../../../api";
import { checkAccountStatusResources_actionType } from "../../actionTypes/userAction-actionTypes/checkAccountStatusResources-actionType";

export function checkAccountStatusInitialize() {
  return {
    type: checkAccountStatusResources_actionType.CHECK_ACCOUNT_STATUS
  };
}

export function checkAccountStatusSuccess(data) {
  return {
    type: checkAccountStatusResources_actionType.CHECK_ACCOUNT_STATUS_SUCCESS,
    payload: data
  };
}

export function resetCheckAccountStatus() {
  return {
    type: checkAccountStatusResources_actionType.RESET_CHECK_ACCOUNT_STATUS
  };
}

export function checkAccountStatusError(error) {
  return {
    type: checkAccountStatusResources_actionType.CHECK_ACCOUNT_STATUS_ERROR,
    payload: error
  };
}

function getAccountStatusData(data) {
  if (data.length === 0) return [];
  return [{
    requestStatus: data.request_status,
    requestName: data.request_id
  }];
}

export function checkAccountStatus(data) {
  const { requestStatus, requestedFor, requestType } = data;
  return async (dispatch) => {
    dispatch(checkAccountStatusInitialize());

    try {
      const queryString = `requestType=${requestType}&request_status=${requestStatus}&requested_for=${requestedFor}`;
      const response = await axiosApigateway.get(`/requests?${queryString}`);
      const [responseData] = response.data.data;
      const accountStatusData  = getAccountStatusData(responseData);
      dispatch(checkAccountStatusSuccess(accountStatusData));
      // console.log("checkAccountStatus", responseData);
    } catch (error) {
      dispatch(checkAccountStatusError(error));
    }
  };
}
