import React from "react";
import Modal from "@mui/material/Modal";
import "./style.css";

const CustomModal = ({
  isModalOpen,
  setIsModalOpen,
  children
}) => {

  return (
    <>
      <Modal
        open={isModalOpen}
        onClose={setIsModalOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="customModal">
           {children}
        </div>
      </Modal>
    </>
  );
};

export default CustomModal;
