import { Card, Grid } from "@mui/material";
import React from "react";

const SelectedProductsCard = ({ selectedProducts }) => {
  const getProductsData = (productGroupId) => {
    return selectedProducts
      ?.filter((o) => productGroupId !=='CMH' ? o.vamProductGroupId === productGroupId : o.vamProductGroupId === 'CMH' ||  o.vamProductGroupId === 'DBU' )
      ?.map((x) => x.product_name)
      ?.join(", ");
  };
  return (
    <Grid
      item
      sm={12}
      md={12}
      xs={12}
      style={{ height: "50%" }}
      className="accountSummary_mainItemSection"
    >
      <Card
        className="accountSummary_rolesProductsParent"
        style={{ height: "142px" }}
      >
        <div className="accountSummary_header">
          <Grid container spacing={2}>
            <Grid item md={11} className="accountSummary_mainHeading">
              Selected Products
            </Grid>
          </Grid>
        </div>
        <div className="accountSummary_section" style={{ height: "93px" }}>
          <table
            className="accountSummary_productsTable"
            style={{ height: "68px" }}
          >
            <tr align="left">
              <th className="accountSummary_tableHeading">CMH Products</th>
              <td width="60%">{getProductsData("CMH")}</td>
            </tr>
            <tr align="left">
              <th className="accountSummary_tableHeading">OBU Products</th>
              <td width="60%">{getProductsData("OBU")}</td>
            </tr>
          </table>
          <span className="bar">|</span>
          <table
            className="accountSummary_productsTable"
            style={{ height: "68px" }}
          >
            <tr align="left">
              <th className="accountSummary_tableHeading">IMBU Products</th>
              <td width="60%">{getProductsData("IMBU")}</td>
            </tr>
            <tr align="left">
              <th className="accountSummary_tableHeading">NBU Products</th>
              <td width="60%">{getProductsData("NBU")}</td>
            </tr>
          </table>
        </div>
      </Card>
    </Grid>
  );
};

export default SelectedProductsCard;
