import { combineReducers } from 'redux';
import ReassignAndTerminateRequestListReducer from './reassignAndTerminateRequestList-reducer';
import ReassignAndTerminateTerminteRequestReducer from "./reassignAndTerminateTerminateRequest-reducer";
import ReassignAndTerminateRequestTasksReducer from './reassignAndTerminateRequestTasks-reducer';
import ReassignAndTerminateReassignTaskReducer from './reassignAndTerminateReassignTask-reducer';
import ReassignAndTerminateSearchApproverReducer from './reassignAndTerminateSearchApprover-reducer';

const ReassignAndTerminateReducer  = combineReducers({
    ReassignAndTerminateRequestListReducer,
    ReassignAndTerminateTerminteRequestReducer,
    ReassignAndTerminateRequestTasksReducer,
    ReassignAndTerminateSearchApproverReducer,
    ReassignAndTerminateReassignTaskReducer
});

export default ReassignAndTerminateReducer;