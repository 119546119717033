import { React, useState, useEffect } from 'react';
import SelectProductCard from './SelectProductCard';
import { useSelector } from 'react-redux';

function ProductCard() {
    const [product, setProduct] = useState([]);
    const { reducerProduct } = useSelector((state) => ({
        reducerProduct: state.ProductReducer.product
    }));

    // all useEffect
    useEffect(() => {
        setInitialValue();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // all JS Function
    const setInitialValue = () => {
        setProduct(reducerProduct);
    };
    
    return <SelectProductCard product={product} setProduct={setProduct}/>;
}

export default ProductCard;