import { React, useState, useEffect } from "react";
import "./styles/Company.css";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { useDispatch, useSelector } from "react-redux"; // , useSelector
import {
  deleteCompanyRequest,
  filterCompany,
  getCompany,
  initiliseCompanyReduerState
} from "../../../../../../redux/actions/adminAccount-actions/company-action"; // , setCompanyToReducer
import CircularProgressLoader from "../../../../../../components/common/CircularProgressLoader";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { CSVLink } from "react-csv";
import { Stack } from "@mui/material";
import CompanyModal from "./CompanyModal";
import ModalMainContainer from "../../../../../ModalNotification/ModalMainContainer";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";

const CompanyComponent = () => {
  const dispatch = useDispatch();
  const [company, setCompany] = useState([]);
  const [searchLocalText, setSearchLocalText] = useState("");
  const [isCompanyModalOpen, setCompanyModalOpen] = useState(false);
  const [isEditRequest, setIsEditRequest] = useState(false);
  const [editCompanyData, setEditCompanyData] = useState([]);
  const [companyId, setCompanyId] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitActive, setIsSubmitActive] = useState(false);
  const [modalMessage, setModalMessage] = useState([]);
  const [isSubmitSuceess, setIsSubmitSuccess] = useState(false);

  const { companies, loading, allCompanies } = useSelector((state) => ({
    companies: state.CompanyReducer.companies,
    loading: state.CompanyReducer.loading,
    allCompanies: state.CompanyReducer.allCompanies
  }));

  const { isRequestCompleted, isRequestSuccess, message } = useSelector(
    (state) => ({
      isRequestCompleted: state.CompanyReducer.isRequestCompleted,
      isRequestSuccess: state.CompanyReducer.isRequestSuccess,
      message: state.CompanyReducer.message
    })
  );

  useEffect(() => {
    dispatch(getCompany());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isRequestCompleted) {
      setIsSubmitActive(true);
      if (isRequestSuccess) {
        setIsSubmitSuccess(true);
      } else {
        setIsSubmitSuccess(false);
      }
      setModalMessage(message);
      setIsModalOpen(true);
      dispatch(initiliseCompanyReduerState());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRequestCompleted]);

  useEffect(() => {
    if (companies.length > 0) {
      setCompany(companies);
    }
  }, [companies]);

  const [isSectionActive, setIsSectionActive] = useState(true);
  const paginationIndex = 0;
  const [totalItemInCompany, setTotalItemInCompany] = useState(0);
  const itemsPerPage = 4;

  // all useEffect
  useEffect(() => {
    setTotalItemInCompany(company.length);
  }, [company]);

  // all JS Function
  const toggleSectionActive = () => {
    setIsSectionActive(!isSectionActive);
  };

  const handelSearchSelected = () => {
    dispatch(filterCompany(searchLocalText, allCompanies));
  };
  const onChangeSearchLocalText = (event) => {
    setSearchLocalText(event.target.value);
    if (event.target.value.trim() === "") {
      handelClearSearchText();
    }
  };

  const handelClearSearchText = () => {
    setSearchLocalText("");
    dispatch(filterCompany("", allCompanies));
  };

  const handleReactivate = () => {
    setIsModalOpen(false);
    dispatch(deleteCompanyRequest(companyId));
  };

  const toggleOpenCompanyModal = () => {
    setIsEditRequest(false);
    setCompanyModalOpen(!isCompanyModalOpen);
  };

  const handleEdit = (row) => {
    setIsEditRequest(true);
    setEditCompanyData(row);
    setCompanyModalOpen(!isCompanyModalOpen);
  };

  const handleDelete = (companyId) => {
    setCompanyId(companyId);
    setIsSubmitActive(false);
    setModalMessage(["Are you sure you want to delete this company?"]);
    setIsModalOpen(true);
  };

  if (loading) {
    return <CircularProgressLoader />;
  }

  return (
    <div className="groupsContainer">
      <div className="groupTableSearchContainer">
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* <div className='selectCompanyCard_home'>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}> */}
          <Paper
            component="form"
            sx={{
              display: "flex",
              alignItems: "center",
              width: 230,
              height: 35,
              borderRadius: "5px 0px 0px 5px",
              boxShadow: 0,
              border: "1px solid",
              borderColor: "lightgrey"
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1, fontSize: "15px" }}
              placeholder="Search Company"
              inputProps={{ "aria-label": "search by full name" }}
              value={searchLocalText}
              onChange={onChangeSearchLocalText}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  handelSearchSelected();
                }
              }}
            />
            {searchLocalText.length > 0 ? (
              <IconButton
                type="button"
                sx={{ p: "5px" }}
                aria-label="search"
                onClick={handelClearSearchText}
              >
                <CloseIcon sx={{ fontSize: "1.6rem" }} />
              </IconButton>
            ) : null}
          </Paper>
          <Button
            variant="contained"
            className={"company_searchButton"}
            onClick={handelSearchSelected}
            sx={{ height: "35px", width: "35px" }}
          >
            <SearchOutlinedIcon size="medium" className="searchIcon" />
          </Button>
        </div>
        <div className="reassignAndTerminate_FilterDownload">
          <Stack spacing={1} direction={"row"}>
            <Button
              sx={{
                height: 35,
                width: 140
              }}
              variant="contained"
              className="btn"
              size="medium"
              onClick={() => setCompanyModalOpen(true)}
            >
              Add Company
            </Button>
            <CSVLink data={allCompanies} filename={"company.csv"}>
              <Button variant="contained" className="btn btn--download">
                <FileDownloadOutlinedIcon className="donwloadIcon" />
                <span>.csv</span>
              </Button>
            </CSVLink>
          </Stack>
        </div>
      </div>
      <div className="groupTableContainer">
        <div className="selectCompanyCard_header">
          <span style={{ display: "none" }}>
            {isSectionActive ? (
              <RemoveCircleOutlineOutlinedIcon onClick={toggleSectionActive} />
            ) : (
              <AddCircleOutlineOutlinedIcon onClick={toggleSectionActive} />
            )}
          </span>
          <span>Existing Companies</span>
        </div>
        {isSectionActive ? (
          <div
            className="selectCompanyCard_section"
            style={{ backgroundColor: "white", height: "400px" }}
          >
            <div className="selectCompanyCard_sectionCenterArticle">
              {company
                .slice(
                  Math.max(0, paginationIndex * itemsPerPage),
                  Math.min(
                    totalItemInCompany,
                    paginationIndex * itemsPerPage + itemsPerPage
                  )
                )
                .map((companyObject, companyKey) => {
                  return (
                    <div
                      className="selectCompanyCard_company"
                      key={companyKey}
                      style={{ backgroundColor: "white" }}
                    >
                      <div className="selectCompanyCard_companyHeader">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={companyObject.checked}
                              name="checkedB"
                              sx={{
                                "&.Mui-checked": {
                                  color: "#00AE43"
                                },
                                display: "none"
                              }}
                            />
                          }
                          label={
                            <span
                              style={{
                                fontWeight: "bold",
                                fontSize: "0.8rem",
                                color: "black",
                                "vertical-align": "middle",
                                margin: "auto"
                              }}
                            >
                              {companyObject.companyName}
                            </span>
                          }
                        />
                      </div>
                      <div className="selectCompanyCard_companySection">
                        {companyObject.companyList.map(
                          (companyInnerObject, companyInnerKey) => {
                            return (
                              <FormControlLabel
                                key={companyInnerKey}
                                sx={{
                                  "&:hover": {
                                    backgroundColor: "#ffeee5"
                                  }
                                }}
                                control={
                                  <Checkbox
                                    checked={companyInnerObject.checked}
                                    name="checkedB"
                                    sx={{
                                      "&.Mui-checked": {
                                        color: "#00AE43"
                                      },
                                      display: "none"
                                    }}
                                  />
                                }
                                label={
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "space-between"
                                    }}
                                  >
                                    <span
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        fontSize: "0.7rem",
                                        color: "black",
                                        "vertical-align": "middle"
                                      }}
                                    >
                                      {companyInnerObject.name}
                                    </span>
                                    <Stack
                                      id={"editDelete"}
                                      spacing={1}
                                      direction={"row"}
                                      width={12}
                                      alignItems={"center"}
                                      // style={{ display: 'none' }}
                                    >
                                      <EditOutlinedIcon
                                        className="editCompanyIcon"
                                        alignItems={"center"}
                                        onClick={(event) => {
                                          event.preventDefault();
                                          handleEdit(companyInnerObject);
                                        }}
                                      />
                                      <DeleteForeverOutlinedIcon
                                        className="deleteCompanyIcon"
                                        onClick={(event) => {
                                          event.preventDefault();
                                          handleDelete(companyInnerObject.id);
                                        }}
                                      />
                                    </Stack>
                                  </div>
                                }
                              />
                            );
                          }
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        ) : null}
      </div>
      <CompanyModal
        isModalOpen={isCompanyModalOpen}
        toggleOpenGroupModal={toggleOpenCompanyModal}
        rowData={editCompanyData}
        isEditRequest={isEditRequest}
      />

      <ModalMainContainer
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        isSubmitActive={isSubmitActive}
        modalMessage={modalMessage}
        isSubmitSuceess={isSubmitSuceess}
        reactivate={true}
        handleReactivate={handleReactivate}
        toggleToLandingPage={() => {
          setIsModalOpen(false);
          setCompanyId(null);
          setIsEditRequest(false);
          setEditCompanyData([]);
        }}
      />
    </div>
  );
};

export default CompanyComponent;
