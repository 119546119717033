import React, { useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useSelector, useDispatch } from "react-redux";
import "../styles/AdminDashboardTableSearch.css";
import { Badge, Pagination } from "@mui/material";
import {
  getAllRequests,
  setCurpage,
  setGlobalSearchText
} from "../../../../../../redux/actions/adminAccount-actions/adminDashboard-action";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import FilterModal from "./FilterModal";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

function AdminDashboardTableSearch() {
  const {
    csvData,
    requestsCount,
    searchText,
    isFilterApplied,
    curPage,
    pageSize,
    isAdminDashboardLoaderActive,
    filterObject,
    allRequests
  } = useSelector((state) => ({
    // allRequest: state.AdminDashboardReducer.requests,
    requestsCount: state.AdminDashboardReducer.requestsCount,
    csvData: state.AdminDashboardReducer.csvData,
    searchText: state.AdminDashboardReducer.searchText,
    isFilterApplied: state.AdminDashboardReducer.isFilterApplied,
    pageSize: state.AdminDashboardReducer.pageSize,
    curPage: state.AdminDashboardReducer.curPage,
    filterObject: state.AdminDashboardReducer.filterQuery,
    isAdminDashboardLoaderActive: state.AdminDashboardReducer.adminTableLoader,
    allRequests: state.AdminDashboardReducer.requests
  }));
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [searchLocalText, setSearchLocalText] = useState(searchText);
  // const [downloadCSV, setDownloadCSV] = useState([]);
  const csvLink = useRef();

  const dispatch = useDispatch();
  // console.log(downloadCSV);

  // useEffect(() => {
  //   let tempAllRequest = allRequest.map((element) => {
  //     return {
  //       "Request ID": element.requestID,
  //       "User Name": element.userName,
  //       "Full Name": element.fullName,
  //       "Global ID": element.globalID,
  //       "User Type": element.userType,
  //       "Request Type": element.requestType,
  //       "Current Status": element.currentStatus,
  //       Affiliate: element.affiliate,
  //       "Last Action Date": element.lastActionDate
  //     };
  //   });

  //   setDownloadCSV(tempAllRequest);
  // }, [allRequest]);

  const handelSearchSelected = () => {
    dispatch(setGlobalSearchText(searchLocalText.trim()));
  };
  const onChangeSearchLocalText = (event) => {
    setSearchLocalText(event.target.value);
    if (event.target.value.trim() === "") {
      handelClearSearchText();
    }

    // if (event.target.value === "") setSearchText("");
  };
  const handelClearSearchText = () => {
    setSearchLocalText("");
    dispatch(setGlobalSearchText(""));
  };
  const toggleOpenFilterBox = () => {
    setFilterModalOpen(!filterModalOpen);
  };
  return (
    <div className="adminDashboardTableSearch">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "35%"
        }}
      >
        <Paper
          component="form"
          sx={{
            display: "flex",
            alignItems: "center",
            width: 230,
            height: 35,
            borderRadius: "5px 0px 0px 5px",
            boxShadow: 0
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1, fontSize: "15px" }}
            placeholder="Search"
            inputProps={{ "aria-label": "search by full name" }}
            value={searchLocalText}
            onChange={onChangeSearchLocalText}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                e.target.blur();
                handelSearchSelected();
              }
            }}
          />
          {searchLocalText.length > 0 ? (
            <IconButton
              type="button"
              sx={{ p: "5px" }}
              aria-label="search"
              onClick={handelClearSearchText}
            >
              <CloseIcon sx={{ fontSize: "1.6rem" }} />
            </IconButton>
          ) : null}
        </Paper>
        <Button
          variant="contained"
          className={"reassignAndTerminateSearchUser_searchButton"}
          onClick={handelSearchSelected}
          sx={{ height: "35px", width: "35px" }}
        >
          <SearchOutlinedIcon size="medium" className="searchIcon" />
        </Button>
      </div>
      <div className="dashBoardPagination">
        {Math.ceil(requestsCount / pageSize) > 0 ? (
          <Pagination
            count={Math.ceil(requestsCount / pageSize)}
            page={curPage}
            disabled={isAdminDashboardLoaderActive}
            // count={totalPage}
            // page={curPage}
            onChange={(event, value) => {
              // value && setCurPage(value);
              value && dispatch(setCurpage(value));
            }}
            // classes={{ ul: classes.ul }}
            sx={{
              ".MuiPaginationItem-root:hover": {
                background: "#ffd6c0"
              },
              ".MuiPaginationItem-root": {
                color: "black"
              },
              ".Mui-selected": {
                bgcolor: "#ffd6b7"
              }
            }}
          />
        ) : (
          ""
        )}
      </div>

      <div className="filterContainer" style={{ width: "15%" }}>
        <div
          className="reassignAndTerminate_FilterDownload "
          style={{
            display: "flex",
            justifyContent: "flex-end",
            paddingRight: "0px",
            width: "100%"
          }}
        >
          <div style={{ paddingRight: "5px" }}>
            <Badge
              invisible={!isFilterApplied}
              color="error"
              variant="dot"
              anchorOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              overlap="circular"
              // badgeContent={"*"}
              sx={{ paddingBottom: "5px" }}
            >
              <Button
                variant="contained"
                className="btn btn--filter"
                onClick={toggleOpenFilterBox}
              >
                <FilterAltOutlinedIcon className="filterIcon" />
              </Button>
            </Badge>
          </div>
          <div>
            <CSVLink data={csvData} filename="requests.csv" ref={csvLink} />
            <Button
              variant="contained"
              className="btn btn--download"
              onClick={() => {
                dispatch(
                  getAllRequests(
                    requestsCount,
                    400,
                    csvLink,
                    filterObject,
                    searchText,
                    allRequests,
                    pageSize
                  )
                );
              }}
            >
              <FileDownloadOutlinedIcon className="donwloadIcon" />
              <span>.csv</span>
            </Button>
          </div>
        </div>
      </div>
      <FilterModal
        isModalOpen={filterModalOpen}
        toggleOpenFilterBox={toggleOpenFilterBox}
      />
    </div>
  );
}

export default AdminDashboardTableSearch;
