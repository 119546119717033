import { axiosApigateway } from "../../../api";
import { trainings_actionType } from "../../actionTypes/adminAction-actionTypes/trainings-actionType";

export function getAllTrainingsRequest() {
    return async (dispatch) => {
        try {
            dispatch({
                type: trainings_actionType.GET_TRAININGS_START

            });
            const response = await axiosApigateway.get("/vam-trainings");
            if (response) {
                dispatch({
                    type: trainings_actionType.GET_TRAININGS_SUCCESS,
                    data: response.data?.data
                });
            }
        } catch (error) {
            dispatch({
                type: trainings_actionType.GET_TRAININGS_FAILURE,
                data: error
            });
        }
    };
}
export function initiliseTrainingsReduerState() {
    return (dispatch) => {
        dispatch({
            type: trainings_actionType.EDIT_REQUEST_START
        });
    };
}

export function saveTrainingsRequest(data, isEditRequest, trainingsId) {
    return async (dispatch) => {
        dispatch(initiliseTrainingsReduerState());
        try {
            let response;
            if (isEditRequest) {
                response = await axiosApigateway.put(`/vam-trainings/${trainingsId}`, data[0]);
            } else {
                response = await axiosApigateway.post("/vam-trainings", data[0]);
            }
            if (response) {
                dispatch({
                    type: trainings_actionType.EDIT_TRAININGS_REQUEST_SUCCESS,
                    data: response?.data?.message || isEditRequest ? "Training successfully updated." : "Training successfully added."
                });
            }
            dispatch(getAllTrainingsRequest());
        } catch (error) {
            dispatch({
                type: trainings_actionType.EDIT_TRAININGS_REQUEST_FAILURE
            });
        }
    };
}

export function deleteTrainingsRequest(groupId) {
    return async (dispatch) => {
        dispatch(initiliseTrainingsReduerState());
        try {
            const response = await axiosApigateway.delete(`/vam-trainings/${groupId}`);
            console.log("respone", response);
            if (response) {
                dispatch({
                    type: trainings_actionType.EDIT_TRAININGS_REQUEST_SUCCESS,
                    data: response?.data?.message
                });
                dispatch(getAllTrainingsRequest());
            }

        } catch (error) {
            dispatch({
                type: trainings_actionType.EDIT_TRAININGS_REQUEST_FAILURE
            });
        }
    };
}