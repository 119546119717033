import { axiosApigateway } from "../../../api";
import { affiliateResources_actionType } from "../../actionTypes/userAction-actionTypes/affiliateResources-actionType";

export function affiliateResourcesInitialize() {
  return {
    type: affiliateResources_actionType.ALL_AFFILIATE_RESOURCES
  };
}

export function affiliateResourcesSuccess(data) {
  return {
    type: affiliateResources_actionType.ALL_AFFILIATE_RESOURCES_SUCCESS,
    payload: data
  };
}

export function resetAffiliateResources() {
  return (dispatch) => {
    dispatch({
      type: affiliateResources_actionType.ALL_AFFILIATE_RESOURCES_SUCCESS
    });
  };
}

export function affiliateResourcesError(error) {
  return {
    type: affiliateResources_actionType.ALL_AFFILIATE_RESOURCES_ERROR,
    payload: error
  };
}

function getAffType(affilateResponseData) {
  const affiliateTypeObj = {
    name: affilateResponseData?.name,
    abbreviation: affilateResponseData?.abbreviation
  };
  return affiliateTypeObj;
}

function getCountriesForParticularAff(affiliateCountriesResponseData) {
  let data = [];
  affiliateCountriesResponseData.forEach(countryObj => {
    const countryDataObj = {
      country_id: countryObj?.country_id,
      country_name: countryObj?.country_name,
      country_active_flg: countryObj?.country_active_flg,
      vamHubAbbreviation: countryObj?.vamHubAbbreviation,
      country_abbreviation: countryObj?.country_abbreviation
    };
    data.push(countryDataObj);
  });
  return data;
}

function getTrainingsForRole(trainingsResponseData) {
  let data = [];
  trainingsResponseData.forEach(trainingObj => {
    const trainingRoleObj = {
      id: trainingObj?.id,
      trainingName: trainingObj?.trainingName,
      description: trainingObj?.description,
      isCurriculam: trainingObj?.isCurriculam,
      isActive: trainingObj?.isActive
    };
    data.push(trainingRoleObj);
  });
  return data;
}

function getRolesForParticularAff(rolesResponseData, userType) {
  let data = [];
  const actualRoles = rolesResponseData.filter(roleObj => roleObj.roleAudience !== null &&
    (roleObj.roleAudience === userType || roleObj.roleAudience === "All"));
  actualRoles.forEach((actualRoleObj) => {
    const rolesDataObj = {
      vam_role_id: actualRoleObj?.vam_role_id,
      name: actualRoleObj?.name,
      description: actualRoleObj?.description,
      license: actualRoleObj?.license,
      isRequireProducts: actualRoleObj?.isRequireProducts,
      vamHubAbbreviation: actualRoleObj?.vamHubAbbreviation,
      isActive: actualRoleObj?.isActive,
      roleAudience: actualRoleObj?.roleAudience,
      isElevatedRole: actualRoleObj?.isElevatedRole,
      securityProfile: actualRoleObj?.securityProfile,
      trainings: getTrainingsForRole(actualRoleObj?.vam_trainings)
    };
    data.push(rolesDataObj);
  });
  return data;
}

function getProductsForProductGroups(productWithGrpResposeData) {
  let data = [];
  productWithGrpResposeData.forEach((prodWithGrpObj) => {
    const prodwithGrpDataObj = {
      product_id: prodWithGrpObj?.product_id,
      product_name: prodWithGrpObj?.product_name,
      vamProductGroupId: prodWithGrpObj?.vamProductGroupId,
      product_active_flg: prodWithGrpObj?.product_active_flg,
      product_abbreviation: prodWithGrpObj?.product_abbreviation,
      applicableAffiliates: prodWithGrpObj?.applicableAffiliates || []
    };
    data.push(prodwithGrpDataObj);
  });
  return data;
}

function getProductwithGroupsForParticularAff(affiliateAbbr, productsResponseData) {
  const data = [];
  productsResponseData.data.forEach(prodWithGrpObj => {
    const prodWithGrpObjData = {
      id: prodWithGrpObj?.id,
      name: prodWithGrpObj?.name,
      description: prodWithGrpObj?.description,
      vamhubs_vamproductgroup_junction: {
        vamHubAbbre:
          affiliateAbbr,
        vamProductG:
          prodWithGrpObj?.id
      },
      products: getProductsForProductGroups(prodWithGrpObj?.vvpm_products)
    };
    data.push(prodWithGrpObjData);
  });
  return data;
}

function createFormattedData(hubsResponse, productsResponse, userType) {
  let data = [];
  hubsResponse.data.forEach((dataObj) => {
    const affiliateType = getAffType(dataObj);
    const countries = getCountriesForParticularAff(dataObj?.vvpm_countries);
    const roles = getRolesForParticularAff(dataObj?.vam_roles, userType);
    const productsWithGroups = getProductwithGroupsForParticularAff(dataObj?.abbreviation, productsResponse);
    const object = {
      affiliateType,
      countries,
      roles,
      productsWithGroups
    };
    data.push(object);
  });
  return data;
}

export function affiliateResources(data) {
  return async (dispatch) => {
    dispatch(affiliateResourcesInitialize());

    try {
      const userType = data.userType;
      const response = await axiosApigateway.get("/hubs");
      const productsResponse = await axiosApigateway.get("/product-groups");
      if (response && productsResponse) {
        const affResources = createFormattedData(response.data, productsResponse.data, userType);
        dispatch(affiliateResourcesSuccess(affResources));
      }
    } catch (error) {
      dispatch(affiliateResourcesError(error));
    }
  };
}
