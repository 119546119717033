import React, { useEffect, useState } from "react";
import GroupsTable from "./GroupsTable";
import GroupsTableSearch from "./GroupsTableSearch";
import "./styles/Groups.css";
import GroupsModal from "./GroupsModal";
import { useDispatch, useSelector } from "react-redux";
import { deleteGroupRequest, getGroupAllRequest, initiliseGroupReduerState } from "../../../../../../redux/actions/adminAccount-actions/group-actions";
import ModalMainContainer from "../../../../../ModalNotification/ModalMainContainer";
import CircularProgressLoader from "../../../../../../components/common/CircularProgressLoader";

const Groups = () => {
  const [isGroupModalOpen, setGroupModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitActive, setIsSubmitActive] = useState(false);
  const [modalMessage, setModalMessage] = useState([]);
  const [isSubmitSuceess, setIsSubmitSuccess] = useState(false);
  const [rowData, setRowData] = useState({});
  const [isEditRequest, setIsEditRequest] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [deleteGroupId, setDeleteGroupId] = useState(null);
  const dispatch = useDispatch();

  const { isRequestCompleted, isRequestSuccess, message, loading } = useSelector((state) => ({
    isRequestCompleted : state.GroupReducer.isRequestCompleted,
    isRequestSuccess : state.GroupReducer.isRequestSuccess,
    message : state.GroupReducer.message,
    loading : state.GroupReducer.loading
  }));

  useEffect(() => {
    dispatch(getGroupAllRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    if(isRequestCompleted){
      setIsSubmitActive(true);
      if(isRequestSuccess){
        setIsSubmitSuccess(true);
        setGroupModalOpen(false);
      }else{
        setIsSubmitSuccess(false);
      }
      setModalMessage(message);
      setIsModalOpen(true);
      dispatch(initiliseGroupReduerState());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isRequestCompleted]);

  const editGroupsModal = (rowData) => {
    setIsEditRequest(true);
    setRowData(rowData);
    setGroupModalOpen(true);
  };

  const handleDelete = (groupId) => {
    setDeleteGroupId(groupId);
    setIsSubmitActive(false);
    setModalMessage(["Are you sure you want to delete this group?"]);
    setIsModalOpen(true);
  };

  const toggleOpenGroupModal = () => {
    setIsEditRequest(false);
    setGroupModalOpen(!isGroupModalOpen);
  };

  const handleReactivate = () => {
    setIsModalOpen(false);
    dispatch(deleteGroupRequest(deleteGroupId));
  };

  if(loading){
    return <CircularProgressLoader />;
  }

  return (
    <div className="groupsContainer">
      <GroupsTableSearch 
        openGroupsModal={toggleOpenGroupModal}
        setSearchText={setSearchText}
       />
      <GroupsTable 
        openGroupsModal={editGroupsModal}
        searchText={searchText}
        handleDelete={handleDelete}
      />
      <GroupsModal
        isModalOpen={isGroupModalOpen}
        toggleOpenGroupModal={toggleOpenGroupModal}
        rowData={rowData}
        isEditRequest={isEditRequest}
      />
      <ModalMainContainer 
        isModalOpen={isModalOpen} 
        setIsModalOpen={setIsModalOpen} 
        isSubmitActive={isSubmitActive}
        modalMessage={modalMessage}
        isSubmitSuceess={isSubmitSuceess}
        reactivate={true}
        handleReactivate={handleReactivate}
        toggleToLandingPage={() => {
          setIsModalOpen(false);
          setDeleteGroupId(null);
        }}
      />
    </div>
  );
};

export default Groups;
