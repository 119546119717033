import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Modal from "@mui/material/Modal";
import PendingRoundedIcon from "@mui/icons-material/PendingRounded";
// import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import {
  submitModifyRequest,
  submitDeleteRequest,
  submitCreateRequest
} from "../../../../../../redux/actions/adminAccount-actions/product-action/product-action";
import {
  getAdminProducts,
  searchForProductID,
  resetReducerCorrespondingToProductID
} from "../../../../../../redux/actions/adminAccount-actions/product-action/product-action";
import Success from "../SuccessFailureModal/Success";
import Failure from "../SuccessFailureModal/Failure";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  minHeight: "60%",
  maxHeight: "90%",
  border: 0,
  borderRadius: "10px",
  backgroundColor: "white",
  outline: "none"
};

import { MenuItem, Paper, Select } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import { FormControl, Grid } from "@mui/material";

const retriveAffiliate = (affiliateObject) => {
  return affiliateObject;
};

function ModalAddNewProduct({
  isAddNewProductModalOpen,
  handelToggleAddNewModal,
  addProductModaMode,
  selectedProductForEdit
}) {
  const [isProductIDAvailable, setIsProductIDAvailable] = useState(false);
  const [productName, setProductName] = useState("");
  const [productID, setProductID] = useState("");
  const [productAbb, setProductAbb] = useState("");
  const [selectedProductGroup, setSelectedProductGroup] = useState("");
  const [selectedAffiliate, setSelectedAffiliate] = useState([]);
  const [isModalOpenForEdit, setIsModalOpenForEdit] = useState(false);
  const [afterSubmitModal, setAfterSubmitModal] = useState(false);
  const dispatch = useDispatch();
  const {
    uniqueAffiliates,
    isSubmitSuccess,
    submitResponse,
    isSearchProductLoading,
    searchedProductMessage,
    isProductIDOpenToAdd,
    searchedProduct
  } = useSelector((state) => ({
    uniqueAffiliates: state.AdminProductReducer.uniqueAffiliates,
    isSubmitSuccess: state.AdminProductReducer.isSubmitSuccess,
    submitResponse: state.AdminProductReducer.submitResponse,
    isSearchProductLoading: state.AdminProductReducer.isSearchProductLoading,
    searchedProductMessage: state.AdminProductReducer.searchedProductMessage,
    isProductIDOpenToAdd: state.AdminProductReducer.isProductIDOpenToAdd,
    searchedProduct: state.AdminProductReducer.searchedProduct
  }));

  // all useEffects
  useEffect(() => {
    if (addProductModaMode) {
      switch (addProductModaMode) {
        case "edit":
          setIsModalOpenForEdit(true);
          setIsProductIDAvailable(true);
          setProductID(selectedProductForEdit.productID);
          setProductName(selectedProductForEdit.productName);
          setProductAbb(selectedProductForEdit.productAbbrebiation);
          setSelectedAffiliate(
            retriveAffiliate(selectedProductForEdit.affiliate)
          );
          setSelectedProductGroup(
            selectedProductForEdit.vamProductGroupId ?? ""
          );
          break;
        case "create":
          setIsProductIDAvailable(false);
          setIsModalOpenForEdit(false);
          setProductID("");
          setProductName("");
          setProductAbb("");
          setSelectedAffiliate([]);
          break;
        default:
          setIsModalOpenForEdit(false);
          setIsProductIDAvailable(false);
          setProductID("");
          setProductName("");
          setProductAbb("");
          setSelectedAffiliate([]);
          break;
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addProductModaMode]);
  useEffect(() => {
    if (!isAddNewProductModalOpen)
      dispatch(resetReducerCorrespondingToProductID());
    // setIsProductIDAvailable(false);
    // setProductAbb("");
    // setProductName("");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddNewProductModalOpen]);
  useEffect(() => {
    if (isProductIDOpenToAdd) {
      setIsProductIDAvailable(true);
      setProductAbb(searchedProduct.product_abbreviation);
      setProductName(searchedProduct.product_name);
      setSelectedProductGroup(selectedProductForEdit.vamProductGroupId ?? "");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProductIDOpenToAdd]);

  const onChangeProductID = (e) => {
    setProductID(e.target.value);
    setIsProductIDAvailable(false);
    setProductAbb("");
    setProductName("");

    // reset reducer state
    dispatch(resetReducerCorrespondingToProductID());
  };
  const handelUpdateAffiliateSelection = (event, newValue) => {
    setSelectedAffiliate(newValue);
  };
  const handleClose = () => {
    handelToggleAddNewModal();
  };
  const handelSave = () => {
    let response;
    switch (addProductModaMode) {
      case "edit":
        handelToggleAddNewModal();
        response = dispatch(
          submitModifyRequest(
            productID,
            selectedAffiliate,
            selectedProductGroup
          )
        );
        if (response) {
          setTimeout(() => {
            setAfterSubmitModal(true);
          }, 3000);
        }
        break;
      default:
        handelToggleAddNewModal();
        response = dispatch(
          submitCreateRequest(
            productID,
            productName,
            productAbb,
            selectedAffiliate,
            selectedProductGroup
          )
        );
        if (response) {
          setTimeout(() => {
            setAfterSubmitModal(true);
          }, 3000);
        }
        break;
    }
  };
  const handelDeleteProduct = () => {
    let response;
    response = dispatch(submitDeleteRequest(productID));

    handelToggleAddNewModal();
    if (response) {
      setTimeout(() => {
        setAfterSubmitModal(true);
      }, 3000);
    }
  };
  const handleRefreshProductData = () => {
    dispatch(getAdminProducts());
  };
  const handelSearchProductID = () => {
    dispatch(searchForProductID(productID));
  };
  return (
    <>
      <Modal
        open={isAddNewProductModalOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={ModalStyle}>
          <div>
            <div>
              <div className="addNewProductContainerCard">
                <div
                  className="adminAddProductCardHeader"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingRight: "15px"
                  }}
                >
                  <div>
                    {isModalOpenForEdit ? "Update Product" : "Add Product"}
                  </div>
                  <HighlightOffIcon
                    sx={{
                      mr: "5px",
                      "&:hover": {
                        color: "gray",
                        transition: "color 0.2s"
                      }
                    }}
                    onClick={handelToggleAddNewModal}
                  />
                </div>
                {/* <div className="adminAddProductCardHeader">
                  <span className="cardHeaderText">
                    {isModalOpenForEdit ? "Update Product" : "Add Product"}
                  </span>
                </div> */}
                <div
                  className="adminAddProductCardForm"
                  style={{
                    height: "255px",
                    overflowY: "scroll",
                    overflowX: "hidden"
                  }}
                >
                  <div className="adminAddProductCardFormLineTwo">
                    <Grid container spacing={1}>
                      <Grid item sm={4} md={1.3}>
                        <div className="adminAddProductCardFormInputLabel">
                          <span>
                            <span>Product ID</span>
                            <span className="dangerColor">*</span>
                          </span>
                        </div>
                      </Grid>
                      <Grid item sm={4} md={3}>
                        <FormControl
                          className="dropdownSelectInput"
                          size="small"
                        >
                          <Paper
                            className="adminAddProductCardFormInputField"
                            component="form"
                            sx={
                              isModalOpenForEdit
                                ? {
                                    display: "flex",
                                    height: "35px",
                                    border: "0.5px solid gray",
                                    borderRadius: "5px",
                                    boxShadow: 0,
                                    backgroundColor: "#c4bebe6b"
                                  }
                                : {
                                    display: "flex",
                                    height: "35px",
                                    border: "0.5px solid gray",
                                    borderRadius: "5px",
                                    boxShadow: 0
                                  }
                            }
                          >
                            <InputBase
                              sx={{ ml: 1, flex: 1, width: "200px" }}
                              placeholder="Enter Product ID"
                              inputProps={{ "aria-label": "Enter Product ID" }}
                              value={productID}
                              onChange={onChangeProductID}
                              disabled={isModalOpenForEdit}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  e.preventDefault();
                                  handelSearchProductID();
                                }
                              }}
                            />
                            <Button
                              variant="contained"
                              className={
                                "reassignAndTerminateSearchUser_searchButton"
                              }
                              onClick={handelSearchProductID}
                              // sx={{ height : '35px', width: '35px' }}
                              disabled={isModalOpenForEdit}
                            >
                              {isSearchProductLoading ? (
                                <PendingRoundedIcon
                                  size="medium"
                                  className="searchIcon"
                                />
                              ) : (
                                <SearchOutlinedIcon
                                  size="medium"
                                  className="searchIcon"
                                />
                              )}
                            </Button>
                          </Paper>
                        </FormControl>
                      </Grid>
                      <Grid item sm={4} md={6}>
                        <div className="modalAdminFooterMandatory">
                          {searchedProductMessage
                            ? "*" + searchedProductMessage
                            : null}
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <div className="adminAddProductCardFormLineTwo">
                    <Grid container spacing={1}>
                      <Grid item sm={4} md={1.3}>
                        <div className="adminAddProductCardFormInputLabel">
                          <span>
                            <span>Product Name</span>
                            <span className="dangerColor">*</span>
                          </span>
                        </div>
                      </Grid>
                      <Grid item sm={4} md={3}>
                        <Paper
                          className="adminAddProductCardFormInputField"
                          component="form"
                          sx={{
                            display: "flex",
                            height: "35px",
                            border: "1px solid gray",
                            borderRadius: "5px",
                            boxShadow: 0,
                            backgroundColor: "#c4bebe6b"
                          }}
                        >
                          <InputBase
                            sx={{ ml: 1, flex: 1, width: "200px" }}
                            placeholder="Enter Product Name"
                            inputProps={{ "aria-label": "Enter Product Name" }}
                            value={productName}
                            disabled
                          />
                        </Paper>
                      </Grid>
                      <Grid item sm={4} md={1.85}>
                        <div className="adminAddProductCardFormInputLabel">
                          <span>
                            <span>Product Abbreviation</span>
                            <span className="dangerColor">*</span>
                          </span>
                        </div>
                      </Grid>
                      <Grid item sm={4} md={2}>
                        <Paper
                          className="adminAddProductCardFormInputField"
                          component="form"
                          sx={{
                            display: "flex",
                            height: "35px",
                            border: "1px solid gray",
                            borderRadius: "5px",
                            boxShadow: 0,
                            backgroundColor: "#c4bebe6b"
                          }}
                        >
                          <InputBase
                            sx={{ ml: 1, flex: 1, width: "200px" }}
                            placeholder="Enter Abbreviation"
                            inputProps={{ "aria-label": "Enter Abbreviation" }}
                            value={productAbb}
                            disabled
                          />
                        </Paper>
                      </Grid>
                      {/* Product Group ID */}
                      <Grid item sm={4} md={1.4}>
                        <div className="adminAddProductCardFormInputLabel">
                          <span>
                            <span>Product Group</span>
                            {/* <span className='dangerColor'>*</span> */}
                          </span>
                        </div>
                      </Grid>
                      <Grid item sm={4} md={2}>
                        <Paper
                          className="adminAddProductCardFormInputField"
                          component="form"
                          sx={{
                            display: "flex",
                            height: "35px",
                            border: "1px solid gray",
                            borderRadius: "5px",
                            boxShadow: 0
                            // backgroundColor: isModalOpenForEdit ? '#c4bebe6b' : 'white'
                          }}
                        >
                          <Select
                            placeholder="Select Product Group ID"
                            sx={{ width: "100%" }}
                            // inputProps={{ 'aria-label': 'Enter Abbreviation' }}
                            value={selectedProductGroup}
                            onChange={(e) =>
                              setSelectedProductGroup(e.target.value)
                            }
                            // disabled={isModalOpenForEdit}
                          >
                            {productGroup.map((object, objectKey) => {
                              return (
                                <MenuItem key={objectKey} value={object.id}>
                                  {object.productName}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </Paper>
                      </Grid>
                    </Grid>
                  </div>
                  <div
                    className="adminAddProductCardFormLineOne"
                    style={{ height: "auto" }}
                  >
                    <Grid container spacing={1}>
                      <Grid item sm={4} md={1.3}>
                        <div className="adminAddProductCardFormInputLabel">
                          <span>
                            <span>Affiliate</span>
                            {/* <span className='dangerColor'>*</span> */}
                          </span>
                        </div>
                      </Grid>
                      <Grid item sm={4} md={10.25}>
                        <FormControl
                          className="dropdownSelectInput"
                          size="small"
                        >
                          <Autocomplete
                            multiple
                            id="tags-outlined"
                            options={uniqueAffiliates}
                            getOptionLabel={(option) => option.name}
                            filterSelectedOptions
                            value={selectedAffiliate}
                            onChange={handelUpdateAffiliateSelection}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label=""
                                placeholder="Add Affiliate"
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <CancelOutlinedIcon
            className="accountSummary_crossModalIcon"
            onClick={() => handelToggleAddNewModal()}
          /> */}
          <div className="modalAdminFooter">
            <div className="modalAdminFooterMandatory">
              {!isModalOpenForEdit ? (
                <span>
                  *Enter full product id and perform search to auto-populate the
                  rest of fields
                </span>
              ) : null}
              <span>**Marked fields are mandatory</span>
            </div>
            <div className="summaryModalButtons">
              {isModalOpenForEdit ? (
                <Button
                  className="buttonDanger"
                  variant="outlined"
                  onClick={handelDeleteProduct}
                  color="error"
                  sx={{
                    width: 100,
                    height: 35,
                    fontSize: 12,
                    ":hover": {
                      bgcolor: "red",
                      color: "white"
                    }
                  }}
                >
                  Delete
                </Button>
              ) : null}
              <Button
                className="buttonDanger"
                variant="outlined"
                onClick={handleClose}
                color="error"
                sx={{
                  width: 100,
                  height: 35,
                  fontSize: 12,
                  ":hover": {
                    bgcolor: "red",
                    color: "white"
                  }
                }}
              >
                Cancel
              </Button>
              <Button
                className="buttonSuccess"
                variant="outlined"
                onClick={handelSave}
                color="success"
                sx={{
                  width: 100,
                  height: 35,
                  fontSize: 12,
                  ":hover": {
                    bgcolor: "green",
                    color: "white"
                  }
                }}
                disabled={!isProductIDAvailable || !selectedProductGroup}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      {isSubmitSuccess ? (
        <Success
          isModalOpen={afterSubmitModal}
          setIsModalOpen={setAfterSubmitModal}
          modalMessage={submitResponse?.data?.message}
          refreshDataAction={handleRefreshProductData}
        />
      ) : (
        <Failure
          isModalOpen={afterSubmitModal}
          setIsModalOpen={setAfterSubmitModal}
          modalMessage={submitResponse?.response?.data?.errormessage}
          refreshDataAction={handleRefreshProductData}
        />
      )}
    </>
  );
}

const productGroup = [
  {
    productName: "OBU Products",
    id: "OBU"
  },
  {
    productName: "CMH Products",
    id: "CMH"
  },
  {
    productName: "NBU Products",
    id: "NBU"
  },
  {
    productName: "IMBU Products",
    id: "IMBU"
  }
];

export default ModalAddNewProduct;
