import * as React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid
} from "@mui/material";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import classNames from "classnames";

export const UserDetailsResult = (props) => {
  const { userDetails, selectAffiliate } = props;
  const {
    veevaUserName,
    firstName,
    lastName,
    emailId,
    userType,
    company,
    location,
    timezone,
    languageName,
    localeName
  } = userDetails;
  const { selectedAffiliateType, selectedCountries } = selectAffiliate;
  const particularAffSelectedCountries = selectedCountries?.filter(
    (ctry) => ctry.affiliateType === selectedAffiliateType
  );

  const [expanded, setExpanded] = React.useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    // User all selected details in previous step section
    <div className="userDetailsResult">
      <Accordion
        expanded={expanded === "userDetailsPanel"}
        disableGutters
        onChange={handleChange("userDetailsPanel")}
        sx={{
          margin: "1rem 0",
          borderBottom: "1px solid #dddddd",
          borderRadius: "0.5rem"
        }}
      >
        <AccordionSummary
          expandIcon={
            expanded === "userDetailsPanel" ? (
              <RemoveCircleOutlineOutlinedIcon sx={{ color: "#000" }} />
            ) : (
              <AddCircleOutlineOutlinedIcon sx={{ color: "#000" }} />
            )
          }
          sx={{
            background: "#DFF1F7",
            flexDirection: "row-reverse",
            boxShadow: "0px 3px 6px #00000029",
            borderRadius: "0.5rem 0.5rem 0 0"
          }}
        >
          <div className="userDetailsResult_header">
            <span className="userDetailsResult_header_heading">
              User Details
            </span>
            <span className="userDetailsResult_header_element">
              <em>
                {firstName} {emailId ? "|" : null} {emailId}
              </em>
            </span>
          </div>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            borderRadius: "0rem 0rem 0.5rem 0.5rem",
            boxShadow: " 0px 3px 6px #00000029"
          }}
        >
          <Grid
            container
            spacing={2}
            md={12}
            className="userDetailsResult_section"
          >
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              className="userDetailsResult_element"
            >
              <Grid container>
                <Grid item md={4} className="userDetailsResult_elementHeading">
                  First Name
                </Grid>

                <Grid item md={4} className="userDetailsResult_elementContent">
                  {firstName}
                </Grid>
                <Grid item md={4}></Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              className="userDetailsResult_element"
            >
              <Grid container>
                <Grid item md={4} className="userDetailsResult_elementHeading">
                  Last Name
                </Grid>
                <Grid item md={4} className="userDetailsResult_elementContent">
                  {lastName}
                </Grid>
                <Grid item md={4}></Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              className="userDetailsResult_element"
            >
              <Grid container>
                <Grid item md={4} className="userDetailsResult_elementHeading">
                  Email Id
                </Grid>
                <Grid item md={4} className="userDetailsResult_elementContent">
                  {emailId}
                </Grid>
                <Grid item md={4}></Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              className="userDetailsResult_element"
            >
              <Grid container>
                <Grid item md={4} className="userDetailsResult_elementHeading">
                  Veeva Username
                </Grid>
                <Grid item md={4} className="userDetailsResult_elementContent">
                  {veevaUserName}
                </Grid>
                <Grid item md={4}></Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              className="userDetailsResult_element"
            >
              <Grid container>
                <Grid item md={4} className="userDetailsResult_elementHeading">
                  User Type
                </Grid>
                <Grid item md={4} className="userDetailsResult_elementContent">
                  {userType}
                </Grid>
                <Grid item md={4}></Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              className="userDetailsResult_element"
            >
              <Grid container>
                <Grid item md={4} className="userDetailsResult_elementHeading">
                  Location
                </Grid>
                <Grid item md={4} className="userDetailsResult_elementContent">
                  {location}
                </Grid>
                <Grid item md={4}></Grid>
              </Grid>
            </Grid>
            {company && company.length ? (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                className="userDetailsResult_element"
              >
                <Grid container>
                  <Grid
                    item
                    md={4}
                    className="userDetailsResult_elementHeading"
                  >
                    Company
                  </Grid>
                  <Grid
                    item
                    md={4}
                    className="userDetailsResult_elementContent"
                  >
                    {company}
                  </Grid>
                  <Grid item md={4}></Grid>
                </Grid>
              </Grid>
            ) : null}
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              className="userDetailsResult_element"
            >
              <Grid container>
                <Grid item md={4} className="userDetailsResult_elementHeading">
                  Language
                </Grid>
                <Grid item md={4} className="userDetailsResult_elementContent">
                  {languageName}
                </Grid>
                <Grid item md={4}></Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              className="userDetailsResult_element"
            >
              <Grid container>
                <Grid item md={4} className="userDetailsResult_elementHeading">
                  Timezone
                </Grid>
                <Grid item md={4} className="userDetailsResult_elementContent">
                  {timezone}
                </Grid>
                <Grid item md={4}></Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              className="userDetailsResult_element"
            >
              <Grid container>
                <Grid item md={4} className="userDetailsResult_elementHeading">
                  Locale
                </Grid>
                <Grid item md={4} className="userDetailsResult_elementContent">
                  {localeName}
                </Grid>
                <Grid item md={4}></Grid>
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion
        disableGutters
        sx={{
          borderRadius: "0.5rem"
        }}
      >
        <AccordionSummary
          sx={{
            background: "#DFF1F7",
            flexDirection: "row-reverse",
            boxShadow: "0px 3px 6px #00000029",
            borderRadius: "0.5rem 0.5rem 0 0"
          }}
        >
          <div className="userDetailsResult_header">
            <span className={classNames("userDetailsResult_header_heading")}>
              Selected Affiliate
            </span>
            <span
              className={classNames(
                "userDetailsResult_header_element",
                "userDetailsResult_selectedAffiliateElement"
              )}
            >
              <em>
                {selectedAffiliateType?.toUpperCase()}
                {particularAffSelectedCountries.length ? " " + "|" + " " : null}
                {particularAffSelectedCountries.length
                  ? particularAffSelectedCountries
                      .map((ctry) => ctry.country.country_name)
                      ?.join(", ")
                  : null}
              </em>
            </span>
          </div>
        </AccordionSummary>
      </Accordion>
    </div>
  );
};
