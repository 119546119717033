import { useDispatch, useSelector } from "react-redux";
import TrainingsModal from "./TrainingsModal";
import TrainingsTableSearch from "./TrainingsTableSearch";
import TrainingsTable from "./TrainingsTable";
import "./styles/Trainings.css";
import { deleteTrainingsRequest, getAllTrainingsRequest, initiliseTrainingsReduerState } from "../../../../../../redux/actions/adminAccount-actions/trainings-actions";
import { useEffect, useState } from "react";
import ModalMainContainer from "../../../../../ModalNotification/ModalMainContainer";
import CircularProgressLoader from "../../../../../../components/common/CircularProgressLoader";


export default function Trainings() {
    const [isTrainingsModalOpen, setTrainingsModalOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [rowData, setRowData] = useState({});
    const [isEditRequest, setIsEditRequest] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [modalMessage, setModalMessage] = useState([]);
    const [isSubmitSuceess, setIsSubmitSuccess] = useState(false);
    const [isSubmitActive, setIsSubmitActive] = useState(false);
    const [deleteTrainingId, setDeleteTrainingId] = useState(null);


    const dispatch = useDispatch();

    const { isRequestCompleted, isRequestSuccess, message, isLoader } = useSelector((state) => ({
        isRequestCompleted: state.TrainingsReducer.isRequestCompleted,
        isRequestSuccess: state.TrainingsReducer.isRequestSuccess,
        message: state.TrainingsReducer.message,
        isLoader: state.TrainingsReducer?.isLoader
    }));

    console.log(isRequestCompleted, isRequestSuccess, message, isLoader);

    useEffect(() => {
        dispatch(getAllTrainingsRequest());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    useEffect(() => {
        if (isRequestCompleted) {
            setIsSubmitActive(true);
            if (isRequestSuccess) {
                setIsSubmitSuccess(true);
            } else {
                setIsSubmitSuccess(false);
            }
            setModalMessage(message);
            setIsModalOpen(true);
            dispatch(initiliseTrainingsReduerState());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRequestCompleted]);

    const editTrainingsModal = (rowData) => {
        setIsEditRequest(true);
        setRowData(rowData);
        setTrainingsModalOpen(true);
    };
    const handleDelete = (trainingsId) => {
        setDeleteTrainingId(trainingsId);
        setIsSubmitActive(false);
        setModalMessage(["Are you sure you want to delete this Training?"]);
        setIsModalOpen(true);
    };
    const toggleOpenTrainingsModal = () => {
        setIsEditRequest(false);
        setTrainingsModalOpen(!isTrainingsModalOpen);
    };

    const handleReactivate = () => {
        setIsModalOpen(false);
        dispatch(deleteTrainingsRequest(deleteTrainingId));
    };

    if (isLoader) {
        return (<CircularProgressLoader />);
    }
    return (< div className="trainingsContainer" >
        <TrainingsTableSearch
            openTrainingsModal={toggleOpenTrainingsModal}
            setSearchText={setSearchText}
        />
        <TrainingsModal
            isModalOpen={isTrainingsModalOpen}
            toggleOpenTrainingsModal={toggleOpenTrainingsModal}
            rowData={rowData}
            isEditRequest={isEditRequest}
        />
        <TrainingsTable
            openTrainingsModal={editTrainingsModal}
            searchText={searchText}
            handleDelete={handleDelete} />
        <ModalMainContainer
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            isSubmitActive={isSubmitActive}
            modalMessage={modalMessage}
            isSubmitSuceess={isSubmitSuceess}
            reactivate={true}
            handleReactivate={handleReactivate}
            toggleToLandingPage={() => {
                setIsModalOpen(false);
                setDeleteTrainingId(null);
            }}
        />
    </div >
    );




}
