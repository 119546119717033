import { Button } from "@mui/material";
import React from "react";

const ButtonError = ({ label, disabled, onClickHandler }) => {
  return (
    <Button
      className="buttonDanger"
      variant="outlined"
      onClick={onClickHandler}
      color="error"
      disabled={disabled}
      sx={{
        width: 100,
        height: 35,
        ":hover": {
          bgcolor: "red",
          color: "white"
        }
      }}
    >
      {label}
    </Button>
  );
};

export default ButtonError;
