import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { Button, IconButton, TextField } from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import "./styles/ReassignAndTerminate.css";
import ReassignAndTerminateFilterModalNotice from "./Modals/ReassignAndTerminateFilterModalNotice";
import ReassignAndTerminateModal from "./Modals/ReassignAndTerminateModal";
import ReassignAndTerminateTerminateModalNotice from "./Modals/ReassignAndTerminateTerminateModalNotice";
import { ReassignAndTerminateReassignApproverModalNotice } from "./Modals/ReassignAndTerminateReassignApproverModalNotice";
import ReassignAndTerminateTable from "./ReassignAndTerminateTable";
import { useDispatch, useSelector } from "react-redux";
import ReassignAndTerminateMessageModalNotice from "./Modals/ReassignAndTerminateMessageModalNotice";
import { reassignAndTerminateTerminateRequestReset } from "../../../../../../redux/actions/adminAccount-actions/reassignAndTerminate/reassignAndTerminateTerminateRequest-action";
import { reassignAndTerminateReassignTaskReset } from "../../../../../../redux/actions/adminAccount-actions/reassignAndTerminate/reassignAndTerminateReassignTask-action";

// const filteredOptionsInitialState = [
//   {
//     name: "Affiliate Type",
//     accessor: "affiliate_type",
//     values: [
//       { name: "US", value: "US", selected: false },
//       { name: "Global", value: "Global", selected: false },
//       { name: "UK Hub", value: "Uk Hub", selected: false }
//     ]
//   },
//   {
//     name: "User Type",
//     accessor: "user_type",
//     values: [
//       { name: "Lilly User", value: "Lilly", selected: false },
//       { name: "Non-Lilly User", value: "Non-Lilly", selected: false }
//     ]
//   },
//   {
//     name: "Request Type",
//     accessor: "request_type",
//     values: [
//       { name: "Create Account", value: "create", selected: false },
//       { name: "Modify Account", value: "modify", selected: false },
//       { name: "Reactivate Account", value: "reactivate", selected: false },
//       { name: "Deactivate Account", value: "deactivate", selected: false }
//     ]
//   }
// ];

const ReassignAndTerminate = () => {
  const {
    allRequest,
    filteredOptionsInitialState,
    reassignStatus,
    terminateStatus
  } = useSelector((state) => {
    return {
      allRequest:
        state.ReassignAndTerminateReducer.ReassignAndTerminateRequestListReducer
          .data,
      filteredOptionsInitialState:
        state.ReassignAndTerminateReducer.ReassignAndTerminateRequestListReducer
          .filterOptions,
      reassignStatus: {
        isRequestCompleted:
          state.ReassignAndTerminateReducer
            .ReassignAndTerminateReassignTaskReducer.isRequestCompleted,
        isSuccess:
          state.ReassignAndTerminateReducer
            .ReassignAndTerminateReassignTaskReducer.isSuccess,
        message:
          state.ReassignAndTerminateReducer
            .ReassignAndTerminateReassignTaskReducer.message
      },
      terminateStatus: {
        isRequestCompleted:
          state.ReassignAndTerminateReducer
            .ReassignAndTerminateTerminteRequestReducer.isRequestCompleted,
        isSuccess:
          state.ReassignAndTerminateReducer
            .ReassignAndTerminateTerminteRequestReducer.isSuccess,
        message:
          state.ReassignAndTerminateReducer
            .ReassignAndTerminateTerminteRequestReducer.message
      }
    };
  });
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeModalNotice, setActiveModalNotice] = useState("");
  const [isTwoBtn, setIsTwoBtn] = useState(false);
  const [isErr, setIsErr] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [filterOptions, setFilterOptions] = useState([]);

  useEffect(() => {
    setFilterOptions(filteredOptionsInitialState);
  }, [filteredOptionsInitialState]);

  const clearSearchBoxHandler = () => {
    setSearchInput("");
    setSearchKeyword("");
  };

  const searchInputHandler = () => {
    setSearchKeyword(searchInput);
  };

  const filterActionHandler = () => {
    setIsModalOpen(true);
    setActiveModalNotice("filter");
  };

  useEffect(() => {
    if (reassignStatus.isRequestCompleted) {
      if (reassignStatus.isSuccess) {
        setIsErr(false);
        setIsTwoBtn(false);
      } else {
        setIsErr(true);
        setIsTwoBtn(false);
      }
      setActiveModalNotice("message");
      setModalMessage(reassignStatus.message);
      setIsModalOpen(true);
      dispatch(reassignAndTerminateReassignTaskReset());
    } else if (terminateStatus.isRequestCompleted) {
      if (terminateStatus.isSuccess) {
        setIsErr(false);
        setIsTwoBtn(false);
      } else {
        setIsErr(true);
        setIsTwoBtn(false);
      }
      setActiveModalNotice("message");
      setModalMessage(terminateStatus.message);
      setIsModalOpen(true);
      dispatch(reassignAndTerminateTerminateRequestReset());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reassignStatus.isRequestCompleted, terminateStatus.isRequestCompleted]);

  function renderModalNotice() {
    switch (activeModalNotice) {
      case "filter":
        return (
          <ReassignAndTerminateFilterModalNotice
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            filterOptions={filterOptions}
            setFilterOptions={setFilterOptions}
            filteredOptionsInitialState={filteredOptionsInitialState}
          />
        );
      case "delete":
        return (
          <ReassignAndTerminateTerminateModalNotice
            setIsModalOpen={setIsModalOpen}
          />
        );
      case "edit":
        return (
          <ReassignAndTerminateReassignApproverModalNotice
            setIsModalOpen={setIsModalOpen}
            setActiveModalNotice={setActiveModalNotice}
          />
        );
      case "message":
        return (
          <ReassignAndTerminateMessageModalNotice
            error={isErr}
            success={!isErr}
            message={modalMessage}
            twoButton={isTwoBtn}
            setIsModalOpen={setIsModalOpen}
            setActiveModalNotice={setActiveModalNotice}
          />
        );
    }
  }

  return (
    <>
      <ReassignAndTerminateModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      >
        {renderModalNotice()}
      </ReassignAndTerminateModal>
      <div className="reassignAndTerminate_container">
        <div className="reassignAndTerminate_searchFilterDownload">
          <div className="reassignAndTerminateSearchUser_textFieldWithButton">
            <TextField
              id="reassignAndTerminate-search-user-input"
              size="small"
              variant="outlined"
              className="reassignAndTerminateSearchUser_textField"
              focused={false}
              placeholder="Search keyword"
              value={searchInput}
              onChange={(event) => {
                setSearchInput(event.target.value);
                if (event.target.value.trim() === "") {
                  clearSearchBoxHandler();
                }
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  searchInputHandler();
                }
              }}
              InputProps={{
                sx: { borderTopRightRadius: 0, borderBottomRightRadius: 0 },
                endAdornment: (
                  <React.Fragment>
                    {searchInput.length ? (
                      <IconButton
                        edge="end"
                        size="medium"
                        className="reassignAndTerminateSearchUser_crossIcon"
                        onClick={clearSearchBoxHandler}
                      >
                        <ClearIcon />
                      </IconButton>
                    ) : null}
                  </React.Fragment>
                )
              }}
            />
            <Button
              variant="contained"
              className={"reassignAndTerminateSearchUser_searchButton"}
              onClick={searchInputHandler}
            >
              <SearchOutlinedIcon size="medium" className="searchIcon" />
            </Button>
          </div>
          <div className="reassignAndTerminate_FilterDownload">
            <Button
              variant="contained"
              className="btn btn--filter"
              onClick={filterActionHandler}
            >
              <FilterAltOutlinedIcon className="filterIcon" />
            </Button>
            <CSVLink data={allRequest} filename="reassign_and_terminate.csv">
              <Button variant="contained" className="btn btn--download">
                <FileDownloadOutlinedIcon className="donwloadIcon" />
                <span>.csv</span>
              </Button>
            </CSVLink>
          </div>
        </div>
        <div className="reassignAndTerminate_tableContainer">
          <ReassignAndTerminateTable
            searchKeyword={searchKeyword}
            setSearchKeyword={setSearchKeyword}
            filterOptions={filterOptions}
            setIsModalOpen={setIsModalOpen}
            setActiveModalNotice={setActiveModalNotice}
          />
        </div>
      </div>
    </>
  );
};

export default ReassignAndTerminate;
