import { React, useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import "../styles/AdminDashboardTableBox.css";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";

import AdminMainArticleLoader from "../../AdminMainArticleLoader/AdminMainArticleLoader";
// import { Pagination } from "@mui/material";
// import { setCurpage } from "../../../../../../redux/actions/adminAccount-actions/adminDashboard-action";
// import { Pagination } from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#f9ccb5",
    color: theme.palette.common.black,
    fontWeight: 600,
    fontSize: 12,
    borderRight: "1px solid #80808040",
    borderBottom: "0px",
    padding: 3
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    color: "black",
    fontWeight: 400,
    borderRight: "1px solid #80808040",
    borderBottom: "0px",
    padding: 3
  }
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "rgb(0 255 205 / 4%)"
  }
  // hide last border
  // "&:last-child td, &:last-child th": {
  //   border: 0
  // }
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "requestID",
    label: "Request ID"
  },
  {
    id: "userName",
    label: "User Name"
  },
  {
    id: "fullName",
    label: "Full Name"
  },
  {
    id: "globalID",
    label: "Global ID"
  },
  {
    id: "userType",
    label: "User Type"
  },
  {
    id: "requestType",
    label: "Request Type"
  },
  {
    id: "currentStatus",
    label: "Status"
  },
  {
    id: "affiliate",
    label: "Affiliate"
  },
  {
    id: "lastActionDate",
    label: "Last Action Date"
  }
];
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <StyledTableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            align="center"
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
        <StyledTableCell align="center">View</StyledTableCell>
      </StyledTableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired
};
//   return {
//     requestID,
//     userName,
//     fullName,
//     globalID,
//     userType,
//     requestType,
//     currentStatus,
//     affiliate,
//     lastActionDate,
//     view
//   };
// }
// let rows = [
//   createData('VAM0390', 'vevvatest279@llyqa.com', 'Jayant Kumar', 2230751, 'Lilly', 'Modify Account', 'Terminated', 'US', '11/23/2022 15:06', <RemoveRedEyeOutlinedIcon />),
//   createData('VAM0391', 'vevvatest279@llyqa.com', 'Aman Kumar', 2230751, 'Lilly', 'Modify Account', 'Terminated', 'US', '11/23/2022 15:06', <RemoveRedEyeOutlinedIcon />),
//   createData('VAM0392', 'vevvatest279@llyqa.com', 'Amit Kumar', 2230751, 'Lilly', 'Modify Account', 'Terminated', 'US', '11/23/2022 15:06', <RemoveRedEyeOutlinedIcon />),
//   createData('VAM0393', 'vevvatest279@llyqa.com', 'Saurabh Kumar', 2230751, 'Lilly', 'Modify Account', 'Terminated', 'US', '11/23/2022 15:06', <RemoveRedEyeOutlinedIcon />),
//   createData('VAM0394', 'vevvatest279@llyqa.com', 'Sumit Kumar', 2230751, 'Lilly', 'Modify Account', 'Terminated', 'US', '11/23/2022 15:06', <RemoveRedEyeOutlinedIcon />),
//   createData('VAM0395', 'vevvatest279@llyqa.com', 'Suman Kumar', 2230751, 'Lilly', 'Modify Account', 'Terminated', 'US', '11/23/2022 15:06', <RemoveRedEyeOutlinedIcon />),
//   createData('VAM0396', 'vevvatest279@llyqa.com', 'Sachin Kumar', 2230751, 'Lilly', 'Modify Account', 'Terminated', 'US', '11/23/2022 15:06', <RemoveRedEyeOutlinedIcon />),
//   createData('VAM0397', 'vevvatest279@llyqa.com', 'Dhundhun Kumar', 2230751, 'Lilly', 'Modify Account', 'Terminated', 'US', '11/23/2022 15:06', <RemoveRedEyeOutlinedIcon />),
//   createData('VAM0398', 'vevvatest279@llyqa.com', 'Piyush Kumar', 2230751, 'Lilly', 'Modify Account', 'Terminated', 'US', '11/23/2022 15:06', <RemoveRedEyeOutlinedIcon />),
//   createData('VAM0399', 'vevvatest279@llyqa.com', 'Jayant Kumar', 2230751, 'Lilly', 'Modify Account', 'Terminated', 'US', '11/23/2022 15:06', <RemoveRedEyeOutlinedIcon />),
//   createData('VAM0400', 'vevvatest279@llyqa.com', 'Jayant Kumar', 2230751, 'Lilly', 'Modify Account', 'Terminated', 'US', '11/23/2022 15:06', <RemoveRedEyeOutlinedIcon />),
//   createData('VAM0401', 'vevvatest279@llyqa.com', 'Jayant Kumar', 2230751, 'Lilly', 'Modify Account', 'Terminated', 'US', '11/23/2022 15:06', <RemoveRedEyeOutlinedIcon />),
//   createData('VAM0402', 'vevvatest279@llyqa.com', 'Jayant Kumar', 2230751, 'Lilly', 'Modify Account', 'Terminated', 'US', '11/23/2022 15:06', <RemoveRedEyeOutlinedIcon />),
//   createData('VAM0403', 'vevvatest279@llyqa.com', 'Jayant Kumar', 2230751, 'Lilly', 'Modify Account', 'Terminated', 'US', '11/23/2022 15:06', <RemoveRedEyeOutlinedIcon />),
//   createData('VAM0404', 'vevvatest279@llyqa.com', 'Jayant Kumar', 2230751, 'Lilly', 'Modify Account', 'Terminated', 'US', '11/23/2022 15:06', <RemoveRedEyeOutlinedIcon />),
//   createData('VAM0405', 'vevvatest279@llyqa.com', 'Jayant Kumar', 2230751, 'Lilly', 'Modify Account', 'Terminated', 'US', '11/23/2022 15:06', <RemoveRedEyeOutlinedIcon />)
// ];

// const getRequestType = (requestType) => {
//   switch(requestType){
//     case 'create':
//       return 'Create Account';
//     case 'modify':
//       return 'Modify Account';
//     case 'reactivate':
//       return 'Reactivate Account';
//     case 'deactivate':
//       return 'Deactivate Account';
//   }
// };

function AdminDashboardTableBox({
  hadelToggleIsViewAdminDashboardActive,
  setViewAdminRequestId
}) {
  // const [rows, setRows] = useState([]);

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const { allRequest, adminTableLoader, curPage, pageSize, requestsCount } =
    useSelector((state) => ({
      allRequest: state.AdminDashboardReducer.requests,
      adminTableLoader: state.AdminDashboardReducer.adminTableLoader,
      curPage: state.AdminDashboardReducer.curPage,
      requestsCount: state.AdminDashboardReducer.requestsCount,
      pageSize: state.AdminDashboardReducer.pageSize
    }));

  const [filterData, setFilterData] = useState([]);
  console.log(setFilterData);
  // all useEffects
  useEffect(() => {
    setFilterData(
      allRequest.map((ele) => ({ ...ele, view: <RemoveRedEyeOutlinedIcon /> }))
    );
  }, [allRequest]);
  // useEffect(() => {
  //   let tempRows = [];
  //   if (allRequest?.length > 0) {
  //     allRequest?.forEach((element) => {
  //       let object = element;
  //       object.view = <RemoveRedEyeOutlinedIcon />;
  //       tempRows.push(object);
  //     });
  //   }
  //   setRows(tempRows);
  // }, [allRequest]);

  // useEffect(() => {
  // let tempFilterData = [...rows];
  // let isFilterApplied = false;

  // apply filter on the basis of filter
  // if (filterQuery.length > 0) {
  //   filterQuery.forEach((filterQueryObj) => {
  //     let tempFilteredDataIndex = new Set();
  //     isFilterApplied = false;
  //     filterQueryObj.value.forEach((element) => {
  //       if (element.checked) {
  //         isFilterApplied = true;
  //         switch (filterQueryObj.abbrebiation) {
  //           case "affiliateType":
  //             tempFilterData.map((object, key) => {
  //               if (toLower(element.name) === toLower(object.affiliate))
  //                 tempFilteredDataIndex.add(key);
  //             });
  //             break;
  //           case "userType":
  //             tempFilterData.map((object, key) => {
  //               if (toLower(element.name) === toLower(object.userType))
  //                 tempFilteredDataIndex.add(key);
  //             });
  //             break;
  //           case "requestType":
  //             tempFilterData.map((object, key) => {
  //               if (toLower(element.name) === toLower(object.requestType))
  //                 tempFilteredDataIndex.add(key);
  //             });
  //             break;
  //           case "currentStatus":
  //             tempFilterData.map((object, key) => {
  //               if (toLower(element.name) === toLower(object.currentStatus))
  //                 tempFilteredDataIndex.add(key);
  //             });
  //             break;
  //         }
  //       }
  //     });
  //     if (isFilterApplied) {
  //       tempFilterData = tempFilterData.filter((object, objectKey) => {
  //         return object && tempFilteredDataIndex.has(objectKey);
  //       });
  //     }
  //   });
  // }

  // apply filter on the basis of search text
  // if (searchText.length > 0) {
  //   tempFilterData = tempFilterData.filter((obj) => {
  //     return (
  //       toLower(obj.fullName).includes(toLower(searchText)) ||
  //       toLower(obj.requestID).includes(toLower(searchText)) ||
  //       toLower(obj.userName).includes(toLower(searchText)) ||
  //       toLower(obj.globalID).includes(toLower(searchText)) ||
  //       toLower(obj.userType).includes(toLower(searchText)) ||
  //       toLower(obj.requestType).includes(toLower(searchText)) ||
  //       toLower(obj.currentStatus).includes(toLower(searchText)) ||
  //       toLower(obj.affiliate).includes(toLower(searchText))
  //     );
  //   });
  // }
  // update tempFilterData
  // setFilterData(tempFilterData);
  // }, [rows, searchText, filterQuery]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const visibleRows = useMemo(
    () => stableSort(filterData, getComparator(order, orderBy)),
    [order, orderBy, filterData]
  );
  if (adminTableLoader) {
    return (
      <div className="adminDashboardTableBox">
        <TableContainer component={Paper} sx={{ maxHeight: "92%" }}>
          <Table
            sx={{ minWidth: 700 }}
            aria-label="customized table"
            stickyHeader
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={filterData?.length}
            />
            <TableBody>
              <StyledTableRow key={"tets"}>
                <StyledTableCell colSpan={10}>
                  <div className="adminTableLoader">
                    <AdminMainArticleLoader />
                  </div>
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
  return (
    <div className="adminDashboardTableBox" style={{ width: "100%" }}>
      <TableContainer
        component={Paper}
        sx={{
          maxHeight: "92%",
          scrollbarWidth: "12px",
          "&::-webkit-scrollbar": {
            width: "12px",
            borderRadius: "10px",
            height: "12px"
          },
          "&::-webkit-scrollbar-track": { borderRadius: "5px" },
          "&::-webkit-scrollbar-thumb": {
            borderRadius: "5px"
            // backgroundColor: "#848482"
          }
        }}
      >
        <Table
          sx={{ minWidth: 700 }}
          aria-label="customized table"
          stickyHeader
        >
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={filterData?.length}
          />
          <TableBody>
            {visibleRows?.length === 0 ? (
              <StyledTableRow key={"test"}>
                <StyledTableCell
                  component="th"
                  scope="row"
                  align="center"
                  colSpan={10}
                >
                  <div>No request found</div>
                </StyledTableCell>
              </StyledTableRow>
            ) : null}
            {visibleRows.map((row) => (
              <StyledTableRow key={row.requestID}>
                <StyledTableCell component="th" scope="row" align="center">
                  {row.requestID}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row.userName?.length > 28
                    ? row.userName?.slice(0, 26)
                    : row.userName}
                  {row.userName?.length > 28 ? (
                    <Tooltip title={row.userName} placement="right-start">
                      ...
                    </Tooltip>
                  ) : null}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row.fullName?.length > 14
                    ? row.fullName?.slice(0, 11)
                    : row.fullName}
                  {row.fullName?.length > 14 ? (
                    <Tooltip title={row.fullName} placement="right-start">
                      ...
                    </Tooltip>
                  ) : null}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row.globalID?.length > 13
                    ? row.globalID?.slice(0, 10)
                    : row.globalID}
                  {row.globalID?.length > 13 ? (
                    <Tooltip title={row.globalID} placement="right-start">
                      ...
                    </Tooltip>
                  ) : null}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row.userType?.length > 10
                    ? row.userType?.slice(0, 7)
                    : row.userType}
                  {row.userType?.length > 10 ? (
                    <Tooltip title={row.userType} placement="right-start">
                      ...
                    </Tooltip>
                  ) : null}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row.requestType?.length > 14
                    ? row.requestType?.slice(0, 14)
                    : row.requestType}
                  {row.requestType?.length > 14 ? (
                    <Tooltip title={row.requestType} placement="right-start">
                      ...
                    </Tooltip>
                  ) : null}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row.currentStatus?.length > 10
                    ? row.currentStatus?.slice(0, 7)
                    : row.currentStatus}
                  {row.currentStatus?.length > 10 ? (
                    <Tooltip title={row.currentStatus} placement="right-start">
                      ...
                    </Tooltip>
                  ) : null}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row.affiliate?.length > 6
                    ? row.affiliate?.slice(0, 3)
                    : row.affiliate}
                  {row.affiliate?.length > 6 ? (
                    <Tooltip title={row.affiliate} placement="right-start">
                      ...
                    </Tooltip>
                  ) : null}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row.lastActionDate?.length > 16
                    ? row.lastActionDate?.slice(0, 16)
                    : row.lastActionDate}
                  {row.lastActionDate?.length > 28 ? (
                    <Tooltip title={row.lastActionDate} placement="left-end">
                      ...
                    </Tooltip>
                  ) : null}
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  onClick={() => {
                    hadelToggleIsViewAdminDashboardActive();
                    setViewAdminRequestId(row.requestID);
                  }}
                  className="clickable"
                >
                  {row.view}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%"
        }}
      >
        {Math.ceil(requestsCount / pageSize) > 0 ? (
          <div className="dashBoardReqCounts">
            <p className="requestCountNumers">
              {requestsCount > 0 ? (curPage - 1) * pageSize + 1 : 0}
            </p>
            <p className="requestCountText">to</p>
            <p className="requestCountNumers">
              {curPage * pageSize < requestsCount
                ? curPage * pageSize
                : requestsCount}
            </p>
            <p className="requestCountText">of</p>
            <p className="requestCountNumers">{requestsCount}</p>
            <p className="requestCountText">Requests</p>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default AdminDashboardTableBox;
