import { reassignAndTerminateRequestTasks_actionType } from "../../../actionTypes/adminAction-actionTypes/reassignAndTerminate/reassignAndTermianteRequestTasks-actionType";

const initialState = {
    loading: false,
    data: [],
    error: undefined
};

const ReassignAndTerminateRequestTasksReducer = (state = initialState, action) =>{
    switch(action.type){
        case reassignAndTerminateRequestTasks_actionType.REASSIGN_AND_TERMINATE_REQUEST_TASKS:
            return{
                ...state,
                loading: true
            };
        case reassignAndTerminateRequestTasks_actionType.REASSIGN_AND_TERMINATE_REQUEST_TASKS_SUCCESS:
            return{
                ...state,
                loading: false,
                data: action.payload
            };
        case reassignAndTerminateRequestTasks_actionType.REASSIGN_AND_TERMINATE_REQUEST_TASKS_ERROR:
            return{
                ...state,
                loading: false,
                error: action.payload
            };
        default: 
            return state;
    }
};

export default ReassignAndTerminateRequestTasksReducer;