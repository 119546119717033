import React from "react";
import { Card, Grid } from "@mui/material";

const SelectedRolesCard = ({ workflow, countries, selectedRoles, cardHeight }) => {
  return (
    <Grid
      item
      sm={12}
      md={12}
      xs={12}
      style={{
        height: "50%"
      }}
      className="accountSummary_mainItemSection"
    >
      <Card
        className="accountSummary_rolesProductsParent"
        style={{ height: cardHeight || "122px" }}
      >
        <div className="accountSummary_header">
          <Grid container spacing={2}>
            <Grid item md={11} className="accountSummary_mainHeading">
              Selected Roles
            </Grid>
          </Grid>
        </div>
        <div className="accountSummary_section userDetailsSection" >
          <Grid container spacing={2} className="userDetailsContainer" >
            <Grid item md={2}><strong>WorkFlow</strong></Grid>
            <Grid item md={10}>{workflow} {countries?.length > 0 ? "| " : ""}{countries?.map(ctryObj => ctryObj?.country_name).join(", ")}</Grid>
            <Grid item md={2}><strong>Roles</strong></Grid>
            <Grid item md={10}> {selectedRoles?.map((x) => x.name).join(", ")}</Grid>
          </Grid>
        </div>
      </Card>
    </Grid >
  );
};

export default SelectedRolesCard;
