import { test_actionType } from '../actionTypes/test-actionType';

const initialState = {
  testData : "Initial Data"
};

const TestReducer = (state = initialState, action) => {
  switch (action.type) {
    case test_actionType.GET_DATA_SUCCESS:
      return {
        ...state,
        testData: action.data
      };
    case test_actionType.GET_DATA_FAILURE:
      return {
        ...state,
        error: action.data
      };
    default:
      return state;
  }
};

export default TestReducer;
