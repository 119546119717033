import { combineReducers } from 'redux';
import RolesListReducer from './rolesRequestList-reducer';
import VAM_DAC_ListReducer from './vamDACRequestList-reducer';
import RolesRequestReducer from "./rolesRequest-reducer";
import GroupsRequestListReducer from './groupsRequestList-reducer';
import TrainingsRequestListReducer from './trainingsRequestList-reducer';

const RolesReducer  = combineReducers({
    RolesListReducer,
    VAM_DAC_ListReducer,
    GroupsRequestListReducer,
    RolesRequestReducer,
    TrainingsRequestListReducer
});

export default RolesReducer;