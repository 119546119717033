// import { userRole_actionTypes } from "../actionTypes/checkUserRole-actionTypes";
import currentEnvVars from "../../lib/utils/getEnvVars";
import { axiosMsGraphBeta } from "../../api";
import { checkUserRole_actionTypes } from "../actionTypes/checkUserRole-actionTypes";

export function checkUserRole(){
    return{
        type: checkUserRole_actionTypes.CHECK_USER_ROLE
    };
}

export function checkUserRoleSuccess(data){
    return{
        type: checkUserRole_actionTypes.CHECK_USER_ROLE_SUCCESS,
        payload: data
    };
}

export function checkUserRoleError(data){
    return{
        type: checkUserRole_actionTypes.CHECK_USER_ROLE_ERROR,
        payload: data
    };
}

const roleObj = {
    SUPER_ADMIN: "superAdmin",
    ADMIN: "admin",
    GENERAL: "general"
};

function getUserRole(data, currentEnvVars){
    let userRole = "";
    const groupIdMapToGrp = currentEnvVars.groupIds;
    const grpIdVal = groupIdMapToGrp.flatMap(grp => Object.values(grp));
    const grpIdMapToName = groupIdMapToGrp.flatMap(grp => Object.entries(grp));
    if(data.length === 2 && data.every(dataObj => grpIdVal.includes(dataObj))){
        userRole = roleObj.SUPER_ADMIN;
    }else if(data.length === 1){
        data.forEach(dataObj => {
            if(grpIdVal.includes(dataObj)){
                grpIdMapToName.forEach(grpIdNameObj =>{
                    if(grpIdNameObj[1] === dataObj){
                        const key = grpIdNameObj[0];
                        if(key === "admin"){
                            userRole = roleObj.SUPER_ADMIN;
                        }else if(key === "adminRO"){
                            userRole = roleObj.ADMIN;
                        }
                    }
                });
            }
        });
    }else{
        userRole = roleObj.GENERAL;
    }
    
    return userRole;
}

export function checkUserRoleCall(data){
    return async(dispatch)=>{
        dispatch(checkUserRole());
        try{
            const response = await axiosMsGraphBeta.post("/me/checkMemberGroups",data);
            const userRole = getUserRole(response.data.value, currentEnvVars);
            dispatch(checkUserRoleSuccess(userRole));
        }catch(error){
            dispatch(checkUserRoleError(error));
        }
    };
}