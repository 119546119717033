import { rolesRequest_actionType } from "../../../actionTypes/adminAction-actionTypes/role-actionTypes/rolesRequest-actionType";

const initialState = {
    loading: false,
    isSuccess: false,
    isRequestCompleted: false,
    message: []
};

const RolesRequestReducer = (state = initialState, action) =>{
    switch(action.type){
        case rolesRequest_actionType.ROLES_REQUEST_START:
            return{
                ...state,
                loading: true
            };
        case rolesRequest_actionType.ROLES_REQUEST_SUCCESS:
            return{
                ...state,
                loading: false,
                isRequestCompleted: true,
                isSuccess:true,
                message: [action.payload]
            };
        case rolesRequest_actionType.ROLES_REQUEST_RESET:
            return{
                ...initialState
            };
        case rolesRequest_actionType.ROLES_REQUEST_ERROR:
            return{
                ...state,
                loading: false,
                isRequestCompleted:true,
                isSuccess: false,
                message: [action.payload]
            };
        default: 
            return state;
    }
};

export default RolesRequestReducer;