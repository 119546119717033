import React, { useEffect, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import "./styles/Roles.css";
import { Button, IconButton, TextField } from "@mui/material";
import { CSVLink } from "react-csv";
import RolesTable from "./RolesTable";
import { useDispatch, useSelector } from "react-redux";
import { rolesRequestListCall } from "../../../../../../redux/actions/adminAccount-actions/roles-action/rolesRequestList-action";
import RolesModal from "./Modals/RolesModal";
import RolesFilterModalNotice from "./Modals/RolesFilterModalNotice";
import AddUpdateRoleModalNotice from "./Modals/AddUpdateRoleModalNotice";
import { groupsRequestListCall } from "../../../../../../redux/actions/adminAccount-actions/roles-action/groupsRequestList-action";
import { trainingsRequestListCall } from "../../../../../../redux/actions/adminAccount-actions/roles-action/trainingsRequestList-action";
import { vamDACRequestListCall } from "../../../../../../redux/actions/adminAccount-actions/roles-action/vamDACRequestList-action";
import MessageModalNotice from "./Modals/MessageModalNotice";
import {
  deleteRoleRequestCall,
  rolesRequestReset
} from "../../../../../../redux/actions/adminAccount-actions/roles-action/rolesRequest-action";
import CircularProgressLoader from "../../../../../../components/common/CircularProgressLoader";
import { getAllAffilateData } from "../../../../../../redux/actions/adminAccount-actions/affiliate-action";

// const filteredOptionsInitialState = [
//   {
//     name: "Role Audience",
//     accessor: "roleAudience",
//     values: [
//       { name: "Lilly", value: "Lilly", selected: false },
//       { name: "Non-Lilly", value: "Non-Lilly", selected: false },
//       { name: "All", value: "All", selected: false }
//     ]
//   },
//   {
//     name: "License",
//     accessor: "license",
//     values: [
//       { name: "full__v", value: "full__v", selected: false },
//       { name: "external__v", value: "external__v", selected: false },
//       { name: "read_only__v", value: "read_only__v", selected: false }
//     ]
//   },
//   {
//     name: "Security Profile",
//     accessor: "securityProfile",
//     values: [
//       { name: "oversight_users_with_bulk_update__c", value: "oversight_users_with_bulk_update__c", selected: false },
//       { name: "clm_gatekeepers__c", value: "clm_gatekeepers__c", selected: false },
//       { name: "approved_email_users__c", value: "approved_email_users__c", selected: false },
//       { name: "gccp_users__c", value: "gccp_users__c", selected: false },
//       { name: "document_user__v", value: "document_user__v", selected: false }
//     ]
//   },
//   {
//     name: "Affiliate",
//     accessor: "vamHubAbbreviation",
//     values: [
//       { name: "US", value: "US", selected: false },
//       { name: "Global", value: "Global", selected: false },
//       { name: "UK Hub", value: "Uk Hub", selected: false }
//     ]
//   }
// ];

const Roles = () => {
  const dispatch = useDispatch();
  const {
    allRoles: { loading, allRoles, filteredOptionsInitialState, csvData },
    allAffiliteData,
    allGroups,
    allTrainings,
    allDAC,
    roleRequest: { isSuccess, isRequestCompleted, message }
  } = useSelector((state) => {
    return {
      allRoles: {
        loading: state.RolesReducer.RolesListReducer.loading,
        allRoles: state.RolesReducer.RolesListReducer.data,
        filteredOptionsInitialState:
          state.RolesReducer.RolesListReducer.filterOptions,
        csvData: state.RolesReducer.RolesListReducer.csvData
      },
      allGroups: state.RolesReducer.GroupsRequestListReducer.data,
      allTrainings: state.RolesReducer.TrainingsRequestListReducer.data,
      allDAC: state.RolesReducer.VAM_DAC_ListReducer.data,
      allAffiliteData: state.AdminAffiliateReducer.data,
      roleRequest: {
        isSuccess: state.RolesReducer.RolesRequestReducer.isSuccess,
        isRequestCompleted:
          state.RolesReducer.RolesRequestReducer.isRequestCompleted,
        message: state.RolesReducer.RolesRequestReducer.message
      }
    };
  });
  const [searchInput, setSearchInput] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const [modalMessage, setModalMessage] = useState([]);
  const [twoButton, setTwoButton] = useState(false);
  const [mode, setMode] = useState("");
  const [activeModalNotice, setActiveModalNotice] = useState("");
  const [filterOptions, setFilterOptions] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");

  useEffect(() => {
    dispatch(rolesRequestListCall());
    dispatch(groupsRequestListCall());
    dispatch(trainingsRequestListCall());
    dispatch(vamDACRequestListCall());
    dispatch(getAllAffilateData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFilterOptions(filteredOptionsInitialState);
  }, [filteredOptionsInitialState]);

  const clearSearchBoxHandler = () => {
    setSearchInput("");
    setSearchKeyword("");
  };

  const searchInputHandler = () => {
    setSearchKeyword(searchInput);
  };

  const filterActionHandler = () => {
    setIsModalOpen(true);
    setActiveModalNotice("filter");
  };

  const addRolesActionHandler = () => {
    setActiveModalNotice("addUpdate");
    setMode("create");
    setIsModalOpen(true);
  };

  const buttonOneClick = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (isRequestCompleted) {
      switch (mode) {
        case "create":
          if (isSuccess) {
            setIsError(false);
            setModalMessage([
              "Role successfully created.",
              "Please create modify request to",
              " attach this role with Groups, Trainings, DAC Role."
            ]);
          } else {
            setIsError(true);
            setModalMessage(message);
          }
          setTwoButton(false);
          setIsModalOpen(true);
          break;
        case "delete":
          if (isSuccess) {
            setIsError(false);
            setModalMessage(["Role successfully deleted."]);
          } else {
            setIsError(true);
            setModalMessage(message);
          }
          setTwoButton(false);
          setIsModalOpen(true);
          break;
        case "edit":
          if (isSuccess) {
            setIsError(false);
            setModalMessage(["Role successfully modified."]);
          } else {
            setIsError(true);
            setModalMessage(message);
          }
          setTwoButton(false);
          setIsModalOpen(true);
          break;
        default:
          setIsError(false);
          setModalMessage([]);
          setTwoButton(false);
          setIsModalOpen(false);
      }
      dispatch(rolesRequestReset());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRequestCompleted]);

  const deleteRoleHandler = () => {
    dispatch(deleteRoleRequestCall(selectedRole.vamRoleId));
  };

  const buttonTwoClick = () => {
    if (mode === "delete") {
      deleteRoleHandler();
    }
    setIsModalOpen(false);
  };

  function renderModalNotice() {
    switch (activeModalNotice) {
      case "filter":
        return (
          <RolesFilterModalNotice
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            filterOptions={filterOptions}
            setFilterOptions={setFilterOptions}
            filteredOptionsInitialState={filteredOptionsInitialState}
          />
        );
      case "addUpdate":
        return (
          <AddUpdateRoleModalNotice
            isModalOpen={isModalOpen}
            selectedRole={selectedRole}
            allRoles={allRoles}
            allGroups={allGroups}
            allTrainings={allTrainings}
            allAffiliteData={allAffiliteData}
            allDAC={allDAC}
            setActiveModalNotice={setActiveModalNotice}
            setIsModalOpen={setIsModalOpen}
            mode={mode}
            setMode={setMode}
          />
        );
      case "message":
        return (
          <MessageModalNotice
            error={isError}
            success={!isError}
            twoButton={twoButton}
            setIsModalOpen={setIsModalOpen}
            message={modalMessage}
            buttonOneClick={buttonOneClick}
            buttonTwoClick={buttonTwoClick}
          />
        );
    }
  }

  if (loading) {
    return <CircularProgressLoader />;
  }

  return (
    <>
      <RolesModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
        {renderModalNotice()}
      </RolesModal>
      <div className="roles_container">
        <div className="roles_addRolesCardHeader">
          <span>Add roles</span>
          <Button
            variant="contained"
            className="roles_addBtn"
            onClick={addRolesActionHandler}
          >
            Add
          </Button>
        </div>
        <div className="roles_searchFilterDownload">
          <div className="rolesSearchUser_textFieldWithButton">
            <TextField
              id="role-search-user-input"
              size="small"
              variant="outlined"
              className="rolesSearchUser_textField"
              focused={false}
              placeholder="Search keyword"
              value={searchInput}
              onChange={(event) => {
                setSearchInput(event.target.value);
                if (event.target.value.trim() === "") {
                  clearSearchBoxHandler();
                }
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  searchInputHandler();
                }
              }}
              InputProps={{
                sx: { borderTopRightRadius: 0, borderBottomRightRadius: 0 },
                endAdornment: (
                  <React.Fragment>
                    {searchInput.length ? (
                      <IconButton
                        edge="end"
                        size="medium"
                        className="roleSearchUser_crossIcon"
                        onClick={clearSearchBoxHandler}
                      >
                        <ClearIcon />
                      </IconButton>
                    ) : null}
                  </React.Fragment>
                )
              }}
            />
            <Button
              variant="contained"
              className={"roleSearchUser_searchButton"}
              onClick={searchInputHandler}
            >
              <SearchOutlinedIcon size="medium" className="searchIcon" />
            </Button>
          </div>
          <div className="roles_FilterDownload">
            <Button
              variant="contained"
              className="btn btn--filter"
              onClick={filterActionHandler}
            >
              <FilterAltOutlinedIcon className="filterIcon" />
            </Button>
            <CSVLink
              data={csvData.data ?? []}
              headers={csvData.headers ?? []}
              filename="roles.csv"
            >
              <Button variant="contained" className="btn btn--download">
                <FileDownloadOutlinedIcon className="donwloadIcon" />
                <span>.csv</span>
              </Button>
            </CSVLink>
          </div>
        </div>
        <div className="roles_existingRolesCardHeader">
          <span>Existing Roles</span>
        </div>
        <div className="roles_tableContainer">
          <RolesTable
            searchKeyword={searchKeyword}
            setSearchKeyword={setSearchKeyword}
            filterOptions={filterOptions}
            setIsModalOpen={setIsModalOpen}
            setSelectedRole={setSelectedRole}
            setMode={setMode}
            setIsError={setIsError}
            setTwoButton={setTwoButton}
            setModalMessage={setModalMessage}
            setActiveModalNotice={setActiveModalNotice}
          />
        </div>
      </div>
    </>
  );
};

export default Roles;
