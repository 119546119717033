import { axiosMsGraph } from "../../../../api";
import { reassignAndTerminateSearchApprover_actionType } from "../../../actionTypes/adminAction-actionTypes/reassignAndTerminate/reassignAndTerminateSearchApprover-actionType";

async function getAllUsersInGraph(data, requestedFor) {
  const toSelect = [
    "id",
    "displayName",
    "preferredLanguage",
    "country",
    "usageLocation",
    "officeLocation",
    "givenname",
    "surname",
    "jobtitle",
    "mail",
    "onPremisesSamAccountName",
    "onPremisesDomainName",
    "onPremisesImmutableId",
    "onPremisesLastSyncDateTime",
    "onPremisesUserPrincipalName",
    "employeeId"
  ].join(",");
  const orderBy = ["displayName", "mail"].join(",");
  // const queryString = `$filter=not(mail eq '${requestedFor}' OR mail eq '${requestedBy}') AND employeeId ne null&$search="displayName:${data}" OR "mail:${data}"&$select=${toSelect}&$orderby=${orderBy}&$count=true`;
  const queryString = `$filter=accountEnabled eq true AND not(mail eq '${requestedFor}') AND employeeId ne null&$search="displayName:${data}" OR "mail:${data}"&$select=${toSelect}&$orderby=${orderBy}&$count=true`;
  const finalURL = `/users?${queryString}`;
  return axiosMsGraph.get(finalURL);
}

export function searchApproverInitialize() {
  return {
    type: reassignAndTerminateSearchApprover_actionType.REASSIGN_AND_TERMINATE_SEARCH_APPROVER
  };
}

export function searchApproverSuccess(data) {
  return {
    type: reassignAndTerminateSearchApprover_actionType.REASSIGN_AND_TERMINATE_SEARCH_APPROVER_SUCCESS,
    payload: data
  };
}

export function searchApproverError(error) {
  return {
    type: reassignAndTerminateSearchApprover_actionType.REASSIGN_AND_TERMINATE_SEARCH_APPROVER_ERROR,
    payload: error
  };
}

export function resetSearchApprover() {
  return {
    type: reassignAndTerminateSearchApprover_actionType.REASSIGN_AND_TERMINATE_SEARCH_APPROVER_RESET
  };
}

export function searchApproverResources(data, requestedFor) {
  return async (dispatch) => {
    dispatch(searchApproverInitialize());
    try {
      const response = await getAllUsersInGraph(data, requestedFor);
      dispatch(searchApproverSuccess(response?.data?.value));
    } catch (error) {
      dispatch(searchApproverError(error));
    }
  };
}
