import { searchUserResources_actionType } from "../../actionTypes/userAction-actionTypes/searchUserResources-actionType";


const initialState = {
  loading: false,
  data: [],
  error: undefined
};

const SearchUserResourcesReducer = (state = initialState, action) => {
  switch (action.type) {
    case searchUserResources_actionType.SEARCH_USER_RESOURCES:
      return {
        ...state,
        loading: true,
        error: undefined
      };
    case searchUserResources_actionType.SEARCH_USER_RESOURCES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload
      };
    case searchUserResources_actionType.RESET_SEARCH_USER_RESOURCES:
      return {
        ...initialState
      };
    case searchUserResources_actionType.SEARCH_USER_RESOURCES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default SearchUserResourcesReducer;
