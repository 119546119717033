import { axiosApigateway } from "../../../../api";
import { trainingsRequestList_actionType } from "../../../actionTypes/adminAction-actionTypes/role-actionTypes/trainingsRequest-actionType";

export function trainingsRequestList() {
    return {
        type: trainingsRequestList_actionType.TRAININGS_REQUEST_START
    };
}

export function trainingsRequestListSuccess(data) {
    return {
        type: trainingsRequestList_actionType.TRAININGS_REQUEST_SUCCESS,
        payload: data
    };
}

export function trainingsRequestListError(error) {
    return {
        type: trainingsRequestList_actionType.TRAININGS_REQUEST_ERROR,
        payload: error
    };
}

function getTrainingsRequestList(data) {
    let dataList = [];
    data.forEach((dataObject) => {
        const formattedDataObject = {
            id: dataObject?.id ?? "",
            isCurriculam: dataObject?.isCurriculam,
            trainingName: dataObject?.trainingName ?? "",
            isActive: dataObject?.isActive
        };
        dataList.push(formattedDataObject);
    });
    return dataList;
}

export function trainingsRequestListCall() {
    return async (dispatch) => {
        dispatch(trainingsRequestList());
        try {
            const response = await axiosApigateway.get("/vam-trainings");
            if (response) {
                const responseData = response.data.data;
                const data = getTrainingsRequestList(responseData);
                dispatch(trainingsRequestListSuccess(data));
            }
        } catch (error) {
            dispatch(trainingsRequestListError(error));
        }
    };
}
