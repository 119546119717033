import { React } from "react";
import { useSelector } from "react-redux";
import "../styles/AdminDashboardTable.css";

import AdminDashboardTableRecord from "./AdminDashboardTableRecord";
import AdminDashboardTableSearch from "./AdminDashboardTableSearch";
import AdminDashboardTableBox from "./AdminDashboardTableBox";
import AdminMainArticleLoader from "../../AdminMainArticleLoader/AdminMainArticleLoader";

function AdminDashboardTable({
  hadelToggleIsViewAdminDashboardActive,
  setViewAdminRequestId
}) {
  const { isAdminLoaderActive } = useSelector((state) => ({
    isAdminLoaderActive: state.LoaderReducer.adminDashboardLoader
  }));

  if (isAdminLoaderActive) return <AdminMainArticleLoader />;
  return (
    <div className="adminDashboardTableContainer">
      <AdminDashboardTableRecord />
      <AdminDashboardTableSearch />
      <AdminDashboardTableBox
        hadelToggleIsViewAdminDashboardActive={
          hadelToggleIsViewAdminDashboardActive
        }
        setViewAdminRequestId={setViewAdminRequestId}
      />
    </div>
  );
}

export default AdminDashboardTable;
