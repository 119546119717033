import { resetAffiliateCountriesReducer } from "./affiliateCountries-action";
import { resetAffiliateResources } from "./affiliateResources-action";
import { resetProductReducer } from "./product-action";
import { resetSelectAffiliateReducer } from "./selectAffiliate-action";
import { resetUserAccessResources } from "./userAccessResources-action";
import { resetUserDetailReducer } from "./userDetail-action";
import { resetWorkFlowRolesReducer } from "./workFlowRoles-action";
import { resetSummaryReducer } from "./summary-action";
import { resetCheckAccountStatus } from "./checkAccountStatusResources-action";

export function resetUserAccountReducer() {
  return (dispatch) => {
    dispatch(resetProductReducer());
    dispatch(resetUserDetailReducer());
    dispatch(resetSelectAffiliateReducer());
    dispatch(resetWorkFlowRolesReducer());
    dispatch(resetAffiliateCountriesReducer());
    dispatch(resetUserAccessResources());
    dispatch(resetAffiliateResources());
    dispatch(resetSummaryReducer());
    dispatch(resetCheckAccountStatus());
  };
}
