import React, { useEffect } from "react";
import ReassignAndTerminate from "./ReassignAndTerminate";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { reassignAndTerminateRequestListCall } from "../../../../../../redux/actions/adminAccount-actions/reassignAndTerminate/reassignAndTerminateRequestList-action";
import CircularProgressLoader from "../../../../../../components/common/CircularProgressLoader";

const ReassignAndTerminateLoader = () => {
  const dispatch = useDispatch();
  const { reassignAndTerminateRequestListProps } = useSelector((state) => {
    return {
      reassignAndTerminateRequestListProps:
        state.ReassignAndTerminateReducer
          .ReassignAndTerminateRequestListReducer
    };
  });

  useEffect(() => {
    dispatch(reassignAndTerminateRequestListCall());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (reassignAndTerminateRequestListProps.loading) {
    return <CircularProgressLoader />;
  }

  return (
    <>
      <ReassignAndTerminate />
    </>
  );
};

export default ReassignAndTerminateLoader;
