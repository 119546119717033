import { axiosApigateway } from "../../../../api";
import { vamDACRequestList_actionType } from "../../../actionTypes/adminAction-actionTypes/role-actionTypes/vamDACRequestList-actionType";

export function vamDACRequestList() {
  return {
    type: vamDACRequestList_actionType.VAM_DAC_REQUEST_LIST_START
  };
}

export function vamDACRequestListSuccess(data) {
  return {
    type: vamDACRequestList_actionType.VAM_DAC_REQUEST_LIST_SUCCESS,
    payload: data
  };
}

export function vamDACRequestListError(error) {
  return {
    type: vamDACRequestList_actionType.VAM_DAC_REQUEST_LIST_ERROR,
    payload: error
  };
}

// function getVVPM_ApplicationRole(dataObject) {
//   return {
//     application_role_id: dataObject?.application_role_id ?? "",
//     application_role_name: dataObject?.application_role_name ?? "",
//     application_role_active_flg: dataObject?.application_role_active_flg ?? ""
//   };
// }

// function getVVPM_DocumentTypeGroup(dataObject) {
//     return  {
//       doc_type_group_id: dataObject?.doc_type_group_id ?? "",
//       doc_type_group_name: dataObject?.doc_type_group_name ?? "",
//       doc_type_group_active_flg: dataObject?.doc_type_group_active_flg
//     };
//   }

function getvamDACRequestList(data) {
  let dataList = [];
  data.forEach((dataObject) => {
    const formattedDataObject = {
      idPk: dataObject?.id_pk ?? "",
      description: dataObject?.description ?? "",
      addOnlyOneCountry: dataObject?.vvpm_country?.country_name || dataObject?.addOnlyOneCountry || null,
      addCountries: dataObject?.addCountries,
      addProducts: dataObject?.addProducts,
      vvpmApplicationRoleApplicationRoleId: dataObject?.vvpmApplicationRoleApplicationRoleId,
      vvpmDocumentTypeGroupDocTypeGroupId: dataObject?.vvpmDocumentTypeGroupDocTypeGroupId,
      vvpmApplicationRoleName: dataObject?.vvpm_application_role?.application_role_name ?? "",
      vvpmApplicationDocTypeGroup: dataObject?.vvpm_document_type_group?.doc_type_group_name ?? ""
    };
    dataList.push(formattedDataObject);
  });
  return dataList;
}

export function vamDACRequestListCall() {
  return async (dispatch) => {
    dispatch(vamDACRequestList());
    try {
      const response = await axiosApigateway.get("/vam-dac-records");
      if (response) {
        const responseData = response.data.data;
        const data = getvamDACRequestList(responseData);
        dispatch(vamDACRequestListSuccess(data));
      }
    } catch (error) {
      dispatch(vamDACRequestListError(error));
    }
  };
}
