import { React, useEffect } from 'react';
import { adminPortalPageNavigation_actionType } from './utils/adminPortalPageNavigation_actionType';
import "./styles/NavigationBar.css";

function NavigationBar({
    pageNavigationList,
    setPageNavigationList,
    setActiveSection
}) {
    useEffect(() => {
        // default navigation active
        setPageNavigationList([
            adminPortalPageNavigation_actionType.ADMIN_DASHBOARD
        ]);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // all JS Function
    const handelNavigationClicked = (text) => {
        switch(text){
            case adminPortalPageNavigation_actionType.ADMIN_DASHBOARD:
                setActiveSection(adminPortalPageNavigation_actionType.ADMIN_DASHBOARD);
                setPageNavigationList([adminPortalPageNavigation_actionType.ADMIN_DASHBOARD]);
                break;
            case adminPortalPageNavigation_actionType.DATA_CONFIGURATION:
                setActiveSection(adminPortalPageNavigation_actionType.COMPANY);
                setPageNavigationList([
                    adminPortalPageNavigation_actionType.DATA_CONFIGURATION,
                    adminPortalPageNavigation_actionType.COMPANY
                ]);
                break;
            case adminPortalPageNavigation_actionType.COMPANY:
                setActiveSection(adminPortalPageNavigation_actionType.COMPANY);
                setPageNavigationList([
                    adminPortalPageNavigation_actionType.DATA_CONFIGURATION,
                    adminPortalPageNavigation_actionType.COMPANY
                ]);
                break;
            case adminPortalPageNavigation_actionType.AFFLIATE:
                setActiveSection(adminPortalPageNavigation_actionType.AFFLIATE);
                setPageNavigationList([
                    adminPortalPageNavigation_actionType.DATA_CONFIGURATION,
                    adminPortalPageNavigation_actionType.AFFLIATE
                ]);
                break;
            case adminPortalPageNavigation_actionType.GROUPS:
                setActiveSection(adminPortalPageNavigation_actionType.GROUPS);
                setPageNavigationList([
                    adminPortalPageNavigation_actionType.DATA_CONFIGURATION,
                    adminPortalPageNavigation_actionType.GROUPS
                ]);
                break;
            case adminPortalPageNavigation_actionType.ROLES:
                setActiveSection(adminPortalPageNavigation_actionType.ROLES);
                setPageNavigationList([
                    adminPortalPageNavigation_actionType.DATA_CONFIGURATION,
                    adminPortalPageNavigation_actionType.ROLES
                ]);
                break;
            case adminPortalPageNavigation_actionType.TRAINING:
                setActiveSection(adminPortalPageNavigation_actionType.TRAINING);
                setPageNavigationList([
                    adminPortalPageNavigation_actionType.DATA_CONFIGURATION,
                    adminPortalPageNavigation_actionType.TRAINING
                ]);
                break;
            case adminPortalPageNavigation_actionType.PRODUCTS:
                setActiveSection(adminPortalPageNavigation_actionType.PRODUCTS);
                setPageNavigationList([
                    adminPortalPageNavigation_actionType.DATA_CONFIGURATION,
                    adminPortalPageNavigation_actionType.PRODUCTS
                ]);
                break;
            case adminPortalPageNavigation_actionType.REASSIGN:
                setActiveSection(adminPortalPageNavigation_actionType.REASSIGN);
                setPageNavigationList([
                    adminPortalPageNavigation_actionType.DATA_CONFIGURATION,
                    adminPortalPageNavigation_actionType.REASSIGN
                ]);
                break;
            case adminPortalPageNavigation_actionType.BUSINESS_UNIT:
                setActiveSection(adminPortalPageNavigation_actionType.BUSINESS_UNIT);
                setPageNavigationList([
                    adminPortalPageNavigation_actionType.DATA_CONFIGURATION,
                    adminPortalPageNavigation_actionType.BUSINESS_UNIT
                ]);
                break;
        }
    };

  return (
    <div className="adminNavigationBar">
        {pageNavigationList.map(
            (pageNavigationObject, pageNavigationKey) => {
            return (
                <span key={pageNavigationKey}>
                <span
                    key={pageNavigationKey}
                    style={
                    pageNavigationKey == pageNavigationList.length - 1
                        ? {
                            color: "#a97007",
                            borderBottom: "1px solid #a97007",
                            fontWeight: "700"
                        }
                        : null
                    }
                    onClick={() =>
                        handelNavigationClicked(pageNavigationObject)
                    }
                    className='clickable'
                >
                    {pageNavigationObject}
                </span>
                <span>
                    {pageNavigationKey != pageNavigationList.length - 1
                    ? " > "
                    : null}
                </span>
                </span>
            );
            }
        )}
    </div>
  );
}

export default NavigationBar;
