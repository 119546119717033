import { summary_actionType } from '../../actionTypes/userAction-actionTypes/summary-actionType';

const initialState = {
  trainings: [],
  comments: '',
  isExistingDataSet: false,
  existingState: [],
  searchSponser:{
    data: [],
    loading: false,
    error: undefined
  },
  selectedSponsor:[]
};

const SummaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case summary_actionType.SET_TRAININGS_SUCCESS:
      return {
        ...state,
        trainings : action.data
      };
    case summary_actionType.GET_TRAININGS_SUCCESS:
      return {
        ...state,
        trainings : action.data
      };
    case summary_actionType.SET_COMMENTS_SUCCESS:
      return {
        ...state,
        comments : action.data
      };
    case summary_actionType.SET_EXISTINGSTATE_SUCCESS:
      return {
        ...state,
        existingState : JSON.stringify(action.data),
        isExistingDataSet: true
      };
    case summary_actionType.RESET_EXISTINGSTATE_SUCCESS:
      return {
        ...state,
        existingState : [],
        isExistingDataSet: false
      };
    case summary_actionType.RESET_SUMMARY_SUCCESS:
      return {
        ...initialState
      };
    case summary_actionType.SEARCH_SPONSER:
      return {
        ...state,
        searchSponser:{
          ...state.searchSponser,
          loading: true,
          error: undefined
        }
      };
    case summary_actionType.SEARCH_SPONSER_SUCCESS:
      return {
        ...state,
        searchSponser:{
          ...state.searchSponser,
          loading: false,
          data: action.payload
        }
      };
    case summary_actionType.SEARCH_SPONSER_ERROR:
      return{
        ...state,
        searchSponser:{
          ...state.searchSponser,
          loading: false,
          error: action.payload
        }
      };
    case summary_actionType.SET_SELECTED_SPONSOR:
      return{
        ...state,
        selectedSponsor: action.payload
      };
    default:
      return state;
  }
};

export default SummaryReducer;