export const loader_actionType = {
    SET_LOADER_ON : 'SET_LOADER_ON',
    SET_LOADER_OFF : 'SET_LOADER_OFF',
    SET_ADMIN_DASHBOARD_LOADER_ON: 'SET_ADMIN_DASHBOARD_LOADER_ON',
    SET_ADMIN_DASHBOARD_LOADER_OFF: 'SET_ADMIN_DASHBOARD_LOADER_OFF',
    SET_VIEW_ADMIN_DASHBOARD_LOADER_ON: 'SET_VIEW_ADMIN_DASHBOARD_LOADER_ON',
    SET_VIEW_ADMIN_DASHBOARD_LOADER_OFF: 'SET_VIEW_ADMIN_DASHBOARD_LOADER_OFF',
    SET_ADMIN_PRODUCT_LOADER_ON: 'SET_ADMIN_PRODUCT_LOADER_ON',
    SET_ADMIN_PRODUCT_LOADER_OFF: 'SET_ADMIN_PRODUCT_LOADER_OFF',
    SET_ADMIN_AFFILIATE_LOADER_ON: 'SET_ADMIN_AFFILIATE_LOADER_ON',
    SET_ADMIN_AFFILIATE_LOADER_OFF: 'SET_ADMIN_AFFILIATE_LOADER_OFF'
};