import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
// import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
// import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import ArrowCircleLeftRoundedIcon from "@mui/icons-material/ArrowCircleLeftRounded";
import ArrowCircleRightRoundedIcon from "@mui/icons-material/ArrowCircleRightRounded";
import { Button, IconButton, TextField } from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import { useDispatch, useSelector } from "react-redux";
// import { setProductToReducer } from "../../../redux/actions/userAccount-actions/product-action";
import { requestAllProductsSuccess } from "../../../redux/actions/approvar-actions/approvar-action";

const ProductCard = ({ product }) => {
  const dispatch = useDispatch();
  const [paginationIndex, setPaginationIndex] = useState(0);
  const [totalItemInProduct, setTotalItemInProduct] = useState(0);
  const [isProductSelectionAllowed, setIsProductSelectionAllowed] =
    useState(false);
  const [totalPage, setTotalPage] = useState(1);
  const [searchValue, setSearchValue] = useState(null);
  const [tempSearchValue, setTempSearchValue] = useState(null);
  const [selectedProductsCount, setSelectedProductsCount] = useState(0);

  const { selectedRoleList, abbreviation } = useSelector((state) => {
    return {
      selectedRoleList: state.ApprovarReducer.selectedRoles,
      abbreviation:
        state.ApprovarReducer.data?.vam_request?.request_payload
          ?.users_access_payload?.abbreviation
    };
  });
  useEffect(() => {
    setTotalPage(product.length / itemsPerPage);
    setTotalItemInProduct(product.length);
    let count = 0;
    product.forEach((productGroup) => {
      // count to be shown
      productGroup?.productList?.forEach((productObj) => {
        if (productObj?.checked) {
          count++;
        }
      });
    });
    setSelectedProductsCount(count);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  useEffect(() => {
    const tempSearchedProduct = [...product];
    tempSearchedProduct?.forEach((productGroup) => {
      productGroup?.productList?.forEach((productObj) => {
        if (searchValue?.length > 0) {
          if (productObj?.text?.toLowerCase()?.includes(searchValue)) {
            productObj.isFilteredProduct = true;
          } else {
            productObj.isFilteredProduct = false;
          }
        } else {
          productObj.isFilteredProduct = true;
        }
      });
      // set parent product group checkbox
      productGroup.checked = productGroup?.productList
        ?.filter((productObj) => {
          if (searchValue?.length > 0) {
            return productObj?.isFilteredProduct;
          } else {
            return true;
          }
        })
        ?.filter((productObj) => {
          return productObj.applicableAffiliates.includes(abbreviation);
        })
        ?.every((val) => val.checked);
    });
    dispatch(requestAllProductsSuccess(tempSearchedProduct));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    let tempIsActive = false;
    let isRequiredProductFromRoleList = selectedRoleList.map((selectedRole) => {
      return selectedRole.isRequireProducts;
    });
    isRequiredProductFromRoleList.forEach((element) => {
      tempIsActive = tempIsActive || element;
    });
    tempIsActive = tempIsActive && product.length > 0;

    setIsProductSelectionAllowed(tempIsActive);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRoleList, product]);

  const handelNextPage = () => {
    setPaginationIndex(paginationIndex + 1);
  };
  const handelPrevPage = () => {
    setPaginationIndex(paginationIndex - 1);
  };

  const handelCheckBoxParent = (index) => {
    let tempProduct = [...product];
    tempProduct[index].checked = !tempProduct[index].checked;
    if (tempProduct[index].checked) {
      // mark all productList checked
      tempProduct[index].productList.forEach((element) => {
        if (
          element?.isFilteredProduct &&
          element?.applicableAffiliates?.some((val) => val === abbreviation)
        ) {
          element.checked = true;
        }
      });
    } else {
      // mark all productList checked
      tempProduct[index].productList.forEach((element) => {
        if (element?.isFilteredProduct) {
          element.checked = false;
        }
      });
    }
    dispatch(requestAllProductsSuccess(tempProduct));
  };

  const handelCheckBoxChild = (parentIndex, childIndex) => {
    let tempProduct = [...product];
    tempProduct[parentIndex].productList[childIndex].checked =
      !tempProduct[parentIndex].productList[childIndex].checked;
    tempProduct?.forEach((productGroup) => {
      productGroup.checked = productGroup?.productList
        ?.filter((productObj) => {
          if (searchValue?.length > 0) {
            return productObj?.isFilteredProduct;
          } else {
            return true;
          }
        })
        ?.filter((productObj) => {
          return productObj.applicableAffiliates.includes(abbreviation);
        })
        ?.every((val) => val.checked);
    });
    dispatch(requestAllProductsSuccess(tempProduct));
  };

  const searchButtonHandler = () => {
    setSearchValue(tempSearchValue?.toLowerCase()?.trim());
  };
  const clearSearchHandler = () => {
    setSearchValue("");
    setTempSearchValue("");
  };

  const itemsPerPage = 4;
  return (
    <Grid item sm={12} md={12} xs={12}>
      <div className="approvar_workFlowCard">
        <div className="header_container">
          <div className="header_text_container">
            <div className="approvar_workFlowCard_header">
              <span className="workflow_Text">Select Products</span>
            </div>
            <div className="approvar_workFlowCard_Text_Container">
              <span className="accountSummary_note">
                <em>Note:</em>
              </span>{" "}
              <span className="accountSummary_red"> ** </span>{" "}
              <span className="note_text">
                <em>Please edit the product sections if needed</em>
              </span>
            </div>
          </div>
        </div>
        <div
          className="selectProductCard_section"
          // style={{ flexDirection: "column" }}
        >
          {isProductSelectionAllowed ? (
            <div
              style={{
                paddingLeft: "3rem",
                paddingRight: "3rem",
                paddingTop: "20px",
                // paddingBottom: "20px",
                justifyContent: "space-between",
                display: "flex"
              }}
            >
              <div style={{ paddingLeft: "8px" }}>
                <TextField
                  size="small"
                  variant="outlined"
                  className="searchUser_textField"
                  sx={{ height: "30px" }}
                  focused={false}
                  placeholder="Search for Products"
                  value={tempSearchValue}
                  onChange={(event) => {
                    setTempSearchValue(event.target.value);
                    if (event.target.value === "") {
                      clearSearchHandler();
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      searchButtonHandler();
                    }
                  }}
                  InputProps={{
                    sx: {
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                      height: "30px"
                    },
                    endAdornment: (
                      <>
                        {tempSearchValue?.length > 0 ? (
                          <IconButton
                            edge="end"
                            size="medium"
                            className="searchUser_crossIcon"
                            sx={{ height: "30px" }}
                            onClick={() => {
                              clearSearchHandler();
                            }}
                          >
                            <ClearIcon />
                          </IconButton>
                        ) : null}
                      </>
                    )
                  }}
                />
                <Button
                  variant="contained"
                  style={{ height: "30px" }}
                  className={
                    // !userSearchDisabled?
                    "searchUser_searchButton"
                    //   : "searchUser_searchButtonDisabled"
                  }
                  //   disabled={userSearchDisabled}
                  // onClick={searchUserHandler}
                  onClick={searchButtonHandler}
                >
                  <SearchOutlinedIcon size="medium" sx={{ height: "30px" }} />
                </Button>
              </div>
              <div style={{ fontWeight: "bold", paddingRight: "20px" }}>
                Selected Products - {selectedProductsCount}
              </div>
            </div>
          ) : null}
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div className="selectProductCard_sectionSideArticle">
              {paginationIndex > 0 ? (
                <ArrowCircleLeftRoundedIcon
                  sx={{ fontSize: "2.8rem", color: "#f8ac85" }}
                  onClick={handelPrevPage}
                />
              ) : null}
            </div>
            {isProductSelectionAllowed ? (
              <div className="selectProductCard_sectionCenterArticle">
                {product
                  .slice(
                    Math.max(0, paginationIndex * itemsPerPage),
                    Math.min(
                      totalItemInProduct,
                      paginationIndex * itemsPerPage + itemsPerPage
                    )
                  )
                  .map((productObject, productKey) => {
                    return (
                      <div
                        className="selectProductCard_product"
                        key={productKey}
                      >
                        <div className="selectProductCard_productHeader">
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={productObject.checked}
                                onChange={() =>
                                  handelCheckBoxParent(
                                    paginationIndex * itemsPerPage + productKey
                                  )
                                }
                                name="checkedB"
                                sx={{
                                  "&.Mui-checked": {
                                    color: "#00AE43"
                                  }
                                }}
                                className="productCheckboxHeaderClass"
                              />
                            }
                            label={
                              <span
                                className="productCheckboxHeaderLabelClass"
                                style={{
                                  fontWeight: "bold",
                                  color: "black"
                                }}
                              >
                                {productObject.productName}
                              </span>
                            }
                          />
                        </div>
                        <div className="selectProductCard_productSection">
                          {productObject.productList.map(
                            (productInnerObject, productInnerKey) => {
                              const isSelectedAffiliateExists =
                                productInnerObject.applicableAffiliates.includes(
                                  abbreviation
                                );
                              if (
                                isSelectedAffiliateExists &&
                                productInnerObject?.isFilteredProduct
                              ) {
                                return (
                                  <FormControlLabel
                                    key={productInnerKey}
                                    control={
                                      <Checkbox
                                        checked={productInnerObject.checked}
                                        onChange={() =>
                                          handelCheckBoxChild(
                                            paginationIndex * itemsPerPage +
                                              productKey,
                                            productInnerKey
                                          )
                                        }
                                        name="checkedB"
                                        sx={{
                                          "&.Mui-checked": {
                                            color: "#00AE43"
                                          }
                                        }}
                                        className="productCheckboxClass"
                                      />
                                    }
                                    label={
                                      <span
                                        style={{
                                          fontSize: "0.9rem",
                                          color: "black",
                                          display: "flex"
                                        }}
                                      >
                                        {productInnerObject.text}
                                      </span>
                                    }
                                  />
                                );
                              }
                            }
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>
            ) : (
              <div className="selectProductCard_sectionCenterArticleSelectionNotAllowed">
                <span>
                  Based on the roles selected for Lilly-based users, access to
                  all products will be provided.{" "}
                </span>
                <span>Products are no longer required to be selected.</span>
              </div>
            )}
            <div className="selectProductCard_sectionSideArticle">
              {paginationIndex < totalPage - 1 ? (
                <ArrowCircleRightRoundedIcon
                  sx={{ fontSize: "2.8rem", color: "#f8ac85" }}
                  onClick={handelNextPage}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </Grid>
  );
};

export default ProductCard;
