import React, { useEffect } from "react";
import { approvar_actionTypes } from "../utils/approval_actionType";
import ApprovarScreen from "./ApprovarScreen";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getRequestData } from "../../redux/actions/approvar-actions/approvar-action";
import CircularProgressLoader from "../../components/common/CircularProgressLoader";
import ExceptionHandler from "./ExceptionHandler";

const Approvar = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { loading, taskType, userMailId } = useSelector((state) => ({
    loading: state.ApprovarReducer.loading,
    taskType: state.ApprovarReducer.data.taskType,
    userMailId: state?.LoggedUserReducer?.loggedUser?.username
  }));

  useEffect(() => {
    if (id) {
      dispatch(getRequestData(id, userMailId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, userMailId]);

  if (loading) {
    return <CircularProgressLoader styles={{ position: "absolute" }} />;
  }

  if (taskType === "all") {
    return (
      <ApprovarScreen actionType={approvar_actionTypes.ALL_IN_ONE} id={id} />
    );
  }

  if (taskType === "trainingsApproval") {
    return (
      <ApprovarScreen
        actionType={approvar_actionTypes.CONFIRM_TRAINING_COMPLETION}
        id={id}
      />
    );
  }

  if (taskType === "sponsorApproval") {
    return (
      <ApprovarScreen
        actionType={approvar_actionTypes.REASSIGN_SUPERVISOR}
        id={id}
      />
    );
  }

  if (taskType === "elevatedRoleApproval") {
    return (
      <ApprovarScreen
        actionType={approvar_actionTypes.APPROVE_PRODUCTS_AND_ROLES}
        id={id}
      />
    );
  }

  if (taskType === "helpDesk") {
    return (
      <ExceptionHandler
        actionType={approvar_actionTypes.APPROVE_EXCEPTION_HANDLER}
        id={id}
      />
    );
  }

  return <></>;
};

export default Approvar;
