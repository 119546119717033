/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { pageTitle_actionTypes } from "../utils/pageTitle_actionType";
import { WorkFlowResult } from "./WorkFlowResult";
import { WorkFlow } from "./WorkFlow";
import "./styles/WorkFlow.css";
import {useSelector } from "react-redux";
//import { setworkFlowRoles } from "../../redux/actions/userAccount-actions/workFlowRoles-action";

const WorkFlowLoader = (props) => {
  // const dispatch = useDispatch();
  const { pageTitle, currentTask, allAffiliatesResources, userAccessResources } = props;
  const { affiliateType, workFlowType, roles, selectedRoles } = useSelector(
    (state) => {
      return {
        affiliateType: state.SelectAffiliateReducer.data.selectedAffiliateType,
        workFlowType: state.WorkFlowRolesReducer.data.workFlowType,
        roles: state.WorkFlowRolesReducer.data.roles,
        selectedRoles: state.WorkFlowRolesReducer.selectedRoles
      };
    }
  );

  useEffect(() => {
  }, []);

  const stateToPropsWF = {
    affiliateType,
    workFlowType,
    roles,
    allAffiliatesResources,
    userAccessResources,
    selectedRoles
  };

  const stateToPropsWR = {
    workFlowType,
    selectedRoles
  };

  if (
    (pageTitle === pageTitle_actionTypes.CREATE_USER_ACCOUNT ||
      pageTitle === pageTitle_actionTypes.MODIFY_USER_ACCOUNT) &&
    currentTask == 1
  ) {
    return <WorkFlow {...stateToPropsWF} />;
  } else if (
    (pageTitle === pageTitle_actionTypes.CREATE_USER_ACCOUNT ||
      pageTitle === pageTitle_actionTypes.MODIFY_USER_ACCOUNT) &&
    currentTask >= 2
  ) {
    return <WorkFlowResult {...stateToPropsWR} />;
  }
};

export default WorkFlowLoader;
