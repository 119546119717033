export const affiliate_actionType = {
    GET_ALL_AFFILIATE_START: 'GET_ALL_AFFILIATE_START',
    GET_ALL_AFFILIATE_SUCCESS: 'GET_ALL_AFFILIATE_SUCCESS',
    GET_ALL_AFFILIATE_FAILURE: 'GET_ALL_AFFILIATE_FAILURE',
    RESET_LOADER: 'RESET_LOADER',
    SET_LOADER: 'SET_LOADER',
    GET_ALL_COUNTRIES_SUCCESS: 'GET_ALL_COUNTRIES_SUCCESS',
    GET_ALL_COUNTRIES_FAILURE: 'GET_ALL_COUNTRIES_FAILURE',
    ADD_AFFILIATE_SUCCESS: 'ADD_AFFILIATE_SUCCESS',
    ADD_AFFILIATE_FAILURE: 'ADD_AFFILIATE_FAILURE',
    EDIT_AFFILIATE_IN_COUNTRIES_SUCCESS: 'EDIT_AFFILIATE_IN_COUNTRIES_SUCCESS',
    EDIT_AFFILIATE_IN_COUNTRIES_FAILURE: 'EDIT_AFFILIATE_IN_COUNTRIES_FAILURE',
    RESET_REDUCER_CORRESPONDING_TO_COUNTRIES: 'RESET_REDUCER_CORRESPONDING_TO_COUNTRIES'
};