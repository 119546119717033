import { axiosApigateway } from "../../../../api";
import { rolesRequest_actionType } from "../../../actionTypes/adminAction-actionTypes/role-actionTypes/rolesRequest-actionType";
import { rolesRequestListCall } from "./rolesRequestList-action";

export function rolesRequestStart() {
  return {
    type: rolesRequest_actionType.ROLES_REQUEST_START
  };
}

export function rolesRequestSuccess(data) {
  return {
    type: rolesRequest_actionType.ROLES_REQUEST_SUCCESS,
    payload: data
  };
}

export function rolesRequestError(error) {
  return {
    type: rolesRequest_actionType.ROLES_REQUEST_ERROR,
    payload: error
  };
}

export function rolesRequestReset() {
  return {
    type: rolesRequest_actionType.ROLES_REQUEST_RESET
  };
}

export function addorModifyRolesRequestCall(mode, data, roleId = "") {
  return async (dispatch) => {
    dispatch(rolesRequestStart());

    try {
      const url = mode === "create" ? "/roles" : `/roles/${roleId}`;
      let response;
      if (mode === "create") {
        response = await axiosApigateway.post(url, data);
      } else if (mode === "edit") {
        response = await axiosApigateway.put(url, data);
      } else {
        response = "";
      }

      if (response) {
        const responseData = response.data.message;
        dispatch(rolesRequestSuccess(responseData));
      }
      dispatch(rolesRequestListCall());
    } catch (error) {
      dispatch(rolesRequestError(error));
    }
  };
}

export function addorModifyRolesGroupRequestCall(mode, data, roleId = "") {
  return async (dispatch) => {
    dispatch(rolesRequestStart());

    try {
      const url = mode === "create" || mode === "edit" ? `/admin-roles-groups/${roleId}` : "";
      let response;
      if (mode === "create" || mode === "edit") {
        response = await axiosApigateway.put(url, data);
      } else {
        response = "";
      }

      if (response) {
        const responseData = response.data.message;
        dispatch(rolesRequestSuccess(responseData));
      }
      dispatch(rolesRequestListCall());
    } catch (error) {
      dispatch(rolesRequestError(error));
    }
  };
}

export function addorModifyRolesTrainingRequestCall(mode, data, roleId = "") {
  return async (dispatch) => {
    dispatch(rolesRequestStart());

    try {
      const url = mode === "create" || mode === "edit" ? `/admin-roles-trainings/${roleId}` : "";
      let response;
      if (mode === "create" || mode === "edit") {
        response = await axiosApigateway.put(url, data);
      } else {
        response = "";
      }

      if (response) {
        const responseData = response.data.message;
        dispatch(rolesRequestSuccess(responseData));
      }
      dispatch(rolesRequestListCall());
    } catch (error) {
      dispatch(rolesRequestError(error));
    }
  };
}

export function addorModifyRolesDACRequestCall(mode, data, roleId = "") {
  return async (dispatch) => {
    dispatch(rolesRequestStart());

    try {
      const url = mode === "create" || mode === "edit" ? `/admin-roles-dac/${roleId}` : "";
      let response;
      if (mode === "create" || mode === "edit") {
        response = await axiosApigateway.put(url, data);
      } else {
        response = "";
      }

      if (response) {
        const responseData = response.data.message;
        dispatch(rolesRequestSuccess(responseData));
      }
      dispatch(rolesRequestListCall());
    } catch (error) {
      dispatch(rolesRequestError(error));
    }
  };
}


export function addRolesTrainingsRequestCall(id, data) {
  return async (dispatch) => {
    dispatch(rolesRequestStart());

    try {
      const response = await axiosApigateway.put(
        `/admin-roles-trainings/${id}`,
        data
      );
      if (response) {
        const responseData = response.data.message;
        dispatch(rolesRequestSuccess(responseData));
      }
    } catch (error) {
      dispatch(rolesRequestError(error));
    }
  };
}

export function addRolesDACRequestCall(id, data) {
  return async (dispatch) => {
    dispatch(rolesRequestStart());

    try {
      const response = await axiosApigateway.put(
        `/admin-roles-dac/${id}`,
        data
      );
      if (response) {
        const responseData = response.data.message;
        dispatch(rolesRequestSuccess(responseData));
      }
    } catch (error) {
      dispatch(rolesRequestError(error));
    }
  };
}

export function addRoleGroupsRequestCall(id, data) {
  return async (dispatch) => {
    dispatch(rolesRequestStart());

    try {
      const response = await axiosApigateway.put(
        `/admin-roles-groups/${id}`,
        data
      );
      if (response) {
        const responseData = response.data.message;
        dispatch(rolesRequestSuccess(responseData));
      }
    } catch (error) {
      dispatch(rolesRequestError(error));
    }
  };
}

export function deleteRoleRequestCall(id) {
  return async (dispatch) => {
    dispatch(rolesRequestStart());

    try {
      const response = await axiosApigateway.delete(`/roles/${id}`);
      if (response) {
        const responseData = response.data.message;
        dispatch(rolesRequestSuccess(responseData));
      }
      dispatch(rolesRequestListCall());
    } catch (error) {
      if (error.response.data) {
        if (error.response.data.data)
          dispatch(rolesRequestError(error.response.data.data));
        else if (error.response.data.errormessage)
          dispatch(rolesRequestError(error.response.data.errormessage));
      } else {
        dispatch(rolesRequestError("An unknown error occured."));
      }
    }
  };
}
