import { affiliateCountries_actionType } from "../../actionTypes/userAction-actionTypes/affiliateCountries-actionType";

export function getAffiliateCountries() {
    return(dispatch) => {
        dispatch({
            type: affiliateCountries_actionType.GET_ALL_AFFILIATE_COUNTRIES_SUCCESS,
            data: "xyz"
        });
    };
}

export function resetAffiliateCountriesReducer() {
    return(dispatch) => {
        dispatch({
            type: affiliateCountries_actionType.RESET_AFFILIATE_COUNTRIES_SUCCESS
        });
    };
}

export function setAffiliateCountries(data) {
    return(dispatch) => {
        if(data){ // validating data
            dispatch({
                type: affiliateCountries_actionType.SET_ALL_AFFILIATE_COUNTRIES_SUCCESS,
                payload: data
            });
        }else{
            dispatch({
                type : affiliateCountries_actionType.SET_ALL_AFFILIATE_COUNTRIES_FAILURE
            });
        }
    };
}