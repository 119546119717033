import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

function AdminMainArticleLoader() {
  return (
    <div
      style={{ 
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#ffc5a0'
      }}
    >
      <CircularProgress color="inherit" />
    </div>
  );
}

export default AdminMainArticleLoader;