import React from 'react';
import { useSelector } from 'react-redux';

function VAMSelectedProducts() {
  const { SelectedProducts } = useSelector((state) => ({
    SelectedProducts: state.ViewAdminDashboardReducer.SelectedProducts
  }));
  return (
    <div className='viewAdminCardContainer adminViewDashboardHalfWidth'>
      <div>
      <div className='addNewProductContainerCard'>
        <div className='adminAddProductCardHeader'>
          <span className='cardHeaderText'>Selected Products</span>
        </div>
        <div className='adminViewDashboardCardForm'>
          <div>
          {
            SelectedProducts.map((productCategory, productCategoryKey) => {
              let productsToDisplay = [];
              productCategory.selectedProducts.forEach(element => {
                productsToDisplay.push(element.productName);
              });
              return(
                <div key={productCategoryKey} className="adminViewDashboardCardFormContent">
                  <span className='adminViewDashboardCardFormTitle'>{ productCategory.categoryName !== 'DBU' ? productCategory.categoryName : 'CMH'}</span>
                  <span className='adminViewDashboardCardFormValue'>
                    {productsToDisplay.join(", ")}
                  </span>
                </div>
              );
            })
          }
          {
            SelectedProducts.length === 0 ?
            <div className='mandateory'>No Products to display</div> : null
          }
          </div>
        </div>
      </div>
      </div>
    </div>
  );
}

export default VAMSelectedProducts;