import { viewAdminDashboard_actionType } from "../../actionTypes/adminAction-actionTypes/viewAdminDashboard-actionType";

const initialState = {
    RequestID: "",
    FullName: "",
    requestModifiedDate: "",
    requestCreateDate: "",
    requesterComment: "",
    UserDetail: {
        veevaUsername: "",
        userType: "",
        firstName: "",
        locale: "",
        lastName: "",
        location: "",
        emailId: "",
        timezone: "",
        language: ""
    },
    SelectedRoles: {
        workflow: [],
        roles: []
    },
    SelectedProducts: [],
    requestStatus: []
};

const ViewAdminDashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case viewAdminDashboard_actionType.RESET_VIEW_DASHBOARD_SUCCESS:
        return state;
    case viewAdminDashboard_actionType.GET_VIEW_DASHBOARD_SUCCESS:
      return {
        ...state,
        RequestID: action.data.RequestID,
        FullName: action.data.FullName,
        requestModifiedDate: action.data.requestModifiedDate,
        requestCreateDate: action.data.requestCreateDate,
        requesterComment: action.data.requesterComment,
        UserDetail: action.data.UserDetail,
        
        SelectedRoles: action.data.SelectedRoles,
        SelectedProducts: action.data.SelectedProducts,
        requestStatus: action.requestStatus,
        loading: false
      };
    case viewAdminDashboard_actionType.GET_VIEW_DASHBOARD_FAILURE:
        return {
            ...state,
            error: action.data
        };
    default:
      return state;
  }
};

export default ViewAdminDashboardReducer;