export function requiredFieldAvailableSelectAffiliate(data, state) {
  const { selectedAffiliateType, selectedCountries } = data;
  const { affiliateType, countries } = state.AffiliateCountriesReducer.data;
  const selectedCountriesOfAff = selectedCountries?.filter(
    (ctry) => ctry.affiliateType === selectedAffiliateType
  );
  if (
    affiliateType &&
    selectedAffiliateType === affiliateType &&
    countries?.length > 0 &&
    selectedCountriesOfAff?.length > 0
  ) {
    return true;
  } else if (
    affiliateType &&
    selectedAffiliateType === affiliateType &&
    countries?.length === 0
  ) {
    return true;
  } else {
    return false;
  }
}
