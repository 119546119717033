import React from "react";
import { useSelector } from "react-redux";

import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import Tooltip from "@mui/material/Tooltip";

function VAMRequestStatus({ viewAdminRequestId }) {
  const {
    viewAdminRequesterName,
    requestStatus,
    requestModifiedDate,
    requesterComment
  } = useSelector((state) => ({
    viewAdminRequesterName: state.ViewAdminDashboardReducer.FullName,
    requestStatus: state.ViewAdminDashboardReducer.requestStatus,
    requestModifiedDate: state.ViewAdminDashboardReducer.requestCreateDate,
    requesterComment: state.ViewAdminDashboardReducer.requesterComment
  }));
  const creationRequest = {
    approverName: "Request Raised",
    comment: requesterComment,
    cureentStatus: null,
    lastModifiedDate: requestModifiedDate
  };
  return (
    <div className="viewAdminCardContainer">
      <div>
        <div className="addNewProductContainerCard">
          <div className="adminAddProductCardHeader">
            <span className="cardHeaderText">
              Status for VVPM Account Request #{viewAdminRequestId} -{" "}
              {viewAdminRequesterName}
            </span>
          </div>
          <div className="adminViewDashboardCardStatusForm">
            <div className="adminDashboardRequestCreation">
              {requestStatus.length > 0 ? (
                <>
                  <Approved requestStatus={creationRequest} />
                  <div className="dashedLine"></div>
                </>
              ) : (
                <>
                  <Approved requestStatus={creationRequest} />
                  <div className="dashedLine"></div>
                </>
              )}
            </div>
            <div className="adminDashboardRequestProgress">
              {requestStatus.map((requestStatusObject, requestStatusObjKey) => {
                return (
                  <div
                    className="adminDashboardRequestProgressTask"
                    key={requestStatusObjKey}
                  >
                    {taskDetail(requestStatusObject)}
                    {requestStatusObjKey !== requestStatus.length - 1 ? (
                      <div className="dashedLineInProgress"></div>
                    ) : null}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const taskDetail = (requestStatus) => {
  switch (requestStatus.cureentStatus) {
    case "draft":
      return <Pending requestStatus={requestStatus} />;
    case "active":
      return <Pending requestStatus={requestStatus} />;
    case "approved":
      return <Approved requestStatus={requestStatus} />;
    case "rejected":
      return <Rejected requestStatus={requestStatus} />;
    case "auto-rejected":
      return <Rejected requestStatus={requestStatus} />;
    case "auto-approved":
      return <Approved requestStatus={requestStatus} />;
    case "pending":
      return <Pending requestStatus={requestStatus} />;
    default:
      return "Unknown taskStatus";
  }
};

const Approved = ({ requestStatus }) => {
  return (
    <div className="adminDashboardRequestStatus">
      <div className="requestStatusOuterSuccessCircle">
        {requestStatus?.taskId ? (
          <Tooltip
            arrow={true}
            title={
              requestStatus?.taskType !== "pending-user"
                ? `${requestStatus?.taskId} - Click to Copy`
                : "Automatic Training Verification Task"
            }
            placement="top-start"
          >
            <CheckCircleOutlineOutlinedIcon
              onClick={async () => {
                if (requestStatus?.taskType !== "pending-user") {
                  await navigator.clipboard.writeText(
                    `${window.location?.origin}/approval/${requestStatus?.taskId}`
                  );
                }
              }}
              sx={{
                width: "40px",
                height: "40px",
                color: "green",
                cursor:
                  requestStatus?.taskType !== "pending-user" ? "pointer" : ""
              }}
            />
          </Tooltip>
        ) : (
          <CheckCircleOutlineOutlinedIcon
            sx={{
              width: "40px",
              height: "40px",
              color: "green"
            }}
          />
        )}
      </div>
      <div className="approverName">
        {requestStatus.approverName?.length > 28 ? (
          <Tooltip
            title={`${requestStatus.approverName}`}
            placement="right-start"
          >
            {requestStatus.approverName?.slice(0, 26)}...
          </Tooltip>
        ) : (
          requestStatus.approverName
        )}
      </div>
      <div className="modifiedDate">
        {requestStatus.lastModifiedDate
          ? requestStatus.lastModifiedDate.slice(0, 10)
          : "null"}
      </div>
      <div
        className="approvarComment"
        style={
          requestStatus.comment?.length > 65
            ? { alignItems: "unset" }
            : { alignItems: "center" }
        }
      >
        {requestStatus.comment}
      </div>
    </div>
  );
};

const Rejected = ({ requestStatus }) => {
  return (
    <div className="adminDashboardRequestStatus">
      <div className="requestStatusOuterErrorCircle">
        {requestStatus?.taskId ? (
          <Tooltip
            arrow={true}
            title={
              requestStatus?.taskType !== "pending-user"
                ? `${requestStatus?.taskId} - Click to Copy`
                : "Automatic Training Verification Task"
            }
            placement="top-start"
          >
            <CancelOutlinedIcon
              sx={{
                width: "40px",
                height: "40px",
                color: "red",
                cursor:
                  requestStatus?.taskType !== "pending-user" ? "pointer" : ""
              }}
              onClick={async () => {
                if (requestStatus?.taskType !== "pending-user") {
                  await navigator.clipboard.writeText(
                    `${window.location?.origin}/approval/${requestStatus?.taskId}`
                  );
                }
              }}
            />
          </Tooltip>
        ) : (
          <CancelOutlinedIcon
            sx={{
              width: "40px",
              height: "40px",
              color: "red"
            }}
          />
        )}
      </div>
      <div className="approverName">
        {requestStatus.approverName?.length > 28 ? (
          <Tooltip
            title={`${requestStatus.approverName}`}
            placement="right-start"
          >
            {requestStatus.approverName?.slice(0, 26)}...
          </Tooltip>
        ) : (
          requestStatus.approverName
        )}
      </div>
      <div className="modifiedDate">
        {requestStatus.lastModifiedDate
          ? requestStatus.lastModifiedDate.slice(0, 10)
          : "null"}
      </div>
      <div
        className="approvarComment"
        style={
          requestStatus.comment?.length > 65
            ? { alignItems: "unset" }
            : { alignItems: "center" }
        }
      >
        {requestStatus.comment}
      </div>
    </div>
  );
};

const Pending = ({ requestStatus }) => {
  return (
    <div className="adminDashboardRequestStatus">
      <div className="requestStatusOuterPendingCircle">
        {requestStatus?.taskId ? (
          <Tooltip
            arrow={true}
            title={
              requestStatus?.taskType !== "pending-user"
                ? `${requestStatus?.taskId} - Click to Copy`
                : "Automatic Training Verification Task, it will approve automatically!"
            }
            placement="top-start"
          >
            <AccessTimeOutlinedIcon
              sx={{
                width: "40px",
                height: "40px",
                color: "#b3b303",
                cursor:
                  requestStatus?.taskType !== "pending-user" ? "pointer" : ""
              }}
              onClick={async () => {
                if (requestStatus?.taskType !== "pending-user") {
                  await navigator.clipboard.writeText(
                    `${window.location?.origin}/approval/${requestStatus?.taskId}`
                  );
                }
              }}
            />
          </Tooltip>
        ) : (
          <AccessTimeOutlinedIcon
            sx={{
              width: "40px",
              height: "40px",
              color: "#b3b303"
            }}
          />
        )}
      </div>
      <div className="approverName">
        {requestStatus.approverName?.length > 28 ? (
          <Tooltip
            title={`${requestStatus.approverName}`}
            placement="right-start"
          >
            {requestStatus.approverName?.slice(0, 26)}...
          </Tooltip>
        ) : (
          requestStatus.approverName
        )}
      </div>
      <div className="modifiedDate">
        {requestStatus.lastModifiedDate
          ? requestStatus.lastModifiedDate.slice(0, 10)
          : "null"}
      </div>
      <div
        className="approvarComment"
        style={
          requestStatus.comment?.length > 65
            ? { alignItems: "unset" }
            : { alignItems: "center" }
        }
      >
        {requestStatus.comment}
      </div>
    </div>
  );
};

export default VAMRequestStatus;
