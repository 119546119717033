import { company_actionType } from "../../actionTypes/adminAction-actionTypes/company-actionType";

const initialState = {
  companies: [],
  allCompanies : [],
  loading: false,
  requiredFieldAvailable: true,// false,
  isRequestCompleted : false,
  isRequestSuccess : false,
  message : []
};

const fetchErrorMessage = (data) => {
  let msg = [];
  if (data) {
    msg.push(data);
  } else {
    msg.push("Something went Wrong, Please try after some time!");
  }

  return msg;
};

const CompanyReducer = (state = initialState, action) => {
  switch (action.type) {
    case company_actionType.RESET_COMPANY_SUCCESS:
        return state;
    case company_actionType.GET_COMPANY_START:
      return {
        ...state,
        loading : true,
        companies :[],
        allCompanies : []
         
      };
    case company_actionType.SET_COMPANY_SUCCESS:
        return {
            ...state,
            loading : false,
            companies: action.data.companyObject,
            allCompanies : action.data.allCompines
        };
    case company_actionType.SET_COMPANY_FAILURE:
        return {
            ...state,
            loading : false,
            error: action.data
        };
        case company_actionType.EDIT_REQUEST_START:
          return{
              ...state,
              isRequestCompleted : false,
              isRequestSuccess : false,
              message : []
          };
      case company_actionType.EDIT_REQUEST_SUCCESS:
          return{
              ...state,
              isRequestCompleted : true,
              isRequestSuccess : true,
              message : [action?.data]
          };
      case company_actionType.EDIT_REQUEST_FAILURE:
        console.log('company Reducer11==========', action);
          return {
              ...state,
              isRequestCompleted : true,
              isRequestSuccess : false,
              // message : ["Something went Wrong, Please try after some time!"]
              message: [fetchErrorMessage(action.data)]
          };
    default:
      return state;
  }
};

export default CompanyReducer;