import { useSelector, useDispatch } from "react-redux";
import React, { useEffect } from "react";
import { UserDetailsSection } from "../UserDetails/UserDetailsSection";
import { WorkFlow } from "../WorkFlow/WorkFlow";
import ProductCard from "../ProductCard/ProductCard";
import { setExistingStateToReducer } from '../../redux/actions/userAccount-actions/summary-action';
import SponserModalNotice from "./SponserModalNotice";
const SummaryModal = (props) => {
    const dispatch = useDispatch();
    const { activeModal, isUserOnboarded, pageTitle, toggleToLandingPage, selectedSponsor } = props;
    const { 
      reducerProduct,
      isExistingDataSet,
      userDetails,
      allUserDetailResources,
      workFlowType,
      roles,
      selectedRoles,
      completeState
    } = useSelector((state) => ({
        reducerProduct: state.ProductReducer.product,
        userDetails: state.UserDetailReducer.data,
        allUserDetailResources: state.AllUserDetailResourcesReducer.data,
        workFlowType: state.WorkFlowRolesReducer.data.workFlowType,
        roles: state.WorkFlowRolesReducer.data.roles,
        selectedRoles: state.WorkFlowRolesReducer.selectedRoles,
        isExistingDataSet: state.SummaryReducer.isExistingDataSet,
        completeState: state
      }));

    const allAffiliatesResources = completeState.AffiliateResourcesReducer.data;
    const userAccessResources = completeState.UserAccessResourcesReducer.data;
    
    const selectedProductProps = {
      reducerProduct
    };
    const userDetailsSectionProps = {
      userDetails,
      isUserOnboarded,
      pageTitle,
      allUserDetailResources,
      toggleToLandingPage
    };

    const selectedRolesAndGroups = {
      workFlowType,
      roles,
      selectedRoles,
      allAffiliatesResources,
      userAccessResources
    };

    const count = 0;

    useEffect(() => {
        if(!isExistingDataSet) {
          if(activeModal === "userDetails") {
            const payload = {
              ...userDetails,
              id: userDetails.id,
              deactivated: userDetails.deactivated,
              isLilly: userDetails.isLilly,
              actionType: userDetails.actionType,
              isExist: userDetails.isExist
            };
            dispatch(setExistingStateToReducer(payload));
          } else if(activeModal === "selectedRolesAndGroups") {
            let rolesProductsExistingState = {
              selectedRolesAndGroups: selectedRoles,
              selectedProducts: selectedProductProps.reducerProduct
            };
            // dispatch(setExistingStateToReducer(selectedRoles));
            dispatch(setExistingStateToReducer(rolesProductsExistingState));
          } else if(activeModal === "selectedProducts") {
            dispatch(setExistingStateToReducer(selectedProductProps.reducerProduct));
          } else {
            console.log('Summary Modal - Component Type Does Not Match!!');
          }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [count]);

    const renderComponent = (activeModal) => {
      switch (activeModal) {
        case "userDetails":
          return <UserDetailsSection {...userDetailsSectionProps} />;
  
        case "selectedRolesAndGroups":
          return <WorkFlow {...selectedRolesAndGroups} />;
  
        case "selectedProducts":
          return <ProductCard {...selectedProductProps} />;

        case "selectSponser": 
          return <SponserModalNotice selectedSponsor={selectedSponsor} />;
  
        default:
          return null;
      }
    };

    const returnComponent = renderComponent(activeModal);
    return returnComponent;
};

export default SummaryModal;
