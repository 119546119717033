import { adminProduct_actionType } from "../../../actionTypes/adminAction-actionTypes/product-actionTypes/product-actionTypes";
import { setAdminProductoaderActive, setAdminProductoaderInActive } from "../../loaderAction";
import { setLoaderActive, setLoaderInActive } from "../../loaderAction";
import { axiosApigateway } from "../../../../api";

const filterQueryInitialState = [
    {
        abbrebiation: "affiliateType",
        name : "Affiliate Type",
        value : []
    }
];

const getInitialFilter = (uniqueAffiliates) => {
    let filterQuery = filterQueryInitialState;

    filterQuery[0].value = uniqueAffiliates;

    return filterQuery;
};

export function getAdminProducts(){
    return async(dispatch)=> { 
        dispatch(setAdminProductoaderActive());

        try{
            const response = await axiosApigateway.get('/products');
            const uniqueAffiliates = await axiosApigateway.get('/hubs');
            let requiredData = getDummyData(response.data.data, uniqueAffiliates.data.data);
            dispatch({
                type: adminProduct_actionType.GET_PRODUCT_SUCCESS,
                data: requiredData,
                filterQueryInitialState: getInitialFilter(uniqueAffiliates.data.data),
                uniqueAffiliates: uniqueAffiliates.data.data
            });

            dispatch(setAdminProductoaderInActive());
            return {success: false};
        }catch(error){
            dispatch({
                type: adminProduct_actionType.GET_PRODUCT_FAILURE,
                data: error
            });

            dispatch(setAdminProductoaderInActive());
            return {success: false};
        }
    };
}

const getDummyData = (response, affiliateFromHub) => {
    let data = []; // list of objects

    const getAffiliateObject = (affiliateAbbr) => {
        let found = null;
        affiliateFromHub.forEach(element => {
            if(element.abbreviation === affiliateAbbr){
                found = element;
            }
        });

        return found; 
    };

    const getAffiliateObjectList = (affiliateStringList) => {
        let affiliateObjList = []; // affiliate Object List

        if(affiliateStringList){
            affiliateStringList.forEach(element => {
                const affObj = getAffiliateObject(element);
                affiliateObjList.push(affObj);
            });
        }

        return affiliateObjList;
    };

    response.forEach(element => {
        data.push({
            productID: element.product_id,
            productName: element.product_name,
            productAbbrebiation: element.product_abbreviation,
            vamProductGroupId: element.vamProductGroupId,
            affiliate: getAffiliateObjectList(element.applicableAffiliates)
        });
    });


    return data;
};

// Modify Request
export function submitModifyRequest(productId, affiliateList, vamProductGroupId){
    return async(dispatch)=> { 
        dispatch(setLoaderActive());

        try{
            let bodyData = {
                applicableAffiliates: [],
                vamProductGroupId: null
            };
            affiliateList.forEach(element => {
                bodyData.applicableAffiliates.push(element.abbreviation);
                bodyData.vamProductGroupId = vamProductGroupId;
            });
            const response = await axiosApigateway.put('/products/'+productId, bodyData);
            
            dispatch({
                type: adminProduct_actionType.SUBMIT_PRODUCT_REQUEST_SUCCESS,
                data: response
            });
            setTimeout(()=>{
                dispatch(setLoaderInActive());
            }, 3000);
            return {response, success: true};
        }catch(error){
            dispatch({
                type: adminProduct_actionType.SUBMIT_PRODUCT_REQUEST_FAILURE,
                data: error
            });

            dispatch(setLoaderInActive());
            return {success: false};
        }
    };
}

// Delete Request
export function submitDeleteRequest(productId){
    return async(dispatch)=> { 
        dispatch(setLoaderActive());

        try{
            const response = await axiosApigateway.delete('/products/'+productId);
            
            dispatch({
                type: adminProduct_actionType.SUBMIT_PRODUCT_REQUEST_SUCCESS,
                data: response
            });
            setTimeout(()=>{
                dispatch(setLoaderInActive());
            }, 3000);
            return {response, success: true};
        }catch(error){
            dispatch({
                type: adminProduct_actionType.SUBMIT_PRODUCT_REQUEST_FAILURE,
                data: error
            });

            dispatch(setLoaderInActive());
            return {success: false};
        }
    };
}

//create  Request
export function searchForProductID(productId){
    return async(dispatch)=> { 
        dispatch({type: adminProduct_actionType.SEARCH_PRODUCT_ID_LOADER_ON});

        try{
            const response = await axiosApigateway.post('/admin-vaultquery/products', { product_id: productId });
            
            dispatch({
                type: adminProduct_actionType.SEARCH_PRODUCT_ID_SUCCESS,
                data: response
            });
            dispatch({type: adminProduct_actionType.SEARCH_PRODUCT_ID_LOADER_OFF});
            return {response, success: true};
        }catch(error){
            dispatch({
                type: adminProduct_actionType.SEARCH_PRODUCT_ID_FAILURE,
                data: error
            });

            dispatch({type: adminProduct_actionType.SEARCH_PRODUCT_ID_LOADER_OFF});
            return {success: false};
        }
    };
}

export function submitCreateRequest(productId, productName, productAbbreviation, affiliateList, vamProductGroupId){
    return async(dispatch)=> { 
        dispatch(setLoaderActive());

        try{
            let applicableAffiliates = [];
            affiliateList.forEach(element => {
                applicableAffiliates.push(element.abbreviation);
            });
            let bodyData = {
                product_id: productId,
                product_name: productName, 
                product_abbreviation: productAbbreviation, 
                applicableAffiliates: applicableAffiliates, 
                vamProductGroupId: vamProductGroupId
            };
            console.log("bodyData", bodyData);
            
            const response = await axiosApigateway.post('/products', bodyData);
            
            dispatch({
                type: adminProduct_actionType.SUBMIT_PRODUCT_REQUEST_SUCCESS,
                data: response
            });
            setTimeout(()=>{
                dispatch(setLoaderInActive());
            }, 3000);
            return {response, success: true};
        }catch(error){
            dispatch({
                type: adminProduct_actionType.SUBMIT_PRODUCT_REQUEST_FAILURE,
                data: error
            });

            dispatch(setLoaderInActive());
            return {success: false};
        }
    };
}

export function resetReducerCorrespondingToProductID() {
    return async(dispatch)=> {
        dispatch({
            type: adminProduct_actionType.RESET_SEARCH_PRODUCT
        });
    };
}