import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableHead,
  Stack
} from "@mui/material";
import React, { useEffect, useState, useMemo } from "react";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { visuallyHidden } from "@mui/utils";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { toLower } from "lodash";

import "./styles/Groups.css";
import { useSelector } from "react-redux";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#f9ccb5",
    color: theme.palette.common.black,
    fontWeight: 600,
    fontSize: 12,
    borderRight: "1px solid #80808040",
    borderBottom: "0px",
    padding: 3
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    color: "black",
    fontWeight: 400,
    borderRight: "1px solid #80808040",
    borderBottom: "0px",
    padding: 3
  }
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "rgb(0 255 205 / 4%)"
  }
  // hide last border
  // '&:last-child td, &:last-child th': {
  //   border: 0
  // }
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  // {
  //   id: 'groupId',
  //   label: 'Group ID'
  // },
  {
    id: "name",
    label: "Group Name"
  },
  {
    id: "description",
    label: "Description"
  }
  // {
  //   id :'isDynamicCountry',
  //   label : 'Is Dynamic Country'
  // },
  // {
  //   id :'isDynamicProduct',
  //   label : 'Is Dynamic Product'
  // }
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <StyledTableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
        <StyledTableCell>Action</StyledTableCell>
      </StyledTableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired
};

const GroupsTable = ({ openGroupsModal, searchText, handleDelete }) => {
  const [filterData, setFilterData] = useState([]);
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const { groupData } = useSelector((state) => ({
    groupData: state.GroupReducer.groupData
  }));
  const trHeigth = 27;
  const handleEdit = (rowData) => {
    openGroupsModal(rowData);
  };

  useEffect(() => {
    let tempFilterData = [...groupData];
    setFilterData(tempFilterData);
  }, [groupData]);

  useEffect(() => {
    let tempFilterData = [...groupData];
    if (searchText?.length > 0) {
      tempFilterData = tempFilterData.filter((obj) => {
        return toLower(obj.name).includes(toLower(searchText));
      });
    }
    setFilterData(tempFilterData);
  }, [groupData, searchText]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const visibleRows = useMemo(
    () => stableSort(filterData, getComparator(order, orderBy)),
    [order, orderBy, filterData]
  );

  const tableHeigth = filterData.length * trHeigth + trHeigth;

  return (
    <div className="groupTableContainer">
      <div className="groupTableHeader">
        <span>Existing Groups</span>
      </div>
      <div className="groupTableSectionContainer">
        <div className="groupTableSection">
          <TableContainer
            sx={{
              height: `${tableHeigth}px`,
              maxHeight: "65vh",
              scrollbarWidth: "12px",
              "&::-webkit-scrollbar": {
                width: "12px",
                borderRadius: "10px",
                height: "12px"
              },
              "&::-webkit-scrollbar-track": { borderRadius: "5px" },
              "&::-webkit-scrollbar-thumb": {
                borderRadius: "5px"
                // backgroundColor: "#848482"
              }
            }}
            component={Paper}
          >
            <Table stickyHeader aria-label="Groups table">
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={filterData.length}
              />
              <TableBody>
                {visibleRows &&
                  visibleRows.map((row) => (
                    <StyledTableRow key={row.id_pk}>
                      {/* <StyledTableCell component="th" scope='row' >{row.id}</StyledTableCell>    */}
                      <StyledTableCell>{row.name}</StyledTableCell>
                      <StyledTableCell>{row.description}</StyledTableCell>
                      {/* <StyledTableCell width={137}>{row.isDynamicCountry ? "true" : "false"}</StyledTableCell>  */}
                      {/* <StyledTableCell width={135}>{row.isDynamicProduct ? "true" : "false"}</StyledTableCell>    */}
                      {/* <StyledTableCell width={137}><div style={{display:"flex", justifyContent:"center" }}><Checkbox checked={row.isDynamicCountry} /></div></StyledTableCell> 
                        <StyledTableCell width={135}><div style={{display:"flex", justifyContent:"center" }}><Checkbox checked={row.isDynamicProduct} /></div> </StyledTableCell>    */}
                      <StyledTableCell>
                        <Stack
                          spacing={1}
                          direction={"row"}
                          alignItems={"center"}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            width: "50px"
                          }}
                        >
                          <EditOutlinedIcon
                            className="clickable"
                            sx={{
                              ":hover": {
                                cursor: "pointer"
                              }
                            }}
                            onClick={() => handleEdit(row)}
                          />
                          <DeleteForeverOutlinedIcon
                            className="clickable"
                            sx={{
                              ":hover": {
                                cursor: "pointer"
                              }
                            }}
                            onClick={() => handleDelete(row.id)}
                          />
                        </Stack>
                      </StyledTableCell>
                      {/* <StyledTableCell width={40} className='clickable'>{row.action}</StyledTableCell>    */}
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default GroupsTable;
