import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
// import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Button from "@mui/material/Button";
import { Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { TextFieldInput } from "../../../../../../components/common/TextFieldInput";
import { saveCompanyRequest } from "../../../../../../redux/actions/adminAccount-actions/company-action";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  height: "35%",
  border: 0,
  borderRadius: "10px",
  backgroundColor: "white",
  outline: "none",
  overflow: "hidden"
};

const CompanyModal = ({
  isModalOpen,
  toggleOpenGroupModal,
  rowData,
  isEditRequest
}) => {
  const dispatch = useDispatch();
  const [name, setName] = useState("");

  useEffect(() => {
    if (isEditRequest) {
      setName(rowData?.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditRequest]);

  const onSaveHandler = () => {
    // const data = [
    //   {
    //     name
    //   }
    // ];
    const data = { name };
    dispatch(saveCompanyRequest(data, isEditRequest, rowData?.id));
    handleClose();
  };

  const handleClose = () => {
    toggleOpenGroupModal();
    clearState();
  };

  const clearState = () => {
    setName("");
  };

  return (
    <Modal
      className="groupModalParent"
      open={isModalOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="fitermodel-company" style={ModalStyle}>
        <div className="filterContent">
          <div
            className="companyFilterContentHeader"
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingRight: "15px"
            }}
          >
            <div>{isEditRequest ? "Edit Company" : "Add New Company"}</div>
            <HighlightOffIcon
              sx={{
                mr: "5px",
                "&:hover": {
                  color: "gray",
                  transition: "color 0.2s"
                }
              }}
              onClick={handleClose}
            />
          </div>
          {/* <div className="companyFilterContentHeader"></div> */}
          <div className="filterContentContent">
            <Grid container spacing={1}>
              <Grid item sm={12}>
                <div className="adminAddProductCardFormInputLabel">
                  <span>
                    <span>Company Name</span>
                    <span className="dangerColor">*</span>
                  </span>
                </div>
              </Grid>
              <Grid item sm={12}>
                <TextFieldInput
                  placeholder="Enter Company Name"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
          </div>
        </div>
        {/* <CancelOutlinedIcon
          className="accountSummary_crossModalIcon"
          onClick={handleClose}
        /> */}
        <div className="summaryModalButtons">
          <Button
            className="buttonDanger"
            variant="outlined"
            onClick={handleClose}
            color="error"
            sx={{
              width: 100,
              height: 35,
              fontSize: 12,
              ":hover": {
                bgcolor: "red",
                color: "white"
              }
            }}
          >
            Cancel
          </Button>
          <Button
            className="buttonSuccess"
            variant="outlined"
            disabled={name?.length > 0 ? false : true}
            onClick={onSaveHandler}
            color="success"
            sx={{
              width: 100,
              height: 35,
              fontSize: 12,
              ":hover": {
                bgcolor: "green",
                color: "white"
              }
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CompanyModal;
