import React from 'react';
import { useSelector } from 'react-redux';

function VAMSelectedRoles() {
  const { SelectedRoles } = useSelector((state) => ({
    SelectedRoles: state.ViewAdminDashboardReducer.SelectedRoles
  }));
  return (
    <div className='viewAdminCardContainer adminViewDashboardHalfWidth'>
      <div>
      <div className='addNewProductContainerCard'>
        <div className='adminAddProductCardHeader'>
          <span className='cardHeaderText'>Selected Roles</span>
        </div>
        <div className='adminViewDashboardCardForm'>
          <div className='adminViewDashboardCardFormColumnFirst'>
            <div className='adminViewDashboardCardFormContent'>
              <span className='adminViewDashboardCardFormTitle'>Workflow</span>
              <span className='adminViewDashboardCardFormValue'>
                {SelectedRoles.workflow.join(" | ")}
              </span>
            </div>
            <div className='adminViewDashboardCardFormContent'>
              <span className='adminViewDashboardCardFormTitle'>Roles</span>
              <span className='adminViewDashboardCardFormValue'>
                {SelectedRoles.roles.join(" | ")}
              </span>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
}

export default VAMSelectedRoles;