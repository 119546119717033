import { pageTitle_actionTypes } from "./utils/pageTitle_actionType";
import { pageNavigation_actionType } from "./utils/pageNavigation_actionType";
import { React, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from "@mui/material/Button";
import "./styles/Home.css";

import AccountSummary from "./AccountSummary";
import CreateModifyScreen from "./CreateModifyScreen/CreateModifyScreen";
import ModalMainContainer from "./ModalNotification/ModalMainContainer";
import HomeHeader from "./HomeHeader/HomeHeader";

import { getTrainings } from "../redux/actions/userAccount-actions/summary-action";
import { setUserDetail } from "../redux/actions/userAccount-actions/userDetail-action";
import { currentEnvironment } from "./utils/currentEnvironment";
import { getVeevaDomainName } from "./utils/getVeevaDomainName";
import { submitUserAccountRequest } from "../redux/actions/userAccount-actions/submitUserAccountRequest-action";
import { affiliateResources } from "../redux/actions/userAccount-actions/affiliateResources-action";
import { userAccessResources } from "../redux/actions/userAccount-actions/userAccessResources-action";
import CircularProgressLoader from "../components/common/CircularProgressLoader";
import { allUserDetailResources } from "../redux/actions/userAccount-actions/allUserDetailResources-action";
import { checkAccountStatus } from "../redux/actions/userAccount-actions/checkAccountStatusResources-action";

export default function Home(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pageTitle, setPageTitle] = useState("");
  const [pageNavigationList, setPageNavigationList] = useState([]);
  const [taskCompleted, setTaskCompleted] = useState(-1);
  const [currentTask, setCurrentTask] = useState(0);
  const [isSubmitActive, setIsSubmitActive] = useState(false);
  const [modalMessage, setModalMessage] = useState([]);

  const dispatch = useDispatch();

  const userOnboarded = props.user;
  const env = currentEnvironment();
  const veevaDomanName = getVeevaDomainName(env);

  const isUserOnboarded = userOnboarded !== null;
  let isLilly = false;
  if (
    userOnboarded?.mail?.toLowerCase()?.includes("@lilly.com") ||
    userOnboarded?.email?.toLowerCase()?.includes("@lilly.com")
  ) {
    isLilly = true;
  }
  const systemId = userOnboarded?.onPremisesSamAccountName;

  const { pageMarkedCompleted, initialPageTitle, completeState } = useSelector(
    (state) => ({
      pageMarkedCompleted: [
        state.UserDetailReducer.requiredFieldAvailable &&
          Object.keys(state.UserDetailReducer.errorMsg).length === 0 &&
          state.SelectAffiliateReducer.requiredFieldAvailable,
        state.WorkFlowRolesReducer.requiredFieldAvailable,
        state.ProductReducer.requiredFieldAvailable ||
          !state.ProductReducer.isMandatoryField,
        state.UserDetailReducer.requiredFieldAvailable &&
          state.SelectAffiliateReducer.requiredFieldAvailable &&
          state.WorkFlowRolesReducer.requiredFieldAvailable &&
          (state.ProductReducer.requiredFieldAvailable ||
            !state.ProductReducer.isMandatoryField) &&
          (!state.UserDetailReducer.data.isOnboardedUser
            ? state.SummaryReducer.selectedSponsor.length
            : true)
      ],
      initialPageTitle: state.UserDetailReducer.data.actionType,
      completeState: state
    })
  );
  // all useEffects
  useEffect(() => {
    dispatch(
      checkAccountStatus({
        requestType: userOnboarded?.isExist ? "reactivate" : "create",
        requestedFor: userOnboarded
          ? userOnboarded.mail || userOnboarded.email
          : "",
        requestStatus: "pending"
      })
    );
    dispatch(allUserDetailResources());
    dispatch(
      affiliateResources({
        userType: isLilly ? "Lilly" : "Non-Lilly"
      })
    );
    dispatch(getTrainings());
    setCurrentTask(0);
    setTaskCompleted(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (pageTitle === pageTitle_actionTypes.ACCOUNT_REQUEST_SUMMARY) {
      if (!pageMarkedCompleted[0]) {
        setTaskCompleted(0);
        setCurrentTask(0);
      } else if (!pageMarkedCompleted[1]) {
        setTaskCompleted(1);
        setCurrentTask(1);
      } else if (!pageMarkedCompleted[2]) {
        setTaskCompleted(2);
        setCurrentTask(2);
      } else {
        setTaskCompleted(3);
        setCurrentTask(3);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageMarkedCompleted[0], pageMarkedCompleted[1], pageMarkedCompleted[2]]);

  useEffect(() => {
    setPageNavigationList([]);
    setPageNavigationList((prevData) => [
      ...prevData,
      pageNavigation_actionType.ACCOUNT_REQUEST_FORM
    ]);
    if (initialPageTitle === "CREATE") {
      setPageTitle(pageTitle_actionTypes.CREATE_USER_ACCOUNT);
      setPageNavigationList((prevData) => [
        ...prevData,
        pageNavigation_actionType.CREATE_USER_ACCOUNT
      ]);
    } else {
      setPageTitle(pageTitle_actionTypes.MODIFY_USER_ACCOUNT);
      setPageNavigationList((prevData) => [
        ...prevData,
        pageNavigation_actionType.MODIFY_USER_ACCOUNT
      ]);
    }
    setPageNavigationList((prevData) => [
      ...prevData,
      pageNavigation_actionType.USER_DETAILS
    ]);
  }, [initialPageTitle]);

  useEffect(() => {
    if (userOnboarded) {
      let payload;
      if (!userOnboarded.isExist) {
        /*MSGraph basis payload*/
        payload = {
          id: userOnboarded?.id,
          company: isLilly ? "Lilly" : "",
          veevaUserName: isUserOnboarded ? systemId + veevaDomanName : "",
          firstName: userOnboarded?.givenName,
          lastName: userOnboarded?.surname,
          userType: isLilly ? "Lilly" : "Non-Lilly",
          emailId: userOnboarded?.mail,
          timezone: "",
          language: "",
          locale: "",
          location: userOnboarded?.country,
          deactivated: false,
          isLilly: isLilly,
          actionType: "CREATE",
          isExist: false,
          globalId: userOnboarded?.employeeId,
          systemID: systemId,
          isOnboardedUser:
            userOnboarded?.mail?.toLowerCase().includes("@lilly.com") ||
            userOnboarded?.mail?.toLowerCase().includes("@network.lilly.com")
              ? true
              : false
        };
      } else {
        /*DB API basis payload*/
        payload = {
          id: userOnboarded?.user_id,
          company: isLilly ? "Lilly" : userOnboarded?.company,
          veevaUserName: userOnboarded?.veeva_username,
          firstName: userOnboarded?.first_name,
          lastName: userOnboarded?.last_name,
          userType: isLilly ? "Lilly" : "Non-Lilly",
          emailId: userOnboarded?.email,
          timezone: userOnboarded?.timezone,
          language: userOnboarded?.language,
          locale: userOnboarded?.locale,
          location: userOnboarded?.location,
          deactivated: !userOnboarded?.active_vault_account,
          isLilly: isLilly,
          actionType: "MODIFY",
          isExist: true,
          globalId: userOnboarded?.employeeId,
          systemID: userOnboarded?.federated_id,
          isOnboardedUser: userOnboarded?.is_on_boarded
        };
      }
      dispatch(setUserDetail(payload));
      /******INFO's in MODIFY CASE*******/
      if (
        userOnboarded &&
        userOnboarded.isExist &&
        userOnboarded.user_id &&
        typeof userOnboarded.id === "undefined"
      ) {
        dispatch(
          userAccessResources({
            userId: userOnboarded.user_id,
            userType: isLilly ? "Lilly" : "Non-Lilly"
          })
        );
      }
      /**********************************/
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userOnboarded]);

  // all JS Function
  const handelBackButton = () => {
    if (
      pageTitle === pageTitle_actionTypes.CREATE_USER_ACCOUNT ||
      pageTitle === pageTitle_actionTypes.MODIFY_USER_ACCOUNT
    ) {
      if (currentTask === 0) props.togglePage();
      else {
        setCurrentTask(currentTask - 1);
        setTaskCompleted(taskCompleted - 1);
        setPageNavigationList(
          pageNavigationList.slice(0, pageNavigationList.length - 1)
        );
      }
    } else if (pageTitle === pageTitle_actionTypes.ACCOUNT_REQUEST_SUMMARY) {
      handleOpen(true);
      setIsSubmitActive(false);
      setModalMessage(["Are You Sure You Want To Cancel This Request?"]);
    }
  };
  const handelNextButton = () => {
    if (
      pageTitle === pageTitle_actionTypes.CREATE_USER_ACCOUNT ||
      pageTitle === pageTitle_actionTypes.MODIFY_USER_ACCOUNT
    ) {
      //Deactivation Handle
      if (
        userOnboarded?.isExist &&
        !userOnboarded?.active_vault_account &&
        currentTask === 0
      ) {
        setIsModalOpen(true);
        setModalMessage(["Are You Sure You Want To Reactivate Account?"]);
      } else {
        setCurrentTask(currentTask + 1);
        setTaskCompleted(taskCompleted + 1);
        if (currentTask + 1 === 1)
          setPageNavigationList((prevData) => [
            ...prevData,
            pageNavigation_actionType.USER_ROLES
          ]);
        else if (currentTask + 1 == 2)
          setPageNavigationList((prevData) => [
            ...prevData,
            pageNavigation_actionType.SELECT_PRODUCTS
          ]);
        else if (currentTask + 1 == 3) {
          setPageNavigationList((prevData) => [
            ...prevData,
            pageNavigation_actionType.SUMMARY
          ]);
          handelUpdatePageTitle(pageTitle_actionTypes.ACCOUNT_REQUEST_SUMMARY);
        } else if (currentTask + 1 == 4) {
          handelUpdatePageTitle(pageTitle_actionTypes.ACCOUNT_REQUEST_SUMMARY);
        }
      }
    } else if (pageTitle === pageTitle_actionTypes.ACCOUNT_REQUEST_SUMMARY) {
      let response = null;
      if (initialPageTitle === "CREATE")
        response = dispatch(submitUserAccountRequest(completeState, "create"));
      else
        response = dispatch(submitUserAccountRequest(completeState, "modify"));
      if (response) {
        setModalMessage(completeState.SubmitUserAccountReducer.message);
        setIsSubmitActive(true);
        setTimeout(() => {
          handleOpen(true);
        }, 2500);
      }
    }
  };
  const handelUpdatePageTitle = (text) => {
    setPageTitle(text);
  };
  const handleOpen = () => {
    setIsModalOpen(true);
  };

  const handleReactivate = () => {
    setIsModalOpen(false);
    let response = dispatch(
      submitUserAccountRequest(completeState, "reactivate")
    );
    if (response) {
      setModalMessage(completeState.SubmitUserAccountReducer.message);
      setIsSubmitActive(true);
      setTimeout(() => {
        setIsModalOpen(true);
      }, 2500);
    }
  };

  const {
    isUserAccessResourceLoading,
    isAffiliateResourceLoading,
    isUserDetailResourceLoading,
    isCheckAccountResourceLoading
  } = useSelector((state) => ({
    isAffiliateResourceLoading: state.AffiliateResourcesReducer.loading,
    isUserAccessResourceLoading: state.UserAccessResourcesReducer.loading,
    isUserDetailResourceLoading: state.AllUserDetailResourcesReducer.loading,
    isCheckAccountResourceLoading:
      state.CheckAccountStatusResourcesReducer.loading
  }));

  if (
    isAffiliateResourceLoading ||
    isUserDetailResourceLoading ||
    isUserAccessResourceLoading ||
    isCheckAccountResourceLoading
  ) {
    return <CircularProgressLoader styles={{ position: "absolute" }} />;
  }

  return (
    <div className="homeContainer">
      <HomeHeader
        pageTitle={pageTitle}
        pageNavigationList={pageNavigationList}
        currentTask={currentTask}
        taskCompleted={taskCompleted}
        setCurrentTask={setCurrentTask}
        setTaskCompleted={setTaskCompleted}
        setPageNavigationList={setPageNavigationList}
        toggleToLandingPage={props.togglePage}
      />
      <div className="homeContainerCards">
        {pageTitle === pageTitle_actionTypes.CREATE_USER_ACCOUNT ||
        pageTitle === pageTitle_actionTypes.MODIFY_USER_ACCOUNT ? (
          <CreateModifyScreen
            currentTask={currentTask}
            pageTitle={pageTitle}
            isUserOnboarded={isUserOnboarded}
            toggleToLandingPage={props.togglePage}
            completeState={completeState}
          />
        ) : null}
        {pageTitle === pageTitle_actionTypes.ACCOUNT_REQUEST_SUMMARY ? (
          <div className="card">
            <AccountSummary
              pageTitle={pageTitle}
              isUserOnboarded={isUserOnboarded}
              toggleToLandingPage={props.togglePage}
            />
          </div>
        ) : null}
      </div>
      <div className="homeContainerButtons">
        <Button
          className="buttonDanger"
          variant="outlined"
          onClick={handelBackButton}
          color="error"
          sx={{
            width: 115,
            height: 40,
            ":hover": {
              bgcolor: "red",
              color: "white"
            }
          }}
        >
          {pageTitle === pageTitle_actionTypes.ACCOUNT_REQUEST_SUMMARY
            ? "Cancel"
            : "Back"}
        </Button>
        <Button
          className="buttonSuccess"
          variant="outlined"
          onClick={handelNextButton}
          color="success"
          disabled={!pageMarkedCompleted[currentTask]}
          sx={{
            width: 115,
            height: 40,
            ":hover": {
              bgcolor: "green",
              color: "white"
            }
          }}
        >
          {pageTitle === pageTitle_actionTypes.ACCOUNT_REQUEST_SUMMARY
            ? "Submit"
            : "Next"}
        </Button>
      </div>
      <ModalMainContainer
        isModalOpen={isModalOpen}
        isSubmitSuceess={completeState.SubmitUserAccountReducer.isSubmitSuccess}
        setIsModalOpen={setIsModalOpen}
        isSubmitActive={isSubmitActive}
        reactivate={
          userOnboarded &&
          userOnboarded.isExist &&
          !userOnboarded.active_vault_account
            ? true
            : false
        }
        handleReactivate={handleReactivate}
        modalMessage={
          isSubmitActive
            ? completeState.SubmitUserAccountReducer.message
            : modalMessage
        }
        toggleToLandingPage={props.togglePage}
      />
    </div>
  );
}
