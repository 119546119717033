/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import SelectDropdownInput from "../../components/common/SelectDropdownInput";
import { TextFieldInput } from "../../components/common/TextFieldInput";
import { useDispatch, useSelector } from "react-redux";
import { setUserDetail } from "../../redux/actions/userAccount-actions/userDetail-action";
import { currentEnvironment } from "../utils/currentEnvironment";
import { getVeevaDomainName } from "../utils/getVeevaDomainName";
import CustomModal from "../../components/common/CustomModal";
import DeactivateModalNotice from "./DeactivateModalNotice";
import { submitUserAccountRequest } from "../../redux/actions/userAccount-actions/submitUserAccountRequest-action";

export const UserDetailsSection = (props) => {
  const dispatch = useDispatch();
  const { completeState } = useSelector((state) => ({
    completeState: state
  }));
  const env = currentEnvironment();
  const veevaDomanName = getVeevaDomainName(env);
  const [localeDropdown, setLocaleDropdown] = useState([]);
  const [isSubmitActive, setSubmitActive] = useState(false);
  const {
    userDetails,
    isUserOnboarded,
    pageTitle,
    toggleToLandingPage,
    allUserDetailResources
  } = props;

  const initialUserDetailsData = {
    company: "",
    veevaUserName: "",
    firstName: "",
    lastName: "",
    userType: "",
    emailId: "",
    timezone: "",
    language: "",
    locale: "",
    location: ""
  };

  const [userDetailsFormData, setUserDetailsFormData] = useState(
    initialUserDetailsData
  );
  const [isPending, setIsPending] = useState(false);

  let TIMEZONE_DROPDOWN = [];
  let COMPANIES_DROPDOWN = [];
  let LANGUAGES_DROPDOWN = [];

  const isAccountStatusPending =
    completeState.CheckAccountStatusResourcesReducer?.data[0]?.requestStatus === "pending";
  const isCreationInProgress =
    isAccountStatusPending &&
    completeState.UserDetailReducer?.data.actionType === "CREATE";
  const isReactivationInProgress =
    isAccountStatusPending &&
    completeState.UserDetailReducer?.data.actionType === "MODIFY";

  if (
    allUserDetailResources &&
    Array.isArray(allUserDetailResources) &&
    allUserDetailResources.length
  ) {
    allUserDetailResources.map((item) => {
      item.timezones.map((timezone, idx) => {
        const dropDown = {
          id: idx,
          value: timezone.name,
          title: timezone.name
        };
        TIMEZONE_DROPDOWN.push(dropDown);
      });

      item.companies.map((company, idx) => {
        const dropDown = {
          id: idx,
          value: company.name,
          title: company.name
        };
        COMPANIES_DROPDOWN.push(dropDown);
      });

      item.languages.map((language) => {
        const dropDown = {
          id: language.abbreviation,
          value: language.abbreviation,
          title: language.name
        };
        LANGUAGES_DROPDOWN.push(dropDown);
      });
    });
  }

  function setAllUserDetail() {
    let userDetailObject = {};
    for (let key in userDetailsFormData) {
      if (key === "userType") {
        userDetailObject[key] = !isUserOnboarded
          ? "Non-Lilly"
          : userDetails[key];
      } else {
        userDetailObject[key] = userDetails[key];
      }
    }
    setUserDetailsFormData(userDetailObject);
  }

  useEffect(() => {
    if (isReactivationInProgress) {
      setIsPending(true);
      setIsModalErr(false);
      setIsTwo(false);
      setModalMessage([
        `Your account reactivation request (${completeState.CheckAccountStatusResourcesReducer?.data[0]?.requestName}) is in progress..`,
        "Please stay tuned!"
      ]);
      setIsModalOpen(true);
    } else if (isCreationInProgress) {
      setIsPending(true);
      setIsModalErr(false);
      setIsTwo(false);
      setModalMessage([
        `Your account creation request (${completeState.CheckAccountStatusResourcesReducer?.data[0]?.requestName}) is in progress..`,
        "Please stay tuned!"
      ]);
      setIsModalOpen(true);
    } else if (userDetails.deactivated) {
      setDeactivateFlag(true);
      setSubmitActive(false);
      setModalMessage([
        "Your account is deactivated!!",
        "Reactivate it by clicking firstly on reactivate button,",
        "then after select one of the affilate you have access,",
        "finally click Next button and confirm the request submission."
      ]);
      setIsModalErr(false);
      setIsTwo(false);
      setIsModalOpen(true);
    }
    setIsLilly(userDetails.isLilly);
    setAllUserDetail();
  }, []);

  const {
    company,
    veevaUserName,
    firstName,
    lastName,
    userType,
    emailId,
    timezone,
    language,
    locale,
    location
  } = userDetailsFormData;

  const [isLilly, setIsLilly] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalErr, setIsModalErr] = useState(true);
  const [modalMessage, setModalMessage] = useState([]);
  const [isTwo, setIsTwo] = useState(false);
  const [deactivateFlag, setDeactivateFlag] = useState(false);

  const langIdentify = LANGUAGES_DROPDOWN.find(
    (lng) => lng.id === userDetails?.language
  );

  const findSelectedLanguage = langIdentify?.value;
  const languageName = langIdentify?.title;

  const localeIdentify = allUserDetailResources
    .map((item) => item.languages)[0]
    .find((lng) => lng.abbreviation === userDetails?.language)
    ?.vvpmlocales.find((lcl) => lcl.code === userDetails?.locale);

  const findSelectedLocale = localeIdentify?.code;
  const localeName = localeIdentify?.name;

  const [selectedLanguage] = useState(findSelectedLanguage);
  const [selectedLocale] = useState(findSelectedLocale);

  const modifiedUserDetails = JSON.stringify({
    company,
    veevaUserName,
    firstName,
    lastName,
    userType,
    emailId,
    timezone,
    language,
    locale,
    location,
    languageName,
    localeName
  });

  useEffect(() => {
    const modifiedUserDetailsParsed = JSON.parse(modifiedUserDetails);
    const payload = {
      ...modifiedUserDetailsParsed,
      id: userDetails.id,
      deactivated: userDetails.deactivated,
      isLilly: userDetails.isLilly,
      actionType: userDetails.actionType,
      isExist: userDetails.isExist,
      systemID: userDetails.systemID,
      globalId: userDetails.globalId,
      isOnboardedUser: userDetails.isOnboardedUser,
      languageName: languageName,
      localeName: localeName
    };
    dispatch(setUserDetail(payload));
  }, [modifiedUserDetails]);

  const inputChangeHandler = (event) => {
    const { name, value } = event.target;
    if (!isUserOnboarded) {
      if (emailId && emailId.includes("@")) {
        const emailSegment = emailId.split("@");
        setUserDetailsFormData({
          ...userDetailsFormData,
          veevaUserName: emailSegment[0] + veevaDomanName,
          [name]: value
        });
      } else {
        setUserDetailsFormData({
          ...userDetailsFormData,
          veevaUserName: "",
          [name]: value
        });
      }
    } else {
      setUserDetailsFormData({
        ...userDetailsFormData,
        [name]: value
      });
    }
  };

  const modalActionButtonOne = () => {
    setIsModalOpen(false);
    setIsModalErr(false);
    setIsTwo(false);
    let response = dispatch(
      submitUserAccountRequest(completeState, "deactivate")
    );
    if (response) {
      setModalMessage(completeState.SubmitUserAccountReducer.message);
      setSubmitActive(true);
      setTimeout(() => {
        setIsModalOpen(true);
      }, 3000);
    }
  };

  useEffect(() => {
    if (language) {
      allUserDetailResources.map((item) => {
        const localeFound = item.languages.find(
          (lang) =>
            typeof language !== "undefined" && lang.abbreviation === language
        )?.vvpmlocales;
        const prepareLocaleDropDown = localeFound?.map((locale) => ({
          id: locale.code,
          value: locale.code,
          title: locale.name
        }));
        setLocaleDropdown(prepareLocaleDropDown);
      });
    }
  }, [language]);

  const modalActionButtonTwo = () => {
    setIsModalOpen(false);
    setModalMessage([]);
  };

  const deactivateAccountHandler = () => {
    setIsModalOpen(true);
    setIsModalErr(true);
    setIsTwo(true);
    setModalMessage(["Are you sure you want to deactivate this user account?"]);
  };

  return (
    <>
      <CustomModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
        <DeactivateModalNotice
          error={isModalErr}
          success={!isModalErr}
          message={
            isSubmitActive
              ? completeState.SubmitUserAccountReducer.message
              : modalMessage
          }
          twoButton={isTwo}
          isPending={isPending}
          deactivate={deactivateFlag}
          toggleToLandingPage={toggleToLandingPage}
          setParentModalOpen={(flag) => setIsModalOpen(flag)}
          buttonTwoText={deactivateFlag ? "REACTIVATE" : "YES"}
          buttonOneAction={modalActionButtonOne}
          buttonTwoAction={modalActionButtonTwo}
        />
      </CustomModal>
      {/* User details section */}
      <div className="userDetailsCardMain">
        <div className="userDetailsCard_header">
          <span>User Details</span>
          {userDetails?.isExist && !userDetails?.deactivated ? (
            <>
              <span className="userDetails_deactivateAccountInstruction">
                <span className="userDetails_note">
                  <em>Note:</em>
                </span>{" "}
                <span className="userDetails_red"> ** </span>{" "}
                <em>
                  For Deactivate account request, please click on Deactivate
                  Account button. For modify account request, click on Next
                  button.
                </em>
              </span>
            </>
          ) : null}
        </div>
        {userDetails?.isExist && !userDetails?.deactivated ? (
          <div className="userDetails_deactivateButtonContainer">
            <Button
              sx={{
                padding: "0.5rem 1rem",
                // marginRight: "5rem",
                color: "#D52B1E",
                background: "#fff",
                "&:hover": { background: "#D52B1E", color: "#fff" }
              }}
              className="userDetails_deactivateAccount"
              variant="outlined"
              color="error"
              onClick={deactivateAccountHandler}
            >
              Deactivate Account
            </Button>
          </div>
        ) : null}
        <div className="userDetailsCard_body">
          <Grid
            container
            rowSpacing={1}
            columnSpacing={6}
            className="userDetails_grid"
          >
            <Grid item sm={12} md={4} className="userDetails_grid_item">
              <TextFieldInput
                id="first-name"
                name="firstName"
                label="First Name"
                value={firstName}
                onChange={inputChangeHandler}
                disabled={isUserOnboarded}
              />
            </Grid>
            <Grid item sm={12} md={4} className="userDetails_grid_item">
              <TextFieldInput
                id="last-name"
                name="lastName"
                label="Last Name"
                value={lastName}
                onChange={inputChangeHandler}
                disabled={isUserOnboarded}
              />
            </Grid>
            <Grid item sm={12} md={4} className="userDetails_grid_item">
              <TextFieldInput
                id="email-id"
                name="emailId"
                label="Email Id"
                value={emailId}
                error={
                  Object.keys(completeState.UserDetailReducer.errorMsg).length
                  && completeState.UserDetailReducer.errorMsg["emailId"]
                }
                helperText={
                  Object.keys(completeState.UserDetailReducer.errorMsg).length
                    && completeState.UserDetailReducer.errorMsg["emailId"]
                    ? completeState.UserDetailReducer.errorMsg["emailId"]
                    : ""
                }
                onChange={inputChangeHandler}
                disabled={isUserOnboarded}
              />
            </Grid>
            <Grid item sm={12} md={4} className="userDetails_grid_item">
              <TextFieldInput
                id="veeva-user-name"
                name="veevaUserName"
                label="Veeva Username"
                value={veevaUserName}
                onChange={inputChangeHandler}
                disabled
              />
            </Grid>
            <Grid item sm={12} md={4} className="userDetails_grid_item">
              <TextFieldInput
                id="user-type"
                name="userType"
                label="User Type"
                value={userType}
                onChange={inputChangeHandler}
                disabled
              />
            </Grid>
            <Grid item sm={12} md={4} className="userDetails_grid_item">
              <TextFieldInput
                id="location"
                name="location"
                label="Location"
                value={location}
                onChange={inputChangeHandler}
                disabled={isUserOnboarded}
              />
            </Grid>
            {(!isUserOnboarded || (isUserOnboarded && !isLilly)) && (
              <Grid item sm={12} md={4} className="userDetails_grid_item">
                <SelectDropdownInput
                  id="select-company"
                  name="company"
                  label="Select Company"
                  options={COMPANIES_DROPDOWN}
                  selectedOption={company}
                  onChange={inputChangeHandler}
                  disabled={userDetails?.isExist}
                  type={
                    pageTitle === "Create User Account"
                      ? "userDetailsMenuList"
                      : ""
                  }
                />
              </Grid>
            )}
            <Grid item sm={12} md={4} className="userDetails_grid_item">
              <SelectDropdownInput
                id="language"
                name="language"
                label="Language"
                options={LANGUAGES_DROPDOWN}
                selectedOption={selectedLanguage}
                onChange={inputChangeHandler}
                disabled={userDetails?.isExist}
                type={
                  pageTitle === "Create User Account"
                    ? "userDetailsMenuList"
                    : ""
                }
              />
            </Grid>
            <Grid item sm={12} md={4} className="userDetails_grid_item">
              <SelectDropdownInput
                id="time-zone"
                name="timezone"
                label="Timezone"
                options={TIMEZONE_DROPDOWN}
                selectedOption={timezone}
                onChange={inputChangeHandler}
                disabled={userDetails?.isExist}
                type={
                  pageTitle === "Create User Account"
                    ? "userDetailsMenuList"
                    : ""
                }
              />
            </Grid>
            <Grid item sm={12} md={4} className="userDetails_grid_item">
              <SelectDropdownInput
                id="locale"
                name="locale"
                label="Locale"
                options={localeDropdown}
                selectedOption={selectedLocale}
                onChange={inputChangeHandler}
                disabled={userDetails?.isExist}
                type={
                  pageTitle === "Create User Account"
                    ? "userDetailsMenuList"
                    : ""
                }
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};
