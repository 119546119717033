import {
  setAdminDashboardLoaderActive,
  setAdminDashboardLoaderInActive
} from "../loaderAction";
import { adminDashboard_actionType } from "../../actionTypes/adminAction-actionTypes/adminDashboard-actionType";
import { axiosApigateway } from "../../../api";
import { toLower, isEmpty } from "lodash";
export function resetAdminDashboardReducer() {
  return (dispatch) => {
    dispatch({
      type: adminDashboard_actionType.RESET_DASHBOARD_SUCCESS
    });
  };
}

const getRequestType = (responseRequestType) => {
  switch (responseRequestType) {
    case "create":
      return "Create Account";
    case "modify":
      return "Modify Account";
    case "reactivate":
      return "Reactivate Account";
    case "deactivate":
      return "Deactivate Account";
    default:
      return "Unknown";
  }
};

const getCurrentStatus = (responseCurrentStatus) => {
  switch (responseCurrentStatus) {
    case "complete":
      return "Completed";
    case "pending":
      return "Pending";
    case "rejected":
      return "Rejected";
    case "failed":
      return "Failed";
    case "approved":
      return "Approved";
    case "terminated":
      return "Terminated";
    default:
      return "Unknown";
  }
};

const createFormatedData = (responseData) => {
  let data = [];
  responseData.data.forEach((element) => {
    let object = {
      requestID: element.request_id,
      userName: element.request_payload?.email,
      fullName: element.request_payload?.display_name,
      globalID: element.request_payload?.federated_id,
      userType: element.request_payload?.user_type,
      requestType: getRequestType(element.requestType),
      currentStatus: getCurrentStatus(element.request_status),
      affiliate: element.request_payload?.users_access_payload?.name,
      lastActionDate: element.updatedAt
    };
    data.push(object);
  });

  return data;
};

const createFormatedDataForDownload = (dataArr) => {
  let data = [];
  dataArr.forEach((element) => {
    let object = {
      "Request ID": element.request_id || element.requestID,
      "User Name": element.request_payload?.email || element.userName,
      "Full Name": element.request_payload?.display_name || element.fullName,
      "Global ID": element.request_payload?.federated_id || element.globalID,
      "User Type": element.request_payload?.user_type || element.userType,
      "Request Type": element.requestID
        ? element.requestType
        : getRequestType(element.requestType),
      "Current Status":
        element.currentStatus || getCurrentStatus(element.request_status),
      Affiliate:
        element.affiliate ||
        element.request_payload?.users_access_payload?.name,
      "Last Action Date": element.updatedAt || element.lastActionDate
    };
    data.push(object);
  });

  return data;
};

function getFilters(affiliates) {
  const filters = [];
  // 0th affiliate type
  filters.push({
    filterType: "abbreviation",
    name: "Affiliate Type",
    value: affiliates.map(({ abbreviation, name }) => ({
      name,
      type: abbreviation,
      checked: false
    }))
  });
  // 1st user type
  filters.push({
    filterType: "user_type",
    name: "User Type",
    value: [
      { name: "Lilly", checked: false, type: "Lilly" },
      { name: "Non-Lilly", checked: false, type: "Non-Lilly" }
    ]
  });
  //2nd request type
  filters.push({
    filterType: "requestType",
    name: "Request Type",
    value: [
      { name: "Create Account", checked: false, type: "create" },
      { name: "Modify Account", checked: false, type: "modify" },
      { name: "Reactivate Account", checked: false, type: "reactivate" },
      { name: "Deactivate Account", checked: false, type: "deactivate" }
    ]
  });
  //3rd status
  filters.push({
    filterType: "request_status",
    name: "Status",
    value: [
      { name: "Completed", checked: false, type: "complete" },
      { name: "Pending", checked: false, type: "pending" },
      { name: "Rejected", checked: false, type: "rejected" },
      { name: "Failed", checked: false, type: "failed" },
      { name: "Approved", checked: false, type: "approved" },
      { name: "Terminated", checked: false, type: "terminated" }
    ]
  });
  return filters;
}

export function getAdminRequestStatisticsAndFilters() {
  return async (dispatch) => {
    dispatch(setAdminDashboardLoaderActive());
    try {
      const responseAdminRequestDetail = await axiosApigateway.get(
        "/admin-request-details"
      );
      const affiliates = await axiosApigateway.get("/hubs");
      const allFilters = getFilters(affiliates?.data?.data);
      dispatch({
        type: adminDashboard_actionType.GET_ADMIN_REQUEST_DETAIL_SUCCESS,
        data: responseAdminRequestDetail.data,
        filterQuery: allFilters
      });

      dispatch(setAdminDashboardLoaderInActive());
      return { success: true };
    } catch (err) {
      dispatch({
        type: adminDashboard_actionType.GET_DASHBOARD_FAILURE,
        data: err
      });
      dispatch(setAdminDashboardLoaderInActive());
      return { success: false };
    }
  };
}
function setAdminTableLoaderActive() {
  return {
    type: adminDashboard_actionType.SET_DASHBOARD_TABLE_LOADER
  };
}
function setAdminTableLoaderInActive() {
  return {
    type: adminDashboard_actionType.RESET_DASHBOARD_TABLE_LOADER
  };
}

function isUniqueRequestPresent(allRequests, searchText) {
  if (
    toLower(searchText)?.trim()?.length === 8 &&
    toLower(searchText)?.trim().startsWith("vam")
  ) {
    allRequests = allRequests.filter((obj) => {
      return toLower(obj.requestID) === toLower(searchText);
    });
    if (allRequests?.length === 1) {
      // check if filters are also satisfy or not

      return { status: true, request: allRequests };
    }
  }
  return { status: false, request: allRequests };
}

export function getAdminDashboardRequests(
  page = 1,
  searchText,
  filterObject,
  curRequests,
  pageSize = 100
) {
  return async (dispatch) => {
    dispatch(setAdminTableLoaderActive());

    try {
      // try to get all filters

      const filterToSend = {};
      filterObject.forEach((ele) => {
        const filtersArr = ele.value.filter((val) => val.checked);
        if (filtersArr?.length > 0) {
          filterToSend[ele.filterType] = filtersArr
            .map((e) => e.type)
            .join(",");
        }
      });

      const encodedFiltersString = encodeURIComponent(
        JSON.stringify(filterToSend)
      );

      //find if data present in current page
      // locally searched id available -> yes
      if (searchText?.trim()?.length > 0) {
        const { status, request } = isUniqueRequestPresent(
          curRequests,
          searchText
        );
        if (status) {
          //check all filters weather this unique request satisfy it or not.
          console.log(request);
          console.log(filterObject);
          console.log(filterToSend);
          let requestSatisfyFilters = true;
          if (requestSatisfyFilters && filterToSend?.abbreviation) {
            //check weather abrivation filter is satisfied by unique request
            const filter1 = filterObject[0]?.value
              ?.filter((val) => val.checked === true)
              ?.filter((val) => val.name === request[0]?.affiliate);

            if (filter1?.length <= 0) {
              requestSatisfyFilters = false;
            }
          }
          if (requestSatisfyFilters && filterToSend?.requestType) {
            //check weather request type filter is satisfied by unique request
            const filter2 = filterObject[2]?.value
              ?.filter((val) => val.checked === true)
              ?.filter((val) => val.name === request[0]?.requestType);
            if (filter2?.length <= 0) {
              requestSatisfyFilters = false;
            }
          }
          if (requestSatisfyFilters && filterToSend?.user_type) {
            //check weather user type filter is satisfied by unique request
            const filter3 = filterObject[1]?.value
              ?.filter((val) => val.checked === true)
              ?.filter((val) => val.name === request[0]?.userType);
            if (filter3?.length <= 0) {
              requestSatisfyFilters = false;
            }
          }
          if (requestSatisfyFilters && filterToSend?.request_status) {
            //check weather request status filter is satisfied by unique request
            const filter4 = filterObject[3]?.value
              ?.filter((val) => val.checked === true)
              ?.filter((val) => val.name === request[0]?.currentStatus);
            if (filter4?.length <= 0) {
              requestSatisfyFilters = false;
            }
          }
          if (requestSatisfyFilters) {
            dispatch({
              type: adminDashboard_actionType.GET_DASHBOARD_SUCCESS,
              data: request,
              requestsCount: 1,
              curPage: 1,
              isFilterApplied: !isEmpty(filterToSend)
            });
            dispatch(setAdminTableLoaderInActive());
            return { success: true };
          } else {
            dispatch({
              type: adminDashboard_actionType.GET_DASHBOARD_SUCCESS,
              data: [],
              requestsCount: 0,
              curPage: 1,
              isFilterApplied: !isEmpty(filterToSend)
            });
            dispatch(setAdminTableLoaderInActive());
            return { success: true };
          }
        }
      }

      const response = await axiosApigateway.get(
        `/admin-dashboard-request?$pagesize=${pageSize}&$page=${page}${
          !isEmpty(filterToSend) ? `&$filter=${encodedFiltersString}` : ""
        }${searchText && `&$search=${searchText?.trim()}`}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          }
        }
      );
      //   const responseAdminRequestDetail = await axiosApigateway.get(
      //     "/admin-request-details"
      //   );
      if (response) {
        let requiredData = createFormatedData(response.data);
        dispatch({
          type: adminDashboard_actionType.GET_DASHBOARD_SUCCESS,
          data: requiredData,
          responseStore: response.data,
          requestsCount: response.data?.count,
          pageSize: response.data?.$pagesize,
          isFilterApplied: !isEmpty(filterToSend),
          curPage: response?.data.$page

          // filterQuery: getUniqueFilterData(requiredData)
        });
        // dispatch({
        //   type: adminDashboard_actionType.GET_ADMIN_REQUEST_DETAIL_SUCCESS,
        //   data: responseAdminRequestDetail.data
        // });
        await new Promise((res) => setTimeout(res, 500));
        dispatch(setAdminTableLoaderInActive());
        return { response, success: true };
      }
    } catch (error) {
      dispatch({
        type: adminDashboard_actionType.GET_DASHBOARD_FAILURE,
        data: error
      });
      dispatch(setAdminTableLoaderInActive());
      return { success: false };
    }
  };
}

export function setGlobalSearchText(searchLocalText) {
  return async (dispatch) => {
    dispatch({
      type: adminDashboard_actionType.SET_DASHBOARD_SEARCH,
      data: searchLocalText
    });
  };
}

export function setDashboardFilters(filters) {
  return async (dispatch) => {
    dispatch({
      type: adminDashboard_actionType.SET_DASHBOARD_FILTERS,
      data: filters
    });
  };
}

export function getAllRequests(
  requestsCount,
  pageSize,
  csvLink,
  filterObject,
  searchText,
  curRequests,
  curPageSize
) {
  return async (dispatch) => {
    dispatch(setAdminTableLoaderActive());

    try {
      // to check if only one page exist for this request then no need to send request to backend and directly handle from UI
      if (curPageSize >= requestsCount) {
        dispatch({
          type: adminDashboard_actionType.CSV_DATA_SUCCESS,
          data: createFormatedDataForDownload(curRequests)
        });
        await new Promise((res) => setTimeout(res, 1000));
        csvLink.current.link.click();
        dispatch(setAdminTableLoaderInActive());
        return { success: true };
      }
      const filterToSend = {};
      filterObject.forEach((ele) => {
        const filtersArr = ele.value.filter((val) => val.checked);
        if (filtersArr?.length > 0) {
          filterToSend[ele.filterType] = filtersArr
            .map((e) => e.type)
            .join(",");
        }
      });
      const isFilterApplied = !isEmpty(filterToSend);
      const isSearchApplied = searchText?.trim().length > 0;
      const encodedFiltersString = isFilterApplied
        ? encodeURIComponent(JSON.stringify(filterToSend))
        : "";

      let allPagesUrl = [];
      for (let i = 0; i < requestsCount / pageSize; i++) {
        allPagesUrl.push(
          `/admin-dashboard-request?$pagesize=${pageSize}&$page=${i + 1}${
            isFilterApplied ? `&$filter=${encodedFiltersString}` : ""
          }${isSearchApplied ? `&$search=${searchText?.trim()}` : ""}`
        );
      }
      const responseData = await Promise.all(
        allPagesUrl.map((getUrl) => axiosApigateway.get(getUrl))
      );
      const rawData = responseData.flatMap((res) => res.data?.data);
      const data = createFormatedDataForDownload(rawData);
      dispatch({
        type: adminDashboard_actionType.CSV_DATA_SUCCESS,
        data: data
      });
      await new Promise((res) => setTimeout(res, 2000));
      csvLink.current.link.click();

      dispatch(setAdminTableLoaderInActive());
      return { success: true };
    } catch (error) {
      dispatch({
        type: adminDashboard_actionType.GET_DASHBOARD_FAILURE,
        data: error
      });
      dispatch(setAdminTableLoaderInActive());
      return { success: false };
    }
  };
}
export function setCurpage(value) {
  return async (dispatch) => {
    dispatch({ type: adminDashboard_actionType.SET_PAGE, value: value });
  };
}

// const getUniqueFilterData = (allRequests) => {
//   let uniqueAffiliateType = new Set();
//   let uniqueUserType = new Set();
//   let uniqueRequestType = new Set();
//   let uniqueCurrentStatus = new Set();

//   let uniqueAffiliateTypeList = [];
//   let uniqueUserTypeList = [];
//   let uniqueRequestTypeList = [];
//   let uniqueCurrentStatusList = [];
//   let filterQuery = filterQueryInitialState;

//   // getting unique values
//   allRequests.forEach((element) => {
//     uniqueAffiliateType.add(element.affiliate);
//     uniqueUserType.add(element.userType);
//     uniqueRequestType.add(element.requestType);
//     uniqueCurrentStatus.add(element.currentStatus);
//   });

//   //converting to list
//   uniqueAffiliateType.forEach((element) => {
//     if (element) uniqueAffiliateTypeList.push(element);
//   });
//   uniqueUserType.forEach((element) => {
//     if (element) uniqueUserTypeList.push(element);
//   });
//   uniqueRequestType.forEach((element) => {
//     if (element) uniqueRequestTypeList.push(element);
//   });
//   uniqueCurrentStatus.forEach((element) => {
//     if (element) uniqueCurrentStatusList.push(element);
//   });

//   filterQuery[0].value = uniqueAffiliateTypeList.map((object) => {
//     return {
//       name: object,
//       checked: false
//     };
//   });
//   filterQuery[1].value = uniqueUserTypeList.map((object) => {
//     return {
//       name: object,
//       checked: false
//     };
//   });
//   filterQuery[2].value = uniqueRequestTypeList.map((object) => {
//     return {
//       name: object,
//       checked: false
//     };
//   });
//   filterQuery[3].value = uniqueCurrentStatusList.map((object) => {
//     return {
//       name: object,
//       checked: false
//     };
//   });

//   return filterQuery;
// };

// const filterQueryInitialState = [
//   {
//     abbrebiation: "affiliateType",
//     name: "Affiliate Type",
//     value: []
//   },
//   {
//     abbrebiation: "userType",
//     name: "User Type",
//     value: []
//   },
//   {
//     abbrebiation: "requestType",
//     name: "Request Type",
//     value: []
//   },
//   {
//     abbrebiation: "currentStatus",
//     name: "Status",
//     value: []
//   }
// ];
