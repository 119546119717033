import { Grid, TextareaAutosize } from "@mui/material";
import React from "react";

const CommentsCard = ({ name, value, onChangeHandler, label, approvarComments, isDisabled }) => {
  return (
    <Grid
      item
      sm={12}
      md={6}
      xs={12}
      className="accountSummary_mainItemSection"
      style={{ height: "207px" }}
    >
      <div
        className={approvarComments ? "approver_commentsParent" : "accountSummary_commentsParent"}
        style={{ height: "190px" }}
      >
        <div className={approvarComments ? "approver_commentHeader" : "accountSummary_commentHeader"}>
          <span className={"accountSummary_mainHeading"}>{label} <em style={{left: '48%', position: 'relative', fontSize: '12px'}}>(limit: {255 - value?.length || 255})</em></span>
        </div>
        <div className="accountSummary_section">
          <TextareaAutosize
            className="acountSummary_commentBox"
            aria-label="minimum height"
            minRows={6}
            maxRows={6}
            maxlength={255}
            name={name}
            value={value}
            disabled={isDisabled}
            placeholder="Add your comment here..."
            onChange={onChangeHandler}
            style={isDisabled ? { backgroundColor: 'lightGray' } : {}}
          />
        </div>
      </div>
    </Grid>
  );
};

export default CommentsCard;
