import { axiosApigateway } from "../../../api";
import { allUserDetailResources_actionType } from "../../actionTypes/userAction-actionTypes/allUserDetailResources-actionType";

export function allUserDetailResourcesInitialize() {
  return {
    type: allUserDetailResources_actionType.ALL_USER_DETAIL_RESOURCES
  };
}

export function allUserDetailResourcesSuccess(data) {
  return {
    type: allUserDetailResources_actionType.ALL_USER_DETAIL_RESOURCES_SUCCESS,
    payload: data
  };
}

export function resetallUserDetailResources() {
  return (dispatch) => {
    dispatch({
      type: allUserDetailResources_actionType.ALL_AFFILIATE_RESOURCES_SUCCESS
    });
  };
}

export function allUserDetailResourcesError(error) {
  return {
    type: allUserDetailResources_actionType.ALL_USER_DETAIL_RESOURCES_ERROR,
    payload: error
  };
}

export function allUserDetailResources() {
  return async (dispatch) => {
    dispatch(allUserDetailResourcesInitialize());

    try {
      const response = await axiosApigateway.get("/vam-configuration");
      const responseData = response.data.data;
      dispatch(allUserDetailResourcesSuccess([responseData]));
    } catch (error) {
      dispatch(allUserDetailResourcesError(error));
    }
  };
}
