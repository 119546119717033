import { combineReducers } from 'redux';
import TestReducer from './test-reducer';
import CheckUserRoleReducer from './checkUserRole-reducer';
import LoggedUserReducer from './loggedUser-reducer';
import SearchUserResourcesReducer from './userAccount-reducers/searchUserResources-reducer';
import UserDetailReducer from './userAccount-reducers/userDetail-reducer';
import ProductReducer from './userAccount-reducers/product-reducer';
import AllAffiliatesReducer from './userAccount-reducers/allAffiliates-reducer';
import AffiliateCountriesReducer from './userAccount-reducers/affiliateCountries-reducer';
import SelectAffiliateReducer from './userAccount-reducers/selectAffiliate-reducer';
import WorkFlowRolesReducer from './userAccount-reducers/workFlowRoles-reducer';
import LoaderReducer from './loader-reducer';
import SubmitUserAccountReducer from './userAccount-reducers/submitUserAccount-reducer';
import SummaryReducer from './userAccount-reducers/summary-reducer';
import AffiliateResourcesReducer from './userAccount-reducers/affiliateResources-reducer';
import UserAccessResourcesReducer from './userAccount-reducers/userAccessResources-reducer';
import AllUserDetailResourcesReducer from './userAccount-reducers/allUserDetailResources-reducer';
import ApprovarReducer from './approvar-reducers/approvar-reducer';
import CheckAccountStatusResourcesReducer from './userAccount-reducers/checkAccountStatusResources-reducer';
import SubmitApprovarRequestReducer from './approvar-reducers/submitApprovaRequest-reducer';
import AdminDashboardReducer from './adminAccount-reducers/adminDashboard-reducer';
import ReassignAndTerminateReducer from './adminAccount-reducers/reassignAndTerminate';
import ViewAdminDashboardReducer from './adminAccount-reducers/viewAdminDashboard-reducer';
import GroupReducer from './adminAccount-reducers/group-reducer';
import AdminProductReducer from './adminAccount-reducers/product-reducer/product-reducer';
import AdminAffiliateReducer from './adminAccount-reducers/affiliate-reducer';
import TrainingsReducer from './adminAccount-reducers/trainings-reducer';
import CompanyReducer from './adminAccount-reducers/company-reducer';
import RolesReducer from './adminAccount-reducers/role-reducer';

const rootReducer = combineReducers({
    TestReducer,
    LoggedUserReducer,
    CheckUserRoleReducer,
    SearchUserResourcesReducer,
    UserDetailReducer,
    ProductReducer,
    AllAffiliatesReducer,
    AffiliateCountriesReducer,
    SelectAffiliateReducer,
    WorkFlowRolesReducer,
    LoaderReducer,
    SubmitUserAccountReducer,
    SummaryReducer,
    AffiliateResourcesReducer,
    UserAccessResourcesReducer,
    AllUserDetailResourcesReducer,
    ApprovarReducer,
    CheckAccountStatusResourcesReducer,
    SubmitApprovarRequestReducer,
    AdminDashboardReducer,
    ReassignAndTerminateReducer,
    ViewAdminDashboardReducer,
    GroupReducer,
    AdminProductReducer,
    AdminAffiliateReducer,
    TrainingsReducer,
    CompanyReducer,
    RolesReducer
});

export default rootReducer;
