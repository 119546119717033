import React, { useRef, useState } from "react";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import Button from "@mui/material/Button";
import ClearIcon from "@mui/icons-material/Clear";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  CircularProgress,
  ClickAwayListener,
  IconButton,
  MenuItem,
  Paper,
  Popper,
  TextField,
  Tooltip
} from "@mui/material";
import { axiosMsGraph } from "../../../api";

export default function SelectedSponsorPopup({
  onClose,
  onSetSponsor,
  loggedUser,
  userMailId
}) {
  const searchTextFieldRef = useRef();
  const [userList, setUserList] = useState([]);
  const [searchedUser, setSearchUser] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [confirmDisable, setConfirmDisable] = useState(true);
  const userSearchDisabled = !searchedUser || searchedUser.length < 3;

  let USERS_DROPDOWN = [];

  if (userList && Array.isArray(userList) && userList.length) {
    userList.map((user) => {
      let mail = user.mail ? user.mail : "";
      const dropDown = {
        id: user.id,
        empId: user?.employeeId || "",
        name: user?.displayName || "",
        email: mail,
        title: user.displayName + " " + "(" + user.employeeId + ")" + " " + mail
      };
      USERS_DROPDOWN.push(dropDown);
      // console.log("dropdown");
      // console.log(USERS_DROPDOWN);
    });
  }

  /* MS GRAPH API */
  async function getAllUsersInGraph() {
    const toSelect = [
      "id",
      "displayName",
      "preferredLanguage",
      "country",
      "usageLocation",
      "officeLocation",
      "givenname",
      "surname",
      "jobtitle",
      "mail",
      "onPremisesSamAccountName",
      "onPremisesDomainName",
      "onPremisesImmutableId",
      "onPremisesLastSyncDateTime",
      "onPremisesUserPrincipalName",
      "employeeId"
    ].join(",");
    const orderBy = ["displayName", "mail"].join(",");
    // const queryString = `$filter=mail ne ${loggedUser}  &$search="displayName: ${searchedUser}" OR "mail:${searchedUser}"&$select=${toSelect}&$orderby=${orderBy}&$count=true`;
    const queryString = `$filter=accountEnabled eq true AND not(mail eq '${loggedUser}' OR mail eq '${userMailId}' ) AND employeeId ne null&$search="displayName: ${searchedUser}" OR "mail: ${searchedUser}"&$select=${toSelect}&$orderby=${orderBy}&$count=true`;
    const finalURL = `/users?${queryString}`;
    return axiosMsGraph.get(finalURL);
  }

  /* DB API */
  // async function getAllUsersInDB() {
  //   let queryString;
  //   if (searchedUser.includes("@") || searchedUser.includes("_")) {
  //     queryString = `email=*${searchedUser}*`;
  //   } else {
  //     queryString = `display_name=*${searchedUser}*`;
  //   }
  //   const finalEndPoint = `/users?${queryString}`;
  //   return axiosApigateway
  //     .get(finalEndPoint)
  //     .then((res) => res.data)
  //     .catch((err) => alert(err));
  // }

  async function getAllUserInBoth() {
    // const combinedResponse = await Promise.all([
    //   getAllUsersInGraph()
    //   getAllUsersInDB()
    // ]);
    const res = await getAllUsersInGraph();
    let adUserList = res?.data?.value;
    // console.log('userList', adUserList);
    // let dbUserList = combinedResponse[1]?.data;

    // console.log("adUserList", adUserList);
    // console.log("dbUserList", dbUserList);
    // console.log(adUserList);
    // let adAndDbUserList = [];

    // for (let i = 0; i < adUserList.length; i++) {
    //   const temp = adUserList[i];
    //   const duplicate = dbUserList.find((obj) => obj.email === temp.mail);
    //   if (!duplicate) {
    //     adAndDbUserList.push({ ...temp, isExist: false });
    //   } else {
    //     adAndDbUserList.push({
    //       ...duplicate,
    //       isExist: true,
    //       employeeId: temp.employeeId
    //     });
    //   }
    // }
    setUserList(adUserList);
    setLoader(false);
  }

  const searchUserHandler = () => {
    if (searchedUser) {
      setLoader(true);
      setUserList([]);
      getAllUserInBoth();
      setAnchorEl(searchTextFieldRef.current);
      setOpen(true);
    }
  };

  const selectedUserHandler = (user) => {
    setSelectedUser(user);
    setSearchUser(user?.title);
    setConfirmDisable(false);
    setOpen(false);
  };

  const clearSearchBoxHandler = () => {
    setSearchUser("");
    setSelectedUser(null);
    setConfirmDisable(true);
  };

  const handleConfirmButton = () => {
    onSetSponsor(selectedUser);
    onClose();
  };

  return (
    <>
      <div className="userDetailsCard_header" style={{ display: "flax" }}>
        <span>Reassign POC</span>

        <HighlightOffIcon
          sx={{
            mr: "5px",
            "&:hover": {
              color: "gray",
              transition: "color 0.3s"
            }
          }}
          onClick={onClose}
        ></HighlightOffIcon>
      </div>

      <div className="searchUser_searchWrapper" style={{ marginTop: "0px" }}>
        <span className="searchUser_searchText">Search POC</span>
        <div className="searchUser_textFieldWithButton">
          <TextField
            id="search-user-input"
            size="small"
            variant="outlined"
            className="searchUser_textField"
            focused={false}
            placeholder="Type Name / Email Id To Search User"
            value={searchedUser}
            ref={searchTextFieldRef}
            onChange={(event) => {
              setSearchUser(event.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                searchUserHandler();
              }
            }}
            InputProps={{
              sx: { borderTopRightRadius: 0, borderBottomRightRadius: 0 },
              endAdornment: (
                <React.Fragment>
                  {(selectedUser && selectedUser.title) ||
                  searchedUser.length ? (
                    <IconButton
                      edge="end"
                      size="small"
                      onClick={clearSearchBoxHandler}
                    >
                      <ClearIcon />
                    </IconButton>
                  ) : null}
                </React.Fragment>
              )
            }}
          />
          <Button
            variant="contained"
            className={
              !userSearchDisabled
                ? "searchUser_searchButton"
                : "searchUser_searchButtonDisabled"
            }
            disabled={userSearchDisabled}
            onClick={searchUserHandler}
          >
            <SearchOutlinedIcon size="large" />
          </Button>
          <Popper open={open} anchorEl={anchorEl} style={{ zIndex: 1300 }}>
            <ClickAwayListener onClickAway={() => setOpen(false)}>
              <Paper
                sx={{
                  position: "absolute",
                  top: 0,
                  left: "-14rem"
                }}
              >
                {loader && (
                  <div className="loader-container">
                    <CircularProgress />
                  </div>
                )}
                {USERS_DROPDOWN && USERS_DROPDOWN.length ? (
                  <div
                    className={
                      USERS_DROPDOWN.length > 5 ? "searchUser_userList" : ""
                    }
                  >
                    {USERS_DROPDOWN.map((user, index) => (
                      <MenuItem
                        key={index}
                        size="large"
                        onClick={() => {
                          selectedUserHandler(user);
                        }}
                        sx={{
                          width: `${
                            USERS_DROPDOWN.length > 5 ? "30.5rem" : "31.5rem"
                          }`,
                          // whiteSpace: "normal",
                          fontSize: "0.95rem",
                          color: "#000000"
                        }}
                      >
                        {user.title && user.title.length > 55 ? (
                          <>
                            {user.title.substr(0, 55)}
                            <Tooltip title={user.title} arrow placement="left">
                              <strong>...</strong>
                            </Tooltip>
                          </>
                        ) : (
                          user.title
                        )}
                      </MenuItem>
                    ))}
                  </div>
                ) : null}
              </Paper>
            </ClickAwayListener>
          </Popper>
        </div>
        <div
          className="homeContainerButtons"
          style={{ marginTop: "20px", marginRight: "-58px" }}
        >
          <Button
            className="buttonDanger"
            variant="outlined"
            onClick={onClose}
            color="error"
            sx={{
              width: 100,
              height: 35,
              ":hover": {
                bgcolor: "red",
                color: "white"
              }
            }}
          >
            Cancel
          </Button>
          <Button
            className="buttonSuccess"
            variant="outlined"
            onClick={handleConfirmButton}
            color="success"
            disabled={confirmDisable}
            sx={{
              width: 100,
              height: 35,
              ":hover": {
                bgcolor: "green",
                color: "white"
              }
            }}
          >
            Confirm
          </Button>
        </div>
      </div>
    </>
  );
}
