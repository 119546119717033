import React from "react";
import UserDetailsLoader from "../UserDetails/UserDetailsLoader";
import WorkFlowLoader from "../WorkFlow/WorkFlowLoader";
import ProductCard from "../ProductCard/ProductCard";
// import { useSelector } from "react-redux";
// import { Backdrop, CircularProgress } from "@mui/material";

const CreateModifyScreen = ({
  currentTask,
  pageTitle,
  isUserOnboarded,
  toggleToLandingPage,
  completeState
}) => {
  const allAffiliatesResources = completeState.AffiliateResourcesReducer.data;
  const userAccessResources = completeState.UserAccessResourcesReducer.data;

  return (
    <>
      {currentTask >= 0 ? (
        <>
          <UserDetailsLoader
            pageTitle={pageTitle}
            currentTask={currentTask}
            isUserOnboarded={isUserOnboarded}
            toggleToLandingPage={toggleToLandingPage}
            allAffiliatesResources={allAffiliatesResources}
            userAccessResources={userAccessResources}
          />
        </>
      ) : null}
      {currentTask >= 1 ? (
        <>
          <WorkFlowLoader
            pageTitle={pageTitle}
            currentTask={currentTask}
            allAffiliatesResources={allAffiliatesResources}
            userAccessResources={userAccessResources}
          />
        </>
      ) : null}
      {currentTask == 2 ? (
        <div className="card">
          <ProductCard pageTitle={pageTitle} />
        </div>
      ) : null}
    </>
  );
};

export default CreateModifyScreen;
