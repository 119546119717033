import { selectAffiliate_actionType } from "../../actionTypes/userAction-actionTypes/selectAffiliate-actionType";

export function getSelectAffiliate() {
    return(dispatch) => {
        dispatch({
            type: selectAffiliate_actionType.GET_SELECT_AFFILIATE_SUCCESS,
            data: "xyz"
        });
    };
}

export function resetSelectAffiliateReducer() {
    return(dispatch) => {
        dispatch({
            type: selectAffiliate_actionType.RESET_SELECT_AFFILIATE_SUCCESS
        });
    };
}

export function setSelectAffiliate(data) {
    return(dispatch, getState) => {
        if(data){
            dispatch({
                type: selectAffiliate_actionType.SET_SELECT_AFFILIATE_SUCCESS,
                payload: data,
                state: getState()
            });
        }else{
            dispatch({
                type : selectAffiliate_actionType.SET_SELECT_AFFILIATE_FAILURE
            });
        }
    };
}