// import { useSelector } from "react-redux";
import { submitApprovarRequest_actionType } from "../../actionTypes/approvar-actionTypes/submitApprovarRequest-actionType";
const initialState = {
  isSubmitSuccess: false,
  message: [],
  isRequesetComplete: false
};

// const { taskType } = useSelector(state => ({ taskType: state.ApprovarReducer.data?.taskType }));
const requestTypeValue = {
  // approveRoleAndProducts: "approveRoleAndProducts",
  // approveTrainings: 'approveTrainings',
  approve: 'approve',
  reject: "reject",
  reassign: "reassign"
};

const fetchErrorMessage = (data) => {
  let msg = [];
  if (data) {
    msg.push(data);
  } else {
    msg.push("Something went Wrong, Please try after some time!");
  }

  return msg;
};

const fetchSuccessMessage = (requestType, taskType) => {
  switch (requestType) {
    case requestTypeValue.approve:
      if (taskType === "trainingsApproval") {
        return "Trainings Verification completed.";
      } else if (taskType === "all") {
        return "Roles, Products & Trainings task has been approved";
      } else {
        return "The roles and products task has been approved.";
      }
    case requestTypeValue.reassign:
      return "The task has been reassigned.";
    case requestTypeValue.reject:
      return "The request task has been rejected.";
  }
};

const SubmitApprovarRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case submitApprovarRequest_actionType.SUBMIT_ACTION_START:
      return {
        ...state,
        isRequesetComplete: false,
        isSubmitSuccess: false
      };
    case submitApprovarRequest_actionType.SUBMIT_ACTION_SUCCESS:
      return {

        ...state,
        isSubmitSuccess: true,
        isRequesetComplete: true,
        message: [fetchSuccessMessage(requestTypeValue[action.requestType], action.taskType)]
        // message : [
        //     requestTypeValue[action.requestType] + "Request Has beed Submitted."
        // ]
      };
    case submitApprovarRequest_actionType.SUBMIT_ACTION_FAILURE:
      return {
        ...state,
        isSubmitSuccess: false,
        isRequesetComplete: true,
        message: [fetchErrorMessage(action.data.response.data.errormessage)]
      };
    case submitApprovarRequest_actionType.APPROVE_OWN_REQUEST_FAILURE:
      return {
        ...state,
        isSubmitSuccess: false,
        isRequesetComplete: true,
        message: ['Approver can not approve own request!']
      };
    default:
      return state;
  }

};

export default SubmitApprovarRequestReducer;
