import React from "react";
import { Grid, Card } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import "./styles/Approval.css";
import { approvar_actionTypes } from "../utils/approval_actionType";

const SponserAndSupervisor = ({
  editRequest,
  sponserDetails,
  handleEditSponser,
  actionType,
  isEditable
}) => {

  if (editRequest) {
    return (
      <Grid
        item
        sm={12}
        md={6}
        xs={12}
        spacing={2}
        className="accountSummary_mainItemSection"
        style={{ height: "100%" }}
      >
        <Card className="accountSummary_userDetailsParent">
          <div className="accountSummary_header">
            <Grid container spacing={2}>
              <Grid item md={11} className="accountSummary_mainHeading">
                Selected Sponsor/Supervisor
              </Grid>
            </Grid>
          </div>
          <div
            className="accountSummary_section"
            style={{ height: "100%", padding: "10px" }}
          >
            <Grid container md={12} sm={12} spacing={2}>
              <Grid item md={4} sm={4}>
                <div>
                  <strong>Login ID</strong>
                </div>
                <div style={{ paddingTop: "8px" }}>
                  {sponserDetails?.employeeId || ""}
                </div>
              </Grid>
              <Grid item md={4} sm={4}>
                <div>
                  <strong>Name</strong>
                </div>
                <div style={{ paddingTop: "8px" }}>
                  {sponserDetails?.displayName || ""}
                </div>
              </Grid>
              <Grid item md={4} sm={4}>
                <div>
                  <strong>Email</strong>
                </div>
                <div style={{ paddingTop: "8px" }}>
                  {sponserDetails?.mail || ""}
                </div>
              </Grid>
            </Grid>
          </div>
        </Card>
      </Grid>
    );
  }

  if (!editRequest) {
    return (
      <Grid
        item
        sm={12}
        md={12}
        xs={12}
        spacing={1}
        style={{ height: "95%" }}
        className="accountSummary_mainItemSection"
      >
        <Card className="selectedSponserSupervisor">
          <div className="edit_header">
            <Grid container spacing={2}>
              <Grid
                item
                md={11}
                sm={11}
                xs={12}
                className="accountSummary_mainHeading"
              >
                {actionType === approvar_actionTypes.ALL_IN_ONE ? 'POC' : "Selected Sponsor/Supervisor"}
              </Grid>
              <Grid
                item
                md={1}
                sm={1}
                xs={1}
                className="accountSummary_mainHeading accountSummary_affiliateIconStyle"
              >
                {isEditable && <EditOutlinedIcon
                  className="editIcon"
                  onClick={handleEditSponser}
                />}
              </Grid>
            </Grid>
          </div>
          <div className="accountSummary_section" style={{ height: "100%" }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between"
              }}
            >
              <Grid container spacing={5} padding={2}>
                <Grid item md="2" sm="2">
                  <span>
                    <strong>Login ID</strong>
                  </span>
                </Grid>
                <Grid item md="10" sm="10">
                  {sponserDetails?.employeeId || ""}
                </Grid>
                <Grid item md="2" sm="2">
                  <span>
                    <strong>Name</strong>
                  </span>
                </Grid>
                <Grid item md="10" sm="10">
                  {sponserDetails?.displayName || ""}
                </Grid>
                <Grid item md="2" sm="2">
                  <span>
                    <strong>Email</strong>
                  </span>
                </Grid>
                <Grid item md={10} sm={10}>
                  {sponserDetails?.mail || ""}
                </Grid>
              </Grid>
            </div>
          </div>
        </Card>
      </Grid>
    );
  }
};

export default SponserAndSupervisor;
