import { reassignAndTerminateRequestList_actionType } from "../../../actionTypes/adminAction-actionTypes/reassignAndTerminate/reassignAndTerminateRequestList-actionType";

const initialState = {
    loading: false,
    data: [],
    filterOptions:[],
    error: undefined,
    selectedRequest:{}
};

const ReassignAndTerminateRequestListReducer = (state = initialState, action) =>{
    switch(action.type){
        case reassignAndTerminateRequestList_actionType.REASSIGN_AND_TERMINATE_REQUEST_LIST:
            return{
                ...state,
                loading: true
            };
        case reassignAndTerminateRequestList_actionType.REASSIGN_AND_TERMINATE_REQUEST_LIST_SUCCESS:
            return{
                ...state,
                loading: false,
                data: action.payload,
                filterOptions: action.filterOptions
            };
        case reassignAndTerminateRequestList_actionType.REASSIGN_AND_TERMINATE_REQUEST_LIST_SELECT_REQUEST:
            return{
                ...state,
                selectedRequest: action.payload
            };
        case reassignAndTerminateRequestList_actionType.REASSIGN_AND_TERMINATE_REQUEST_LIST_ERROR:
            return{
                ...state,
                loading: false,
                error: action.payload
            };
        default: 
            return state;
    }
};

export default ReassignAndTerminateRequestListReducer;