export const REASSIGN_AND_TERMINATE_TABLE_COLUMNS = {
  columns: [
    { name: "Request ID", accessor: "request_id", sortable: true },
    { name: "Veeva User Name", accessor: "veeva_username", sortable: true },
    { name: "Full Name", accessor: "full_name", sortable: true },
    { name: "System Id", accessor: "system_id", sortable: true },
    { name: "User Type", accessor: "user_type", sortable: true },
    { name: "Request Type", accessor: "request_type", sortable: true },
    { name: "Affiliate", accessor: "affiliate_type", sortable: true },
    { name: "Last Action Date", accessor: "last_action_date", sortable: true },
    { name: "Action", accessor:null, actionable: true,  sortable: false }
  ]
};
