import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
// import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import Button from "@mui/material/Button";
import { FormControlLabel, Grid, Stack, Checkbox } from "@mui/material";
import "./styles/Groups.css";
import { useDispatch } from "react-redux";
import { saveGroupRequest } from "../../../../../../redux/actions/adminAccount-actions/group-actions";
import { TextFieldInput } from "../../../../../../components/common/TextFieldInput";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  height: "65%",
  border: 0,
  borderRadius: "10px",
  backgroundColor: "white",
  outline: "none",
  overflow: "hidden"
};

const GroupsModal = ({
  isModalOpen,
  toggleOpenGroupModal,
  rowData,
  isEditRequest
}) => {
  const dispatch = useDispatch();
  const [groupName, setGroupName] = useState("");
  const [description, setDescription] = useState("");
  const [isProductChecked, setIsProductChecked] = useState(false);
  const [isCountryChecked, setIsCountryChecked] = useState(false);
  const [tip, setTip] = useState("");
  const [isSaveDisabled, setSaveDisabled] = useState(true);

  useEffect(() => {
    if (isEditRequest) {
      setGroupName(rowData.name);
      setDescription(rowData.description);
      setIsProductChecked(rowData.isDynamicProduct);
      setIsCountryChecked(rowData.isDynamicCountry);
    } else {
      setTip("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditRequest]);

  useEffect(() => {
    let _tip;
    if (isProductChecked && isCountryChecked) {
      if (groupName.includes("<COUNTRY>") && groupName.includes("<PRODUCT>")) {
        _tip = groupName
          .replace("<COUNTRY>", "US")
          .replace("<PRODUCT>", "ALIMTA");
        setTip(`Example group name : ${_tip}`);
        setSaveDisabled(false);
      } else {
        setTip(
          "Note: group name with variable country and product must include <COUNTRY> and <PRODUCT>"
        );
        setSaveDisabled(true);
      }
    } else if (isProductChecked) {
      if (groupName.includes("<PRODUCT>")) {
        _tip = groupName.replace("<PRODUCT>", "ALIMTA");
        setTip(`Example group name : ${_tip}`);
        setSaveDisabled(false);
      } else {
        setTip("Note: group name with variable product must include <PRODUCT>");
        setSaveDisabled(true);
      }
    } else if (isCountryChecked) {
      if (groupName.includes("<COUNTRY>")) {
        _tip = groupName.replace("<COUNTRY>", "US");
        setTip(`Example group name : ${_tip}`);
        setSaveDisabled(false);
      } else {
        setTip("Note: group name with variable country must include <COUNTRY>");
        setSaveDisabled(true);
      }
    } else {
      setTip("");
      if (groupName.trim().length > 0) {
        setSaveDisabled(false);
      } else {
        setSaveDisabled(true);
      }
    }
  }, [isProductChecked, isCountryChecked, groupName]);

  const onSaveHandler = () => {
    const data = {
      isDynamicProduct: isProductChecked,
      isDynamicCountry: isCountryChecked,
      name: groupName,
      description: description
    };
    dispatch(saveGroupRequest(data, isEditRequest, rowData?.id));
    handleClose();
    // clearState();
  };

  const handleClose = () => {
    toggleOpenGroupModal();
    clearState();
  };

  const clearState = () => {
    setGroupName("");
    setDescription("");
    setIsProductChecked(false);
    setIsCountryChecked(false);
    setSaveDisabled(true);
  };

  return (
    <Modal
      className="groupModalParent"
      open={isModalOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="fitermodel-group" style={ModalStyle}>
        <div className="filterContent">
          {/* <div className="filterContentHeader">
            {isEditRequest ? "Edit Group" : "Add Group"}
          </div> */}
          <div
            className="filterContentHeader"
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingRight: "15px"
            }}
          >
            <div> {isEditRequest ? "Edit Group" : "Add Group"}</div>
            <HighlightOffIcon
              sx={{
                mr: "5px",
                "&:hover": {
                  color: "gray",
                  transition: "color 0.2s"
                }
              }}
              onClick={handleClose}
            />
          </div>
          <div className="filterContentContent">
            <Grid container spacing={1}>
              <Grid item sm={12}>
                <div className="adminAddProductCardFormInputLabel">
                  <span>
                    <span>Group Name</span>
                    <span className="dangerColor">*</span>
                    <span className="userDetails_deactivateAccountInstruction">
                      <span className="userDetails_red"></span>
                      <em>
                        {
                          "For variable country group must include <COUNTRY> and for variable product group must include <PRODUCT> in group name."
                        }
                      </em>
                    </span>
                  </span>
                </div>
              </Grid>
              <Grid item sm={12}>
                <TextFieldInput
                  placeholder="Enter Group Name"
                  value={groupName}
                  onChange={(e) => {
                    setGroupName(e.target.value);
                  }}
                />
              </Grid>
              <Grid item sm={12}>
                <div className="adminAddProductCardFormInputLabel">
                  <span>
                    <span>Description</span>
                  </span>
                </div>
              </Grid>
              <Grid item sm={12} md={12} xs={12}>
                <TextFieldInput
                  isMultiple={true}
                  rows={2}
                  placeholder="Enter Description"
                  value={description}
                  onChange={(event) => setDescription(event.target.value)}
                />
              </Grid>
              <Grid item sm={12} display={"flex"}>
                <Stack spacing={2} direction={"row"}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isCountryChecked}
                        onChange={(evt) =>
                          setIsCountryChecked(evt.target.checked)
                        }
                        sx={{
                          "&.Mui-checked": {
                            color: "#00AE43"
                          }
                        }}
                      />
                    }
                    label="Variable Country"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isProductChecked}
                        onChange={(evt) =>
                          setIsProductChecked(evt.target.checked)
                        }
                        sx={{
                          "&.Mui-checked": {
                            color: "#00AE43"
                          }
                        }}
                      />
                    }
                    label="Variable Product"
                  />
                </Stack>
              </Grid>
              <Grid item sm={12}>
                <span style={{ fontSize: "12px", fontStyle: "italic" }}>
                  {tip}
                </span>
              </Grid>
            </Grid>
          </div>
        </div>
        {/* <CancelOutlinedIcon
          className="accountSummary_crossModalIcon"
          onClick={handleClose}
        /> */}
        <div className="summaryModalButtons">
          <Button
            className="buttonDanger"
            variant="outlined"
            onClick={handleClose}
            color="error"
            sx={{
              width: 100,
              height: 35,
              fontSize: 12,
              ":hover": {
                bgcolor: "red",
                color: "white"
              }
            }}
          >
            Cancel
          </Button>
          <Button
            className="buttonSuccess"
            variant="outlined"
            disabled={isSaveDisabled}
            onClick={onSaveHandler}
            color="success"
            sx={{
              width: 100,
              height: 35,
              fontSize: 12,
              ":hover": {
                bgcolor: "green",
                color: "white"
              }
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default GroupsModal;
