export function errMsgValidatorUserDetail(data) {
    let errMsg = {};
    // eslint-disable-next-line no-control-regex
    // let emailRegex = /(?:[a-z0-9+!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gi;
    let emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const { isOnboardedUser, emailId } = data;
    if (
        emailId.length
        && !emailId.match(emailRegex)
    ) {
        errMsg["emailId"] =
            "Please enter valid mail address.";
    } else if (
        !isOnboardedUser
        && emailId.length
        && (emailId.includes("@lilly.com") || emailId.includes("@network.lilly.com"))
    ) {
        errMsg["emailId"] =
            "Please enter external mail. If onboarded to lilly, please proceed through search user.";
    }

    return errMsg;
}