import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Modal from "@mui/material/Modal";
// import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Button from "@mui/material/Button";
import Success from "../SuccessFailureModal/Success";
import Failure from "../SuccessFailureModal/Failure";
import {
  addAffiliate,
  editAffiliate
} from "../../../../../../redux/actions/adminAccount-actions/affiliate-action";
import {
  getAllAffilateData,
  getCountries,
  editAffiliateInCountrieList,
  resetReducerCorrespondingToCountries
} from "../../../../../../redux/actions/adminAccount-actions/affiliate-action";

import { Paper } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import { FormControl, Grid } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  minHeight: "48%",
  maxHeight: "60%",
  border: 0,
  borderRadius: "10px",
  backgroundColor: "white",
  outline: "none"
};

function ModalAddNewAffiliate({
  isAddNewProductModalOpen,
  handelToggleAddNewModal,
  addAffiliateModaMode,
  currentSelection
}) {
  const [isEditMode, setIsEditMode] = useState(false);
  const [affiliateName, setAffiliateName] = useState("");
  const [affiliateAbbr, setAffiliateAbbr] = useState("");
  const [description, setDescription] = useState("");
  const [afterSubmitModal, setAfterSubmitModal] = useState(false);
  const [allCountries, setAllCountries] = useState([]);
  const [openToast, setopenToast] = useState(false);
  const [countryValueUpdatedInEditMode, setCountryValueUpdatedInEditMode] =
    useState(false);
  const dispatch = useDispatch();
  const {
    isSubmitSuccess,
    submitResponse,
    notTaggedCountries,
    failureForCountries
  } = useSelector((state) => ({
    isSubmitSuccess: state.AdminAffiliateReducer.isRequestSuccess,
    submitResponse: state.AdminAffiliateReducer.message,
    notTaggedCountries: state.AdminAffiliateReducer.countriesData,
    failureForCountries: state.AdminAffiliateReducer.failureForCountries
  }));

  useEffect(() => {
    let data = [];
    switch (addAffiliateModaMode) {
      case "edit":
        setDescription(currentSelection.description);
        setAffiliateName(currentSelection.name);

        currentSelection.vvpm_countries.forEach((element) => {
          element.checked = true;
          data.push(element);
        });
        setAllCountries(data);
        dispatch(getCountries());
        setIsEditMode(true);
        break;
      default:
        setIsEditMode(false);
        break;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addAffiliateModaMode]);
  useEffect(() => {
    if (!isAddNewProductModalOpen)
      dispatch(resetReducerCorrespondingToCountries());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddNewProductModalOpen]);
  useEffect(() => {
    let data = [...allCountries];
    if (notTaggedCountries) {
      notTaggedCountries.forEach((element) => {
        element.checked = false;
        data.push(element);
      });

      setAllCountries(data);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notTaggedCountries]);

  const handleClose = () => {
    if (addAffiliateModaMode === "edit" && countryValueUpdatedInEditMode)
      handelDataRefresh();
    handelToggleAddNewModal();
  };
  const handelDataRefresh = () => {
    dispatch(getAllAffilateData());
    dispatch(resetReducerCorrespondingToCountries());
  };

  const handelSave = () => {
    let response;
    handelToggleAddNewModal();
    if (!isEditMode) {
      response = dispatch(
        addAffiliate({
          abbreviation: affiliateAbbr,
          name: affiliateName,
          description: description
        })
      );
    } else {
      // get all countries
      let markSelectedCountryIDList = [];
      let markNotSelectedCountryIDList = [];
      allCountries.forEach((element) => {
        if (element.checked)
          markSelectedCountryIDList.push({
            countryId: element.country_id,
            countryName: element.country_name
          });
        else
          markNotSelectedCountryIDList.push({
            countryId: element.country_id,
            countryName: element.country_name
          });
      });

      response = dispatch(
        editAffiliateInCountrieList(
          markSelectedCountryIDList,
          markNotSelectedCountryIDList,
          { vamHubAbbreviation: currentSelection.abbreviation }
        )
      );
    }
    if (response) {
      setTimeout(() => {
        setAfterSubmitModal(true);
      }, 3000);
    }
  };

  const handelUpdateAffiliateName = () => {
    handelToggleAddNewModal();
    let response = dispatch(
      editAffiliate(currentSelection.abbreviation, {
        name: affiliateName,
        description: description
      })
    );
    if (response) {
      setTimeout(() => {
        setAfterSubmitModal(true);
      }, 3000);
    }
  };

  const handleCloseToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setopenToast(false);
  };
  const state = {
    vertical: "top",
    horizontal: "center"
  };
  const { vertical, horizontal } = state;
  return (
    <>
      <Modal
        open={isAddNewProductModalOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="fitermodel-affiliate" style={ModalStyle}>
          <div>
            <div>
              <div className="addNewProductContainerCard">
                <div
                  className="adminAddProductCardHeader"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingRight: "15px"
                  }}
                >
                  <span className="cardHeaderText">
                    {isEditMode
                      ? "Update Affiliate - " + affiliateName
                      : "Add Affiliates"}
                  </span>
                  <HighlightOffIcon
                    sx={{
                      mr: "5px",
                      "&:hover": {
                        color: "gray",
                        transition: "color 0.2s"
                      }
                    }}
                    onClick={handleClose}
                  />
                </div>
                {/* <div className="adminAddProductCardHeader"></div> */}
                {isEditMode
                  ? editHub({
                      allCountries,
                      setAllCountries,
                      affiliateName,
                      setAffiliateName,
                      description,
                      setDescription,
                      handelUpdateAffiliateName,
                      openToast,
                      setopenToast,
                      isSubmitSuccess,
                      setCountryValueUpdatedInEditMode
                    })
                  : createHub({
                      affiliateName,
                      setAffiliateName,
                      affiliateAbbr,
                      setAffiliateAbbr,
                      description,
                      setDescription
                    })}
              </div>
            </div>
          </div>

          {/* <CancelOutlinedIcon
            className="accountSummary_crossModalIcon"
            onClick={handleClose}
          /> */}
          <div className="modalAdminFooter">
            <div className="modalAdminFooterMandatory">
              {isEditMode ? (
                <>
                  {/* <span>*Any country field selection will result in update of value</span> */}
                </>
              ) : null}
            </div>
            <div className="summaryModalButtons">
              <Button
                className="buttonDanger"
                variant="outlined"
                onClick={handleClose}
                color="error"
                sx={{
                  width: 100,
                  height: 35,
                  fontSize: 12,
                  ":hover": {
                    bgcolor: "red",
                    color: "white"
                  }
                }}
              >
                {isEditMode ? "Close" : "Cancel"}
              </Button>
              <Button
                className="buttonSuccess"
                variant="outlined"
                onClick={handelSave}
                color="success"
                sx={{
                  width: 100,
                  height: 35,
                  fontSize: 12,
                  ":hover": {
                    bgcolor: "green",
                    color: "white"
                  }
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      {isSubmitSuccess ? (
        <Success
          isModalOpen={afterSubmitModal}
          setIsModalOpen={setAfterSubmitModal}
          modalMessage={
            failureForCountries?.length > 0
              ? "Request successfully submitted, Failure case occurred for Country(s): " +
                failureForCountries.join(", ") +
                " as Country(s) already configured with User(s)."
              : submitResponse[0]
          }
          refreshDataAction={handelDataRefresh}
          scrollerMessage={true}
        />
      ) : (
        <Failure
          isModalOpen={afterSubmitModal}
          setIsModalOpen={setAfterSubmitModal}
          modalMessage={submitResponse[0]}
          refreshDataAction={handelDataRefresh}
        />
      )}
      {isSubmitSuccess ? (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={openToast}
          autoHideDuration={1000}
          onClose={handleCloseToast}
        >
          <Alert
            onClose={handleCloseToast}
            severity="success"
            sx={{ width: "100%" }}
          >
            Request Success!!!
          </Alert>
        </Snackbar>
      ) : (
        <Snackbar
          open={openToast}
          autoHideDuration={1000}
          onClose={handleCloseToast}
        >
          <Alert
            onClose={handleCloseToast}
            severity="error"
            sx={{ width: "100%" }}
          >
            Request Failed!!!
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

function editHub({
  allCountries,
  setAllCountries,
  affiliateName,
  setAffiliateName,
  description,
  setDescription,
  handelUpdateAffiliateName
}) {
  const onChangeCheck = (objectKey) => {
    let data = [...allCountries];
    data[objectKey].checked = !data[objectKey].checked;
    setAllCountries(data);
  };

  const onChangeName = (e) => {
    setAffiliateName(e.target.value);
  };
  const onChangeDescription = (e) => {
    setDescription(e.target.value);
  };
  const handelSave = () => {
    handelUpdateAffiliateName();
  };
  return (
    <div className="editAffiliate">
      <div className="adminAddProductCardFormLineTwo">
        <Grid container spacing={1}>
          <Grid item sm={1} md={2.09}>
            <div className="adminAddProductCardFormInputLabel">
              <span>
                <span>Affiliate Name</span>
                <span className="dangerColor">*</span>
              </span>
            </div>
          </Grid>
          <Grid item sm={1} md={2.3}>
            <FormControl className="dropdownSelectInput" size="small">
              <Paper
                className="adminAddProductCardFormInputField"
                component="form"
                sx={{
                  display: "flex",
                  height: "35px",
                  border: "0.5px solid gray",
                  borderRadius: "5px",
                  boxShadow: 0
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1, width: "200px" }}
                  placeholder="Enter Affiliate Name"
                  inputProps={{ "aria-label": "Enter Affiliate Name" }}
                  value={affiliateName}
                  onChange={onChangeName}
                />
              </Paper>
            </FormControl>
          </Grid>
          <Grid item sm={1} md={1.6}>
            <div className="adminAddProductCardFormInputLabel">
              <span>
                <span>Description</span>
                <span className="dangerColor">*</span>
              </span>
            </div>
          </Grid>
          <Grid item sm={1} md={3.4}>
            <FormControl className="dropdownSelectInput" size="small">
              <Paper
                className="adminAddProductCardFormInputField"
                component="form"
                sx={{
                  display: "flex",
                  height: "35px",
                  border: "0.5px solid gray",
                  borderRadius: "5px",
                  boxShadow: 0
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1, width: "200px" }}
                  placeholder="Enter Affiliate Description"
                  inputProps={{ "aria-label": "Enter Affiliate Description" }}
                  value={description}
                  onChange={onChangeDescription}
                />
              </Paper>
            </FormControl>
          </Grid>
          <Grid item sm={1} md={2}>
            <FormControl className="dropdownSelectInput" size="small">
              <Button
                className="buttonSuccess"
                variant="outlined"
                onClick={handelSave}
                color="success"
                sx={{
                  width: 100,
                  height: 35,
                  fontSize: 12,
                  ":hover": {
                    bgcolor: "green",
                    color: "white"
                  }
                }}
              >
                Update
              </Button>
            </FormControl>
          </Grid>
        </Grid>
      </div>

      <div
        style={{
          paddingLeft: "20px",
          overflow: "auto"
        }}
      >
        <div
          style={{
            fontSize: "15px",
            fontWeight: 700
          }}
        >
          Update Country Selection :{" "}
        </div>
        <div className="affiliateOverflow">
          {allCountries.map((object, objectKey) => {
            return (
              <FormControlLabel
                key={objectKey}
                control={
                  <Checkbox
                    checked={object.checked}
                    onChange={() => onChangeCheck(objectKey)}
                  />
                }
                label={object.country_name}
              />
            );
          })}
          {allCountries.length === 0 ? (
            <div className="selectProductCard_sectionCenterArticleSelectionNotAllowed">
              <span>No country available to attach in Affiliate</span>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

function createHub({
  affiliateName,
  setAffiliateName,
  affiliateAbbr,
  setAffiliateAbbr,
  description,
  setDescription
}) {
  const onChangeName = (e) => {
    setAffiliateName(e.target.value);
  };
  const onChangeAbbr = (e) => {
    setAffiliateAbbr(e.target.value);
  };
  const onChangeDescription = (e) => {
    setDescription(e.target.value);
  };
  return (
    <div className="adminAddProductCardForm">
      <div className="adminAddProductCardFormLineTwo">
        <Grid container spacing={1}>
          <Grid item sm={4} md={3.9}>
            <div className="adminAddProductCardFormInputLabel">
              <span>
                <span>Affiliate Name</span>
                <span className="dangerColor">*</span>
              </span>
            </div>
          </Grid>
          <Grid item sm={4} md={7}>
            <FormControl className="dropdownSelectInput" size="small">
              <Paper
                className="adminAddProductCardFormInputField"
                component="form"
                sx={{
                  display: "flex",
                  height: "35px",
                  border: "0.5px solid gray",
                  borderRadius: "5px",
                  boxShadow: 0
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1, width: "200px" }}
                  placeholder="Enter Affiliate Name"
                  inputProps={{ "aria-label": "Enter Affiliate Name" }}
                  value={affiliateName}
                  onChange={onChangeName}
                />
              </Paper>
            </FormControl>
          </Grid>
        </Grid>
      </div>
      <div className="adminAddProductCardFormLineTwo">
        <Grid container spacing={1}>
          <Grid item sm={4} md={3.9}>
            <div className="adminAddProductCardFormInputLabel">
              <span>
                <span>Affiliate Abbreviation</span>
                <span className="dangerColor">*</span>
              </span>
            </div>
          </Grid>
          <Grid item sm={4} md={7}>
            <FormControl className="dropdownSelectInput" size="small">
              <Paper
                className="adminAddProductCardFormInputField"
                component="form"
                sx={{
                  display: "flex",
                  height: "35px",
                  border: "0.5px solid gray",
                  borderRadius: "5px",
                  boxShadow: 0
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1, width: "200px" }}
                  placeholder="Enter Affiliate Abbreviation"
                  inputProps={{ "aria-label": "Enter Affiliate Abbreviation" }}
                  value={affiliateAbbr}
                  onChange={onChangeAbbr}
                />
              </Paper>
            </FormControl>
          </Grid>
        </Grid>
      </div>
      <div className="adminAddProductCardFormLineTwo">
        <Grid container spacing={1}>
          <Grid item sm={4} md={3.9}>
            <div className="adminAddProductCardFormInputLabel">
              <span>
                <span>Description</span>
                <span className="dangerColor">*</span>
              </span>
            </div>
          </Grid>
          <Grid item sm={4} md={7}>
            <FormControl className="dropdownSelectInput" size="small">
              <Paper
                className="adminAddProductCardFormInputField"
                component="form"
                sx={{
                  display: "flex",
                  height: "35px",
                  border: "0.5px solid gray",
                  borderRadius: "5px",
                  boxShadow: 0
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1, width: "200px" }}
                  placeholder="Description"
                  inputProps={{ "aria-label": "Description" }}
                  value={description}
                  onChange={onChangeDescription}
                />
              </Paper>
            </FormControl>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default ModalAddNewAffiliate;
