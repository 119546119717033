import React, { useState, useEffect } from "react";
import { Card, Grid } from "@mui/material";
import { TextareaAutosize } from "@mui/base";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Modal from "@mui/material/Modal";
import "./styles/AccountSummary.css";
import { useSelector, useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import {
  setCommentsToReducer,
  setSelectedSponsor
} from "../../redux/actions/userAccount-actions/summary-action";
import { setProductToReducer } from "../../redux/actions/userAccount-actions/product-action";
import { resetExistingStateToReducer } from "../../redux/actions/userAccount-actions/summary-action";
import { setUserDetail } from "../../redux/actions/userAccount-actions/userDetail-action";
import { setSelectedWorkFlowRoles } from "../../redux/actions/userAccount-actions/workFlowRoles-action";
import SummaryModal from "./summaryModal";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "600px",
  minHeight: "200px",
  borderRadius: "10px",
  backgroundColor: "white",
  padding: "0px 2px 10px 0px"
};

const AccountSummary = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeModal, setActiveModal] = useState("");
  const [sponsor, selectedSponsor] = useState("");
  const { isUserOnboarded, pageTitle, toggleToLandingPage } = props;
  const dispatch = useDispatch();

  const [commentsVal, setComments] = useState("");
  const [summaryData, setSummaryData] = useState({
    trainings: []
  });

  const editComponent = (type) => {
    handleOpen(true);
    setActiveModal(type);
  };

  const handleOpen = () => {
    setIsModalOpen(true);
  };
  const handleClose = () => {
    if (activeModal === "userDetails") {
      dispatch(setUserDetail(JSON.parse(existingState)));
    } else if (activeModal === "selectedRolesAndGroups") {
      // dispatch(setSelectedWorkFlowRoles(JSON.parse(existingState)));
      let parsedState = JSON.parse(existingState);
      dispatch(setSelectedWorkFlowRoles(parsedState.selectedRolesAndGroups));
      dispatch(setProductToReducer(parsedState.selectedProducts));
    } else if (activeModal === "selectedProducts") {
      dispatch(setProductToReducer(JSON.parse(existingState)));
    }
    dispatch(resetExistingStateToReducer());
    setIsModalOpen(false);
  };

  const handelSave = () => {
    dispatch(resetExistingStateToReducer());
    setIsModalOpen(false);
  };

  const confirmHandler = () => {
    setIsModalOpen(false);
    dispatch(
      setSelectedSponsor([
        {
          employeeId: sponsor?.employeeId,
          displayName: sponsor?.displayName,
          mail: sponsor?.mail
        }
      ])
    );
    selectedSponsor("");
  };

  const trainingsRows = summaryData.trainings.map(function (columnValObj) {
    return (
      <tr key={columnValObj?.id}>
        <td width="30%" className="accountSummary_tableHeading">
          {columnValObj?.id}
        </td>
        <td width="70%">{columnValObj?.trainingName}</td>
      </tr>
    );
  });

  /* User Details Section Props*/
  const { userDetails } = useSelector((state) => {
    return {
      userDetails: state.UserDetailReducer.data
    };
  });

  /* Trainings */
  const {
    // trainings,
    commentsReducer,
    existingState,
    selectedSponsorFinal
  } = useSelector((state) => {
    return {
      // trainings: state.SummaryReducer.trainings,
      commentsReducer: state.SummaryReducer.comments,
      existingState: state.SummaryReducer.existingState,
      selectedSponsorFinal: state.SummaryReducer.selectedSponsor
    };
  });

  useEffect(() => {
    setComments(commentsReducer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Selected Affiliate Section Props*/
  const { selectAffiliate } = useSelector((state) => {
    return {
      selectAffiliate: state.SelectAffiliateReducer.data
    };
  });

  const particularAffSelectedCountries =
    selectAffiliate.selectedCountries?.filter(
      (ctry) => ctry.affiliateType === selectAffiliate.selectedAffiliateType
    );

  /* Workflow Section Props */
  const { workFlowType, selectedRoles, trainings } = useSelector((state) => {
    return {
      selectedRoles: state.WorkFlowRolesReducer.selectedRoles,
      workFlowType: state.WorkFlowRolesReducer.data.workFlowType,
      trainings: state.WorkFlowRolesReducer.trainings
    };
  });

  useEffect(() => {
    // if (trainings.length > 0) {
    const rolesTraingings = trainings.filter(
      (o) => o.workFlowType === workFlowType
    );
    setSummaryData({ trainings: rolesTraingings });
    // }
  }, [workFlowType, trainings]);

  // Product Section
  const { reducerProduct } = useSelector((state) => ({
    reducerProduct: state.ProductReducer.product
  }));

  let products = [];

  reducerProduct.forEach((obj) => {
    let tempList = [];
    obj.productList.forEach((internalObj) => {
      if (internalObj.checked) {
        tempList.push(internalObj.text);
      }
    });

    if (tempList.length > 0) {
      products.push({
        productName: obj.productName,
        productList: tempList
      });
    }
  });

  const renderModalComponent = (activeModal) => {
    const summaryModalProps = {
      activeModal,
      isUserOnboarded,
      pageTitle,
      toggleToLandingPage,
      selectedSponsor
    };
    return <SummaryModal {...summaryModalProps} />;
  };

  return (
    <div>
      <Grid container spacing={2} className="accountSumary_parentSection">
        {/* User Details Section */}
        <Grid item sm={12} md={12} className="accountSummary_mainItemSection">
          <Card className="accountSummary_userDetailsParent">
            <div className="accountSummary_header">
              <Grid container spacing={2}>
                <Grid item md={11} className="accountSummary_mainHeading">
                  User Details
                </Grid>
                <Grid
                  item
                  md={1}
                  className="accountSummary_mainHeading accountSummary_affiliateIconStyle"
                >
                  {userDetails.actionType === "CREATE" ? (
                    <EditOutlinedIcon
                      className="accountSummary_editIcon"
                      onClick={() => editComponent("userDetails")}
                    />
                  ) : null}
                </Grid>
              </Grid>
            </div>
            <div className="accountSummary_section userDetailsSection">
              <Grid container spacing={2} className="userDetailsContainer">
                <Grid item md={1.5}>
                  <div>
                    <strong>First Name</strong>
                  </div>
                </Grid>
                <Grid item md={3}>
                  <div>{userDetails.firstName}</div>
                </Grid>
                <Grid item md={1}>
                  <div>
                    <strong>Last Name</strong>
                  </div>
                </Grid>
                <Grid item md={2}>
                  <div>{userDetails.lastName}</div>
                </Grid>
                <Grid item md={1}>
                  <div>
                    <strong>Email Id</strong>
                  </div>
                </Grid>
                <Grid item md={3}>
                  <div>{userDetails.emailId}</div>
                </Grid>
                <Grid item md={1.5}>
                  <div>
                    <strong>Veeva Username</strong>
                  </div>
                </Grid>
                <Grid item md={3}>
                  <div>{userDetails.veevaUserName}</div>
                </Grid>
                <Grid item md={1}>
                  <div>
                    <strong>User Type</strong>
                  </div>
                </Grid>
                <Grid item md={2}>
                  <div>{userDetails.userType}</div>
                </Grid>
                <Grid item md={1}>
                  <div>
                    <strong>Location</strong>
                  </div>
                </Grid>
                <Grid item md={3}>
                  <div>{userDetails.location}</div>
                </Grid>
                <Grid item md={1.5}>
                  <div>
                    <strong>Company</strong>
                  </div>
                </Grid>
                <Grid item md={3}>
                  <div>{userDetails.company}</div>
                </Grid>
                <Grid item md={1}>
                  <div>
                    <strong>Language</strong>
                  </div>
                </Grid>
                <Grid item md={2}>
                  <div>{userDetails.languageName}</div>
                </Grid>
                <Grid item md={1}>
                  <div>
                    <strong>Timezone</strong>
                  </div>
                </Grid>
                <Grid item md={3}>
                  <div>{userDetails.timezone}</div>
                </Grid>
                <Grid item md={1.5}>
                  <div>
                    <strong>Locale</strong>
                  </div>
                </Grid>
                <Grid item md={3}>
                  <div>{userDetails.localeName}</div>
                </Grid>
              </Grid>
            </div>
          </Card>
        </Grid>
        {/* Affiliate Section */}
        <Grid
          item
          sm={12}
          md={12}
          style={{ height: "62px" }}
          className="accountSummary_mainItemSection"
        >
          <Card className="accountSummary_affiliatesParent">
            <div className="accountSummary_header">
              <Grid container spacing={2}>
                <Grid item md={2} className="accountSummary_mainHeading">
                  Selected Affiliate
                </Grid>
                <Grid item md={9} className="accountSummary_headingNoteText">
                  <span className="accountSummary_headingSubNoteText">
                    <em>
                      {selectAffiliate.selectedAffiliateType?.toUpperCase()}{" "}
                    </em>
                    <span className="accountSummary_affiliateBar">
                      {particularAffSelectedCountries.length
                        ? " " + "|" + " "
                        : null}
                    </span>
                    <em>
                      {particularAffSelectedCountries.length
                        ? particularAffSelectedCountries
                            .map((ctry) => ctry.country.country_name)
                            ?.join(", ")
                        : null}
                    </em>
                  </span>
                </Grid>
              </Grid>
            </div>
          </Card>
        </Grid>
        {/* Roles & Products Section */}
        <Grid
          item
          sm={12}
          md={6}
          style={{ height: "152px" }}
          className="accountSummary_mainItemSection"
        >
          <Card
            className="accountSummary_rolesProductsParent"
            style={{ height: "138px" }}
          >
            <div className="accountSummary_header">
              <Grid container spacing={2}>
                <Grid item md={11} className="accountSummary_mainHeading">
                  Selected Roles
                </Grid>
                <Grid
                  item
                  md={1}
                  className="accountSummary_mainHeading accountSummary_affiliateIconStyle"
                >
                  <EditOutlinedIcon
                    className="accountSummary_editIcon"
                    onClick={() => editComponent("selectedRolesAndGroups")}
                  />
                </Grid>
              </Grid>
            </div>
            <div className="accountSummary_section" style={{ height: "93px" }}>
              <table
                width="100%"
                style={{ height: "92px" }}
                className="accountSummary_rolesProductsTable"
              >
                <thead></thead>
                <tbody>
                  <tr
                    align="left"
                    className="accountSummary_rolesRowsSize accountSummary_rolesRows"
                  >
                    <th width="30%">Workflow</th>
                    <td width="70%">
                      {workFlowType?.charAt(0).toUpperCase() +
                        workFlowType?.slice(1)}
                    </td>
                  </tr>
                  <tr className="accountSummary_rolesRowSize" align="left">
                    <th width="30%">Roles</th>
                    <td width="70%">
                      {selectedRoles
                        ?.filter((rle) => rle.workFlowType === workFlowType)
                        .map((rle) => rle.role.name)
                        .join(" | ")}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Card>
        </Grid>
        <Grid
          item
          sm={12}
          md={6}
          className="accountSummary_mainItemSection"
          style={{ height: "152px" }}
        >
          <Card
            className="accountSummary_rolesProductsParent"
            style={{ height: "138px" }}
          >
            <div className="accountSummary_header">
              <Grid container spacing={2}>
                <Grid item md={11} className="accountSummary_mainHeading">
                  Selected Products
                </Grid>
                <Grid
                  item
                  md={1}
                  className="accountSummary_mainHeading accountSummary_affiliateIconStyle"
                >
                  <EditOutlinedIcon
                    className="accountSummary_editIcon"
                    onClick={() => editComponent("selectedProducts")}
                  />
                </Grid>
              </Grid>
            </div>
            <div className="accountSummary_section" style={{ height: "93px" }}>
              <table
                className="accountSummary_productsTable"
                style={{ height: "68px" }}
              >
                <thead></thead>
                <tbody>
                  <tr align="left">
                    <th className="accountSummary_tableHeading">
                      CMH Products
                    </th>
                    <td width="60%">
                      {products.map(function (obj) {
                        return obj.productName === "CMH Products"
                          ? obj.productList.join(", ")
                          : "";
                      })}
                    </td>
                  </tr>
                  <tr align="left">
                    <th className="accountSummary_tableHeading">
                      OBU Products
                    </th>
                    <td width="60%">
                      {products.map(function (obj) {
                        return obj.productName === "OBU Products"
                          ? obj.productList.join(", ")
                          : "";
                      })}
                    </td>
                  </tr>
                </tbody>
              </table>
              <span className="bar">|</span>
              <table
                className="accountSummary_productsTable"
                style={{ height: "68px" }}
              >
                <thead></thead>
                <tbody>
                  <tr align="left">
                    <th className="accountSummary_tableHeading">
                      IMBU Products
                    </th>
                    <td width="60%">
                      {products.map(function (obj) {
                        return obj.productName === "IMBU Products"
                          ? obj.productList.join(", ")
                          : "";
                      })}
                    </td>
                  </tr>
                  <tr align="left">
                    <th className="accountSummary_tableHeading">
                      NBU Products
                    </th>
                    <td width="60%">
                      {products.map(function (obj) {
                        return obj.productName === "NBU Products"
                          ? obj.productList.join(", ")
                          : "";
                      })}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Card>
        </Grid>
        {/* Sponser & Supervisor */}
        {!userDetails.isOnboardedUser ? (
          <Grid item sm={12} md={12} className="accountSummary_mainItemSection">
            <Card
              className="accountSummary_sponserSupervisorParent"
              style={{ height: "100px" }}
            >
              <div className="accountSummary_header">
                <Grid container spacing={2}>
                  <Grid item md={7} className="accountSummary_mainHeading">
                    Select Sponsor / Supervisor**
                  </Grid>
                  <Grid
                    item
                    md={5}
                    className="accountSummary_mainHeading accountSummary_affiliateIconStyle accountSummary_sponsorWithEditIcon"
                  >
                    <Grid item md={10}>
                      <span className="accountSummary_sponsorInstruction">
                        <span className="accountSummary_note">
                          <em>Note:</em>
                        </span>
                        <span className="accountSummary_red">**</span>{" "}
                        <em>
                          Please select sponsor details before proceeding ahead.
                        </em>
                      </span>
                    </Grid>
                    <Grid item md={2} sx={{ padding: "1rem 1rem 1rem 0" }}>
                      <EditOutlinedIcon
                        className="accountSummary_editIcon"
                        onClick={() => editComponent("selectSponser")}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </div>
              <div
                className="accountSummary_section"
                style={{ height: "50px" }}
              >
                <Grid container>
                  <Grid
                    item
                    sm={12}
                    md={4}
                    className="accountSummary_sponserSection"
                  >
                    <Grid container>
                      <Grid item sm={12} md={6}>
                        <span className="accountSummary_sponserHeading">
                          Login Id
                        </span>
                      </Grid>
                      <Grid item sm={12} md={6}>
                        <span className="accountSummary_sponserText">
                          {selectedSponsorFinal[0]?.employeeId}
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    md={4}
                    className="accountSummary_sponserSection"
                  >
                    <Grid container>
                      <Grid item sm={12} md={6}>
                        <span className="accountSummary_sponserHeading">
                          Name
                        </span>
                      </Grid>
                      <Grid item sm={12} md={6}>
                        <span className="accountSummary_sponserText">
                          {selectedSponsorFinal[0]?.displayName}
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    md={4}
                    className="accountSummary_sponserSection"
                  >
                    <Grid container>
                      <Grid item sm={12} md={6}>
                        <span className="accountSummary_sponserHeading">
                          Email
                        </span>
                      </Grid>
                      <Grid item sm={12} md={6}>
                        <span className="accountSummary_sponserText">
                          {selectedSponsorFinal[0]?.mail}
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Card>
          </Grid>
        ) : null}

        {/* training & comment section */}
        <Grid
          item
          sm={12}
          md={6}
          className="accountSummary_mainItemSection"
          style={{ height: "190px" }}
        >
          <Card className="accountSummary_parent" style={{ height: "173px" }}>
            <div className="accountSummary_header">
              <Grid container spacing={2}>
                <Grid item md={3} className="accountSummary_mainHeading">
                  Trainings
                </Grid>
                <Grid
                  item
                  md={9}
                  className="accountSummary_trainingsHeadingNoteText"
                  style={{ flexBasis: "70%" }}
                >
                  <span className="accountSummary_note">
                    <em>Note:</em>
                  </span>{" "}
                  <span className="accountSummary_red"> ** </span>{" "}
                  <span>
                    <em>
                      Access will be provided only after completion of the
                      training.
                    </em>
                  </span>
                </Grid>
              </Grid>
            </div>
            <div className="" style={{ height: "107px", display: "block" }}>
              <table
                width="100%"
                className="accountSummary_trainingsTable"
                style={{ height: "101px", marginBottom: "10px" }}
              >
                <thead>
                  <tr align="left">
                    <th width="30%">Curriculum ID</th>
                    <th width="70%">Curriculum Name</th>
                  </tr>
                </thead>
                <tbody>{trainingsRows}</tbody>
              </table>
              <div
                className="accountSummary_trainingsBottomNote"
                style={{ bottom: "10px" }}
              >
                <em>
                  (Note: For U.S. Employees seeking a U.S. VVPM account, please
                  contact Kurt Clemenz to add or confirm the VVPM curricula is
                  added to your Learning plan)
                </em>
              </div>
            </div>
          </Card>
        </Grid>
        <Grid
          item
          sm={12}
          md={6}
          className="accountSummary_mainItemSection"
          style={{ height: "190px" }}
        >
          <div
            className="accountSummary_commentsParent"
            style={{ height: "173px" }}
          >
            <div className="accountSummary_commentHeader">
              <span className="accountSummary_mainHeading">
                Requestor Comments{" "}
                <em
                  style={{
                    float: "right",
                    marginRight: "10px",
                    fontSize: "12px"
                  }}
                >
                  (limit: {255 - commentsVal.length})
                </em>
              </span>
            </div>
            <div className="accountSummary_section">
              <TextareaAutosize
                className="acountSummary_commentBox"
                aria-label="minimum height"
                maxRows={6}
                minRows={6}
                maxlength={255}
                placeholder="Add your comment here..."
                value={commentsVal}
                onChange={(event) => {
                  dispatch(setCommentsToReducer(event.target.value));
                  setComments(event.target.value);
                }}
              />
            </div>
          </div>
        </Grid>
      </Grid>

      <Modal
        className="summaryModalParent"
        open={isModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={ModalStyle}>
          {renderModalComponent(activeModal)}
          <CancelOutlinedIcon
            className="accountSummary_crossModalIcon"
            onClick={() => handleClose()}
          />
          <div className="summaryModalButtons">
            <Button
              className="buttonDanger"
              variant="outlined"
              onClick={handleClose}
              color="error"
              sx={{
                width: 100,
                height: 35,
                fontSize: 12,
                ":hover": {
                  bgcolor: "red",
                  color: "white"
                }
              }}
            >
              Cancel
            </Button>
            {activeModal === "selectSponser" ? (
              <Button
                className="buttonSuccess"
                variant="outlined"
                onClick={confirmHandler}
                disabled={!sponsor}
                color="success"
                sx={{
                  width: 100,
                  height: 35,
                  fontSize: 12,
                  ":hover": {
                    bgcolor: "green",
                    color: "white"
                  }
                }}
              >
                Confirm
              </Button>
            ) : (
              <Button
                className="buttonSuccess"
                variant="outlined"
                onClick={handelSave}
                color="success"
                sx={{
                  width: 100,
                  height: 35,
                  fontSize: 12,
                  ":hover": {
                    bgcolor: "green",
                    color: "white"
                  }
                }}
              >
                Save
              </Button>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AccountSummary;
