import { React } from "react";
import "./styles/AdminFooter.css";

const footerTerms = {
  AUDIENCE_AGE_DISCLAIMER: "Audience Age Disclaimer",
  TERMS_OF_USE: "Terms Of Use",
  PRIVACY_STATEMENT: "Privacy Statement",
  ACCESSIBILITY_STATEMENT: "Accessibility Statement",
  SITEMAP: "Sitemap"
};
const copyRightMessage = `Copyright ©${new Date().getFullYear()} Eli Lilly And Company. All Rights Reserved.`;

function AdminFooter() {
  const footerTermsList = [
    { termsName: footerTerms.AUDIENCE_AGE_DISCLAIMER },
    { termsName: footerTerms.TERMS_OF_USE },
    { termsName: footerTerms.PRIVACY_STATEMENT },
    { termsName: footerTerms.ACCESSIBILITY_STATEMENT },
    { termsName: footerTerms.SITEMAP }
  ];

  return (
    <div className="footer">
      <div className="footerTermContainer">
        <div className="footerTerm">
          {footerTermsList.map((footerTermsObject, footerTermsKey) => {
            return (
              <span key={footerTermsKey}>{footerTermsObject.termsName}</span>
            );
          })}
        </div>
      </div>
      <div className="footerDivider"></div>
      <div className="footerCopyRightMsg">{copyRightMessage}</div>
    </div>
  );
}

export default AdminFooter;
