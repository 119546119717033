import { setLoaderActive, setLoaderInActive } from "../loaderAction";
import { submitUserAccountRequest_actionType } from "../../actionTypes/userAction-actionTypes/submitUserAccountRequest-actionType";
import { axiosApigateway } from "../../../api";

const fetchSelectedProducts = (productObject) => {
    let tempProduct = [];

    productObject.forEach(product => {
        product.productList.forEach(element => {
            if(element.checked){
                tempProduct.push({
                    "product_id": element.product_id,
                    "product_name": element.text,
                    "product_abbreviation": element.product_abbreviation,
                    "product_active_flg": element.product_active_flg,
                    "vamProductGroupId": product.id,
                    "applicableAffiliates": element?.applicableAffiliates || []
                });
            }
        });
    });
    return tempProduct;
};

const fetchUserAccessPayLoad = (completeState) => {
    console.log("checkFetchUserAccesspaylado****", completeState.SelectAffiliateReducer.data.selectedCountries.filter(Object=>Object.affiliateType === completeState.SelectAffiliateReducer.data.selectedAffiliateType).map((Object) => {
        return {
            "country_id": Object.country.country_id,
            "country_name": Object.country.country_name,
            "country_abbreviation": Object.country.country_abbreviation,
            "country_active_flg": Object.country.country_active_flg,
            "vamHubAbbreviation": Object.country.vamHubAbbreviation
        };
    }));
    console.log("check***",completeState.WorkFlowRolesReducer.selectedRoles.filter(Object=>Object.workFlowType === completeState.WorkFlowRolesReducer.data.workFlowType).map((Object) => {
        return {
            "vam_role_id": Object.role.vam_role_id,
            "name": Object.role.name,
            "description": Object.role.description,
            // "approvers_ad_groups": Object.role.approvers_ad_groups, (will remove)
            "isActive": Object.role.isActive,
            "roleAudience": Object.role.roleAudience,
            "isElevatedRole": Object.role.isElevatedRole,
            "isRequireProducts": Object.role.isRequireProducts,
            "role_license": Object.role.role_license,
            "role_security_profile": Object.role.role_security_profile,
            "vamHubAbbreviation": Object.role.vamHubAbbreviation
        };
    }));
    const userAccessPayLoad = {
        "abbreviation": completeState.SelectAffiliateReducer.data.selectedAffiliateTypeAbbrebiation,
        "name": completeState.SelectAffiliateReducer.data.selectedAffiliateType,
        "vam_roles" : completeState.WorkFlowRolesReducer.selectedRoles.filter(Object=>Object.workFlowType === completeState.WorkFlowRolesReducer.data.workFlowType).map((Object) => {
            return {
                "vam_role_id": Object.role.vam_role_id,
                "name": Object.role.name,
                "description": Object.role.description,
                // "approvers_ad_groups": Object.role.approvers_ad_groups, (will remove)
                "isActive": Object.role.isActive,
                "roleAudience": Object.role.roleAudience,
                "isElevatedRole": Object.role.isElevatedRole,
                "isRequireProducts": Object.role.isRequireProducts,
                "role_license": Object.role.role_license,
                "role_security_profile": Object.role.role_security_profile,
                "vamHubAbbreviation": Object.role.vamHubAbbreviation
            };
        }),
        "vvpm_products" : fetchSelectedProducts(completeState.ProductReducer.product),
        "vvpm_countries" : completeState.SelectAffiliateReducer.data.selectedCountries.filter(Object=>Object.affiliateType === completeState.SelectAffiliateReducer.data.selectedAffiliateType).map((Object) => {
            return {
                "country_id": Object.country.country_id,
                "country_name": Object.country.country_name,
                "country_abbreviation": Object.country.country_abbreviation,
                "country_active_flg": Object.country.country_active_flg,
                "vamHubAbbreviation": Object.country.vamHubAbbreviation
            };
        })
    };

    return userAccessPayLoad;
};

const fetchRequiredDataForCreateScreen = (completeState) => {
    const userEmail = completeState.UserDetailReducer.data.emailId;
    const userId = completeState.UserDetailReducer.data.id;
    // const isUserOnBoarded = userEmail.includes('lilly.com') && userId ? true : false;
    const isUserOnBoarded = userId ? true : false;
    const fedrattionID = (isUserOnBoarded ? completeState.UserDetailReducer.data.systemID : completeState.UserDetailReducer.data.emailId);
    const payLoad = {
        "first_name": completeState.UserDetailReducer.data.firstName,
        "last_name": completeState.UserDetailReducer.data.lastName,
        "display_name": completeState.UserDetailReducer.data.firstName + " " + completeState.UserDetailReducer.data.lastName,
        "email": userEmail,
        "federated_id": fedrattionID,
        "globalId": completeState.UserDetailReducer.data.globalId,
        "company": completeState.UserDetailReducer.data.company,
        "veeva_username": completeState.UserDetailReducer.data.veevaUserName,
        "timezone": completeState.UserDetailReducer.data.timezone,
        "language": completeState.UserDetailReducer.data.language,
        "locale": completeState.UserDetailReducer.data.locale,
        "location": completeState.UserDetailReducer.data.location,
        "user_type": completeState.UserDetailReducer.data.userType,
        "is_on_boarded": isUserOnBoarded,
        "users_access_payload": fetchUserAccessPayLoad(completeState),
        ...(!completeState.UserDetailReducer.data.isOnboardedUser? {
        "lillySponsor":{
            "uid": completeState.SummaryReducer.selectedSponsor[0].employeeId,
            "name": completeState.SummaryReducer.selectedSponsor[0].displayName,
            "mail": completeState.SummaryReducer.selectedSponsor[0].mail
        }
        }: {})
    };

    return payLoad;
};

const fetchRequiredDataForModifyScreen = (completeState, requestType) => {
    const payLoad = {
        "user_id": completeState.UserDetailReducer.data.id,
        "users_access_payload": fetchUserAccessPayLoad(completeState),
        ...(!completeState.UserDetailReducer.data.isOnboardedUser && requestType === "modify" ? {
            "lillySponsor":{
                "uid": completeState.SummaryReducer.selectedSponsor[0].employeeId,
                "name": completeState.SummaryReducer.selectedSponsor[0].displayName,
                "mail": completeState.SummaryReducer.selectedSponsor[0].mail
            }
        } :{})
    };

    return payLoad;
};

const fetchRequiredDataForDeactivateScreen = (completeState) => {
    const payLoad = {
        "user_id": completeState.UserDetailReducer.data.id
    };

    return payLoad;
};

const fetchRequestPayLoad = (completeState, requestType) => {
    switch(requestType){
        case "create":
            return fetchRequiredDataForCreateScreen(completeState);
        case "modify":
            return fetchRequiredDataForModifyScreen(completeState, "modify");
        case "reactivate":
            return fetchRequiredDataForModifyScreen(completeState, "reactivate");
        case "deactivate":
            return fetchRequiredDataForDeactivateScreen(completeState);
    }
};

const fetchRequiredData = (completeState, requestType) => {
    const requiredData = {
        "requested_for": completeState.UserDetailReducer.data.emailId,
        "requested_by": completeState.LoggedUserReducer.loggedUser.username,
        "requested_for_name": completeState.UserDetailReducer.data.firstName+ " " + completeState.UserDetailReducer.data.lastName,
        "requested_by_name": completeState.LoggedUserReducer.loggedUser.name.split("-")[0].trim(),
        "requestType": requestType,
        "requester_comments": completeState.SummaryReducer.comments,
        "request_payload": fetchRequestPayLoad(completeState, requestType)
    };

    return requiredData;
};

export function submitUserAccountRequest(completeState, requestType){
    return async(dispatch)=> {
        dispatch(setLoaderActive());

        try{
            const requiredData = fetchRequiredData(completeState, requestType);
            
            // calling API
            const response = await axiosApigateway.post('/requests', requiredData);
            if (response) {
                dispatch({
                    type: submitUserAccountRequest_actionType.SUBMIT_ACTION_SUCCESS,
                    data : response.data.data.request_id,
                    requestType: requestType
                });

                setTimeout(()=>{
                    dispatch(setLoaderInActive());
                }, 3000);
                return {response, success: true};
                
            }
        }catch(error){
            dispatch({
                type: submitUserAccountRequest_actionType.SUBMIT_ACTION_FAILURE,
                data: error
            });
            setTimeout(()=>{
                dispatch(setLoaderInActive());
            }, 3000);
        }
    };
}
