import React from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
// import classNames from "classnames";

export const WorkFlowResult = (props) => {
  const { workFlowType, selectedRoles } = props;
  const [expanded, setExpanded] = React.useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    // WorkFlow Result
    <div className="workflowResult">
      <Accordion
        expanded={expanded === "workflowPanel"}
        disableGutters
        onChange={handleChange("workflowPanel")}
        sx={{
          borderBottom: "1px solid #dddddd",
          borderRadius: "0.5rem",
          margin: "1rem 0"
        }}
      >
        <AccordionSummary
          expandIcon={
            expanded === "workflowPanel" ? (
              <RemoveCircleOutlineOutlinedIcon sx={{ color: "#000" }} />
            ) : (
              <AddCircleOutlineOutlinedIcon sx={{ color: "#000" }} />
            )
          }
          sx={{
            background: "#DFF1F7",
            flexDirection: "row-reverse",
            boxShadow: "0px 3px 6px #00000029",
            borderRadius: "0.5rem 0.5rem 0 0"
          }}
        >
          <div className="userDetailsResult_header">
            <span className="userDetailsResult_header_heading">
              Roles
            </span>
          </div>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            borderRadius: "0rem 0rem 0.5rem 0.5rem",
            boxShadow: " 0px 3px 6px #00000029"
          }}
        >
          <div className="workflowResult_section">
            <div className="workflowResult_element">
              <span className="workflowResult_elementHeading">Workflow</span>
              <span className="workflowResult_elementContent">
                {workFlowType?.charAt(0).toUpperCase() + workFlowType?.slice(1)}
              </span>
            </div>
            <div className="workflowResult_element">
              <span className="workflowResult_elementHeading">Roles</span>
              <span className="workflowResult_elementContent">
                {" "}
                {selectedRoles
                  ?.filter((rle) => rle.workFlowType === workFlowType)
                  .map((rle) => rle.role.name)
                  .join(" | ")}
              </span>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
