import { reassignAndTerminateReassignTask_actionType } from "../../../actionTypes/adminAction-actionTypes/reassignAndTerminate/reassignAndTerminateReassignTask-actionType";

const initialState = {
    loading: false,
    isRequestCompleted: false,
    isSuccess: false,
    message: []
};

const ReassignAndTerminateReassignTaskReducer = (state = initialState, action) =>{
    switch(action.type){
        case reassignAndTerminateReassignTask_actionType.REASSIGN_AND_TERMINATE_REASSIGN_TASK:
            return{
                ...state,
                loading: true
            };
        case reassignAndTerminateReassignTask_actionType.REASSIGN_AND_TERMINATE_REASSIGN_TASK_SUCCESS:
            return{
                ...state,
                loading: false,
                isRequestCompleted: true,
                isSuccess:true,
                message: [action.payload]
            };
        case reassignAndTerminateReassignTask_actionType.REASSIGN_AND_TERMINATE_REASSIGN_TASK_RESET:
            return{
                ...initialState
            };
        case reassignAndTerminateReassignTask_actionType.REASSIGN_AND_TERMINATE_REASSIGN_TASK_ERROR:
            return{
                ...state,
                loading: false,
                isRequestCompleted:true,
                isSuccess: false,
                message: [action.payload]
            };
        default: 
            return state;
    }
};

export default ReassignAndTerminateReassignTaskReducer;