import React from 'react';
import "./styles/AdminMainArticle.css";

import { adminPortalPageNavigation_actionType } from "../../utils/adminPortalPageNavigation_actionType";

import AdminDashboard from './AdminDashboard/AdminDashboard';
import Affliate from './DataConfiguration/Affliate/Affliate';
import BusinessUnit from './DataConfiguration/BusinessUnit/BusinessUnit';
import Roles from './DataConfiguration/Roles/Roles';
import Products from './DataConfiguration/Products/Products';
import ReassignAndTerminateLoader from './DataConfiguration/ReassignAndTerminate/ReassignAndTerminateLoader';
import Trainings from './DataConfiguration/Trainings/Trainings';
import Groups from './DataConfiguration/Groups/Groups';
import Company from './DataConfiguration/Company/Company';

// const yetToImplement = "Yet To Implement";
function AdminMainArticle({
  activeSection
}) {
  switch (activeSection) {
    case adminPortalPageNavigation_actionType.ADMIN_DASHBOARD:
      return <div className='adminMainArticle'><AdminDashboard /></div>;
    case adminPortalPageNavigation_actionType.COMPANY:
      return <div className='adminMainArticle'><Company /></div>;
    case adminPortalPageNavigation_actionType.AFFLIATE:
      return <div className='adminMainArticle'><Affliate /></div>;
    case adminPortalPageNavigation_actionType.GROUPS:
      return <div className='adminMainArticle'><Groups /></div>;
    case adminPortalPageNavigation_actionType.ROLES:
      return <div className='adminMainArticle'><Roles /></div>;
    case adminPortalPageNavigation_actionType.TRAINING:
      return <div className='adminMainArticle'><Trainings /></div>;
    case adminPortalPageNavigation_actionType.PRODUCTS:
      return <div className='adminMainArticle'><Products /></div>;
    case adminPortalPageNavigation_actionType.REASSIGN:
      return <div className='adminMainArticle'><ReassignAndTerminateLoader /></div>;
    case adminPortalPageNavigation_actionType.BUSINESS_UNIT:
      return <div className='adminMainArticle'><BusinessUnit /></div>;
    default:
      return <div className='adminMainArticle'><AdminDashboard /></div>;
  }
}

export default AdminMainArticle;