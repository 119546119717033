import { loader_actionType } from '../actionTypes/loader-actionType';

const initialState = {
  loaderActive : false,
  adminDashboardLoader: false,
  viewAdminDashboardLoader: false,
  adminProductLoader: false,
  adminAffiliateLoader: false
};

const LoaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case loader_actionType.SET_LOADER_ON:
      return {
        ...state,
        loaderActive: true
      };
    case loader_actionType.SET_LOADER_OFF:
      return {
        ...state,
        loaderActive: false
      };
    case loader_actionType.SET_ADMIN_DASHBOARD_LOADER_ON:
      return {
        ...state,
        adminDashboardLoader: true
      };
    case loader_actionType.SET_ADMIN_DASHBOARD_LOADER_OFF:
      return {
        ...state,
        adminDashboardLoader: false
      };
    case loader_actionType.SET_VIEW_ADMIN_DASHBOARD_LOADER_ON:
      return {
        ...state,
        viewAdminDashboardLoader: true
      };
    case loader_actionType.SET_VIEW_ADMIN_DASHBOARD_LOADER_OFF:
      return {
        ...state,
        viewAdminDashboardLoader: false
      };
    case loader_actionType.SET_ADMIN_PRODUCT_LOADER_ON:
      return {
        ...state,
        adminProductLoader: true
      };
    case loader_actionType.SET_ADMIN_PRODUCT_LOADER_OFF:
      return {
        ...state,
        adminProductLoader: false
      };
    case loader_actionType.SET_ADMIN_AFFILIATE_LOADER_ON:
      return{
        ...state,
        adminAffiliateLoader: true
      };
    case loader_actionType.SET_ADMIN_AFFILIATE_LOADER_OFF:
      return{
        ...state,
        adminAffiliateLoader: false
      };
    default:
      return state;
  }
};

export default LoaderReducer;