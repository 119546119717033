import { axiosApigateway } from "../../../../api";
import { reassignAndTerminateRequestList_actionType } from "../../../actionTypes/adminAction-actionTypes/reassignAndTerminate/reassignAndTerminateRequestList-actionType";

export function reassignAndTerminateRequestList() {
  return {
    type: reassignAndTerminateRequestList_actionType.REASSIGN_AND_TERMINATE_REQUEST_LIST
  };
}

export function reassignAndTerminateRequestListSuccess(data) {
  return {
    type: reassignAndTerminateRequestList_actionType.REASSIGN_AND_TERMINATE_REQUEST_LIST_SUCCESS,
    payload: data,
    filterOptions:getUniqueFilterOptions(data)
  };
}

export function reassignAndTerminateRequestListError(error) {
  return {
    type: reassignAndTerminateRequestList_actionType.REASSIGN_AND_TERMINATE_REQUEST_LIST_ERROR,
    payload: error
  };
}

export function reassignAndTerminateRequestListSelectRequest(data) {
  return {
    type: reassignAndTerminateRequestList_actionType.REASSIGN_AND_TERMINATE_REQUEST_LIST_SELECT_REQUEST,
    payload: data
  };
}

function getReassignAndTerminateRequestList(data) {
  let dataList = [];
  data.forEach((dataObject) => {
    const formattedDataObject = {
      request_id: dataObject?.request_id ?? "",
      request_type: dataObject?.requestType ?? "",
      user_type: dataObject?.request_payload?.user_type ?? "",
      full_name: dataObject?.request_payload?.display_name ?? "",
      system_id: dataObject?.request_payload?.federated_id ?? "",
      veeva_username: dataObject?.request_payload?.veeva_username ?? "",
      affiliate_type:
        dataObject?.request_payload?.users_access_payload?.name ?? "",
      last_action_date: dataObject?.updatedAt
    };
    dataList.push(formattedDataObject);
  });

  return dataList;
}

export function reassignAndTerminateRequestListCall() {
  return async (dispatch) => {
    dispatch(reassignAndTerminateRequestList());

    try {
      const response = await axiosApigateway.get(`/admin-reassign-request?request_status=pending`);
      if (response) {
        const responseData = response.data.data;
        const data = getReassignAndTerminateRequestList(responseData);
        dispatch(reassignAndTerminateRequestListSuccess(data));
      }
    } catch (error) {
      dispatch(reassignAndTerminateRequestListError(error));
    }
  };
}

const getUniqueFilterOptions = (allRequests) => {
  let uniqueAffiliateType = new Set();
  let uniqueUserType = new Set();
  let uniqueRequestType = new Set();

  let uniqueAffiliateTypeList = [];
  let uniqueUserTypeList = [];
  let uniqueRequestTypeList = [];
  let filterOptions = filteredOptionsInitialState;

  // getting unique values
  allRequests.forEach(element => {
    uniqueAffiliateType.add(element.affiliate_type);
    uniqueUserType.add(element.user_type);
    uniqueRequestType.add(element.request_type);
  });

  //converting to list
  uniqueAffiliateType.forEach(element => {
    if (element)
    uniqueAffiliateTypeList.push(element);
  });
  uniqueUserType.forEach(element => {
    if (element)
    uniqueUserTypeList.push(element);
  });
  uniqueRequestType.forEach(element => {
    if (element)
    uniqueRequestTypeList.push(element);
  });

  filterOptions[0].values = uniqueAffiliateTypeList.map((object) => {
    return {
      name: object,
      value: object,
      selected: false
    };
  });
  filterOptions[1].values = uniqueUserTypeList.map((object) => {
    return {
      name: object,
      value: object,
      selected: false
    };
  });
  filterOptions[2].values = uniqueRequestTypeList.map((object) => {
    return {
      name: object?.charAt(0).toUpperCase() + object?.slice(1) + " Account",
      value: object,
      selected: false
    };
  });

  return filterOptions;
};

const filteredOptionsInitialState = [
  {
    name: "Affiliate Type",
    accessor: "affiliate_type",
    values: []
  },
  {
    name: "User Type",
    accessor: "user_type",
    values: []
  },
  {
    name: "Request Type",
    accessor: "request_type",
    values: []
  }
];
