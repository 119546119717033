import { checkAccountStatusResources_actionType } from "../../actionTypes/userAction-actionTypes/checkAccountStatusResources-actionType";

const initialState = {
  loading: false,
  data: [],
  error: undefined
};

const CheckAccountStatusResourcesReducer = (state = initialState, action) => {
  switch (action.type) {
    case checkAccountStatusResources_actionType.CHECK_ACCOUNT_STATUS:
      return {
        ...state,
        loading: true,
        error: undefined
      };
    case checkAccountStatusResources_actionType.CHECK_ACCOUNT_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload
      };
    case checkAccountStatusResources_actionType.RESET_CHECK_ACCOUNT_STATUS:
      return {
        ...initialState
      };
    case checkAccountStatusResources_actionType.CHECK_ACCOUNT_STATUS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default CheckAccountStatusResourcesReducer;
