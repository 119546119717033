import React, { useEffect, useState } from 'react';
import "./styles/exceptionHandler.css";

import ApprovarHeader from './ApprovarHeader';
import { useDispatch, useSelector } from 'react-redux';
// import { axiosApigateway } from '../../api';
import { Button } from '@mui/material';
import CircularProgressLoader from '../../components/common/CircularProgressLoader';
import { retryTrainingVarify } from '../../redux/actions/approvar-actions/approvar-action';
import ModalMainContainer from '../ModalNotification/ModalMainContainer';
import { approvar_actionType } from '../../redux/actionTypes/approvar-actionTypes/approvar-actionType';
import { useNavigate } from 'react-router-dom';

function ExceptionHandler({
  actionType,
  id
}) {
  const { requestId, exceptionMessage, isLoading, isEditOrUpdateFailed, taskStatus, error, retryExceptionSuccess, retryTrainingSuccessMessage, isExceptionAccessible } = useSelector(state => ({
    requestId: state?.ApprovarReducer?.data?.vamRequestRequestId,
    exceptionMessage: state?.ApprovarReducer?.data?.taskComments,
    isLoading: state?.ApprovarReducer?.loading,
    isEditOrUpdateFailed: state.ApprovarReducer?.isEditOrUpdateFailed,
    error: state.ApprovarReducer?.error,
    retryExceptionSuccess: state.ApprovarReducer?.retryExceptionSuccess,
    retryTrainingSuccessMessage: state.ApprovarReducer?.retryTrainingSuccessMessage,
    isExceptionAccessible: state.ApprovarReducer?.isExceptionAccessible,
    taskStatus: state?.ApprovarReducer?.data?.taskStatus
  }));
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const navigate = useNavigate();

  // console.log(navigate);
  // "taskStatus": "auto-approved"

  useEffect(() => {
    if (
      !isLoading &&
      !isExceptionAccessible
    ) {
      navigate("/access-denied");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isExceptionAccessible]);

  useEffect(() => {
    if (isEditOrUpdateFailed) {
      setIsSubmitSuccess(false);
      setModalMessage([error]);
      setIsModalOpen(true);
    }
    else if (retryExceptionSuccess) {
      setIsSubmitSuccess(true);
      setModalMessage([retryTrainingSuccessMessage]);
      setIsModalOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditOrUpdateFailed, retryExceptionSuccess]);

  const handleRetryHandler = () => {
    dispatch(retryTrainingVarify(id));
  };
  // console.log(requestId, activityName, exceptionMessage);
  if (isLoading) {
    return (<CircularProgressLoader />);
  }
  return (
    <>
      <div className='homeContainer'>
        <ApprovarHeader
          actionType={actionType}
          id={id}
        />
        <div className='card'>
          <div className='exception_home'>
            <div className='exception_header'>VAM-VVPM Support Task</div>
            <div className='exception_section'>
              <div>
                <span className='exception_sectionTitle'>Request ID</span>
                <span className='exception_sectionValue'>{requestId}</span>
              </div>
              <div>
                <span className='exception_sectionTitle'>Task ID</span>
                <span className='exception_sectionValue'>{id}</span>
              </div>
              <div>
                <span className='exception_sectionTitle'>Activity Name</span>
                <span className='exception_sectionValue'>Check Mandatory Training Status - {requestId}</span>
              </div>
              <div>
                <span className='exception_sectionTitle'>Exception Message</span>
                <span className='exception_sectionValue exception_sectionComment'>{(retryExceptionSuccess || taskStatus === "auto-approved") ? '' : exceptionMessage}</span>
              </div>
            </div>
          </div>
        </div>
        <div className='homeContainerButtons'>
          <Button
            className="buttonDanger"
            variant="outlined"
            onClick={handleRetryHandler}
            disabled={retryExceptionSuccess || taskStatus === "auto-approved"}
            color="error"
            sx={{
              width: 115,
              height: 40,
              ":hover": {
                bgcolor: "red",
                color: "white"
              }
            }}
          >
            Retry
          </Button>
        </div>
      </div>
      <ModalMainContainer
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        isSubmitActive={true}
        modalMessage={modalMessage}
        isSubmitSuceess={isSubmitSuccess}
        reactivate={false}
        handleReactivate={() => { }}
        toggleToLandingPage={() => {
          setIsModalOpen(false);
          if (isEditOrUpdateFailed) {
            dispatch({
              type: approvar_actionType.RESET_ERROR_MODEL
            });
          }
        }}
      />
    </>
  );
}

export default ExceptionHandler;