import { workFlowRoles_actionType } from "../../actionTypes/userAction-actionTypes/workFlowRoles-actionType";
import { requiredFieldAvailableWorkFlowRoles } from "./utility/requiredFieldsWorkFlowRoles";

const initialState = {
  data: {
    workFlowType: "",
    roles: []
  },
  selectedRoles: [],
  userAccessRolesModified: false,
  requiredFieldAvailable: false,
  trainings : [],
  error: undefined
};

const WorkFlowRolesReducer = (state = initialState, action) => {
  switch (action.type) {
    case workFlowRoles_actionType.SET_WORKFLOW_ROLES_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: undefined
      };
    case workFlowRoles_actionType.SELECT_DESELECT_WORKFLOW_ROLES:
      return {
        ...state,
        selectedRoles: action.payload,
        requiredFieldAvailable: requiredFieldAvailableWorkFlowRoles(
          action.payload,
          action.state
        ),
        error: undefined
      };
    case workFlowRoles_actionType.SET_WORKFLOW_ROLES_FAILURE:
      return {
        ...state,
        error: action.data,
        data: initialState
      };
    case workFlowRoles_actionType.GET_WORKFLOW_ROLES_SUCCESS:
      return {
        ...state.data
      };
    case workFlowRoles_actionType.SET_USER_ACCESS_ROLES_MODIFIED:
      return {
        ...state,
        userAccessRolesModified: action.payload
      };
    case workFlowRoles_actionType.RESET_WORKFLOW_ROLES_SUCCESS:
      return {
        ...initialState
      };
    case workFlowRoles_actionType.GET_WORKFLOW_ROLES_FAILURE:
      return {
        ...state.error
      };
    case workFlowRoles_actionType.SET_TRAININGS:
      return {
        ...state,
        trainings : action.payload
      };
    default:
      return state;
  }
};

export default WorkFlowRolesReducer;
