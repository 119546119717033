import React from "react";
import { Card, Grid } from "@mui/material";

const UserDetailsCard = ({ userDetails }) => {
  return (
    <Grid item sm={12} md={12} className="accountSummary_mainItemSection">
      <Card className="accountSummary_userDetailsParent">
        <div className="accountSummary_header">
          <Grid container spacing={2}>
            <Grid item md={11} className="accountSummary_mainHeading">
              User Details
            </Grid>
          </Grid>
        </div>
        <div className="accountSummary_section userDetailsSection">
          <Grid container spacing={2} className="userDetailsContainer">
          <Grid item md={2}>
                  <div><strong>First Name</strong></div>
                </Grid>
                <Grid item md={2}>
                  <div>{userDetails?.first_name}</div>
                </Grid>
                <Grid item md={1}>
                  <div><strong>Last Name</strong></div>
                </Grid>
                <Grid item md={2}>
                  <div>{userDetails?.last_name}</div>
                </Grid>
                <Grid item md={1}>
                  <div><strong>Email Id</strong></div>
                </Grid>
                <Grid item md={3}>
                  <div>{userDetails?.email}</div>
                </Grid>
                <Grid item md={2}>
                  <div><strong>Veeva Username</strong></div>
                </Grid>
                <Grid item md={2}>
                  <div>{userDetails?.veeva_username}</div>
                </Grid>
                <Grid item md={1}>
                  <div><strong>User Type</strong></div>
                </Grid>
                <Grid item md={2}>
                  <div>{userDetails?.user_type}</div>
                </Grid>
                <Grid item md={1}>
                  <div><strong>Location</strong></div>
                </Grid>
                <Grid item md={3}>
                  <div>{userDetails?.location}</div>
                </Grid>
                <Grid item md={2}>
                  <div><strong>Company</strong></div>
                </Grid>
                <Grid item md={2}>
                  <div>{userDetails?.company}</div>
                </Grid>
                <Grid item md={1}>
                  <div><strong>Language</strong></div>
                </Grid>
                <Grid item md={2}>
                  <div>{userDetails?.language}</div>
                </Grid>
                <Grid item md={1}>
                  <div><strong>Timezone</strong></div>
                </Grid>
                <Grid item md={3}>
                  <div>{userDetails?.timezone}</div>
                </Grid>
                <Grid item md={2}>
                  <div><strong>Locale</strong></div>
                </Grid>
                <Grid item md={2}>
                  <div>{userDetails?.locale}</div>
                </Grid>
          </Grid>
        </div>
      </Card>
    </Grid>
  );
};

export default UserDetailsCard;
