export const product_actionType = {
  SET_PRODUCT_SUCCESS: "SET_PRODUCT_SUCCESS",
  GET_PRODUCT_SUCCESS: "GET_PRODUCT_SUCCESS",
  GET_PRODUCT_FAILURE: "GET_PRODUCT_FAILURE",
  RESET_PRODUCT_SUCCESS: "RESET_PRODUCT_SUCCESS",
  UPDATE_IS_PRODUCT_MANDATORY: "UPDATE_IS_PRODUCT_MANDATORY",
  SET_IS_PRODUCT_MANDATORY_TRUE: "SET_IS_PRODUCT_MANDATORY_TRUE",
  SET_IS_PRODUCT_MANDATORY_FALSE: "SET_IS_PRODUCT_MANDATORY_FALSE",
  SET_USER_ACCESS_PRODUCT_MODIFIED: "SET_USER_ACCESS_PRODUCT_MODIFIED",
  SET_PRODUCT_SEARCH_TEXT: "SET_PRODUCT_SEARCH_TEXT",
  SET_REQUIRED_FIELD_AVAILABLE: "SET_REQUIRED_FIELD_AVAILABLE"
};
