import { taskNavigation_actionType } from '../utils/taskNavigation_actionType';
import { pageNavigation_actionType } from '../utils/pageNavigation_actionType';
import { pageTitle_actionTypes } from '../utils/pageTitle_actionType';

import React from 'react';

import CheckIcon from "@mui/icons-material/Check";

const HomeHeader = ({
    pageTitle, 
    pageNavigationList, 
    currentTask, 
    taskCompleted, 
    setCurrentTask,
    setTaskCompleted,
    setPageNavigationList, 
    toggleToLandingPage
}) => {
    const classNameIfNavigationTaskNotSelected = "pageNumberTag";
    const classNameIfNavigationTaskSelected =
        "pageNumberTag pageNumberTagSelected";
    const classNameIfNavigationTaskCompleted =
        "pageNumberTag pageNumberTagCompleted";

    // JS Functions -
    const handelNavigationClicked = (text) => {
        if (pageTitle === pageTitle_actionTypes.CREATE_USER_ACCOUNT) {
          if (
            text === pageNavigation_actionType.ACCOUNT_REQUEST_FORM ||
            text === pageNavigation_actionType.CREATE_USER_ACCOUNT
          )
            toggleToLandingPage();
          else if (text === pageNavigation_actionType.USER_DETAILS) {
            setCurrentTask(0);
            setTaskCompleted(0);
            setPageNavigationList([
              pageNavigation_actionType.ACCOUNT_REQUEST_FORM,
              pageNavigation_actionType.CREATE_USER_ACCOUNT,
              pageNavigation_actionType.USER_DETAILS
            ]);
          } else if (text === pageNavigation_actionType.USER_ROLES) {
            setCurrentTask(1);
            setTaskCompleted(1);
            setPageNavigationList([
              pageNavigation_actionType.ACCOUNT_REQUEST_FORM,
              pageNavigation_actionType.CREATE_USER_ACCOUNT,
              pageNavigation_actionType.USER_DETAILS,
              pageNavigation_actionType.USER_ROLES
            ]);
          } else if (text === pageNavigation_actionType.SELECT_PRODUCTS) {
            setCurrentTask(2);
            setTaskCompleted(2);
            setPageNavigationList([
              pageNavigation_actionType.ACCOUNT_REQUEST_FORM,
              pageNavigation_actionType.CREATE_USER_ACCOUNT,
              pageNavigation_actionType.USER_DETAILS,
              pageNavigation_actionType.SELECT_PRODUCTS
            ]);
          } else if (text === pageNavigation_actionType.SUMMARY) {
            setCurrentTask(3);
            setTaskCompleted(3);
            setPageNavigationList([
              pageNavigation_actionType.ACCOUNT_REQUEST_FORM,
              pageNavigation_actionType.CREATE_USER_ACCOUNT,
              pageNavigation_actionType.USER_DETAILS,
              pageNavigation_actionType.SELECT_PRODUCTS,
              pageNavigation_actionType.SUMMARY
            ]);
          }
        }
    };

  return (
    <div className="pageTitleContainer">
        <div className="pageTitleText">
          <span className="pageTitleHeader">{pageTitle}</span>
          <span className="pageTitleNavigator">
            {pageNavigationList.map(
              (pageNavigationObject, pageNavigationKey) => {
                return (
                  <span key={pageNavigationKey}>
                    <span
                      key={pageNavigationKey}
                      style={
                        pageNavigationKey == pageNavigationList.length - 1
                          ? {
                              color: "orange",
                              borderBottom: "1px solid orange",
                              fontWeight: "700"
                            }
                          : null
                      }
                      onClick={() =>
                        handelNavigationClicked(pageNavigationObject)
                      }
                      className={pageTitle != pageTitle_actionTypes.ACCOUNT_REQUEST_SUMMARY ? 'clickable' : 'notClickable'}
                    >
                      {pageNavigationObject}
                    </span>
                    <span>
                      {pageNavigationKey != pageNavigationList.length - 1
                        ? " > "
                        : null}
                    </span>
                  </span>
                );
              }
            )}
          </span>
        </div>
        <div className="pageTitleIcon">
          <div className="pageTitleIconBox">
            <span
              className={
                currentTask === 0
                  ? classNameIfNavigationTaskSelected
                  : taskCompleted >= 1
                  ? classNameIfNavigationTaskCompleted
                  : classNameIfNavigationTaskNotSelected
              }
            >
              {taskCompleted >= 1 ? <CheckIcon style={{ 'fontSize' : '26px' }}/> : 1}
            </span>
            <span className="horizontalConnectorOrange"></span>
            <span
              className={
                taskCompleted >= 1
                  ? "horizontalConnectorOrange"
                  : "horizontalConnectorGray"
              }
            ></span>
            <span
              className={
                currentTask === 1
                  ? classNameIfNavigationTaskSelected
                  : taskCompleted >= 2
                  ? classNameIfNavigationTaskCompleted
                  : classNameIfNavigationTaskNotSelected
              }
            >
              {taskCompleted >= 2 ? <CheckIcon style={{ 'fontSize' : '26px' }} /> : 2}
            </span>
            <span
              className={
                taskCompleted >= 2 || (taskCompleted >= 1 && currentTask === 1)
                  ? "horizontalConnectorOrange"
                  : "horizontalConnectorGray"
              }
            ></span>
            <span
              className={
                taskCompleted >= 2
                  ? "horizontalConnectorOrange"
                  : "horizontalConnectorGray"
              }
            ></span>
            <span
              className={
                currentTask === 2
                  ? classNameIfNavigationTaskSelected
                  : taskCompleted >= 3
                  ? classNameIfNavigationTaskCompleted
                  : classNameIfNavigationTaskNotSelected
              }
            >
              {taskCompleted >= 3 ? <CheckIcon style={{ 'fontSize' : '26px' }}/> : 3}
            </span>
          </div>
          <div className="pageTitleIconText">
            <span className="pageTitleIconTextFirst">
              {taskNavigation_actionType.USER_DETAILS}
            </span>
            <span className="pageTitleIconTextSecond">
              {taskNavigation_actionType.SELECT_ROLES_OR_GROUPS}
            </span>
            <span className="pageTitleIconTextThird">
              {taskNavigation_actionType.SELECT_PRODUCTS}
            </span>
          </div>
        </div>
      </div>
  );
};

export default HomeHeader;