import { allUserDetailResources_actionType } from "../../actionTypes/userAction-actionTypes/allUserDetailResources-actionType";

const initialState = {
    loading: false,
    data: [],
    error: undefined
  };
  
  const AllUserDetailResourcesReducer = (state = initialState, action) => {
    switch (action.type) {
      case allUserDetailResources_actionType.ALL_USER_DETAIL_RESOURCES:
        return {
          ...state,
          loading: true,
          error: undefined
        };
      case allUserDetailResources_actionType.ALL_USER_DETAIL_RESOURCES_SUCCESS:
        return {
          ...state,
          loading: false,
          data: action.payload
        };
      case allUserDetailResources_actionType.RESET_ALL_USER_DETAIL_RESOURCES:
        return {
          ...initialState
        };
      case allUserDetailResources_actionType.ALL_USER_DETAIL_RESOURCES_ERROR:
        return {
          ...state,
          loading: false,
          error: action.payload
        };
      default:
        return state;
    }
  };
  
  export default AllUserDetailResourcesReducer;
  