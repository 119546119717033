import { axiosApigateway } from "../../../../api";
import { groupsRequestList_actionType } from "../../../actionTypes/adminAction-actionTypes/role-actionTypes/groupsRequestList-actionType";

export function groupsRequestList() {
    return {
        type: groupsRequestList_actionType.GROUPS_REQUEST_LIST_START
    };
}

export function groupsRequestListSuccess(data) {
    return {
        type: groupsRequestList_actionType.GROUPS_REQUEST_LIST_SUCCESS,
        payload: data
    };
}

export function groupsRequestListError(error) {
    return {
        type: groupsRequestList_actionType.GROUPS_REQUEST_LIST_ERROR,
        payload: error
    };
}

function getGroupsRequestList(data) {
    let dataList = [];
    data.forEach((dataObject) => {
        const formattedDataObject = {
            idPk: dataObject?.id_pk ?? "",
            isDynamicProduct: dataObject?.isDynamicProduct,
            isDynamicCountry: dataObject?.isDynamicCountry,
            name: dataObject?.name ?? "",
            description: dataObject?.description ?? ""
        };
        dataList.push(formattedDataObject);
    });
    return dataList;
}

export function groupsRequestListCall() {
    return async (dispatch) => {
        dispatch(groupsRequestList());
        try {
            const response = await axiosApigateway.get("/vam-groups");
            if (response) {
                const responseData = response.data.data;
                const data = getGroupsRequestList(responseData);
                dispatch(groupsRequestListSuccess(data));
            }
        } catch (error) {
            dispatch(groupsRequestListSuccess(error));
        }
    };
}
