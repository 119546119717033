import React from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Button } from "@mui/material";

const ReassignAndTerminateMessageModalNotice = (props) => {
  const {
    error,
    success,
    message,
    twoButton,
    buttonOneText = "NO",
    buttonTwoText = "YES",
    setIsModalOpen
  } = props;
  const okButtonHandler = () =>{
    setIsModalOpen(false);
  };
  return (
    <div className="reassignAndTerminate_msgModalContainer">
      <div
        className={
          error
            ? "reassignAndTerminate_topBorderError"
            : success
            ? "reassignAndTerminate_topBorderSuccess"
            : ""
        }
      ></div>
      <div className="reassignAndTerminate_icon">
        {error ? (
          <div className="reassignAndTerminate_iconError">
            <HelpOutlineIcon
              sx={{ width: "2.54em", height: "2em", color: "#D52B1E" }}
            />
          </div>
        ) : success ? (
          <div className="reassignAndTerminate_iconSuccess">
            <CheckCircleOutlineIcon
              sx={{ width: "2.54em", height: "2em", color: "#00AE43" }}
            />
          </div>
        ) : null}
      </div>
      <div className="reassignAndTerminate_message">
        {message?.map((messageObject, messageKey) => {
          return <span key={messageKey}>{messageObject}</span>;
        })}
      </div>
      <div className="reassignAndTerminate_actionButtons">
        {twoButton ? (
          <div className="reassignAndTerminate_buttonsTwo">
            <Button
              className="buttonDanger"
              variant="outlined"
              onClick={() => {}}
              color={"error"}
              sx={{
                color: "#D52B1E",
                ":hover": {
                  bgcolor: "#D52B1E",
                  color: "white"
                }
              }}
            >
              {buttonOneText}
            </Button>
            <Button
              className="reassignAndTerminate_buttonSuccess"
              variant="outlined"
              onClick={() => {}}
              color="success"
              sx={{
                color: "#00AE43",
                ":hover": {
                  bgcolor: "#00AE43",
                  color: "white"
                }
              }}
            >
              {buttonTwoText}
            </Button>
          </div>
        ) : (
          <>
            <Button
              className="reassignAndTerminate_buttonSuccess"
              variant="outlined"
              onClick={okButtonHandler}
              color="success"
              sx={{
                color: "#00AE43",
                ":hover": {
                  bgcolor: "#00AE43",
                  color: "white"
                }
              }}
            >
              OK
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default ReassignAndTerminateMessageModalNotice;
