import { axiosApigateway } from "../../../../api";
import { reassignAndTerminateTerminateRequest_actionType } from "../../../actionTypes/adminAction-actionTypes/reassignAndTerminate/reassignAndTerminateTerminateRequest-actionType";
import { reassignAndTerminateRequestListCall } from "./reassignAndTerminateRequestList-action";

export function reassignAndTerminateTerminateRequest() {
  return {
    type: reassignAndTerminateTerminateRequest_actionType.REASSIGN_AND_TERMINATE_TERMINATE_REQUEST
  };
}

export function reassignAndTerminateTerminateRequestSuccess(data) {
  return {
    type: reassignAndTerminateTerminateRequest_actionType.REASSIGN_AND_TERMINATE_TERMINATE_REQUEST_SUCCESS,
    payload: data
  };
}

export function reassignAndTerminateTerminateRequestError(error) {
  return {
    type: reassignAndTerminateTerminateRequest_actionType.REASSIGN_AND_TERMINATE_TERMINATE_REQUEST_ERROR,
    payload: error
  };
}

export function reassignAndTerminateTerminateRequestReset() {
  return {
    type: reassignAndTerminateTerminateRequest_actionType.REASSIGN_AND_TERMINATE_TERMINATE_REQUEST_RESET
  };
}

export function reassignAndTerminateTerminateRequestCall(data) {
  return async (dispatch) => {
    dispatch(reassignAndTerminateTerminateRequest());

    try {
      const response = await axiosApigateway.put("/admin-request-terminate",data);
      if (response) {
        const responseData = response.data.message;
        dispatch(reassignAndTerminateTerminateRequestSuccess(responseData));
      }
      dispatch(reassignAndTerminateRequestListCall());
    } catch (error) {
      dispatch(reassignAndTerminateTerminateRequestError(error));
    }
  };
}
