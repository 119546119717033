import { TextField } from "@mui/material";
import React from "react";
import "./styles.css";

export const TextFieldInput = (props) => {
  const { id, label, value, isMultiple = false, ...rest } = props;
  return (
    <div>
      <label htmlFor={id} className="displayLabelTextField">
        {label}
      </label>
      <TextField
        type="text"
        size="small"
        variant="outlined"
        focused={false}
        autoComplete="off"
        className="inputTextField"
        value={value === null ? "" : value}
        InputProps={{
          style: { fontSize: "0.9rem" }
        }}
        {...rest}
        multiline={isMultiple}
      />
    </div>
  );
};
