import React, { useEffect, useRef, useState } from "react";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Button,
  CircularProgress,
  ClickAwayListener,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Popper,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { useDispatch, useSelector } from "react-redux";
import {
  resetSearchApprover,
  searchApproverResources
} from "../../../../../../../redux/actions/adminAccount-actions/reassignAndTerminate/reassignAndTerminateSearchApprover-action";
import { reassignAndTerminateReassignTaskCall } from "../../../../../../../redux/actions/adminAccount-actions/reassignAndTerminate/reassignAndTerminateReassignTask-action";
import CircularProgressLoader from "../../../../../../../components/common/CircularProgressLoader";

export const ReassignAndTerminateReassignApproverModalNotice = (props) => {
  const dispatch = useDispatch();
  const { setIsModalOpen } = props;
  const {
    allRequestTasks: { isLoading, allRequestTasks, allRequestTaskError },
    allApprovers: { loading, data, error },
    loggedInUser,
    selectedRequest
  } = useSelector((state) => {
    return {
      allRequestTasks: {
        isLoading:
          state.ReassignAndTerminateReducer
            .ReassignAndTerminateRequestTasksReducer.loading,
        allRequestTasks:
          state.ReassignAndTerminateReducer
            .ReassignAndTerminateRequestTasksReducer.data,
        allRequestTaskError:
          state.ReassignAndTerminateReducer
            .ReassignAndTerminateSearchApproverReducer.error
      },
      allApprovers: {
        loading:
          state.ReassignAndTerminateReducer
            .ReassignAndTerminateSearchApproverReducer.loading,
        data: state.ReassignAndTerminateReducer
          .ReassignAndTerminateSearchApproverReducer.data,
        error:
          state.ReassignAndTerminateReducer
            .ReassignAndTerminateSearchApproverReducer.error
      },
      loggedInUser: state.LoggedUserReducer.loggedUser,
      selectedRequest:
        state.ReassignAndTerminateReducer.ReassignAndTerminateRequestListReducer
          .selectedRequest
    };
  });
  const searchTextFieldRef = useRef();
  const [userList, setUserList] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchedApproverActive, setSearchApproverActive] = useState(false);
  const [searchedApprover, setSearchApprover] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedUserDetail, setSelectedUserDetail] = useState(null);
  const [selectedTask, setSelectedTask] = useState("");
  const [open, setOpen] = useState(false);

  let REASSIGN_APPROVER_DROPDOWN = [];
  let isTaskReassignable = false;

  const clearSearchBoxHandler = () => {
    setSearchApprover("");
    setSelectedUser(null);
    dispatch(resetSearchApprover());
  };
  // console.log(searchedApproverActive);
  const getParticularUser = (user) => {
    return userList.find((usr) => {
      return usr?.id?.toString() === user?.id?.toString();
    });
  };

  const searchUserHandler = (requestedFor) => {
    if (searchedApprover) {
      setAnchorEl(searchTextFieldRef.current);
      setOpen(true);
      dispatch(searchApproverResources(searchedApprover, requestedFor));
    }
  };

  const selectedUserHandler = (user) => {
    setSelectedUser(user);
    setSearchApprover(user.title);
    setSelectedUserDetail(getParticularUser(user));
    setOpen(false);
  };

  const onReassignTaskClick = (task) => {
    setSelectedTask(task);
    setSearchApproverActive(true);
    dispatch(resetSearchApprover());
  };

  const cancelSearchApproverHandler = () => {
    setSearchApproverActive(false);
    dispatch(resetSearchApprover());
  };

  const confirmSearchApproverHandler = () => {
    dispatch(
      reassignAndTerminateReassignTaskCall({
        taskId: selectedTask.taskId,
        taskAssignedTo: selectedUserDetail.mail,
        taskAssignedBy: loggedInUser.username,
        vamRequestRequestId: selectedTask.vamRequestId
      })
    );
    setIsModalOpen(false);
  };

  useEffect(() => {
    setUserList(data);
  }, [data]);

  if (userList && Array.isArray(userList) && userList.length) {
    userList.map((user) => {
      let id, mail, employeeId, displayName;

      mail = user.mail || "";
      id = user.id || "";
      displayName = user.displayName || "";
      employeeId = user.employeeId || "";

      const dropDown = {
        id: id,
        title: `${displayName} ${employeeId ? `( ${employeeId} )` : ""} ${
          mail ? mail : ""
        }`
      };

      REASSIGN_APPROVER_DROPDOWN.push(dropDown);
    });
  }

  function renderTaskLabel(taskType) {
    let taskLabel = "";
    switch (taskType) {
      case "all":
        taskLabel = "Roles, Products & Trainings approval";
        break;
      case "sponsorApproval":
        taskLabel = "Products & Roles approval";
        break;
      case "elevatedRoleApproval":
        taskLabel = "Elevated Role approval";
        break;
      case "trainingsApproval":
        taskLabel = "Training verification task";
        break;
      case "helpDesk":
        taskLabel = "Helpdesk: Roles, Products & Trainings approval";
        break;
      default:
        taskLabel = taskType;
    }
    return taskLabel;
  }

  return (
    <div className="reassignAndTerminateRA_ModalCard">
      <div className="reassignAndTerminateRA_modalCardHeader">
        <span>Reassign Approver</span>
        <span>
          <CancelOutlinedIcon
            sx={{ fontSize: "1.5rem", cursor: "pointer" }}
            onClick={() => setIsModalOpen(false)}
          />
        </span>
      </div>
      {isLoading ? (
        <CircularProgressLoader styles={{ position: "absolute" }} />
      ) : allRequestTaskError ? (
        <div className="reassignAndTerminateRA_noTasks">
          <Typography color="red">{allRequestTaskError}</Typography>
        </div>
      ) : allRequestTasks.length ? (
        <>
          <div className="ReassignTerminateTasksPanel">
            <span className="reassignAndTerminateRASearchApprover_mainHead">
              Request {selectedRequest.request_id} - All Tasks
            </span>
            <Grid container className="reassignAndTerminateRATasks_wrapper">
              {allRequestTasks.map((taskObject) => {
                if (
                  taskObject.taskStatus === "active" &&
                  !taskObject.viewerADGroup
                ) {
                  isTaskReassignable = true;
                }
                return (
                  <Grid
                    key={taskObject.taskId}
                    container
                    style={{ padding: "1rem" }}
                  >
                    <Grid item md={12} style={{ marginBottom: "0.5rem" }}>
                      <span className="reassignAndTerminateRATasks_taskHead">
                        {taskObject.taskId}
                      </span>
                    </Grid>
                    <Grid item md={4} style={{ paddingRight: "5px" }}>
                      <span className="reassignAndTerminateRATasks_name">
                        Requested For{" "}
                      </span>
                      <br />
                      <span className="reassignAndTerminateRATasks_detail">
                        {taskObject.requestedFor}
                      </span>
                    </Grid>
                    <Grid item md={4} style={{ paddingRight: "5px" }}>
                      <span className="reassignAndTerminateRATasks_name">
                        Requested By{" "}
                      </span>
                      <br />
                      <span className="reassignAndTerminateRATasks_detail">
                        {taskObject.requestedBy}
                      </span>
                    </Grid>
                    <Grid item md={4}>
                      <span className="reassignAndTerminateRATasks_name">
                        Task Type{" "}
                      </span>
                      <br />
                      <span className="reassignAndTerminateRATasks_detail">
                        {renderTaskLabel(taskObject.taskType)}
                      </span>
                    </Grid>
                    <Grid item md={4} style={{ paddingRight: "5px" }}>
                      <span className="reassignAndTerminateRATasks_name">
                        Task Assigned To{" "}
                      </span>
                      <br />
                      <span className="reassignAndTerminateRATasks_detail">
                        {!taskObject.taskAssignedBy.length
                          ? taskObject.viewerADGroup.length
                            ? taskObject.initialTaskAudience.split("@")[0]
                            : taskObject.taskAssignedTo
                          : taskObject.taskAssignedTo}
                        {/* {taskObject.taskAssignedTo.length ? taskObject.taskAssignedTo : taskObject.initialTaskAudience} */}
                      </span>
                    </Grid>
                    <Grid item md={4} style={{ paddingRight: "5px" }}>
                      <span className="reassignAndTerminateRATasks_name">
                        Task Status{" "}
                      </span>
                      <br />
                      <span className="reassignAndTerminateRATasks_detail">
                        {taskObject.taskStatus.charAt(0).toUpperCase() +
                          taskObject.taskStatus.slice(1)}
                      </span>
                    </Grid>
                    <Grid item md={4}>
                      <span className="reassignAndTerminateRATasks_name">
                        Task Reassignable{" "}
                      </span>
                      <br />
                      <span className="reassignAndTerminateRATasks_detail">
                        {isTaskReassignable ? "Yes" : "No"}
                      </span>
                    </Grid>
                    {isTaskReassignable ? (
                      <Grid
                        item
                        md={12}
                        className="reassignAndTerminateRATasks_reassignAction"
                        style={{ paddingRight: "10px" }}
                      >
                        <Button
                          variant="outlined"
                          sx={{
                            color: "#f5ad8a",
                            background: "#fff",
                            border: "none",
                            "&:hover": {
                              color: "#f48c57",
                              textDecoration: "underline",
                              border: "none",
                              background: "#fff"
                            }
                          }}
                          color="info"
                          onClick={() => onReassignTaskClick(taskObject)}
                        >
                          Click to reassign task
                        </Button>
                      </Grid>
                    ) : null}
                  </Grid>
                );
              })}
            </Grid>
          </div>
          {isTaskReassignable && searchedApproverActive ? (
            <div className="reassignAndTerminateRASearchApprover_wrapper">
              <span className="reassignAndTerminateRASearchApprover_searchText">
                {selectedTask.taskId} - Search Approver
              </span>
              <div className="reassignAndTerminateRASearchApprover_textFieldWithButton">
                <TextField
                  id="reassignAndTerminateRA-search-user-input"
                  size="small"
                  variant="outlined"
                  className="reassignAndTerminateRASearchApprover_textField"
                  focused={false}
                  placeholder="Type Name / Email To Search Supervisor"
                  value={searchedApprover}
                  ref={searchTextFieldRef}
                  onChange={(event) => {
                    setSearchApprover(event.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      searchUserHandler(selectedTask.requestedFor);
                    }
                  }}
                  InputProps={{
                    sx: { borderTopRightRadius: 0, borderBottomRightRadius: 0 },
                    endAdornment: (
                      <React.Fragment>
                        {(selectedUser && selectedUser.title) ||
                        searchedApprover.length ? (
                          <IconButton
                            edge="end"
                            size="medium"
                            className="reassignAndTerminateRASearchApprover_crossIcon"
                            onClick={clearSearchBoxHandler}
                          >
                            <ClearIcon />
                          </IconButton>
                        ) : null}
                      </React.Fragment>
                    )
                  }}
                />
                <Button
                  variant="contained"
                  className={
                    "reassignAndTerminateRASearchApprover_searchButton"
                  }
                  onClick={() => searchUserHandler(selectedTask.requestedFor)}
                >
                  <SearchOutlinedIcon size="medium" className="searchIcon" />
                </Button>
                <Popper open={open} anchorEl={anchorEl} sx={{ zIndex: 1500 }}>
                  <ClickAwayListener onClickAway={() => setOpen(false)}>
                    <Paper
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: "-14rem"
                      }}
                    >
                      {loading ? (
                        <div className="loader-container">
                          <CircularProgress />
                        </div>
                      ) : error ? (
                        <div className="error-container">
                          <p className="error-text">{error}</p>
                        </div>
                      ) : null}
                      {REASSIGN_APPROVER_DROPDOWN &&
                      REASSIGN_APPROVER_DROPDOWN.length ? (
                        <div
                          className={
                            REASSIGN_APPROVER_DROPDOWN.length > 5
                              ? "reassignAndTerminateRASearchApprover_userList"
                              : ""
                          }
                        >
                          {REASSIGN_APPROVER_DROPDOWN.map((user, index) => (
                            <MenuItem
                              key={index}
                              size="large"
                              onClick={() => {
                                selectedUserHandler(user);
                              }}
                              sx={{
                                width: `${
                                  REASSIGN_APPROVER_DROPDOWN.length > 5
                                    ? "30.5rem"
                                    : "31.5rem"
                                }`,
                                // whiteSpace: "normal",
                                fontSize: "0.95rem",
                                color: "#000000",
                                backgroundcolor: "red"
                              }}
                            >
                              {user.title && user.title.length > 55 ? (
                                <>
                                  {user.title.substr(0, 50)}
                                  <Tooltip
                                    title={user.title}
                                    arrow
                                    placement="left"
                                    componentsProps={{
                                      tooltip: {
                                        sx: {
                                          color: "#000",
                                          backgroundColor: "#fff",
                                          fontSize: "0.9rem",
                                          border: "2px solid #707070"
                                        }
                                      }
                                    }}
                                  >
                                    <strong>...</strong>
                                  </Tooltip>
                                </>
                              ) : (
                                user.title
                              )}
                            </MenuItem>
                          ))}
                        </div>
                      ) : null}
                    </Paper>
                  </ClickAwayListener>
                </Popper>
              </div>
              <div className="reassignAndTerminateRASearchApprover_approverActions">
                <Button
                  className="buttonDanger"
                  variant="outlined"
                  onClick={cancelSearchApproverHandler}
                  color="error"
                  sx={{
                    width: 100,
                    height: 35,
                    marginRight: "1rem",
                    fontSize: 12,
                    ":hover": {
                      bgcolor: "red",
                      color: "white"
                    }
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className="buttonSuccess"
                  variant="outlined"
                  onClick={confirmSearchApproverHandler}
                  disabled={!selectedUser}
                  color="success"
                  sx={{
                    width: 100,
                    height: 35,
                    fontSize: 12,
                    ":hover": {
                      bgcolor: "green",
                      color: "white"
                    }
                  }}
                >
                  Confirm
                </Button>
              </div>
            </div>
          ) : null}
        </>
      ) : (
        <div className="reassignAndTerminateRA_noTasks">
          <Typography color="red">No Tasks available to Reassign</Typography>
        </div>
      )}
    </div>
  );
};
