import { axiosMsGraph } from "../../../api";
import { summary_actionType } from "../../actionTypes/userAction-actionTypes/summary-actionType";

export function resetTrainingsReducer() {
  return (dispatch) => {
    dispatch({
      type: summary_actionType.RESET_TRAININGS_SUCCESS
    });
  };
}

export function setTrainingsToReducer(trainingsData) {
  return (dispatch) => {
    dispatch({
      type: summary_actionType.SET_TRAININGS_SUCCESS,
      data: trainingsData
    });
  };
}

export function setCommentsToReducer(commentsData) {
  return (dispatch) => {
    dispatch({
      type: summary_actionType.SET_COMMENTS_SUCCESS,
      data: commentsData
    });
  };
}

export function setExistingStateToReducer(existingData) {
  return (dispatch) => {
    dispatch({
      type: summary_actionType.SET_EXISTINGSTATE_SUCCESS,
      data: existingData
    });
  };
}

export function resetExistingStateToReducer() {
  return (dispatch) => {
    dispatch({
      type: summary_actionType.RESET_EXISTINGSTATE_SUCCESS
    });
  };
}

export function resetSummaryReducer() {
  return (dispatch) => {
    dispatch({
      type: summary_actionType.RESET_SUMMARY_SUCCESS
    });
  };
}

export function getTrainings() {
  const trainings = [
    {
      id: "2345602",
      value: "MKT_OPS_VVPM: Vault PromoMats Global Consumer Materials 1"
    },
    {
      id: "2345603",
      value: "MKT_OPS_VVPM: Vault PromoMats Global Consumer Materials 2"
    },
    {
      id: "2345604",
      value: "MKT_OPS_VVPM: Vault PromoMats Global Consumer Materials 3"
    }
  ];
  return (dispatch) => {
    dispatch({
      type: summary_actionType.GET_TRAININGS_SUCCESS,
      data: trainings
    });
  };
}

/* MS GRAPH API */
async function getAllUsersInGraph(searchedSponser, userMail) {
  const toSelect = [
    "id",
    "displayName",
    "preferredLanguage",
    "country",
    "usageLocation",
    "officeLocation",
    "givenname",
    "surname",
    "jobtitle",
    "mail",
    "onPremisesSamAccountName",
    "onPremisesDomainName",
    "onPremisesImmutableId",
    "onPremisesLastSyncDateTime",
    "onPremisesUserPrincipalName",
    "employeeId"
  ].join(",");
  const orderBy = ["displayName", "mail"].join(",");
  // const queryString = `$filter=employeeId ne null&$search="displayName: ${data}" OR "mail:${data}"&$select=${toSelect}&$orderby=${orderBy}&$count=true`;
  // const queryString = `$filter=not(mail eq '${loggedUserMail}' OR mail eq '${userMail}') AND employeeId ne null&$search="displayName:${searchedSponser}" OR "mail:${searchedSponser}"&$select=${toSelect}&$orderby=${orderBy}&$count=true`;
  const queryString = `$filter=accountEnabled eq true AND not(mail eq '${userMail}') AND employeeId ne null&$search="displayName:${searchedSponser}" OR "mail:${searchedSponser}"&$select=${toSelect}&$orderby=${orderBy}&$count=true`;
  const finalURL = `/users?${queryString}`;
  return axiosMsGraph.get(finalURL);
}

export function searchSponserInitialize() {
  return {
    type: summary_actionType.SEARCH_SPONSER
  };
}

export function searchSponserSuccess(data) {
  return {
    type: summary_actionType.SEARCH_SPONSER_SUCCESS,
    payload: data
  };
}

export function searchSponserError(error) {
  return {
    type: summary_actionType.SEARCH_SPONSER_ERROR,
    payload: error
  };
}

export function setSelectedSponsor(data) {
  return {
    type: summary_actionType.SET_SELECTED_SPONSOR,
    payload: data
  };
}

export function searchSponserResources(searchedSponser, userMail) {
  return async (dispatch) => {
    dispatch(searchSponserInitialize());
    try {
      const response = await getAllUsersInGraph(searchedSponser, userMail);
      dispatch(searchSponserSuccess(response?.data?.value));
    } catch (error) {
      dispatch(searchSponserError(error));
    }
  };
}
