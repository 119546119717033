import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector } from 'react-redux/es/hooks/useSelector';

function Loader() {
    const { loaderActive } = useSelector((state) => ({
      loaderActive: state.LoaderReducer.loaderActive
    }));
  return (
    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loaderActive}
      >
        <CircularProgress color="inherit" />
    </Backdrop>
  );
}

export default Loader;