import { userAccessResources_actionType } from "../../actionTypes/userAction-actionTypes/userAccessResources-actionType";
/*Format data object
data:
    [{
        user:{
            "userHubId": 1,
            "vamHubAbbreviation": "UK",
            "vamUserUserId": 14860441
        },
        countries:[{
            "country_abbreviation": "FI",
            "country_active_flg": true,
            "country_id": "00C000000000E11",
            "country_name": "Finland",
            "vamHubAbbreviation": "UK",
            "user_hub_countries_jn":{
                "vamusersVamhubsJunctionUse": 1,
                "vvpmCountryCountryId": "00C000000000E11"
            }
        }],
        roles:[{
            "vam_role_id": 2,
            "name": "Lilly Regulatory",
            "description": "This is Lilly Regulatory role in UK Hub",
            "isActive": true,
            "roleAudience": "Lilly",
            "isElevatedRole": false,
            "isRequireProducts": false,
            "license": "full user",
            "securityProfile": "mfa_sso",
            "vamHubAbbreviation": "UK",
            "user_hub_roles_jn": {
                  "vamRoleVamRoleId": 27,
                  "vamusersVamhubsJunctionUserHubId": 8
             }
        }],
        "products":[{
            "product_id": "00P000000000107",
            "product_name": "Alimta",
            "product_abbreviation": "PM",
            "product_active_flg": true,
            "vamProductGroupId": "OBU"
            "user_hub_products_jn":{
                "vamusersVamhubsJunctionUserH": 1,
                "vvpmProductProductId": "00P000000000107"
            }
        }]
    }]
******************/

const initialState = {
  loading: false,
  data: [],
  error: undefined
};

const UserAccessResourcesReducer = (state = initialState, action) => {
  switch (action.type) {
    case userAccessResources_actionType.USER_ACCESS_RESOURCES:
      return {
        ...state,
        loading: true,
        error: undefined
      };
    case userAccessResources_actionType.USER_ACCESS_RESOURCES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload
      };
    case userAccessResources_actionType.RESET_USER_ACCESS_RESOURCES:
      return {
        ...initialState
      };
    case userAccessResources_actionType.USER_ACCESS_RESOURCES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default UserAccessResourcesReducer;
