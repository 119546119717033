import React from 'react';

import Button from '@mui/material/Button';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import "./styles/ModalNotice.css";

const  ModalNotice = ({ error, isSubmitSuceess, message, twoButton, buttonOneAction, buttonTwoAction, reactivate, handleReactivate })  =>{
  const handelSuccess = () => {
    buttonOneAction();
  };
  const handelSelectNo = () => {
    buttonTwoAction();
  };
  const handelSelectYes = () => {
    if(reactivate){
      handleReactivate();
    }else{
      buttonOneAction();
    }
  };
  return (
    <div className='modalContainer'>
        <div className={error || !isSubmitSuceess ? 'topBorderError' : 'topBorderSuccess'}></div>
        <div className='icon'>
          {
            error ? 
            <div className='iconError'><HelpOutlineIcon sx={{width: '2.54em', height: '2em', color: 'red' }}/></div> :
            isSubmitSuceess ?
            <div className='iconSuccess'>
              <CheckCircleOutlineIcon sx={{width: '2.54em', height: '2em', color: 'green' }}/>
            </div>:
            <div className='iconFail'>
              <CancelOutlinedIcon sx={{width: '2.54em', height: '2em', color: 'red' }}/>
            </div>
          }
        </div>
        <div className='message'>
          {
            message.map((messageObject, messageKey) => {
              return(
                <span key={messageKey}>{messageObject}</span>
              );
            })
          }
        </div>
        <div className='actionButton'>
          {
            twoButton ?
            <div className='actionButtonError'>
              <Button className="buttonDanger" 
              variant="outlined" 
              onClick={handelSelectNo}
              color="error"
              sx={{
                ':hover': {
                  bgcolor: 'red',
                  color: 'white'
                }
              }}
            >
              NO
            </Button>
            <Button className="buttonSuccess" 
              variant="outlined" 
              onClick={handelSelectYes}
              color="success"
              sx={{
                ':hover': {
                  bgcolor: 'green',
                  color: 'white'
                }
              }}
            >
              YES
            </Button>
            </div>
            :
            <Button variant="contained" color={isSubmitSuceess ? "success" : "error"} onClick={handelSuccess}>
              OK
            </Button>
          }
        </div>
    </div>
  );
};

export default ModalNotice;