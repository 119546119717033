import { React, useEffect, useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Modal from "@mui/material/Modal";
// import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import { setDashboardFilters } from "../../../../../../redux/actions/adminAccount-actions/adminDashboard-action";
import { useDispatch, useSelector } from "react-redux";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  // height: "75%",
  // minHeight: "80%",
  maxHeight: "95%",
  border: 0,
  borderRadius: "10px",
  backgroundColor: "white"
};

function FilterModal({ isModalOpen, toggleOpenFilterBox }) {
  const dispatch = useDispatch();
  const { filterQuery } = useSelector((state) => ({
    filterQuery: state.AdminDashboardReducer.filterQuery
  }));

  const [data, setData] = useState(filterQuery);

  useEffect(() => {
    const localData = JSON.stringify(filterQuery);
    setData(JSON.parse(localData));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  // to check if filter is chenged or not, if not chenged then no need to send request to backend.
  const filterChanged = () => {
    let changeFlag = false;
    filterQuery.forEach((filters) => {
      const curDataFilter = data.filter(
        (fil) => fil.name === filters.name
      )?.[0];
      if (!changeFlag) {
        filters.value.forEach((val) => {
          const curCheckedFil = curDataFilter.value.filter(
            (v) => v.type === val.type
          )?.[0];
          console.log(val.checked !== curCheckedFil?.checked);
          if (val.checked !== curCheckedFil?.checked) {
            changeFlag = true;
            return;
          }
        });
      }
    });
    return changeFlag;
  };
  const handleClose = () => {
    toggleOpenFilterBox();
  };
  const handelSave = () => {
    if (filterChanged()) {
      dispatch(setDashboardFilters(data));
    }
    toggleOpenFilterBox();
  };

  const handleClearAllFilter = () => {
    setData((data) => {
      return data.map((ele) => {
        const newVal = ele.value.map((val) => {
          return { ...val, checked: false };
        });
        return {
          ...ele,
          value: newVal
        };
      });
    });
  };

  const handleClick = (dataIndex, selectedChipIndex) => {
    let tempData = [...data];
    tempData[dataIndex].value[selectedChipIndex].checked =
      !tempData[dataIndex].value[selectedChipIndex].checked;
    setData(tempData);
  };
  return (
    <Modal
      open={isModalOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="fltermodel-dash" style={ModalStyle}>
        <div className="filterContent">
          <div
            className="filterContentHeader"
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingRight: "15px"
            }}
          >
            <div>Search Filter</div>
            <HighlightOffIcon
              sx={{
                mr: "5px",
                "&:hover": {
                  color: "gray",
                  transition: "color 0.2s"
                }
              }}
              onClick={handleClose}
            />
          </div>

          {data.length > 0 ? (
            <div className="filterContentContent">
              <div className="filterContentContentLineOne">
                <div className="filterContentContentHeader">Affiliate Type</div>
                <div
                  className="filterContentContentSection"
                  style={{ maxHeight: "110px", overflowY: "scroll" }}
                >
                  {data[0].value.map((Object, key) => {
                    return (
                      <Chip
                        label={Object.name}
                        key={key}
                        onClick={() => handleClick(0, key)}
                        color={
                          data[0].value[key].checked ? "primary" : "default"
                        }
                        variant={data[0].value[key].checked ? "" : "outlined"}
                      />
                    );
                  })}
                </div>
              </div>
              <div className="filterContentContentLineTwo">
                <div className="filterContentContentHeader">User Type</div>
                <div className="filterContentContentSection">
                  {data[1].value.map((Object, key) => {
                    return (
                      <Chip
                        label={Object.name}
                        key={key}
                        onClick={() => handleClick(1, key)}
                        color={
                          data[1].value[key].checked ? "primary" : "default"
                        }
                        variant={data[1].value[key].checked ? "" : "outlined"}
                      />
                    );
                  })}
                </div>
              </div>
              <div className="filterContentContentLineThree">
                <div className="filterContentContentHeader">Request Type</div>
                <div className="filterContentContentSection">
                  {data[2].value.map((Object, key) => {
                    return (
                      <Chip
                        label={Object.name}
                        key={key}
                        onClick={() => handleClick(2, key)}
                        color={
                          data[2].value[key].checked ? "primary" : "default"
                        }
                        variant={data[2].value[key].checked ? "" : "outlined"}
                      />
                    );
                  })}
                </div>
              </div>
              <div className="filterContentContentLineFour">
                <div className="filterContentContentHeader">Status</div>
                <div className="filterContentContentSection">
                  {data[3].value.map((Object, key) => {
                    return (
                      <Chip
                        label={Object.name}
                        key={key}
                        onClick={() => handleClick(3, key)}
                        color={
                          data[3].value[key].checked ? "primary" : "default"
                        }
                        variant={data[3].value[key].checked ? "" : "outlined"}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          ) : null}
        </div>
        {/* <CancelOutlinedIcon
          className="accountSummary_crossModalIcon"
          onClick={() => handleClose()}
        /> */}

        <div className="summaryModalButtons" style={{ paddingBottom: "10px" }}>
          <Button
            className="buttonDanger"
            variant="outlined"
            onClick={handleClose}
            color="error"
            sx={{
              width: 100,
              height: 35,
              fontSize: 12,
              ":hover": {
                bgcolor: "red",
                color: "white"
              }
            }}
          >
            Cancel
          </Button>
          <Button
            className="buttonDanger"
            variant="outlined"
            onClick={handleClearAllFilter}
            color="error"
            sx={{
              width: 104,
              height: 35,
              fontSize: 12,
              ":hover": {
                bgcolor: "red",
                color: "white"
              }
            }}
          >
            Clear All
          </Button>
          <Button
            className="buttonSuccess"
            variant="outlined"
            onClick={handelSave}
            color="success"
            sx={{
              width: 100,
              height: 35,
              fontSize: 12,
              ":hover": {
                bgcolor: "green",
                color: "white"
              }
            }}
          >
            Apply
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default FilterModal;
