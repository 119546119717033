import { axiosApigateway } from "../../../api";
import { userAccessResources_actionType } from "../../actionTypes/userAction-actionTypes/userAccessResources-actionType";

export function userAccessResourcesInitialize() {
  return {
    type: userAccessResources_actionType.USER_ACCESS_RESOURCES
  };
}

export function userAccessResourcesSuccess(data) {
  return {
    type: userAccessResources_actionType.USER_ACCESS_RESOURCES_SUCCESS,
    payload: data
  };
}

export function resetUserAccessResources() {
  return (dispatch) => {
    dispatch({
      type: userAccessResources_actionType.RESET_USER_ACCESS_RESOURCES
    });
  };
}

export function userAccessResourcesError(error) {
  return {
    type: userAccessResources_actionType.USER_ACCESS_RESOURCES_ERROR,
    payload: error
  };
}

const setTrainings =(hubsData, abbreviation) => {
  let trainings = [];
  const vam_roles = hubsData.find(o => o.abbreviation === abbreviation).vam_roles;//[0].vam_trainings
  vam_roles.forEach(_role => {
    const _trainings = _role.vam_trainings;
    _trainings.forEach(_training => {
      trainings.push(_training);
    });
  });
  return trainings;
};

export function userAccessResources(data) {
  return async (dispatch) => {
    dispatch(userAccessResourcesInitialize());

    try {
      const { userId, userType } = data;
      const responseHub = await axiosApigateway.get(`/hubs`);
      const hubData = responseHub.data.data;
      const response = await axiosApigateway.get(`/useraccess/${userId}`);
      const responseData = response.data;
      const userAccessResources = responseData.data
        .filter((item) => item.vamHubAbbreviation !== null)
        .map((item) => ({
          user: {
            userHubId: item.userHubId,
            vamHubAbbreviation: item.vamHubAbbreviation,
            vamUserUserId: item.vamUserUserId
          },
          countries: item.vvpm_countries.map((country) => {
            return {
              country_id: country.country_id,
              country_name: country.country_name,
              country_active_flg: country.country_active_flg,
              vamHubAbbreviation: country.vamHubAbbreviation,
              country_abbreviation: country.country_abbreviation
            };
          }),
          roles: item.vam_roles
            .filter(
              (role) =>
                role.roleAudience !== null &&
                (role.roleAudience === userType || role.roleAudience === "All")
            )
            .map((role) => {
              return {
                vam_role_id: role.vam_role_id,
                name: role.name,
                description: role.description,
                license: role.license,
                isRequireProducts: role.isRequireProducts,
                vamHubAbbreviation: role.vamHubAbbreviation,
                isActive: role.isActive,
                roleAudience: role.roleAudience,
                isElevatedRole: role.isElevatedRole,
                securityProfile: role.securityProfile,
                user_hub_roles_jn: {
                  vamRoleVamRoleId: role.user_hub_roles_jn.vamRoleVamRoleId,
                  vamusersVamhubsJunctionUserHubId:
                    role.user_hub_roles_jn.vamusersVamhubsJunctionUserHubId
                },
                trainings : setTrainings(hubData, role.vamHubAbbreviation)
              };
            }),
          products: item.vvpm_products
            .filter(prod => prod?.applicableAffiliates?.includes(item?.vamHubAbbreviation))
            .map((prod) => ({
            product_id: prod.product_id,
            product_name: prod.product_name,
            vamProductGroupId: prod.vamProductGroupId,
            product_active_flg: prod.product_active_flg,
            product_abbreviation: prod.product_abbreviation,
            user_hub_products_jn: {
              vamusersVamhubsJunctionUserH:
                prod.user_hub_products_jn.vamusersVamhubsJunctionUserH,
              vvpmProductProductId:
                prod.user_hub_products_jn.vvpmProductProductId
            },
            applicableAffiliates : prod?.applicableAffiliates || []
          }))
        }));
      dispatch(userAccessResourcesSuccess(userAccessResources));
    } catch (error) {
      dispatch(userAccessResourcesError(error));
    }
  };
}


