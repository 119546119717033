import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import "../styles/ViewAdminDashboard.css";

import VAMRequestStatus from './VAMRequestStatus';
import VAMUserDetail from './VAMUserDetail';
import VAMSelectedRoles from './VAMSelectedRoles';
import VAMSelectedProducts from './VAMSelectedProducts';
import AdminMainArticleLoader from '../../AdminMainArticleLoader/AdminMainArticleLoader';
import { Button } from '@mui/material';
import { setAdminDashboardAllRequests } from '../../../../../../redux/actions/adminAccount-actions/viewAdminDashboard-action';

function ViewAdminDashboard({
  viewAdminRequestId,
  hadelToggleIsViewAdminDashboardActive
}) {
  const dispatch = useDispatch();
  const { isScreenLoading, responseStore } = useSelector((state) => ({
    isScreenLoading: state.LoaderReducer.viewAdminDashboardLoader,
    responseStore: state.AdminDashboardReducer.responseStore
  }));

  useEffect(() => {
    dispatch(setAdminDashboardAllRequests(responseStore, viewAdminRequestId));
  }, [dispatch, viewAdminRequestId, responseStore]);

  if(isScreenLoading)
    return <AdminMainArticleLoader />;
  return (
    <div className='adminViewDashboardContainer'>
      <VAMRequestStatus viewAdminRequestId={viewAdminRequestId}/>
      <VAMUserDetail />
      <div className='adminDashboardTwoCard'>
        <VAMSelectedRoles />
        <VAMSelectedProducts />
      </div>
      <div className='viewAdminDashboardbutton'>
        <Button 
          variant='outlined'
          color="success"
          sx={{
            width: 115,
            height: 40,
            ":hover": {
              bgcolor: "green",
              color: "white"
            }
          }}
          onClick={hadelToggleIsViewAdminDashboardActive} 
        >Back</Button>
      </div>
    </div>
  );
}

export default ViewAdminDashboard;