import { loggedUser_actionType } from '../actionTypes/loggedUser-actionType';

const initialState = {
  loggedUser : null
};

const LoggedUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case loggedUser_actionType.GET_LOGGED_USER_SUCCESS:
      return {
        ...state,
        loggedUser: action.data
      };
    case loggedUser_actionType.GET_LOGGED_USER_FAILURE:
      return {
        ...state,
        error: action.data
      };
    default:
      return state;
  }
};

export default LoggedUserReducer;