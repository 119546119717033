import { Grid, Card, FormControlLabel, Checkbox } from "@mui/material";
import React from "react";
import { approvar_actionTypes } from "../utils/approval_actionType";

const Trainings = ({
  editRequest,
  trainings,
  actionType,
  onTrainingConfirmation,
  isTrainingConfirmed
}) => {
  const trainingsRows = trainings?.map(function (columnValObj) {
    return (
      <tr key={columnValObj?.id}>
        <td width="30%" className="accountSummary_tableHeading">
          {columnValObj?.id}
        </td>
        <td width="70%">{columnValObj?.trainingName}</td>
      </tr>
    );
  });

  return (
    <Grid
      item
      sm={12}
      xs={12}
      md={
        editRequest && actionType === approvar_actionTypes.REASSIGN_SUPERVISOR
          ? 6
          : 12
      }
      className="accountSummary_mainItemSection"
      style={{ height: "95%" }}
    >
      <Card
        className="accountSummary_userDetailsParent"
        style={{ height: "100%" }}
      >
        <div
          className="accountSummary_header"
          style={
            actionType === approvar_actionTypes.CONFIRM_TRAINING_COMPLETION
              ? { backgroundColor: "#ffeee5" }
              : {}
          }
        >
          <Grid container spacing={2}>
            <Grid item md={11} className="accountSummary_mainHeading">
              Mandatory VVPM Trainings To Be Completed.
            </Grid>
          </Grid>
        </div>
        <div
          className="accountSummary_section"
          style={
            editRequest
              ? { overflowY: "auto", maxHeight: "160px", minHeight: "160px" }
              : { overflowY: "auto", maxHeight: "196px", minHeight: "196px" }
          }
        >
          <table
            width="100%"
            className="accountSummary_trainingsTable"
            style={{ height: "100%", overflowY: "auto" }}
          >
            <thead>
              <tr align="left">
                <th width="30%">Curriculum ID</th>
                <th width="70%">Curriculum Name</th>
              </tr>
            </thead>
            <tbody>{trainingsRows}</tbody>
          </table>
        </div>
        {(actionType === approvar_actionTypes.CONFIRM_TRAINING_COMPLETION && !editRequest) && (
          <div className="approvar_trainingsCheckbox">
            <FormControlLabel
              // className="accountSummary_mainHeading"
              sx={{ marginLeft: "5px", position: "absolute" }}
              control={
                <Checkbox
                  checked={isTrainingConfirmed}
                  onChange={onTrainingConfirmation}
                  name="check"
                  sx={{
                    "&.Mui-checked": {
                      color: "#00AE43"
                    }
                  }}
                />
              }
              label={
                <span
                  style={{
                    fontSize: "12px",
                    fontStyle: "italic",
                    justifyContent: "center"
                  }}
                >
                  The user has completed all the mandatory trainings.
                </span>
              }
            />
          </div>
        )}
      </Card>
    </Grid>
  );
};

export default Trainings;
