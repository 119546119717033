import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getProduct } from "../../../redux/actions/userAccount-actions/product-action";
import ProductCard from "./ProductCard";

const Product = () => {
  const dispatch = useDispatch();

  const { reducerProduct } = useSelector((state) => ({
    reducerProduct: state.ApprovarReducer?.allProducts || []
  }));

  useEffect(() => {
    dispatch(getProduct());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <ProductCard product={reducerProduct} />;
};

export default Product;
