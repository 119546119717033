export const summary_actionType = {
    SET_TRAININGS_SUCCESS : 'SET_TRAININGS_SUCCESS',
    GET_TRAININGS_SUCCESS : 'GET_TRAININGS_SUCCESS',
    RESET_TRAININGS_SUCCESS : 'RESET_TRAININGS_SUCCESS',
    SET_COMMENTS_SUCCESS : 'SET_COMMENTS_SUCCESS',
    SET_EXISTINGSTATE_SUCCESS : 'SET_EXISTINGSTATE_SUCCESS',
    RESET_EXISTINGSTATE_SUCCESS: 'RESET_EXISTINGSTATE_SUCCESS',
    RESET_SUMMARY_SUCCESS: 'RESET_SUMMARY_SUCCESS',
    SEARCH_SPONSER: 'SEARCH_SPONSER',
    SEARCH_SPONSER_SUCCESS: 'SEARCH_SPONSER_SUCCESS',
    SEARCH_SPONSER_ERROR: 'SEARCH_SPONSER_ERROR',
    SET_SELECTED_SPONSOR: 'SET_SELECTED_SPONSOR'
};