export function currentEnvironment(){
    let env = "";
    const currentHostName = window.location.hostname;
    if(
      currentHostName === "dev.vam-vvpm.am.lilly.com"
      || currentHostName === "dev.vam-vvpm.lilly.com"
      || currentHostName === "localhost"
      )
      env = "dev";
    else if(
      currentHostName === "qa.vam-vvpm.am.lilly.com"
      || currentHostName === "qa.vam-vvpm.lilly.com"
      )
      env = "qa";
    else if(
      currentHostName === "vam-vvpm.am.lilly.com"
      || currentHostName === "vam-vvpm.lilly.com"
    )
      env = "prod";
    else
      env = "";
    return env;
}