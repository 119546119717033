import React from 'react';
import "./styles/AdminMain.css";

import AdminMainArticle from './AdminMainArticle/AdminMainArticle';
import AdminMainSection from './AdminMainSection/AdminMainSection';

function AdminMain({
  setPageNavigationList,
  activeSection,
  setActiveSection
}) {
  return (
    <div className='adminMain'>
      <AdminMainSection
          setPageNavigationList={setPageNavigationList}
          setActiveSection={setActiveSection}
          activeSection={activeSection}
      />
      <AdminMainArticle 
        activeSection={activeSection}
        setPageNavigationList={setPageNavigationList}
        setActiveSection={setActiveSection}
      />
    </div>
  );
}

export default AdminMain;