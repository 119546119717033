import { useSelector } from "react-redux";
import { approvar_actionTypes } from "../utils/approval_actionType";
import ButtonError from "./ButtonError";

const ApprovarHeader = ({ handleEditRequest, actionType, editRequest, id, isEditEnabled, isEditHidden }) => {
  const { isApproveEnabled, isReassignEnabled, requestedUserName } = useSelector(state => ({
    isApproveEnabled: state.ApprovarReducer?.actionButtonState?.isApproveEnabled || false,
    isReassignEnabled: state.ApprovarReducer?.actionButtonState?.isReassignEnabled || false,
    // taskStatus: state.ApprovarReducer?.data?.taskStatus,
    requestedUserName: state.ApprovarReducer?.data?.userDetails?.display_name
  }));
  let pageSubTitle = '';
  if (actionType === approvar_actionTypes.APPROVE_PRODUCTS_AND_ROLES) {
    pageSubTitle = 'Elevated Role approval Task';
  }
  else if (actionType === approvar_actionTypes.CONFIRM_TRAINING_COMPLETION) {
    pageSubTitle = 'Trainings Verification Task';
  }
  else if (actionType === approvar_actionTypes.APPROVE_EXCEPTION_HANDLER) {
    pageSubTitle = 'Training Exception Task';
  }
  else if (actionType === approvar_actionTypes.REASSIGN_SUPERVISOR) {
    if (isApproveEnabled && isReassignEnabled) {
      pageSubTitle = 'Reassign Supervisor or Approve Roles & Products';
    }
    else if (isReassignEnabled) {
      pageSubTitle = 'Reassign Supervisor Task';
    } else {
      pageSubTitle = 'Approve Roles & Products';
    }
  }
  else if (actionType === approvar_actionTypes.ALL_IN_ONE) {
    if (isApproveEnabled && isReassignEnabled) {
      pageSubTitle = 'Helpdesk Task';
    }
    else {
      pageSubTitle = 'Roles, Products & Trainings Approval Task';
    }
  }
  else {
    pageSubTitle = 'Approve Roles & Products';
  }
  return (
    <div className="pageTitleContainer">
      <div className="pageTitleText">
        <span className="pageTitleHeader">VVPM Account Request #{id} {actionType !== approvar_actionTypes.APPROVE_EXCEPTION_HANDLER ? '' : ` for ${requestedUserName}`}</span>
        <span className="pageTitleNavigator" style={{ fontSize: '14px' }}>{pageSubTitle}</span>
      </div>
      {(!editRequest && actionType !== approvar_actionTypes.CONFIRM_TRAINING_COMPLETION && actionType !== approvar_actionTypes.APPROVE_EXCEPTION_HANDLER) && (!isEditHidden) && (
        <div className="pageTitleIcon">
          <div className="pageTitleIconBox">
            <ButtonError label="Edit" onClickHandler={handleEditRequest} disabled={!isEditEnabled} />
          </div>
        </div>
      )}
    </div>
  );
};

export default ApprovarHeader;
