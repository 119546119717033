import { Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import classNames from "classnames";
import { useEffect, useState } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SelectDropdownInput from "../../components/common/SelectDropdownInput";
import { useDispatch } from "react-redux";
import { setAffiliateCountries } from "../../redux/actions/userAccount-actions/affiliateCountries-action";
import { setSelectAffiliate } from "../../redux/actions/userAccount-actions/selectAffiliate-action";
import {
  setSelectedWorkFlowRoles,
  setUserAccessRolesModified,
  setworkFlowRoles
} from "../../redux/actions/userAccount-actions/workFlowRoles-action";
import {
  setProductToReducer,
  setUserAccessProductModified
} from "../../redux/actions/userAccount-actions/product-action";

export const SelectAffiliateSection = (props) => {
  const dispatch = useDispatch();
  const {
    affiliateCountries,
    selectAffiliate,
    userDetails,
    // isUserOnboarded,
    allAffiliatesResources,
    userAccessResources,
    allAffiliates,
    completeState
  } = props;

  const [countriesSelected, setCountriesSelected] = useState([]);
  const [selectedAffiliate, setSelectedAffiliate] = useState("");
  const [selectedAffiliateCountries, setSelectedAffiliateCountries] = useState(
    []
  );
  const [affAbbreviation, setAffAbbreviation] = useState("");
  const userDeactivated = userDetails.deactivated;
  const selectedRoles = completeState.WorkFlowRolesReducer.selectedRoles;
  const userAccessRolesModified =
    completeState.WorkFlowRolesReducer.userAccessRolesModified;
  const isUserOnboardedLocal =
    completeState.UserDetailReducer.data.isOnboardedUser;

  let userAccessRoles = [];
  let userAccessCountries = [];
  let ALL_AFFILIATES_DROPDOWN = [];

  function getAffiliateCountries(selectedAffiliate) {
    const countries =
      allAffiliatesResources.find(
        (affiliate) => affiliate.affiliateType.name === selectedAffiliate
      )?.countries ?? [];
    return countries;
  }

  // function getAffiliateCountriesFromAbrr(selectedAffiliate) {
  //   const countries =
  //     allAffiliatesResources.find(
  //       (affiliate) =>
  //         affiliate.affiliateType.abbreviation === selectedAffiliate
  //     )?.countries ?? [];
  //   return countries;
  // }

  function getAffiliateWorkFlowRoles(selectedAffiliate) {
    const roles =
      allAffiliatesResources.find(
        (affiliate) => affiliate.affiliateType.name === selectedAffiliate
      )?.roles ?? [];
    return roles;
  }

  function getAffiliateNameFromAbbr(affiliateAbbr) {
    return (
      allAffiliatesResources.find(
        (affiliate) => affiliate.affiliateType.abbreviation === affiliateAbbr
      )?.affiliateType?.name ?? ""
    );
  }

  function getAffiliateAbbrFromName(selectedAffiliate) {
    return (
      allAffiliatesResources.find(
        (affiliate) => affiliate.affiliateType.name === selectedAffiliate
      )?.affiliateType?.abbreviation ?? ""
    );
  }

  if (
    userAccessResources &&
    Array.isArray(userAccessResources) &&
    userAccessResources.length &&
    allAffiliatesResources &&
    Array.isArray(allAffiliatesResources) &&
    allAffiliatesResources.length
  ) {
    userAccessResources.map((resource) => {
      const affiliate = allAffiliatesResources.find(
        (affiliate) =>
          affiliate?.affiliateType?.abbreviation ===
          resource?.user?.vamHubAbbreviation
      );
      const affiliateType = affiliate?.affiliateType?.name;

      affiliate.roles
        .filter((role) =>
          resource.roles
            .map((roleObj) => roleObj?.vam_role_id)
            .includes(role?.vam_role_id)
        )
        .map((role) => {
          const roleMapToWorkFlowType = {
            workFlowType: affiliateType,
            role: role
          };
          userAccessRoles.push(roleMapToWorkFlowType);
        });
      // resource.roles.map((role) => {
      //   const roleMapToWorkFlowType = {
      //     workFlowType: affiliateType,
      //     role: role
      //   };
      //   userAccessRoles.push(roleMapToWorkFlowType);
      // });

      resource.countries.map((country) => {
        const countryMapToAffiliateType = {
          affiliateType: affiliateType,
          country: country
        };
        userAccessCountries.push(countryMapToAffiliateType);
      });
    });
  }

  const userAffiliateAccess = userAccessResources.map(
    (aff) => aff.user.vamHubAbbreviation
  );

  useEffect(() => {
    //Default set up - nothing
    let GLOBAL = "Global";
    // let GLOBAL_ABBR = "GL";
    let globalCountries = getAffiliateCountries(GLOBAL);
    let tempGlobalArr = [];
    globalCountries.map((globalCountryObj) => {
      const obj = {
        affiliateType: GLOBAL,
        country: globalCountryObj
      };
      tempGlobalArr.push(obj);
    });

    if (
      userAccessResources &&
      Array.isArray(userAccessResources) &&
      userAccessResources.length
    ) {
      //edit
      setSelectedAffiliate(
        selectAffiliate.selectedAffiliateType.length
          ? selectAffiliate.selectedAffiliateType
          : ""
      );
      setAffAbbreviation(
        selectAffiliate.selectedAffiliateTypeAbbrebiation.length
          ? selectAffiliate.selectedAffiliateTypeAbbrebiation
          : []
      );
      setCountriesSelected(
        selectAffiliate.selectedCountries.length
          ? selectAffiliate.selectedCountries
          : checkDuplicateObject(userAccessCountries, tempGlobalArr)
      );
      if (!userAccessRolesModified) {
        dispatch(setSelectedWorkFlowRoles(userAccessRoles));
      }
    } else {
      //first load and create
      setSelectedAffiliate(
        selectAffiliate.selectedAffiliateType.length
          ? selectAffiliate.selectedAffiliateType
          : ""
      );
      setAffAbbreviation(
        selectAffiliate.selectedAffiliateTypeAbbrebiation.length
          ? selectAffiliate.selectedAffiliateTypeAbbrebiation
          : []
      );
      setCountriesSelected(
        selectAffiliate.selectedCountries.length
          ? selectAffiliate.selectedCountries
          : []
      );
    }
    //common case
    dispatch(
      setAffiliateCountries({
        affiliateType: selectAffiliate.selectedAffiliateType.length
          ? selectAffiliate.selectedAffiliateType
          : userDeactivated
          ? getAffiliateNameFromAbbr(userAffiliateAccess[0])
          : GLOBAL,
        countries: getAffiliateCountries(
          selectAffiliate.selectedAffiliateType.length
            ? selectAffiliate.selectedAffiliateType
            : userDeactivated
            ? getAffiliateNameFromAbbr(userAffiliateAccess[0])
            : GLOBAL
        )
      })
    );
    dispatch(
      setworkFlowRoles({
        workFlowType: selectAffiliate.selectedAffiliateType.length
          ? selectAffiliate.selectedAffiliateType
          : userDeactivated
          ? getAffiliateNameFromAbbr(userAffiliateAccess[0])
          : GLOBAL,
        roles: getAffiliateWorkFlowRoles(
          selectAffiliate.selectedAffiliateType.length
            ? selectAffiliate.selectedAffiliateType
            : userDeactivated
            ? getAffiliateNameFromAbbr(userAffiliateAccess[0])
            : GLOBAL
        )
      })
    );
    setSelectedAffiliateCountries(
      affiliateCountries.countries.length ? affiliateCountries.countries : []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //affiliate dropdown
  if (allAffiliates && Array.isArray(allAffiliates) && allAffiliates.length) {
    const allAffiliateToProcess = !userDeactivated
      ? allAffiliates
      : allAffiliates.filter((affiliate) =>
          userAffiliateAccess.includes(affiliate.abbreviation)
        );

    allAffiliateToProcess.map((affiliate) => {
      const dropDown = {
        id: affiliate.abbreviation,
        title: affiliate.name,
        value: affiliate.name
      };
      ALL_AFFILIATES_DROPDOWN.push(dropDown);
    });
  }

  const modifiedAffiliate = JSON.stringify({
    selectedAffiliateType: selectedAffiliate,
    selectedAffiliateTypeAbbrebiation: affAbbreviation,
    selectedCountries: countriesSelected
  });

  useEffect(() => {
    const modifiedAffiliateParsed = JSON.parse(modifiedAffiliate);
    const payload = { ...modifiedAffiliateParsed };
    dispatch(setSelectAffiliate(payload));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modifiedAffiliate]);

  const modifiedWorkFlow = JSON.stringify({
    roles: selectedRoles
  });

  useEffect(() => {
    if (userAccessRolesModified) {
      const modifiedWorkFlowParsed = JSON.parse(modifiedWorkFlow);
      const payload = modifiedWorkFlowParsed.roles;
      dispatch(setSelectedWorkFlowRoles([...payload]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modifiedWorkFlow]);

  const countryChangeHandler = (event, country) => {
    const { checked } = event.target;
    const newArr = checked
      ? [...countriesSelected, { affiliateType: selectedAffiliate, country }]
      : countriesSelected.filter(
          (ctry) =>
            ctry.affiliateType === selectedAffiliate &&
            ctry.country.country_id !== country.country_id
        );
    setCountriesSelected(newArr);
  };

  const checkDuplicateObject = (accessCountries, identicalCountry) => {
    let finalResult = accessCountries.filter((obj) => {
      let isIdentical = false;
      identicalCountry.forEach((element) => {
        if (
          obj.country.country_abbreviation ===
          element.country.country_abbreviation
        )
          isIdentical = true;
      });
      return !isIdentical;
    });
    return [...finalResult, ...identicalCountry];
  };

  const selectedAffiliateHandler = (event) => {
    //common case
    const selectedAffiliate = event.target.value;
    dispatch(
      setAffiliateCountries({
        affiliateType: selectedAffiliate,
        countries: getAffiliateCountries(selectedAffiliate)
      })
    );
    dispatch(
      setworkFlowRoles({
        workFlowType: selectedAffiliate,
        roles: getAffiliateWorkFlowRoles(selectedAffiliate)
      })
    );
    dispatch(setSelectedWorkFlowRoles([]));
    dispatch(setUserAccessRolesModified(false));
    dispatch(setUserAccessProductModified(false));
    dispatch(setProductToReducer([]));

    setSelectedAffiliate(selectedAffiliate);
    setAffAbbreviation(getAffiliateAbbrFromName(selectedAffiliate));
    setSelectedAffiliateCountries(getAffiliateCountries(selectedAffiliate));

    //special case - when affiliate name and country name is same then select it automatically
    let selectedAffCountries = getAffiliateCountries(selectedAffiliate);
    let tempCountryArr = [];
    selectedAffCountries.map((affCountryObj) => {
      if (
        affCountryObj.country_name.toLowerCase() ===
        selectedAffiliate.toLowerCase()
      ) {
        tempCountryArr.push({
          affiliateType: selectedAffiliate,
          country: affCountryObj
        });
      }
    });

    if (
      userAccessResources &&
      Array.isArray(userAccessResources) &&
      userAccessResources.length
    ) {
      //modify
      setCountriesSelected(
        checkDuplicateObject(userAccessCountries, tempCountryArr)
      );
      dispatch(setSelectedWorkFlowRoles(userAccessRoles));
    } else {
      //create
      setCountriesSelected(tempCountryArr);
    }
  };

  const renderCountries = () => {
    return selectedAffiliateCountries &&
      Array.isArray(selectedAffiliateCountries) &&
      selectedAffiliateCountries?.filter(
        (ctry) =>
          ctry.country_name.toLowerCase() !== selectedAffiliate.toLowerCase()
      ).length ? (
      <div className="affiliate_selectCountry">
        <h4>
          Select Country -{" "}
          {
            countriesSelected?.filter(
              (ctry) => ctry.affiliateType === selectedAffiliate
            )?.length
          }{" "}
          Selected
        </h4>
        <Grid container md={12} className="country_display">
          {selectedAffiliateCountries
            ?.filter(
              (ctry) =>
                ctry.country_name.toLowerCase() !==
                selectedAffiliate.toLowerCase()
            )
            ?.map((country, idx) => (
              <Grid
                item
                xs={12}
                sm={4}
                md={3}
                key={idx}
                className="country_displayIndividual"
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        "&.Mui-checked": {
                          color: !userDeactivated ? "#00AE43" : "#80808085"
                        }
                      }}
                      checked={
                        countriesSelected.find(
                          (ctry) =>
                            ctry.affiliateType === selectedAffiliate &&
                            ctry.country.country_id === country.country_id
                        )
                          ? true
                          : false
                      }
                      disabled={userDeactivated}
                      onChange={(event) => countryChangeHandler(event, country)}
                    />
                  }
                  sx={{ fontSize: "0.9rem" }}
                  label={
                    <Typography style={{ fontSize: "0.95rem" }}>
                      {country.country_name}
                    </Typography>
                  }
                />
              </Grid>
            ))}
        </Grid>
      </div>
    ) : null;
  };

  return (
    <>
      {/* Select affiliate section */}
      <div className="selectAffiliateCard">
        <div className="selectAffiliateCard_header">
          <div className="affiliate_textInput">
            <span>Select Affiliate</span>
            <span style={{ marginTop: "-0.5rem" }}>
              <SelectDropdownInput
                id="affiliate"
                label=""
                className="selectAffiliate_dropdown"
                customDropdownClass="custom-dropdown-class"
                sx={{ background: "white" }}
                // userAffiliateAccess={userAffiliateAccess}
                options={
                  isUserOnboardedLocal
                    ? ALL_AFFILIATES_DROPDOWN
                    : ALL_AFFILIATES_DROPDOWN.filter(
                        (dropDown) =>
                          dropDown.title !== "US" && dropDown.value !== "US"
                      )
                }
                selectedOption={selectedAffiliate}
                onChange={selectedAffiliateHandler}
              />
            </span>
          </div>

          {/* Select affiliate tooltip initially hidden */}
          <div className={classNames("affiliate_InfoDetails", "tooltip")}>
            <InfoOutlinedIcon />
            <div className="tooltipContent">
              <div className="afffiliate_quickNoteGuidelines">
                <div className="affiliate_quckNote">
                  <h4>Quick Note</h4>
                  <div className="affiliate_globalUS">
                    <div className="affiliate_global">
                      <div className="affiliate_globalCol">
                        <span className="affiliate_globalCol_head">Global</span>
                      </div>
                      <div className="affiliate_globalCol">
                        Own, Coordinate, Approve/ Review Materials For Global
                        Brand Teams (Excludes Affiliates)
                      </div>
                    </div>
                    <div className="affilicate_US">
                      <div className="affiliate_globalCol_head">
                        <span className="affiliate_globalCol_US">US</span>
                      </div>
                      <div className="affiliate_globalCol">
                        Own, Coordinate, Approve/ Review Materials For
                        FCAP/PCA/Key Content
                      </div>
                    </div>
                  </div>
                </div>
                <div className="affiliate_guideLines">
                  <p className="affiliate_guideLinesText">
                    <span className="affiliate_stars">**</span> Most Users Only
                    Have Roles And Responsibilities Related To Either US Or
                    Global Brand Teams. However If A User Needs Access To
                    Perform Tasks For Both US & Global Brand Teams They Will
                    Need To Complete A Request For Both Types (Two Separate
                    Requests)
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Select country section */}
        {renderCountries()}
      </div>
    </>
  );
};
