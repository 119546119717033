import { reassignAndTerminateTerminateRequest_actionType } from "../../../actionTypes/adminAction-actionTypes/reassignAndTerminate/reassignAndTerminateTerminateRequest-actionType";

const initialState = {
    loading: false,
    isSuccess: false,
    isRequestCompleted: false,
    message: []
};

const ReassignAndTerminateTerminteRequestReducer = (state = initialState, action) =>{
    switch(action.type){
        case reassignAndTerminateTerminateRequest_actionType.REASSIGN_AND_TERMINATE_TERMINATE_REQUEST:
            return{
                ...state,
                loading: true
            };
        case reassignAndTerminateTerminateRequest_actionType.REASSIGN_AND_TERMINATE_TERMINATE_REQUEST_SUCCESS:
            return{
                ...state,
                loading: false,
                isRequestCompleted: true,
                isSuccess:true,
                message: [action.payload]
            };
        case reassignAndTerminateTerminateRequest_actionType.REASSIGN_AND_TERMINATE_TERMINATE_REQUEST_RESET:
            return{
                ...initialState
            };
        case reassignAndTerminateTerminateRequest_actionType.REASSIGN_AND_TERMINATE_TERMINATE_REQUEST_ERROR:
            return{
                ...state,
                loading: false,
                isRequestCompleted:true,
                isSuccess: false,
                message: [action.payload]
            };
        default: 
            return state;
    }
};

export default ReassignAndTerminateTerminteRequestReducer;