import { rolesRequestList_actionType } from "../../../actionTypes/adminAction-actionTypes/role-actionTypes/rolesRequestList-actionType";

const initialState = {
  loading: false,
  data: [],
  filterOptions:[],
  csvData:[],
  error: undefined
};

const RolesListReducer = (state = initialState, action) => {
  switch (action.type) {
    case rolesRequestList_actionType.ROLES_REQUEST_LIST_START:
      return {
        ...state,
        loading: true
      };
    case rolesRequestList_actionType.ROLES_REQUEST_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        filterOptions: action.filterOptions,
        csvData: action.csvData
      };
    case rolesRequestList_actionType.ROLES_REQUEST_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default RolesListReducer;
