export const adminProduct_actionType = {
    GET_PRODUCT_SUCCESS : 'GET_PRODUCT_SUCCESS',
    GET_PRODUCT_FAILURE : 'GET_PRODUCT_FAILURE',
    RESET_PRODUCT_SUCCESS : 'RESET_PRODUCT_SUCCESS',
    SUBMIT_PRODUCT_REQUEST_SUCCESS: 'SUBMIT_PRODUCT_REQUEST_SUCCESS',
    SUBMIT_PRODUCT_REQUEST_FAILURE: 'SUBMIT_PRODUCT_REQUEST_FAILURE',
    SEARCH_PRODUCT_ID_LOADER_ON: 'SEARCH_PRODUCT_ID_LOADER_ON',
    SEARCH_PRODUCT_ID_LOADER_OFF: 'SEARCH_PRODUCT_ID_LOADER_OFF',
    SEARCH_PRODUCT_ID_SUCCESS: 'SEARCH_PRODUCT_ID_SUCCESS',
    SEARCH_PRODUCT_ID_FAILURE: 'SEARCH_PRODUCT_ID_FAILURE',
    RESET_SEARCH_PRODUCT: 'RESET_SEARCH_PRODUCT'
};