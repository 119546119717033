import { React, useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CreateIcon from "@mui/icons-material/Create";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import { toLower } from "lodash";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#f9ccb5",
    color: theme.palette.common.black,
    fontWeight: 600,
    fontSize: 12,
    borderRight: "1px solid #80808040",
    borderBottom: "0px",
    padding: 3
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    color: "black",
    fontWeight: 400,
    borderRight: "1px solid #80808040",
    borderBottom: "0px",
    padding: 3
  }
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "rgb(0 255 205 / 4%)"
  }
  // hide last border
  // '&:last-child td, &:last-child th': {
  //   border: 0
  // }
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "productID",
    label: "Product ID"
  },
  {
    id: "productName",
    label: "Product Name"
  },
  {
    id: "vamProductGroupId",
    label: "Product Group"
  },
  {
    id: "affiliate",
    label: "Affiliate"
  }
];
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <StyledTableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            align="center"
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
        <StyledTableCell align="center">Action</StyledTableCell>
      </StyledTableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired
};

function ProductTable({
  searchText,
  filterQuery,
  setSelectedProductForEdit,
  handelToggleAddNewModal
}) {
  const [rows, setRows] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const { allRequest } = useSelector((state) => ({
    allRequest: state.AdminProductReducer.productList
  }));

  // all useEffects
  useEffect(() => {
    let tempFilterData = [...rows];
    setFilterData(tempFilterData);
  }, [rows]);

  useEffect(() => {
    let tempRows = [];
    if (allRequest.length > 0) {
      allRequest.forEach((element) => {
        let object = element;
        object.action = <CreateIcon />;
        tempRows.push(object);
      });
    }
    setRows(tempRows);
  }, [allRequest]);

  useEffect(() => {
    let tempFilterData = [...rows];

    // apply filter on the basis of filter
    if (filterQuery.length > 0) {
      filterQuery.forEach((filterQueryObj) => {
        filterQueryObj.value.forEach((element) => {
          if (element.checked) {
            switch (filterQueryObj.abbrebiation) {
              case "affiliateType":
                tempFilterData = tempFilterData.filter((object) => {
                  let availableInRow = false;
                  object.affiliate.forEach((affiliateObj) => {
                    if (toLower(element.name) === toLower(affiliateObj.name))
                      availableInRow = true;
                  });
                  return availableInRow;
                });
                break;
            }
          }
        });
      });
    }

    // apply filter on the basis of search text
    if (searchText.length > 0) {
      tempFilterData = tempFilterData.filter((obj) => {
        return (
          toLower(obj.productID).includes(toLower(searchText)) ||
          toLower(obj.productName).includes(toLower(searchText)) ||
          toLower(obj.affiliate).includes(toLower(searchText))
        );
      });
    }
    // update tempFilterData
    setFilterData(tempFilterData);
  }, [rows, searchText, filterQuery]);

  // all JS
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const visibleRows = useMemo(
    () => stableSort(filterData, getComparator(order, orderBy)),
    [order, orderBy, filterData]
  );

  return (
    <div className="productTableContainer">
      <TableContainer
        component={Paper}
        sx={{
          maxHeight: "75vh",
          scrollbarWidth: "12px",
          "&::-webkit-scrollbar": {
            width: "12px",
            borderRadius: "10px",
            height: "12px"
          },
          "&::-webkit-scrollbar-track": { borderRadius: "5px" },
          "&::-webkit-scrollbar-thumb": {
            borderRadius: "5px"
            // backgroundColor: "#848482"
          }
        }}
      >
        <Table
          sx={{ minWidth: 700 }}
          aria-label="customized table"
          stickyHeader
        >
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={filterData.length}
          />
          <TableBody>
            {visibleRows.length === 0 ? (
              <div style={{ width: "300%" }}>No record found</div>
            ) : null}
            {visibleRows.map((row, rowKey) => (
              <StyledTableRow key={rowKey}>
                <StyledTableCell component="th" scope="row" align="center">
                  {row.productID}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row.productName}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row.vamProductGroupId
                    ? row.vamProductGroupId + " Products"
                    : null}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row.affiliate.map((object, objectKey) => {
                    if (objectKey === row.affiliate?.length - 1)
                      return object.name;
                    return object.name + ", ";
                  })}
                </StyledTableCell>
                <StyledTableCell>
                  <EditOutlinedIcon
                    className="clickable"
                    sx={{
                      ":hover": {
                        cursor: "pointer"
                      }
                    }}
                    onClick={() => {
                      setSelectedProductForEdit(row);
                      handelToggleAddNewModal("edit");
                    }}
                  />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default ProductTable;
