import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./styles/AdminDashboard.css";

import AdminDashboardTable from "./AdminDashboardTable/AdminDashboardTable";
import ViewAdminDashboard from "./ViewAdminDashboard/ViewAdminDashboard";
import {
  getAdminDashboardRequests,
  getAdminRequestStatisticsAndFilters
} from "../../../../../redux/actions/adminAccount-actions/adminDashboard-action";
import { isEmpty } from "lodash";

function AdminDashboard() {
  const [isViewAdminDashboardActive, setIsViewAdminDashboardActive] =
    useState(false);
  const [viewAdminRequestId, setViewAdminRequestId] = useState(null);

  const dispatch = useDispatch();
  const { curPage, searchText, filterObject, curRequests, changeType } =
    useSelector((state) => ({
      curPage: state.AdminDashboardReducer.curPage,
      curRequests: state.AdminDashboardReducer.requests,
      filterObject: state.AdminDashboardReducer.filterQuery,
      searchText: state.AdminDashboardReducer.searchText,
      changeType: state.AdminDashboardReducer.changeType
    }));
  const hadelToggleIsViewAdminDashboardActive = () => {
    setIsViewAdminDashboardActive(!isViewAdminDashboardActive);
  };
  useEffect(() => {
    dispatch(getAdminRequestStatisticsAndFilters());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // useEffect(() => {
  //   console.log("Inside page change");
  //   if (!isEmpty(filterObject))
  //     dispatch(
  //       getAdminDashboardRequests(
  //         curPage,
  //         searchText,
  //         filterObject,
  //         curRequests
  //       )
  //     );
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [curPage]);
  // useEffect(() => {
  //   console.log("Inside searchtext and filter change");
  //   if (!isEmpty(filterObject)) {
  //     // dispatch(setCurpage(1));
  //     dispatch(
  //       getAdminDashboardRequests(1, searchText, filterObject, curRequests)
  //     );
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [searchText, filterObject]);

  useEffect(() => {
    dispatch(
      getAdminDashboardRequests(
        curPage,
        searchText,
        filterObject,
        curRequests,
        50
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEmpty(filterObject)) {
      if (changeType !== "page") {
        dispatch(
          getAdminDashboardRequests(
            1,
            searchText,
            filterObject,
            curRequests,
            50
          )
        );
      }
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, filterObject]);

  useEffect(() => {
    if (!isEmpty(filterObject)) {
      if (changeType === "page") {
        dispatch(
          getAdminDashboardRequests(
            curPage,
            searchText,
            filterObject,
            curRequests,
            50
          )
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curPage]);

  return (
    <div className="adminDashboardContainer">
      {!isViewAdminDashboardActive ? (
        <AdminDashboardTable
          hadelToggleIsViewAdminDashboardActive={
            hadelToggleIsViewAdminDashboardActive
          }
          setViewAdminRequestId={setViewAdminRequestId}
        />
      ) : (
        <ViewAdminDashboard
          viewAdminRequestId={viewAdminRequestId}
          hadelToggleIsViewAdminDashboardActive={
            hadelToggleIsViewAdminDashboardActive
          }
        />
      )}
    </div>
  );
}

export default AdminDashboard;
