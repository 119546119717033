// import { useParams } from "react-router-dom"
import { useState, useEffect } from 'react';
import { axiosApigateway } from '../api';
import CircularProgress from '@mui/material/CircularProgress';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import { deepOrange } from '@mui/material/colors';

export default function Home() {
    // const {reviewid}  = useParams()
    const [userData, setUserData] = useState({ name: 'Jawan' });
    const [apiCallFinished, setApiCallFinished] = useState(false);
    useEffect(() => {
        async function fetchApiData() {
            const respo = await axiosApigateway.get('/users');
            setApiCallFinished(true);
            setUserData(respo.data);
        }
        fetchApiData();
    }, []);

    if (!apiCallFinished) {
        return <CircularProgress disableShrink />;
    }
    return (
        <>
            This is Review.js
            <hr />
            {userData.map((user) => {
                return (<CardContent key={user.id}>
                    <ul > <Avatar sx={{ bgcolor: deepOrange[500] }} component="span">{user.name[0]}</Avatar>
                        <li>Name - {user.name}</li>
                        <li>Email - {user.email}</li>
                    </ul>
                    <hr />
                </CardContent>
                );
            })}

        </>
    );
}
