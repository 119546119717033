import { groupsRequestList_actionType } from "../../../actionTypes/adminAction-actionTypes/role-actionTypes/groupsRequestList-actionType";

const initialState = {
    loading: false,
    data: [],
    error: undefined
};

const GroupsRequestListReducer = (state = initialState, action) => {
    switch (action.type) {
        case groupsRequestList_actionType.GROUPS_REQUEST_LIST_START:
            return {
                ...state,
                loading: true
            };
        case groupsRequestList_actionType.GROUPS_REQUEST_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload
            };
        case groupsRequestList_actionType.GROUPS_REQUEST_LIST_RESET:
            return {
                ...initialState
            };
        case groupsRequestList_actionType.GROUPS_REQUEST_LIST_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
};

export default GroupsRequestListReducer;
