import React from 'react';
import Modal from "@mui/material/Modal";
import Button from '@mui/material/Button';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const ModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "500px",
    height: "200px",
    borderRadius: "10px",
    backgroundColor: "white",
    zIndex: '9999999999999'
};

function Success({
    isModalOpen,
    setIsModalOpen,
    modalMessage,
    refreshDataAction,
    scrollerMessage
}) {

    let overFlowMessageClassName = (!scrollerMessage ? 'message' : 'message overFlowMessage');


    const handelClick = () => {
        setIsModalOpen(false);
        refreshDataAction();
    };
  return (
    <Modal
        open={isModalOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <div style={ModalStyle}>
            <div className='modalContainer'>
                <div className='topBorderSuccess'></div>
                <div className='icon'>
                    <div className='iconSuccess'>
                        <CheckCircleOutlineIcon sx={{width: '2.54em', height: '2em', color: 'green' }}/>
                    </div>
                </div>
                <div className={overFlowMessageClassName}>
                <span >{
                    modalMessage && (typeof modalMessage === "string") ?
                        modalMessage
                        : "Request Success"
                }</span>
                </div>
                <div className='actionButton'>
                {
                    <Button variant="contained" color="success" onClick={handelClick}>
                        OK
                    </Button>
                }
                </div>
                </div>
            </div>
    </Modal>
  );
}

export default Success;