import { reassignAndTerminateSearchApprover_actionType } from "../../../actionTypes/adminAction-actionTypes/reassignAndTerminate/reassignAndTerminateSearchApprover-actionType";

const initialState = {
    loading: false,
    data: [],
    error: undefined
};

const ReassignAndTerminateSearchApproverReducer = (state = initialState, action) =>{
    switch(action.type){
        case reassignAndTerminateSearchApprover_actionType.REASSIGN_AND_TERMINATE_SEARCH_APPROVER:
            return{
                ...state,
                loading: true
            };
        case reassignAndTerminateSearchApprover_actionType.REASSIGN_AND_TERMINATE_SEARCH_APPROVER_SUCCESS:
            return{
                ...state,
                loading: false,
                data: action.payload
            };
        case reassignAndTerminateSearchApprover_actionType.REASSIGN_AND_TERMINATE_SEARCH_APPROVER_RESET:
            return{
               ...initialState
            };
        case reassignAndTerminateSearchApprover_actionType.REASSIGN_AND_TERMINATE_SEARCH_APPROVER_ERROR:
            return{
                ...state,
                loading: false,
                error: action.payload
            };
        default: 
            return state;
    }
};

export default ReassignAndTerminateSearchApproverReducer;