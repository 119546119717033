import { approvar_actionType } from "../../actionTypes/approvar-actionTypes/approvar-actionType";
const initialState = {
  loading: false,
  data: {},
  trainings: [],
  error: undefined,
  isUpdating: false,
  isUpdated: false,
  isEditOrUpdateFailed: false,
  retryExceptionSuccess: false,
  retryTrainingSuccessMessage: "",
  isExceptionAccessible: false,
  sponsorData: {
    employeeId: null,
    displayName: null,
    mail: null
  },
  actionButtonState: {
    isApproveEnabled: true,
    isRejectEnabled: true,
    isReassignEnabled: false,
    isEditEnabled: true,
    isApproveHidden: false,
    isRejectHidden: false,
    isEditHidden: false,
    isReassignedHidden: true
  }
};

const ApprovarReducer = (state = initialState, action) => {
  switch (action.type) {
    case approvar_actionType.GET_REQUEST_DATA_SUCCESS:
      return {
        ...state,
        loading: true
      };
    case approvar_actionType.SET_REQUEST_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload
      };
    case approvar_actionType.SET_REQUEST_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case approvar_actionType.HUB_ROLES_SUCCESS:
      return {
        ...state,
        loading: false,
        allRoles: action.payload
      };
    case approvar_actionType.HUB_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        allProducts: action.payload
      };
    case approvar_actionType.SELECTED_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedProducts: action.payload
      };
    case approvar_actionType.SELECTED_ROLES_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedRoles: action.payload
      };
    case approvar_actionType.HUB_ROLES_AND_PRODUCTS_FAILURE:
      return {
        ...state,
        loading: false,
        hubDataError: action.payload
      };
    case approvar_actionType.UPDATE_APPROVAR_DATA_REQUEST:
      return {
        ...state,
        isUpdating: true,
        isUpdated: false
      };
    case approvar_actionType.UPDATE_APPROVAR_DATA_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        isUpdated: true
      };
    case approvar_actionType.GET_SPONSOR_DATA_SUCCESS:
      return {
        ...state,
        sponsorData: action.payload
      };
    case approvar_actionType.GET_SPONSOR_DATA_FAILURE:
      return {
        ...state,
        error: action.payload
      };

    case approvar_actionType.SET_LOADER:
      return {
        ...state,
        loading: false
      };
    case approvar_actionType.RESET_LOADER:
      return {
        ...state,
        loading: false
      };
    case approvar_actionType.SET_TRAINGINGS:
      return {
        ...state,
        trainings: action.payload
      };
    case approvar_actionType.SET_ACTION_BUTTON_FLAGS:
      return {
        ...state,
        actionButtonState: action.payload
      };
    case approvar_actionType.UPDATE_EXCEPTION_MESSAGE_SUCCESS:
      return {
        ...state,
        data: { ...state.data, taskComments: action.data }
      };
    case approvar_actionType.UPDATE_EXCEPTION_MESSAGE_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case approvar_actionType.EDIT_OR_UPDATE_FAILURE:
      return {
        ...state,
        error: action.error,
        isEditOrUpdateFailed: true,
        loading: false
      };
    case approvar_actionType.RESET_ERROR_MODEL:
      return {
        ...state,
        isEditOrUpdateFailed: false
      };
    case approvar_actionType.SET_HUB_DATA:
      return {
        ...state,
        hubsData: action.payload
      };
    case approvar_actionType.RETRY_TRAINING_SUCCESS:
      return {
        ...state,
        retryTrainingSuccessMessage: action.data,
        retryExceptionSuccess: true
      };
    case approvar_actionType.ACCESS_CHECK_FOR_EXCEPTION:
      return {
        ...state,
        isExceptionAccessible: action.data
      };
    default:
      return state;
  }
};

export default ApprovarReducer;
