import React from "react";
import WorkflowCard from "./WorkflowCard";
// import { useSelector } from "react-redux";

const Workflow = ({ hubname, selectedRoles, allRoles }) => {
  // const { reducerData } = useSelector((state) => ({ reducerData: state.ApprovarReducer.data }));

  const stateToPropsWF = {
    workFlowType: hubname || '',
    roles: allRoles || [],
    selectedRoles: selectedRoles || []

  };

  // const stateToPropsWF = {
  //   workFlowType,
  //   roles,
  //   selectedRoles
  // };

  return <WorkflowCard {...stateToPropsWF} />;
};

export default Workflow;
