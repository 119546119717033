import { allAffiliates_actionType } from "../../actionTypes/userAction-actionTypes/allAffiliates-actionType";

const initialState = {
  data: [],
  error: undefined
};

const AllAffiliatesReducer = (state = initialState, action) => {
  switch (action.type) {
    case allAffiliates_actionType.SET_ALL_AFFILIATES_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: undefined
      };
    case allAffiliates_actionType.SET_ALL_AFFILIATES_FAILURE:
      return {
        ...state,
        error: action.data,
        data: initialState
      };
    case allAffiliates_actionType.GET_ALL_AFFILIATES_SUCCESS:
      return {
        ...state.data
      };
    case allAffiliates_actionType.GET_ALL_AFFILIATES_FAILURE:
      return {
        ...state.error
      };
    default:
      return state;
  }
};

export default AllAffiliatesReducer;
