import React from 'react';

import "./styles/AccessDenied.css";

import LockIcon from '@mui/icons-material/Lock';
import { Link } from 'react-router-dom';

function AccessDenied() {
  return (
    <div className="wrapper">
      <LockIcon sx={{ fontSize: '4rem' }} />
      <h1 className="glitch">
        Access denied
      </h1>
      <h4 className="errDetails">
        You do not have permissions to access this request.
      </h4>
      <p className="errHelp">
        If you need access for business purposes, please contact admin team.{' '}

      </p>
      <Link to={'/'}>Go to home</Link>
    </div>
  );
}

export default AccessDenied;