import { product_actionType } from "../../actionTypes/userAction-actionTypes/product-actionType";

const initialState = {
  product: [],
  isMandatoryField: true,
  requiredFieldAvailable: false,
  userAccessProductModified: false,
  searchProductValue: null
};

const ProductReducer = (state = initialState, action) => {
  switch (action.type) {
    case product_actionType.RESET_PRODUCT_SUCCESS:
      return {
        ...state,
        product: [],
        isMandatoryField: true,
        requiredFieldAvailable: false,
        userAccessProductModified: false
      };
    case product_actionType.SET_PRODUCT_SUCCESS:
      return {
        ...state,
        product: action.data,
        requiredFieldAvailable: action.isRequiredFieldAvailable
      };
    case product_actionType.SET_PRODUCT_SEARCH_TEXT:
      return {
        ...state,
        searchProductValue: action.data
      };
    case product_actionType.SET_REQUIRED_FIELD_AVAILABLE:
      return {
        ...state,
        requiredFieldAvailable: action.payload
      };
    case product_actionType.GET_PRODUCT_SUCCESS:
      return {
        ...state,
        product: action.data
      };
    case product_actionType.GET_PRODUCT_FAILURE:
      return {
        ...state,
        error: action.data
      };
    case product_actionType.SET_USER_ACCESS_PRODUCT_MODIFIED:
      return {
        ...state,
        userAccessProductModified: action.payload
      };
    case product_actionType.SET_IS_PRODUCT_MANDATORY_TRUE:
      return {
        ...state,
        isMandatoryField: true
      };
    case product_actionType.SET_IS_PRODUCT_MANDATORY_FALSE:
      return {
        ...state,
        isMandatoryField: false,
        product: []
      };
    default:
      return state;
  }
};

export default ProductReducer;
