import { affiliateCountries_actionType } from "../../actionTypes/userAction-actionTypes/affiliateCountries-actionType";

const initialState = {
  data: {
    affiliateType: "",
    countries: []
  },
  error: undefined
};

const AffiliateCountriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case affiliateCountries_actionType.SET_ALL_AFFILIATE_COUNTRIES_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: undefined
      };
    case affiliateCountries_actionType.SET_ALL_AFFILIATE_COUNTRIES_FAILURE:
      return {
        ...state,
        error: action.data,
        data: initialState
      };
    case affiliateCountries_actionType.RESET_AFFILIATE_COUNTRIES_SUCCESS:
      return {
        ...initialState
      };
    case affiliateCountries_actionType.GET_ALL_AFFILIATE_COUNTRIES_SUCCESS:
      return {
        ...state.data
      };
    case affiliateCountries_actionType.GET_ALL_AFFILIATE_COUNTRIES_FAILURE:
      return {
        ...state.error
      };
    default:
      return state;
  }
};

export default AffiliateCountriesReducer;
