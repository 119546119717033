import { adminProduct_actionType } from "../../../actionTypes/adminAction-actionTypes/product-actionTypes/product-actionTypes";

const initialState = {
  productList: [],
  filterQueryInitialState: [],
  uniqueAffiliates: [],
  submitResponse: null,
  isSubmitSuccess: false,
  isSearchProductLoading: false,
  searchedProduct: null,
  searchedProductMessage: "",
  isProductIDOpenToAdd: false
};

const AdminProductReducer = (state = initialState, action) => {
  switch (action.type) {
    case adminProduct_actionType.RESET_PRODUCT_SUCCESS:
        return state;
    case adminProduct_actionType.GET_PRODUCT_SUCCESS:
      return {
        ...state,
        productList: action.data,
        filterQueryInitialState: action.filterQueryInitialState,
        uniqueAffiliates: action.uniqueAffiliates
      };
    case adminProduct_actionType.GET_PRODUCT_FAILURE:
        return {
            ...state,
            error: action.data
        };
    case adminProduct_actionType.SUBMIT_PRODUCT_REQUEST_SUCCESS:
      return {
        ...state,
        submitResponse: action.data,
        isSubmitSuccess: true
      };
    case adminProduct_actionType.SUBMIT_PRODUCT_REQUEST_FAILURE:
      return {
        ...state,
        submitResponse: action.data,
        isSubmitSuccess: false
      };
    case adminProduct_actionType.SEARCH_PRODUCT_ID_LOADER_ON:
      return {
        ...state,
        isSearchProductLoading: true
      };
    case adminProduct_actionType.SEARCH_PRODUCT_ID_LOADER_OFF:
      return {
        ...state,
        isSearchProductLoading: false
      };
    case adminProduct_actionType.SEARCH_PRODUCT_ID_SUCCESS:
      return {
        ...state,
        searchedProduct: action.data.data.data,
        searchedProductMessage: "",
        isProductIDOpenToAdd: true
      };
    case adminProduct_actionType.SEARCH_PRODUCT_ID_FAILURE:
        return {
            ...state,
            searchedProductMessage: action.data.response.data.errormessage,
            error: action.data,
            isProductIDOpenToAdd: false
        };
    case adminProduct_actionType.RESET_SEARCH_PRODUCT:
      return {
        ...state,
        isSearchProductLoading: false,
        searchedProduct: null,
        searchedProductMessage: "",
        isProductIDOpenToAdd: false
      };
    default:
      return state;
  }
};

export default AdminProductReducer;