import { useEffect } from 'react';
import { useAuth } from '../contexts/auth/AuthContext';

// import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

export default function AuthCallback() {
    const auth = useAuth();
    // const navigate = useNavigate();
    useEffect(() => {
        auth.handlePromiseRedirectCallback(() => {
            // console.log('authentication done');
            // navigate(Cookies.get('initialroute') || '/');
            window.location.href = (Cookies.get('initialroute') || '/');
        });
    });
    return (
        <div>Signing in...</div>
    );
}