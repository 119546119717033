import { workFlowRoles_actionType } from "../../actionTypes/userAction-actionTypes/workFlowRoles-actionType";

export function getworkFlowRoles() {
  return (dispatch) => {
    dispatch({
      type: workFlowRoles_actionType.GET_WORKFLOW_ROLES_SUCCESS,
      data: "xyz"
    });
  };
}

export function resetWorkFlowRolesReducer() {
  return (dispatch) => {
    dispatch({
      type: workFlowRoles_actionType.RESET_WORKFLOW_ROLES_SUCCESS
    });
  };
}

export function setworkFlowRoles(data) {
  return (dispatch, getState) => {
    if (data) {
      // validating data
      dispatch({
        type: workFlowRoles_actionType.SET_WORKFLOW_ROLES_SUCCESS,
        payload: data,
        state: getState()
      });
    } else {
      dispatch({
        type: workFlowRoles_actionType.SET_WORKFLOW_ROLES_FAILURE
      });
    }
  };
}

export function setUserAccessRolesModified(data) {
  return (dispatch) => {
    dispatch({
      type: workFlowRoles_actionType.SET_USER_ACCESS_ROLES_MODIFIED,
      payload: data
    });
  };
}

export function setSelectedWorkFlowRoles(data) {
  return (dispatch, getState) => {
    if (data) {
      // validating data
      dispatch({
        type: workFlowRoles_actionType.SELECT_DESELECT_WORKFLOW_ROLES,
        payload: data,
        state: getState()
      });
      dispatch(setTrainings(data));
    }
  };
}

export function setTrainings(roles) {
  const trainings = roles.map((_role) => {
    if (_role?.role?.trainings) {
      const trainings = [];
      const workFlowType = _role.workFlowType;
      const rolesTraingings = _role?.role?.trainings || [];
      rolesTraingings.forEach((element) => {
        trainings.push({ ...element, workFlowType });
      });
      return trainings;
    } else {
      return [];
    }
    // return _role?.role?.trainings ? [..._role?.role?.trainings, _role?.workFlowType] :[];
  });
  const data = trainings?.flat()?.reduce((unique, o) => {
    if (
      !unique.some(
        (obj) => obj.id === o.id && obj?.workFlowType === o?.workFlowType
      )
    ) {
      unique.push(o);
    }
    return unique;
  }, []);
  return (dispatch) => {
    dispatch({
      type: workFlowRoles_actionType.SET_TRAININGS,
      payload: data
    });
  };
}
