import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
// import Typography from '@mui/material/Typography';
import IconButton from "@mui/material/IconButton";
import { useSelector } from "react-redux";
import { Link } from "@mui/material";
import './styles/Header.css';

const headerHeight = '53px';

export default function MenuAppBar() {
  const { loggedUser } = useSelector((state) => ({
    loggedUser:
      state.LoggedUserReducer.loggedUser
  }));


  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" color="transparent">
        <Toolbar sx={{ height: headerHeight }}>
          <div style={{flexGrow: 1, padding: "0.5rem 1.5rem"}}>
            <Link href="/" sx={{}}>
              <img src="/vvpm-lilly-logo.png" alt="Lilly | VVPM Logo" style={{maxHeight: '44px'}} />
            </Link>
          </div>
          <div>
            <IconButton
              size="small"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="transparent"
            >
              {loggedUser.name}
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
