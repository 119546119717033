import { trainingsRequestList_actionType } from "../../../actionTypes/adminAction-actionTypes/role-actionTypes/trainingsRequest-actionType";

const initialState = {
    loading: false,
    data: [],
    error: undefined
};

const TrainingsRequestListReducer = (state = initialState, action) => {
    switch (action.type) {
        case trainingsRequestList_actionType.TRAININGS_REQUEST_START:
            return {
                ...state,
                loading: true
            };
        case trainingsRequestList_actionType.TRAININGS_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload
            };
        case trainingsRequestList_actionType.TRAININGS_REQUEST_RESET:
            return {
                ...initialState
            };
        case trainingsRequestList_actionType.TRAININGS_REQUEST_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
};

export default TrainingsRequestListReducer;
