import { checkUserRole_actionTypes} from "../actionTypes/checkUserRole-actionTypes";

const initialState = {
  loading: false,
  role: "",
  error: undefined
};

const CheckUserRoleReducer = (state = initialState, action) => {
  switch (action.type) {
    case checkUserRole_actionTypes.CHECK_USER_ROLE:
      return {
        ...state,
        loading:true
      };
    case checkUserRole_actionTypes.CHECK_USER_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        role: action.payload
      };
    case checkUserRole_actionTypes.CHECK_USER_ROLE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default CheckUserRoleReducer;