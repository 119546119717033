import { Button, FormControl, FormControlLabel, FormLabel, Grid, MenuItem, Radio, RadioGroup, Select, TextField } from '@mui/material';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addorModifyRolesRequestCall } from '../../../../../../../redux/actions/adminAccount-actions/roles-action/rolesRequest-action';

const RoleTab = (props) => {
  // const {allRoles} = props;
  const { mode, currentSelectedRole, setIsModalOpen ,setActiveModalNotice, allAffiliteData  } = props;
  const dispatch = useDispatch();
  const [roleId, setRoleId] = useState("");
  const [roleName, setRoleName] = useState("");
  const [roleAudience, setRoleAudience] = useState("");
  const [license, setLicense] = useState("");
  const [securityProfile, setSecurityProfile] = useState("");
  const [vamHubAbbr, setVamHubAbr] = useState("");
  const [elevatedRole, setElevatedRole] = useState(null);
  const [requireProducts, setRequireProducts] = useState(null);
  const [roleDesc, setRoleDesc] = useState("");


  useEffect(() => {
    if (mode) {
      switch (mode) {
        case "edit":
          setRoleId(currentSelectedRole?.vamRoleId);
          setRoleName(currentSelectedRole?.name);
          setRoleAudience(currentSelectedRole?.roleAudience);
          setLicense(currentSelectedRole?.license);
          setSecurityProfile(currentSelectedRole?.securityProfile);
          setVamHubAbr(currentSelectedRole?.vamHubAbbreviation);
          setElevatedRole(currentSelectedRole?.isElevatedRole);
          setRequireProducts(currentSelectedRole?.isRequireProducts);
          setRoleDesc(currentSelectedRole?.description);
          break;
        case "create":
          setRoleId("");
          setRoleName("");
          setRoleAudience("");
          setLicense("");
          setSecurityProfile("");
          setVamHubAbr("");
          setElevatedRole(null);
          setRequireProducts(null);
          setRoleDesc("");
          break;
        default:
          setRoleId("");
          setRoleName("");
          setRoleAudience("");
          setLicense("");
          setSecurityProfile("");
          setVamHubAbr("");
          setElevatedRole(null);
          setRequireProducts(null);
          setRoleDesc("");
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  const ROLE_AUDIENCE_DROPDOWN = [
    {
      title: 'Non-Lilly',
      value: 'Non-Lilly'
    },
    {
      title: 'Lilly',
      value: 'Lilly'
    },
    {
      title: 'All',
      value: 'All'
    }
  ];
  const SECURITY_PROFILE_DROPDOWN = [
    {
      title: 'oversight_users_with_bulk_update__c',
      value: 'oversight_users_with_bulk_update__c'
    },
    {
      title: 'clm_gatekeepers__c',
      value: 'clm_gatekeepers__c'
    },
    {
      title: 'approved_email_users__c',
      value: 'approved_email_users__c'
    },
    {
      title: 'gccp_users__c',
      value: 'gccp_users__c'
    },
    {
      title: 'document_user__v',
      value: 'document_user__v'
    }
  ];
  const LICENSE_DROPDOWN = [
    {
      title: 'full__v',
      value: 'full__v'
    },
    {
      title: 'external__v',
      value: 'external__v'
    },
    {
      title: 'read_only__v',
      value: 'read_only__v'
    }
  ];

  const AFFILIATES_DROPDOWN = allAffiliteData.map(affObj =>{
    return{
      title: affObj.name,
      value: affObj.abbreviation
    };
  });

  const cancelCreateModifyRoleHandler = () =>{
    setIsModalOpen(false);
  };
  
  const createModifyRoleHandler = () => {
    let payload;
    payload = {
      name: roleName,
      description: roleDesc,
      isElevatedRole: elevatedRole,
      isRequireProducts: requireProducts,
      roleAudience: roleAudience,
      license: license,
      securityProfile: securityProfile,
      vamHubAbbreviation: vamHubAbbr
    };
    switch (mode) {
      case "edit":
        dispatch(addorModifyRolesRequestCall("edit", payload, roleId));
        setIsModalOpen(false);
        setActiveModalNotice("message");
        break;
      case "create":
        dispatch(addorModifyRolesRequestCall("create", payload));
        setIsModalOpen(false);
        setActiveModalNotice("message");
        break;
      default:
        null;
    }
  };

  console.log(roleId);
  return (
    <div className='role_addOrModifyRoleContainer'>
      <Grid container spacing={1} md={12} className="role_addOrModifyRole">
        <Grid item sm={4}>
          <FormControl>
            <FormLabel id="is-elevated-role">
              <span className='role_formLabel'>Role Name</span>
              <span className='dangerColor'>*</span>
            </FormLabel>
            <TextField
              sx={{ width: "18rem", marginTop: "0.5rem" }}
              id="is-elevated-role"
              type="text"
              size="small"
              variant="outlined"
              autoComplete="off"
              value={roleName}
              onChange={(event) => {
                setRoleName(event.target.value);
              }}
              focused={false} />
          </FormControl>
        </Grid>
        <Grid item sm={4}>
          <FormControl>
            <FormLabel id="role-audience">
              <span className='role_formLabel'>Role Audience</span>
              <span className='dangerColor'>*</span>
            </FormLabel>
            <Select
              sx={{ width: "18rem", marginTop: "0.5rem" }}
              placeholder="Role Audience"
              size='small'
              aria-labelledby="role-audience"
              value={roleAudience}
              onChange={(event) => {
                setRoleAudience(event.target.value);
              }}
            >
              {
                ROLE_AUDIENCE_DROPDOWN.map((object, objectKey) => {
                  return (
                    <MenuItem key={objectKey} value={object.value}>{object.title}</MenuItem>
                  );
                })
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid item sm={4}>
          <FormControl>
            <FormLabel id="license">
              <span className='role_formLabel'>License</span>
              <span className='dangerColor'>*</span>
            </FormLabel>
            <Select
              sx={{ width: "18rem", marginTop: "0.5rem" }}
              placeholder="License"
              size='small'
              aria-labelledby="license"
              value={license}
              onChange={(event) => {
                setLicense(event.target.value);
              }}
            >
              {
                LICENSE_DROPDOWN.map((object, objectKey) => {
                  return (
                    <MenuItem key={objectKey} value={object.value}>{object.title}</MenuItem>
                  );
                })
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid item sm={4}>
          <FormControl>
            <FormLabel id="security-profile">
              <span className='role_formLabel'>Security Profile</span>
              <span className='dangerColor'>*</span>
            </FormLabel>
            <Select
              sx={{ width: "18rem", marginTop: "0.5rem" }}
              placeholder="Security Profile"
              size='small'
              aria-labelledby="security-profile"
              value={securityProfile}
              onChange={(event) => {
                setSecurityProfile(event.target.value);
              }}
            >
              {
                SECURITY_PROFILE_DROPDOWN.map((object, objectKey) => {
                  return (
                    <MenuItem key={objectKey} value={object.value}>{object.title}</MenuItem>
                  );
                })
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid item sm={8}>
          <FormControl>
            <FormLabel id="vam-hub-abbreviation">
              <span className='role_formLabel'>Affiliate</span>
              <span className='dangerColor'>*</span>
            </FormLabel>
            <Select
              sx={{ width: "18rem", marginTop: "0.5rem" }}
              placeholder="Affiliate"
              size='small'
              aria-labelledby="affiliate"
              value={vamHubAbbr}
              disabled={mode === "edit" ? true : false}
              onChange={(event) => {
                setVamHubAbr(event.target.value);
              }}
            >
              {
                AFFILIATES_DROPDOWN.map((object, objectKey) => {
                  return (
                    <MenuItem key={objectKey} value={object.value}>{object.title}</MenuItem>
                  );
                })
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid item sm={4}>
          <FormControl>
            <FormLabel id="is-elevated-role">
              <span className='role_formLabel'>Elevated Role</span>
              <span className='dangerColor'>*</span>
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="is-elevated-role"
              name="row-radio-buttons-group"
              value={elevatedRole}
              onChange={(event) => {
                setElevatedRole(event.target.value);
              }}
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item sm={8}>
          <FormControl>
            <FormLabel id="is-require-products">
              <span className='role_formLabel'>Require Products</span>
              <span className='dangerColor'>*</span>
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="is-require-products"
              name="row-radio-buttons-group"
              value={requireProducts}
              onChange={(event) => {
                setRequireProducts(event.target.value);
              }}
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item sm={4}>
          <FormControl>
            <FormLabel id="description">
              <span className='role_formLabel'>Description</span>
            </FormLabel>
            <TextareaAutosize
              className="role_textArea"
              aria-label="description"
              minRows={6}
              maxRows={6}
              maxlength={255}
              placeholder="Enter role description here"
              value={roleDesc}
              onChange={(event) => {
                setRoleDesc(event.target.value);
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2} md={12} className="role_actionButons">
        <Button variant="outlined" color="error" className='btnRole btnRoleCancel' onClick={cancelCreateModifyRoleHandler}>Cancel</Button>
        <Button variant="outlined" color="success" className='btnRole' onClick={createModifyRoleHandler}>Submit</Button>
      </Grid>
    </div>
  );
};

export default RoleTab;