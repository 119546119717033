const GATEWAY_SCOPES = {
    NON_PRODUCTION_GATEWAY_SCOPE: 'api://39b64b04-e385-4232-a4e2-604367e2d849/.default',
    PRODUCTION_GATEWAY_SCOPE: 'api://39b64b04-e385-4232-a4e2-604367e2d849/.default'
  };
  
  const GATEWAY_SCOPES_TYPES = {
    PRODUCTION: 'production',
    NON_PRODUCTION: 'non_production'
  };
  
  Object.freeze(GATEWAY_SCOPES);
  Object.freeze(GATEWAY_SCOPES_TYPES);
  export { GATEWAY_SCOPES, GATEWAY_SCOPES_TYPES };