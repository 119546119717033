import React from "react";
import Modal from "@mui/material/Modal";

const ReassignAndTerminateModal = ({
  isModalOpen,
  setIsModalOpen,
  children
}) => {

  return (
    <>
      <Modal
        open={isModalOpen}
        onClose={setIsModalOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="reassignAndTerminate_modal">
           {children}
        </div>
      </Modal>
    </>
  );
};

export default ReassignAndTerminateModal;
