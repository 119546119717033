import { React, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import "./styles/Products.css";

import FilterInProduct from './FilterInProduct';
import ProductTable from './ProductTable';
import AdminMainArticleLoader from '../../AdminMainArticleLoader/AdminMainArticleLoader';

import { getAdminProducts } from '../../../../../../redux/actions/adminAccount-actions/product-action/product-action';
import ModalAddNewProduct from './ModalAddNewProduct';

function Products() {
  const [searchText, setSearchText] = useState("");
  const [filterQuery, setFilterQuery] = useState([]);
  const [isAddNewProductModalOpen, setIsAddNewProductModalOpen] = useState(false);
  const [selectedProductForEdit, setSelectedProductForEdit] = useState();
  const [addProductModaMode, setAddProductModaMode] = useState("create");
  const dispatch = useDispatch();
  const { isScreenLoading, filterQueryInitialState } = useSelector((state) => ({
    isScreenLoading: state.LoaderReducer.adminProductLoader,
    filterQueryInitialState: state.AdminProductReducer.filterQueryInitialState
  }));

  useEffect(() => {
    dispatch(getAdminProducts());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if(isScreenLoading){
      // reset all filter and serach to default
      setSearchText("");
      setFilterQuery(filterQueryInitialState);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScreenLoading]);

  useEffect(() => {
    setFilterQuery(filterQueryInitialState);
  }, [filterQueryInitialState]);

  // all JS
  const handelToggleAddNewModal = (mode="create") => {
    setIsAddNewProductModalOpen(!isAddNewProductModalOpen);
    setAddProductModaMode(mode);
  };

  if(isScreenLoading)
    return <AdminMainArticleLoader />;
  return (
    <div className='productContainer'>
        <FilterInProduct 
          searchText={searchText}
          setSearchText={setSearchText}
          filterQueryInitialState={filterQueryInitialState}
          filterQuery={filterQuery}
          setFilterQuery={setFilterQuery}
          handelToggleAddNewModal={handelToggleAddNewModal}
        />
        <ProductTable 
          searchText={searchText}
          filterQuery={filterQuery}
          setSelectedProductForEdit={setSelectedProductForEdit}
          handelToggleAddNewModal={handelToggleAddNewModal}
        />
        <ModalAddNewProduct
          isAddNewProductModalOpen={isAddNewProductModalOpen}
          handelToggleAddNewModal={handelToggleAddNewModal}
          addProductModaMode={addProductModaMode}
          selectedProductForEdit={selectedProductForEdit}
        />
    </div>
  );
}

export default Products;