import React, { useState } from "react";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Button, TextareaAutosize } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { reassignAndTerminateTerminateRequestCall } from "../../../../../../../redux/actions/adminAccount-actions/reassignAndTerminate/reassignAndTerminateTerminateRequest-action";

const ReassignAndTerminateTerminateModalNotice = (props) => {
  const { setIsModalOpen } = props;
  const dispatch = useDispatch();
  const { selectedRequest } = useSelector((state) => {
    return {
      selectedRequest:
        state.ReassignAndTerminateReducer.ReassignAndTerminateRequestListReducer
          .selectedRequest
    };
  });

  const { request_id } = selectedRequest;

  const [adminComment, setAdminComment] = useState("");

  const adminCommentChangeHandler = (event) => {
    setAdminComment(event.target.value);
  };

  const noActionHandler = () => {
    setIsModalOpen(false);
  };
  const yesActionHandler = () => {
    //deleteRequest
    dispatch(
      reassignAndTerminateTerminateRequestCall({
        request_id: request_id,
        comments: adminComment
      })
    );
    setIsModalOpen(false);
  };
  return (
    <div className="reassignAndTerminate_terminateModalContainer">
      <div className="reassignAndTerminate_terminate_topBorder"></div>
      <div className="terminate_icon">
        <div className="reassignAndTerminate_iconFail">
          <HelpOutlineIcon
            sx={{ width: "2.54em", height: "2em", color: "red" }}
          />
        </div>
      </div>
      <div className="reassignAndTerminate_terminateInstrWithTextBox">
        <span className="reassignAndTerminate_terminateInstruction">
          Are you sure you want to terminate {request_id}{" "}
          request?
        </span>
        <div className="reassignAndTerminate_terminatelabelWithTextBox">
          <span className="reassignAndTerminate_terminateLabel">
            Admin comment*
          </span>
          <TextareaAutosize
            className="terminate_textBox"
            aria-label="minimum height"
            maxRows={6}
            minRows={6}
            maxlength={255}
            placeholder="Enter your comment here..."
            value={adminComment}
            onChange={adminCommentChangeHandler}
          />
        </div>
      </div>
      <div className="reassignAndTerminate_terminateActionButtons">
        <Button variant="outlined" color="error" onClick={noActionHandler}>
          NO
        </Button>
        <Button variant="outlined" color="success" onClick={yesActionHandler}>
          YES
        </Button>
      </div>
    </div>
  );
};

export default ReassignAndTerminateTerminateModalNotice;
