import React from "react";
import { Button } from "@mui/material";

const ButtonSuccess = ({id, label, disabled, onClickHandler, width }) => {
  return (
    <Button
      id={id}
      className="buttonSuccess"
      variant="outlined"
      onClick={onClickHandler}
      color="success"
      disabled={disabled}
      sx={{
        width: width ? width : 100,
        height: 35,
        ":hover": {
          bgcolor: "green",
          color: "white"
        }
      }}
    >
      {label}
    </Button>
  );
};

export default ButtonSuccess;
