import { React, useState, useEffect } from "react";
import "./styles/SelectProductCard.css";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import ArrowCircleLeftRoundedIcon from "@mui/icons-material/ArrowCircleLeftRounded";
import ArrowCircleRightRoundedIcon from "@mui/icons-material/ArrowCircleRightRounded";
import { useDispatch, useSelector } from "react-redux";
import {
  setProductRequiredFieldAvailableChecker,
  setProductToReducer,
  // setSearchTextToReducer,
  setUserAccessProductModified
} from "../../redux/actions/userAccount-actions/product-action";
import { Button, IconButton, TextField } from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import ClearIcon from "@mui/icons-material/Clear";

const SelectProductCard = ({ product, setProduct }) => {
  const [isSectionActive, setIsSectionActive] = useState(true);
  const [paginationIndex, setPaginationIndex] = useState(0);
  const [totalPage, setTotalPage] = useState(1);
  const [totalItemInProduct, setTotalItemInProduct] = useState(0);

  const itemsPerPage = 4;

  const [selectedProductsCount, setSelectedProductsCount] = useState(0);

  const dispatch = useDispatch();
  const {
    selectedAffiliateTypeAbbrebiation,
    isMandatoryField,
    productsFromReducer
    // searchProductValue
  } = useSelector((state) => ({
    selectedRoleList: state.WorkFlowRolesReducer.selectedRoles,
    selectedAffiliateTypeAbbrebiation:
      state.SelectAffiliateReducer.data.selectedAffiliateTypeAbbrebiation,
    isMandatoryField: state.ProductReducer.isMandatoryField,
    searchProductValue: state.ProductReducer.searchProductValue,
    productsFromReducer: state.ProductReducer.product
  }));
  const [tempSearchValue, setTempSearchValue] = useState(null);
  const [searchValue, setSearchValue] = useState(null);

  // all useEffect
  useEffect(() => {
    const tempSearchedProduct = [...product];
    tempSearchedProduct?.forEach((productGroup) => {
      productGroup?.productList?.forEach((productObj) => {
        productObj.isFilteredProduct = true;
      });
      // set parent product group checkbox
      productGroup.checked = productGroup?.productList
        // ?.filter((productObj) => {
        //   if (searchValue?.length > 0) {
        //     return productObj?.isFilteredProduct;
        //   } else {
        //     return true;
        //   }
        // })
        ?.filter((productObj) => {
          return productObj.applicableAffiliates.includes(
            selectedAffiliateTypeAbbrebiation
          );
        })
        ?.every((val) => val.checked);
    });
    dispatch(setProductToReducer(tempSearchedProduct));
    setProduct(tempSearchedProduct);

    return () => {
      const tempSearchedProduct = [...productsFromReducer];
      tempSearchedProduct?.forEach((productGroup) => {
        productGroup?.productList?.forEach((productObj) => {
          productObj.isFilteredProduct = true;
        });
        // set parent product group checkbox
        productGroup.checked = productGroup?.productList
          // ?.filter((productObj) => {
          //   if (searchValue?.length > 0) {
          //     return productObj?.isFilteredProduct;
          //   } else {
          //     return true;
          //   }
          // })
          ?.filter((productObj) => {
            return productObj.applicableAffiliates.includes(
              selectedAffiliateTypeAbbrebiation
            );
          })
          ?.every((val) => val.checked);
      });

      dispatch(setProductToReducer(tempSearchedProduct));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTotalPage(product.length / itemsPerPage);
    setTotalItemInProduct(product.length);
    let count = 0;
    product.forEach((productGroup) => {
      // count to be shown
      productGroup?.productList?.forEach((productObj) => {
        if (productObj?.checked) {
          count++;
        }
      });
    });
    setSelectedProductsCount(count);
    if (count > 0) {
      dispatch(setProductRequiredFieldAvailableChecker(true));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  useEffect(() => {
    const tempSearchedProduct = [...product];
    tempSearchedProduct?.forEach((productGroup) => {
      productGroup?.productList?.forEach((productObj) => {
        if (searchValue?.length > 0) {
          if (productObj?.text?.toLowerCase()?.includes(searchValue)) {
            productObj.isFilteredProduct = true;
          } else {
            productObj.isFilteredProduct = false;
          }
        } else {
          productObj.isFilteredProduct = true;
        }
      });
      // set parent product group checkbox
      productGroup.checked = productGroup?.productList
        ?.filter((productObj) => {
          if (searchValue?.length > 0) {
            return productObj?.isFilteredProduct;
          } else {
            return true;
          }
        })
        ?.filter((productObj) => {
          return productObj.applicableAffiliates.includes(
            selectedAffiliateTypeAbbrebiation
          );
        })
        ?.every((val) => val.checked);
    });
    setProduct(tempSearchedProduct);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  // all JS Function

  const toggleSectionActive = () => {
    setIsSectionActive(!isSectionActive);
  };
  const handelCheckBoxParent = (index) => {
    let tempProduct = [...product];
    tempProduct[index].checked = !tempProduct[index].checked;
    if (tempProduct[index].checked) {
      // mark all productList checked
      tempProduct[index].productList.forEach((element) => {
        if (
          (searchValue === null || element?.isFilteredProduct) &&
          element?.applicableAffiliates?.some(
            (val) => val === selectedAffiliateTypeAbbrebiation
          )
        ) {
          element.checked = true;
        }
      });
    } else {
      // mark all productList checked
      tempProduct[index].productList.forEach((element) => {
        if (searchValue === null || element?.isFilteredProduct) {
          element.checked = false;
        }
      });
    }
    dispatch(setProductToReducer(tempProduct));
    setProduct(tempProduct);
    dispatch(setUserAccessProductModified(true));
  };
  const handelCheckBoxChild = (parentIndex, childIndex) => {
    let tempProduct = [...product];
    tempProduct[parentIndex].productList[childIndex].checked =
      !tempProduct[parentIndex].productList[childIndex].checked;

    // set parent product group checkbox
    tempProduct?.forEach((productGroup) => {
      productGroup.checked = productGroup?.productList
        ?.filter((productObj) => {
          if (searchValue?.length > 0) {
            return productObj?.isFilteredProduct;
          } else {
            return true;
          }
        })
        ?.filter((productObj) => {
          return productObj.applicableAffiliates.includes(
            selectedAffiliateTypeAbbrebiation
          );
        })
        ?.every((val) => val.checked);
    });
    dispatch(setProductToReducer(tempProduct));
    setProduct(tempProduct);
    dispatch(setUserAccessProductModified(true));
  };
  const handelNextPage = () => {
    setPaginationIndex(paginationIndex + 1);
  };
  const handelPrevPage = () => {
    setPaginationIndex(paginationIndex - 1);
  };

  const searchButtonHandler = () => {
    setSearchValue(tempSearchValue?.toLowerCase()?.trim());
    // dispatch(setSearchTextToReducer(tempSearchValue?.toLowerCase()?.trim()));
  };
  const clearSearchHandler = () => {
    setSearchValue("");
    setTempSearchValue("");
    // dispatch(setSearchTextToReducer(""));
  };

  // const searchBoxValueChangeHandler = (productObjId, searchValue) => {
  //   setTempSearchValue((oldVal) => ({
  //     ...oldVal,
  //     [productObjId]: searchValue
  //   }));
  //   if (searchValue === "") {
  //     clearSearchedProducts(productObjId);
  //   }
  // };

  // const searchButtonHandler = (productObjId) => {
  //   setSearchValue((oldVal) => ({
  //     ...oldVal,
  //     [productObjId]: tempSearchValue?.[productObjId]
  //   }));
  // };
  return (
    <div className="selectProductCard_home">
      <div className="selectProductCard_header">
        <span style={{ display: "none" }}>
          {isSectionActive ? (
            <RemoveCircleOutlineOutlinedIcon onClick={toggleSectionActive} />
          ) : (
            <AddCircleOutlineOutlinedIcon onClick={toggleSectionActive} />
          )}
        </span>
        <span>Select Products</span>
      </div>
      {isSectionActive ? (
        <div
          className="selectProductCard_section"
          // style={{ flexDirection: "column" }}
        >
          {isMandatoryField ? (
            <div
              style={{
                paddingLeft: "3rem",
                paddingRight: "3rem",
                paddingTop: "20px",
                // paddingBottom: "20px",
                justifyContent: "space-between",
                display: "flex"
              }}
            >
              <div style={{ paddingLeft: "8px" }}>
                <TextField
                  size="small"
                  variant="outlined"
                  className="searchUser_textField"
                  sx={{ height: "30px" }}
                  focused={false}
                  placeholder="Search for Products"
                  value={tempSearchValue}
                  onChange={(event) => {
                    setTempSearchValue(event.target.value);
                    if (event.target.value === "") {
                      clearSearchHandler();
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      searchButtonHandler();
                    }
                  }}
                  InputProps={{
                    sx: {
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                      height: "30px"
                    },
                    endAdornment: (
                      <>
                        {tempSearchValue?.length > 0 ? (
                          <IconButton
                            edge="end"
                            size="medium"
                            className="searchUser_crossIcon"
                            sx={{ height: "30px" }}
                            onClick={() => {
                              clearSearchHandler();
                            }}
                          >
                            <ClearIcon />
                          </IconButton>
                        ) : null}
                      </>
                    )
                  }}
                />
                <Button
                  variant="contained"
                  style={{ height: "30px" }}
                  className={
                    // !userSearchDisabled?
                    "searchUser_searchButton"
                    //   : "searchUser_searchButtonDisabled"
                  }
                  //   disabled={userSearchDisabled}
                  // onClick={searchUserHandler}
                  onClick={searchButtonHandler}
                >
                  <SearchOutlinedIcon size="medium" sx={{ height: "30px" }} />
                </Button>
              </div>
              <div style={{ fontWeight: "bold", paddingRight: "20px" }}>
                Selected Products - {selectedProductsCount}
              </div>
            </div>
          ) : null}
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div className="selectProductCard_sectionSideArticle">
              {paginationIndex > 0 ? (
                <ArrowCircleLeftRoundedIcon
                  sx={{ fontSize: "2.8rem", color: "#f8ac85" }}
                  onClick={handelPrevPage}
                />
              ) : null}
            </div>
            {isMandatoryField ? (
              <div className="selectProductCard_sectionCenterArticle">
                {product
                  .slice(
                    Math.max(0, paginationIndex * itemsPerPage),
                    Math.min(
                      totalItemInProduct,
                      paginationIndex * itemsPerPage + itemsPerPage
                    )
                  )
                  .map((productObject, productKey) => {
                    return (
                      <div
                        className="selectProductCard_product"
                        key={productKey}
                      >
                        <div className="selectProductCard_productHeader">
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={productObject.checked}
                                onChange={() =>
                                  handelCheckBoxParent(
                                    paginationIndex * itemsPerPage + productKey
                                  )
                                }
                                name="checkedB"
                                sx={{
                                  "&.Mui-checked": {
                                    color: "#00AE43"
                                  }
                                }}
                                className="productCheckboxHeaderClass"
                              />
                            }
                            label={
                              <span
                                className="productCheckboxHeaderLabelClass"
                                style={{
                                  fontWeight: "bold",
                                  color: "black"
                                }}
                              >
                                {productObject.productName}
                              </span>
                            }
                          />
                        </div>
                        {/* <div
                          className="searchUser_textFieldWithButton"
                          style={{ height: "30px" }}
                        >
                          <TextField
                            size="small"
                            variant="outlined"
                            className="searchUser_textField"
                            sx={{ height: "30px" }}
                            focused={false}
                            placeholder={`Search ${productObject?.productName}`}
                            value={tempSearchValue?.[productObject.id]}
                            onChange={(event) => {
                              searchBoxValueChangeHandler(
                                productObject.id,
                                event.target.value
                              );
                            }}
                            InputProps={{
                              sx: {
                                borderTopRightRadius: 0,
                                borderBottomRightRadius: 0,
                                height: "30px"
                              },
                              endAdornment: (
                                <>
                                  {tempSearchValue?.[productObject.id]?.length >
                                  0 ? (
                                    <IconButton
                                      edge="end"
                                      size="medium"
                                      className="searchUser_crossIcon"
                                      sx={{ height: "30px" }}
                                      onClick={() => {
                                        clearSearchedProducts(productObject.id);
                                      }}
                                    >
                                      <ClearIcon />
                                    </IconButton>
                                  ) : null}
                                </>
                              )
                            }}
                          />
                          <Button
                            variant="contained"
                            style={{ height: "30px" }}
                            className={
                              // !userSearchDisabled?
                              "searchUser_searchButton"
                              //   : "searchUser_searchButtonDisabled"
                            }
                            //   disabled={userSearchDisabled}
                            // onClick={searchUserHandler}
                            onClick={() => {
                              searchButtonHandler(productObject.id);
                            }}
                          >
                            <SearchOutlinedIcon
                              size="medium"
                              sx={{ height: "30px" }}
                            />
                          </Button>
                        </div> */}
                        <div className="selectProductCard_productSection">
                          {productObject.productList.map(
                            (productInnerObject, productInnerKey) => {
                              const isSelectedAffiliateExists =
                                productInnerObject.applicableAffiliates.includes(
                                  selectedAffiliateTypeAbbrebiation
                                );

                              // const isSearchStringExist =
                              //   searchValue?.[productObject.id]?.length > 0
                              //     ? productInnerObject.text
                              //         ?.toLowerCase()
                              //         ?.includes(
                              //           searchValue?.[
                              //             productObject.id
                              //           ]?.toLowerCase()
                              //         )
                              //     : true;
                              if (
                                isSelectedAffiliateExists &&
                                (searchValue === null ||
                                  productInnerObject?.isFilteredProduct)
                              ) {
                                return (
                                  <FormControlLabel
                                    key={productInnerKey}
                                    control={
                                      <Checkbox
                                        checked={productInnerObject.checked}
                                        onChange={() =>
                                          handelCheckBoxChild(
                                            paginationIndex * itemsPerPage +
                                              productKey,
                                            productInnerKey
                                          )
                                        }
                                        name="checkedB"
                                        sx={{
                                          "&.Mui-checked": {
                                            color: "#00AE43"
                                          }
                                        }}
                                        className="productCheckboxClass"
                                      />
                                    }
                                    label={
                                      <span
                                        style={{
                                          fontSize: "0.8rem",
                                          color: "black"
                                        }}
                                      >
                                        {productInnerObject.text}
                                      </span>
                                    }
                                  />
                                );
                              }
                            }
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>
            ) : (
              <div
                className="selectProductCard_sectionCenterArticleSelectionNotAllowed"
                style={{
                  paddingTop: "40px",
                  paddingBottom: "40px"
                }}
              >
                <span>
                  Based on the roles selected for Lilly-based users, access to
                  all products will be provided.{" "}
                </span>
                <span>Products are no longer required to be selected.</span>
              </div>
            )}
            <div className="selectProductCard_sectionSideArticle">
              {paginationIndex < totalPage - 1 ? (
                <ArrowCircleRightRoundedIcon
                  sx={{ fontSize: "2.8rem", color: "#f8ac85" }}
                  onClick={handelNextPage}
                />
              ) : null}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SelectProductCard;
