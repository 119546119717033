export const adminDashboard_actionType = {
  GET_DASHBOARD_SUCCESS: "GET_DASHBOARD_SUCCESS",
  GET_DASHBOARD_FAILURE: "GET_DASHBOARD_FAILURE",
  RESET_DASHBOARD_SUCCESS: "RESET_DASHBOARD_SUCCESS",
  GET_ADMIN_REQUEST_DETAIL_SUCCESS: "GET_ADMIN_REQUEST_DETAIL_SUCCESS",
  GET_ADMIN_REQUEST_DETAIL_FAILURE: "GET_ADMIN_REQUEST_DETAIL_FAILURE",
  SET_PAGE: "SET_PAGE",
  SET_DASHBOARD_TABLE_LOADER: "SET_DASHBOARD_TABLE_LOADER",
  RESET_DASHBOARD_TABLE_LOADER: "RESET_DASHBOARD_TABLE_LOADER",
  CSV_DATA_SUCCESS: "CSV_DATA_SUCCESS",
  SET_DASHBOARD_FILTERS: "SET_DASHBOARD_FILTERS",
  SET_DASHBOARD_SEARCH: "SET_DASHBOARD_SEARCH"
};
