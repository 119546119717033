import { React, useEffect, useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import Modal from "@mui/material/Modal";
// import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  minHeight: "45%",
  maxHeight: "80%",
  border: 0,
  borderRadius: "10px",
  backgroundColor: "white",
  outline: "none"
};

function FilterModal({
  isModalOpen,
  toggleOpenFilterBox,
  filterQueryInitialState,
  filterQuery,
  setFilterQuery
}) {
  const [data, setData] = useState(filterQueryInitialState);

  useEffect(() => {
    const localData = JSON.stringify(filterQuery);
    setData(JSON.parse(localData));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  const handleClose = () => {
    toggleOpenFilterBox();
  };
  const handelSave = () => {
    setFilterQuery(data);
    toggleOpenFilterBox();
  };
  const handleClick = (dataIndex, selectedChipIndex) => {
    let tempData = [...data];
    tempData[dataIndex].value[selectedChipIndex].checked =
      !tempData[dataIndex].value[selectedChipIndex].checked;
    setData(tempData);
  };
  return (
    <Modal
      open={isModalOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div style={ModalStyle}>
        <div className="filterContent">
          <div
            className="filterContentHeader"
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingRight: "15px"
            }}
          >
            <div>Search Filter</div>
            <HighlightOffIcon
              sx={{
                mr: "5px",
                "&:hover": {
                  color: "gray",
                  transition: "color 0.2s"
                }
              }}
              onClick={handleClose}
            />
          </div>
          {/* <div className="filterContentHeader">Search Filter</div> */}
          {data.length > 0 ? (
            <div className="filterContentContent">
              <div className="filterContentContentLineOne">
                <div className="filterContentContentHeader">Affiliate Type</div>
                <div
                  className="filterContentContentSectionForProduct"
                  style={{ maxHeight: "250px" }}
                >
                  {data[0].value.map((Object, key) => {
                    return (
                      <Chip
                        label={Object.name}
                        key={key}
                        onClick={() => handleClick(0, key)}
                        color={
                          data[0].value[key].checked ? "primary" : "default"
                        }
                        variant={data[0].value[key].checked ? "" : "outlined"}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          ) : null}
        </div>
        {/* <CancelOutlinedIcon
          className="accountSummary_crossModalIcon"
          onClick={() => handleClose()}
        /> */}
        <div className="summaryModalButtons">
          <Button
            className="buttonDanger"
            variant="outlined"
            onClick={handleClose}
            color="error"
            sx={{
              width: 100,
              height: 35,
              fontSize: 12,
              ":hover": {
                bgcolor: "red",
                color: "white"
              }
            }}
          >
            Cancel
          </Button>
          <Button
            className="buttonSuccess"
            variant="outlined"
            onClick={handelSave}
            color="success"
            sx={{
              width: 100,
              height: 35,
              fontSize: 12,
              ":hover": {
                bgcolor: "green",
                color: "white"
              }
            }}
          >
            Apply
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default FilterModal;
