import {
  Button,
  CircularProgress,
  ClickAwayListener,
  IconButton,
  MenuItem,
  Paper,
  Popper,
  TextField,
  Tooltip
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { useDispatch, useSelector } from "react-redux";
import { searchSponserResources } from "../../redux/actions/userAccount-actions/summary-action";

const SponserModalNotice = ({ selectedSponsor }) => {
  const {
    sponsor: { loading, error, data },
    userDetail: { userMail }
  } = useSelector((state) => {
    return {
      sponsor: {
        loading: state.SummaryReducer.searchSponser.loading,
        data: state.SummaryReducer.searchSponser.data,
        error: state.SummaryReducer.searchSponser.error
      },
      userDetail: {
        userMail: state.UserDetailReducer.data.emailId
      }
    };
  });

  const dispatch = useDispatch();
  const searchTextFieldRef = useRef();
  const [userList, setUserList] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchedSponser, setSearchSponser] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [open, setOpen] = useState(false);

  let SUPERVISOR_SPONSER_DROPDOWN = [];

  useEffect(() => {
    setUserList(data);
  }, [data]);

  if (userList && Array.isArray(userList) && userList.length) {
    userList.map((user) => {
      let id, mail, employeeId, displayName;

      mail = user.mail || "";
      id = user.id || "";
      displayName = user.displayName || "";
      employeeId = user.employeeId || "";

      const dropDown = {
        id: id,
        title: `${displayName} ${employeeId ? `( ${employeeId} )` : ""} ${
          mail ? mail : ""
        }`
      };

      SUPERVISOR_SPONSER_DROPDOWN.push(dropDown);
    });
  }

  const getParticularUser = (user) => {
    return userList.find((usr) => {
      return usr?.id?.toString() === user?.id?.toString();
    });
  };

  const clearSearchBoxHandler = () => {
    setSearchSponser("");
    setSelectedUser(null);
    selectedSponsor("");
  };

  const searchUserHandler = () => {
    if (searchedSponser) {
      setUserList([]);
      dispatch(searchSponserResources(searchedSponser, userMail));
      setAnchorEl(searchTextFieldRef.current);
      setOpen(true);
    }
  };

  const selectedUserHandler = (user) => {
    setSelectedUser(user);
    setSearchSponser(user.title);
    setOpen(false);
    selectedSponsor(getParticularUser(user));
  };
  return (
    <div className="sponserSupervisor_ModalCard">
      <div className="sponserSupervisor_modalCardHeader">
        <span>Select Sponsor / Supervisor**</span>
      </div>
      <div className="sponserSupervisorSearch_wrapper">
        <span className="sponserSupervisorSearch_searchText">
          Search Sponsor / Supervisor
        </span>
        <div className="sponserSupervisorSearch_textFieldWithButton">
          <TextField
            id="sponserSupervisor-search-user-input"
            size="small"
            variant="outlined"
            className="sponserSupervisorSearch_textField"
            focused={false}
            placeholder="Type Name / Email To Search Supervisor"
            value={searchedSponser}
            ref={searchTextFieldRef}
            onChange={(event) => {
              setSearchSponser(event.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                searchUserHandler();
              }
            }}
            InputProps={{
              sx: { borderTopRightRadius: 0, borderBottomRightRadius: 0 },
              endAdornment: (
                <React.Fragment>
                  {(selectedUser && selectedUser.title) ||
                  searchedSponser.length ? (
                    <IconButton
                      edge="end"
                      size="medium"
                      className="sponserSupervisorSearchApprover_crossIcon"
                      onClick={clearSearchBoxHandler}
                    >
                      <ClearIcon />
                    </IconButton>
                  ) : null}
                </React.Fragment>
              )
            }}
          />
          <Button
            variant="contained"
            className={"sponserSupervisorSearch_searchButton"}
            onClick={searchUserHandler}
          >
            <SearchOutlinedIcon
              size="medium"
              className="sponserSupervisor_searchIcon"
            />
          </Button>
          <Popper open={open} anchorEl={anchorEl} sx={{ zIndex: 1500 }}>
            <ClickAwayListener onClickAway={() => setOpen(false)}>
              <Paper
                sx={{
                  position: "absolute",
                  top: "0rem",
                  left: "-12rem"
                }}
              >
                {loading ? (
                  <div className="loader-container">
                    <CircularProgress />
                  </div>
                ) : error ? (
                  <div className="error-container">
                    <p className="error-text">{error}</p>
                  </div>
                ) : null}
                {SUPERVISOR_SPONSER_DROPDOWN &&
                SUPERVISOR_SPONSER_DROPDOWN.length ? (
                  <div
                    className={
                      SUPERVISOR_SPONSER_DROPDOWN.length > 5
                        ? "sponserSupervisorSearch_userList"
                        : ""
                    }
                  >
                    {SUPERVISOR_SPONSER_DROPDOWN.map((user, index) => (
                      <MenuItem
                        key={index}
                        size="large"
                        onClick={() => {
                          selectedUserHandler(user);
                        }}
                        sx={{
                          width: `${
                            SUPERVISOR_SPONSER_DROPDOWN.length > 5
                              ? "26.5rem"
                              : "27.5rem"
                          }`,
                          // whiteSpace: "normal",
                          fontSize: "0.9rem",
                          color: "#000000"
                        }}
                      >
                        {user.title && user.title.length > 55 ? (
                          <>
                            {user.title.substr(0, 50)}
                            <Tooltip
                              title={user.title}
                              arrow
                              placement="left"
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    color: "#000",
                                    backgroundColor: "#fff",
                                    fontSize: "0.9rem",
                                    border: "2px solid #707070"
                                  }
                                }
                              }}
                            >
                              <strong>...</strong>
                            </Tooltip>
                          </>
                        ) : (
                          user.title
                        )}
                      </MenuItem>
                    ))}
                  </div>
                ) : null}
              </Paper>
            </ClickAwayListener>
          </Popper>
        </div>
      </div>
    </div>
  );
};

export default SponserModalNotice;
