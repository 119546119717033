import React, { useEffect, useState } from "react";
import ReassignAndTerminateTableHeader from "./ReassignAndTerminateTableHeader";
import ReassignAndTerminateTableBody from "./ReassignAndTerminateTableBody";
import { REASSIGN_AND_TERMINATE_TABLE_COLUMNS } from "./ReassignAndTerminateTableColumns";
import "./styles/ReassignAndTerminateTable.css";
import { useSelector } from "react-redux";

const ReassignAndTerminateTable = ({
  searchKeyword,
  // setSearchKeyword,
  filterOptions,
  setIsModalOpen,
  setActiveModalNotice
}) => {
  const { allRequest } = useSelector((state) => {
    return {
      allRequest:
        state.ReassignAndTerminateReducer.ReassignAndTerminateRequestListReducer
          .data
    };
  });

  const [rows, setRows] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    let tempRows = [];
    if (allRequest.length) {
      tempRows = [...allRequest];
    }
    setRows(tempRows);
  }, [allRequest]);

  useEffect(() => {
    let tempFilteredData = [...rows];
    setFilteredData(tempFilteredData);
  }, [rows]);

  useEffect(() => {
    let tempFilterData = [...rows];
    let isFilterApplied = false;

    if (filterOptions.length > 0) {
      filterOptions.forEach((filterOptionObj) => {
        let tempFilteredDataIndex = new Set();
        isFilterApplied = false;
        filterOptionObj.values.forEach((element) => {
          if (element.selected) {
            isFilterApplied = true;
            switch (filterOptionObj.accessor) {
              case "affiliate_type":
                tempFilterData.map((dataObj, dataObjIdx) => {
                  if (
                    dataObj["affiliate_type"].toLowerCase() ===
                    element.value.toLowerCase()
                  )
                    tempFilteredDataIndex.add(dataObjIdx);
                });
                break;
              case "user_type":
                tempFilterData.map((dataObj, dataObjIdx) => {
                  if (
                    dataObj["user_type"].toLowerCase() ===
                    element.value.toLowerCase()
                  )
                    tempFilteredDataIndex.add(dataObjIdx);
                });
                break;
              case "request_type":
                tempFilterData.map((dataObj, dataObjIdx) => {
                  if (
                    dataObj["request_type"].toLowerCase() ===
                    element.value.toLowerCase()
                  )
                    tempFilteredDataIndex.add(dataObjIdx);
                });
                break;
            }
          }
        });
        if (isFilterApplied) {
          tempFilterData = tempFilterData.filter(
            (filterDataObj, filterDataObjIdx) => {
              return (
                filterDataObj && tempFilteredDataIndex.has(filterDataObjIdx)
              );
            }
          );
        }
      });
    }

    if (searchKeyword.length) {
      tempFilterData = tempFilterData.filter((filterDataObj) => {
        return Object.values(filterDataObj)
          .join(" ")
          .toLowerCase()
          .includes(searchKeyword.toLowerCase());
      });
    }

    setFilteredData(tempFilterData);
  }, [rows, searchKeyword, filterOptions]);

  const sortHandler = (sortField, sortBy) => {
    if (sortField) {
      const sorted = [...filteredData].sort((a, b) => {
        if (a[sortField] === "") return 1;
        if (b[sortField] === "") return -1;
        if (a[sortField] === "" && b[sortField] === "") return 0;
        return (
          a[sortField].toString().localeCompare(b[sortField].toString(), "en", {
            numeric: true
          }) * (sortBy === "asc" ? 1 : -1)
        );
      });
      setFilteredData(sorted);
    }
  };
  return (
    <table className="reassignAndTerminate_recordTable">
      <ReassignAndTerminateTableHeader
        columns={REASSIGN_AND_TERMINATE_TABLE_COLUMNS.columns}
        sortHandler={sortHandler}
      />
      <ReassignAndTerminateTableBody
        rows={filteredData}
        columns={REASSIGN_AND_TERMINATE_TABLE_COLUMNS.columns}
        setIsModalOpen={setIsModalOpen}
        setActiveModalNotice={setActiveModalNotice}
      />
    </table>
  );
};

export default ReassignAndTerminateTable;
