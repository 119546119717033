/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector } from "react-redux";
import { pageTitle_actionTypes } from "../utils/pageTitle_actionType";
import { UserDetails } from "./UserDetails";
import { UserDetailsResult } from "./UserDetailsResult";
import "./styles/UserDetails.css";

const UserDetailsLoader = (props) => {
  const {
    pageTitle,
    currentTask,
    isUserOnboarded,
    toggleToLandingPage,
    userAccessResources,
    allAffiliatesResources
  } = props;
  const allAffiliates = allAffiliatesResources.map(
    (affiliate) => affiliate.affiliateType
  );

  const {
    userDetails,
    affiliateCountries,
    selectAffiliate,
    allUserDetailResources,
    completeState
  } = useSelector((state) => {
    return {
      userDetails: state.UserDetailReducer.data,
      affiliateCountries: state.AffiliateCountriesReducer.data,
      selectAffiliate: state.SelectAffiliateReducer.data,
      allUserDetailResources: state.AllUserDetailResourcesReducer.data,
      completeState: state
    };
  });

  const stateToPropsUD = {
    userDetails,
    isUserOnboarded,
    allAffiliates,
    affiliateCountries,
    allAffiliatesResources,
    userAccessResources,
    selectAffiliate,
    pageTitle,
    toggleToLandingPage,
    allUserDetailResources,
    completeState
  };

  const stateToPropsUR = {
    userDetails,
    selectAffiliate
  };


  if (
    (pageTitle === pageTitle_actionTypes.CREATE_USER_ACCOUNT ||
      pageTitle === pageTitle_actionTypes.MODIFY_USER_ACCOUNT) &&
    currentTask == 0
  ) {
    return <UserDetails {...stateToPropsUD} />;
  } else if (
    (pageTitle === pageTitle_actionTypes.CREATE_USER_ACCOUNT ||
      pageTitle === pageTitle_actionTypes.MODIFY_USER_ACCOUNT) &&
    currentTask > 0
  ) {
    return <UserDetailsResult {...stateToPropsUR} />;
  }
};

export default UserDetailsLoader;
