import { affiliate_actionType } from "../../actionTypes/adminAction-actionTypes/affiliate-actionType";

const initialState = {
    data: [], // hubs data
    error: undefined,
    isLoader: true,
    message: [],
    countriesData: [], // unique country data
    isRequestCompleted: false,
    isRequestSuccess: false,
    failureForCountries: [],
    successForCountries: []
};
const AdminAffiliateReducer = (state = initialState, action) => {
    switch (action.type) {
        case affiliate_actionType.SET_LOADER:
            return {
                ...state,
                isLoader: true
            };
        case affiliate_actionType.RESET_REDUCER_CORRESPONDING_TO_COUNTRIES:
            return{
                ...state,
                countriesData: [],
                message: []
            };
        case affiliate_actionType.GET_ALL_AFFILIATE_SUCCESS:
            return {
                ...state,
                data: action.data
            };
        case affiliate_actionType.GET_ALL_AFFILIATE_FAILURE:
            return {
                ...state,
                error: action.data
            };
        case affiliate_actionType.ADD_AFFILIATE_SUCCESS:
            return {
                ...state,
                isRequestCompleted: true,
                isRequestSuccess: true,
                message: [action?.data]
            };
        case affiliate_actionType.ADD_AFFILIATE_FAILURE:
            return {
                ...state,
                isRequestCompleted: true,
                isRequestSuccess: false,
                message: [[action?.data]]
            };
        case affiliate_actionType.EDIT_AFFILIATE_IN_COUNTRIES_SUCCESS:
            return {
                ...state,
                isRequestCompleted: true,
                isRequestSuccess: true,
                successForCountries: action.successForCountries,
                failureForCountries: action.failureForCountries,
                message: [action?.data]
            };
        case affiliate_actionType.EDIT_AFFILIATE_IN_COUNTRIES_FAILURE:
            return {
                ...state,
                isRequestCompleted: true,
                isRequestSuccess: false,
                message: [[action?.data]]
            };
        case affiliate_actionType.GET_ALL_COUNTRIES_SUCCESS:
            return {
                ...state,
                countriesData: action.data
            };
        case affiliate_actionType.GET_ALL_COUNTRIES_FAILURE:
            return {
                ...state,
                error: action.data
            };

        case affiliate_actionType.RESET_LOADER:
            return {
                ...state,
                isLoader: false
            };
        default:
            return state;
    }
};



export default AdminAffiliateReducer;