import React from 'react';
import Modal from "@mui/material/Modal";
import ModalNotice from './ModalNotice';

const ModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "500px",
    height: "200px",
    borderRadius: "10px",
    backgroundColor: "white"
};

function ModalMainContainer({
    isModalOpen, 
    isSubmitSuceess,
    handleReactivate,
    setIsModalOpen, 
    isSubmitActive, 
    modalMessage,
    reactivate,
    toggleToLandingPage})
{
    // const handleRequest = () =>{
    //     if(reactivate){
    //         handleReactivate();
    //     }else{
    //         handleClose();
    //     }
    // };
    const handleClose = () => setIsModalOpen(false);
  
    return (
    <>
        <Modal
            open={isModalOpen}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div style={ModalStyle}>
            <ModalNotice
                error={!isSubmitActive}
                isSubmitSuceess={isSubmitSuceess}
                message={modalMessage}
                twoButton={!isSubmitActive}
                reactivate={reactivate}
                handleReactivate={handleReactivate}
                buttonOneAction={toggleToLandingPage}
                buttonTwoAction={handleClose}
            />
            </div>
        </Modal>
    </>
  );
}

export default ModalMainContainer;