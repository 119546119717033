import { createTheme } from "@mui/material";

const theme = createTheme({
  /* Palette Settings - 
  palette: {
    primary: {
      main: "",
      light: "",
      dark: "",
      contrastText: "",
    },
    secondary: {
      main: "",
      light: "",
      dark: "",
      contrastText: "",
    },
    ochre: {
        main: '#E3D026',
        light: '#E9DB5D',
        dark: '#A29415',
        contrastText: '#242105',
      },
  },*/
  palette: {
    text: {
      disabled: '#575656'
    }
  },
  typography: {
    fontFamily: [
      "Roboto",
      "URW-DIN",
      "Merriweather",
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      "sans-serif"
    ].join(","),
    h1: {
      fontSize: "96px",
      fontWeight: 300,
      letterSpacing: "-1.5px",
      lineHeight: "112px",
      color: "#808080"
    },
    h2: {
      fontSize: "60px",
      fontWeight: 300,
      letterSpacing: "-.5px",
      lineHeight: "72px",
      color: "#808080"
    },
    h3: {
      fontSize: "96px",
      lineHeight: "56px",
      color: "#808080",
      padding: "0"
    },
    h4: {
      fontSize: "34px",
      lineHeight: "36px",
      color: "#808080"
    },
    h5: {
      fontSize: "24px",
      letterSpacing: "0.18px",
      lineHeight: "24px",
      color: "#808080"
    },
    h6: {
      fontSize: "20px",
      fontWeight: 500,
      letterSpacing: "0.15px",
      lineHeight: "24px",
      color: "#808080"
    },
    subtitle1: {
      fontSize: "16px",
      fontWeight: 500,
      letterSpacing: "0.15px",
      lineHeight: "24px",
      color: "#808080"
    },
    subtitle2: {
      fontSize: "14px",
      fontWeight: 500,
      letterSpacing: "0.1px",
      lineHeight: "24px",
      color: "#808080"
    },
    body1: {
      fontSize: "16px",
      letterSpacing: ".5px",
      lineHeight: "24px",
      color: "#808080"
    },
    body2: {
      fontSize: "14px",
      fontWeight: 300,
      letterSpacing: "0.25px",
      lineHeight: "20px",
      color: "#808080"
    },
    button: {
      fontSize: "14px",
      fontWeight: 500,
      letterSpacing: "1.25px",
      lineHeight: "16px",
      color: "#808080"
    },
    caption: {
      fontSize: "12px",
      letterSpacing: "0.4px",
      lineHeight: "16px",
      color: "#808080"
    },
    overline: {
      fontSize: "10px",
      fontWeight: 500,
      letterSpacing: "1.5px",
      lineHeight: "16px",
      color: "#808080"
    }
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':{
            borderColor: "#9a9898cc"
          },
          '& .Mui-disabled':{
            background: "#f3f3f3",
            color: "#575656"
          },
        option: {}
      }
    }
  },
  MuiSelect: {
    styleOverrides: {
      root: {
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "#9a9898cc"
        },
        '& .Mui-disabled':{
          background: "#f3f3f3",
          color: "#575656"
        },
      option: {}
    }
  }
}
}});

export default theme;
