/* eslint-disable no-useless-escape */
import { isArray } from "lodash";
import { axiosApigateway } from "../../../api";
import { company_actionType } from "../../actionTypes/adminAction-actionTypes/company-actionType";

export function resetCompanyReducer() {
  return (dispatch) => {
    dispatch({
      type: company_actionType.RESET_COMPANY_SUCCESS
    });
  };
}

export function setCompanyToReducer(companyObject, allCompines) {
  return (dispatch) => {
    dispatch({
      type: company_actionType.SET_COMPANY_SUCCESS,
      data: {companyObject : companyObject, allCompines : allCompines} 
    });
  };
}

const filterByAlphaRange = (arr = [], start = 'a', end = 'z') => {
    const isGreater = (c1, c2) => c1 >= c2;
    const isSmaller = (c1, c2) => c1 <= c2;
    const filtered = arr.filter(el => {
       const [firstChar] = el.name.toLowerCase();
       return isGreater(firstChar, start) && isSmaller(firstChar, end);
    });
    return filtered;
 };

 function containsSpecialChars(str) {
    const disgitsRegex = /\d/;
    const specialCharsRegex =   /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return disgitsRegex.test(str) ||  specialCharsRegex.test(str);
  }

  const createCompanyData = (responseData) =>{
    const companyWithSpecialChars = responseData.map(o => containsSpecialChars(o.name.charAt(0)) ? o : []);
    const data = [
            {
                companyName : 'Company Name A-M',
                companyList : filterByAlphaRange(responseData, 'a', 'm').map(o => {return {...o, checked : false};}),
                checked : false
            },
            {
                companyName : 'Company Name N-Z',
                companyList : filterByAlphaRange(responseData, 'n', 'z').map(o => {return {...o, checked : false};}),
                checked : false
            },
            {
                companyName : 'Company Name 0-9, Special Characters',
                companyList : companyWithSpecialChars.filter(e => !isArray(e)),
                checked : false
            }
        ];
     return data;
  };

export function getCompany() {

  return async(dispatch)=> {
      try{
          dispatch({
              type : company_actionType.GET_COMPANY_START
          });
          // calling API
          const response = await axiosApigateway.get("/admin-companies");
          const data = createCompanyData(response?.data?.data);
          if(data){
              dispatch(setCompanyToReducer(data,response?.data?.data));
          }
          
      }catch(error){
          dispatch({
              type : company_actionType.SET_COMPANY_FAILURE,
              data : error
          });
      }
  };
}

export function filterCompany(searchText, allCompines){
  return (dispatch) => {
  const filterCompaines = allCompines.filter(o => o.name.toLowerCase().includes(searchText.toLowerCase()));
  const data = createCompanyData(filterCompaines);
  if(data){
      dispatch(setCompanyToReducer(data,allCompines));
    }
  };
}

export function saveCompanyRequest(data, isEditRequest, companyId){
  return async(dispatch) => {
      dispatch({
        type : company_actionType.EDIT_REQUEST_START
      });
      // dispatch(initiliseGroupReduerState());
      try{
          let response;
          if(isEditRequest){
              response = await axiosApigateway.put(`/admin-companies/${companyId}`, data);
          }else{
              response = await axiosApigateway.post("/admin-companies", data);
          }
          if(response){
              dispatch({
                  type : company_actionType.EDIT_REQUEST_SUCCESS,
                  data : response?.data?.message || isEditRequest ? "Company successfully updated." : "Company successfully added."
              });
          }
          dispatch(getCompany());
      }catch(error){
          dispatch({
              type: company_actionType.EDIT_REQUEST_FAILURE,
              data: error.response?.data?.data || 'Something went wrong!!'
          });
      }
  };
}

export function initiliseCompanyReduerState(){
  return(dispatch) => {
      dispatch({
          type: company_actionType.EDIT_REQUEST_START
      });
  };
}

export function deleteCompanyRequest(companyId){
  return async(dispatch) => {
    dispatch({
      type : company_actionType.EDIT_REQUEST_START
    });
      try{
          const response  = await axiosApigateway.delete(`/admin-companies/${companyId}`);
          if(response){
              dispatch({
                  type : company_actionType.EDIT_REQUEST_SUCCESS,
                  data : response?.data?.message
              });
              dispatch(getCompany());
          }
          
      }catch(error){
          dispatch({
              type: company_actionType.EDIT_REQUEST_FAILURE,
              data : "Currently company is assigned to other user, can not be deleted."
          });
      }
  };
}



