export const adminPortalPageNavigation_actionType = {
    ADMIN_DASHBOARD: "Admin Dashboard",
    DASHBOARD: "Dashboard",
    VIEW: 'View',
    DATA_CONFIGURATION: "Data Configuration",
    COMPANY: "Company",
    AFFLIATE: "Affiliate",
    GROUPS: "Groups",
    ROLES: "Roles",
    TRAINING: "Training",
    PRODUCTS: "Products",
    REASSIGN: "Reassign",
    BUSINESS_UNIT: "Business Unit"
};
