import { affiliateResources_actionType } from "../../actionTypes/userAction-actionTypes/affiliateResources-actionType";

/*Format data object
data:[
    {
        affiliateType:{
            name: "UK Hub",
            abbreviation: "UK"
        },
        countries:[{
            "country_id": "00C000000000E24",
            "country_name": "Norway",
            "country_abbreviation": "NO",
            "country_active_flg": true,
            "vamHubAbbreviation": "UK"
        }],
        roles:[{
            "vam_role_id": 7,
            "name": "Change Approver",
            "description": "This is Change Approver role in UK Hub",
            "isElevatedRole":false,
            "roleAudience": Lilly, // values - Lilly, Non-Lilly, null, All
            "isActive": true
            "isRequireProducts": false,
            "license": "full user",
            "securityProfile": "mfa_sso",
            "vamHubAbbreviation": "UK"
        }],
        productsWithGroups:[{
            "id": "OBU",
            "name": "OBU Products",
            "description": "This product group holds OBU product",
            "vamhubs_vamproductgroup_junction":{
                "vamHubAbbre": "UK",
                "vamProductG": "OBU"
            },
            "products":[{
                "product_id": "00P000000000106",
                "product_name": "Lilly Oncology",
                "product_abbreviation": "ON",
                "product_active_flg": true,
                "vamProductGroupId": "OBU"
            }]
        }]
    }
]
******************/

const initialState = {
  loading: false,
  data: [],
  error: undefined
};

const AffiliateResourcesReducer = (state = initialState, action) => {
  switch (action.type) {
    case affiliateResources_actionType.ALL_AFFILIATE_RESOURCES:
      return {
        ...state,
        loading: true,
        error: undefined
      };
    case affiliateResources_actionType.ALL_AFFILIATE_RESOURCES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload
      };
    case affiliateResources_actionType.RESET_ALL_AFFILIATE_RESOURCES:
      return {
        ...initialState
      };
    case affiliateResources_actionType.ALL_AFFILIATE_RESOURCES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default AffiliateResourcesReducer;
