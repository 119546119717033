import { product_actionType } from "../../actionTypes/userAction-actionTypes/product-actionType";

export function resetProductReducer() {
  return (dispatch) => {
    dispatch({
      type: product_actionType.RESET_PRODUCT_SUCCESS
    });
  };
}

const requiredFieldAvailableChecker = (productObject) => {
  let found = false;
  productObject.forEach((object) => {
    object.productList.forEach((element) => {
      if (element?.checked) {
        found = true;
      }
    });
  });
  return found;
};

export function setProductRequiredFieldAvailableChecker(flag) {
  return (dispatch) => {
    dispatch({
      type: product_actionType.SET_REQUIRED_FIELD_AVAILABLE,
      payload: flag
    });
  };
}

export function setProductToReducer(productObject) {
  let isRequiredFieldAvailableChecker =
    requiredFieldAvailableChecker(productObject);
  return (dispatch) => {
    dispatch({
      type: product_actionType.SET_PRODUCT_SUCCESS,
      data: productObject,
      isRequiredFieldAvailable: isRequiredFieldAvailableChecker
    });
  };
}

export function setSearchTextToReducer(searchValue) {
  return (dispatch) => {
    dispatch({
      type: product_actionType.SET_PRODUCT_SEARCH_TEXT,
      data: searchValue
    });
  };
}

export function getProduct() {
  const dummyData = [
    {
      productName: "CMH Products",
      productList: [
        { text: "Abasaglar", checked: false },
        { text: "Basaglar", checked: false },
        { text: "Glucagon", checked: false },
        { text: "Humalog", checked: false },
        { text: "Humalog H100", checked: false },
        { text: "Abasaglar", checked: false },
        { text: "Basaglar", checked: false },
        { text: "Diabetes Branded Portfoliio", checked: false },
        { text: "Glucagon", checked: false },
        { text: "Humalog", checked: false },
        { text: "Humalog H100", checked: false },
        { text: "Abasaglar", checked: false }
      ],
      checked: false
    },
    {
      productName: "OBU Products",
      productList: [
        { text: "Basaglar", checked: false },
        { text: "Glucagon", checked: false },
        { text: "Diabetes Branded", checked: false },
        { text: "Abasaglar", checked: false },
        { text: "Humalog", checked: false },
        { text: "Humalog H100", checked: false },
        { text: "Abasaglar", checked: false }
      ],
      checked: false
    },
    {
      productName: "IMBU Products",
      productList: [
        { text: "Humalog", checked: false },
        { text: "Basaglar", checked: false },
        { text: "Glucagon", checked: false },
        { text: "Abasaglar", checked: false },
        { text: "Humalog H100", checked: false },
        { text: "Abasaglar", checked: false }
      ],
      checked: false
    },
    {
      productName: "NBU Products",
      productList: [
        { text: "Abasaglar", checked: false },
        { text: "Basaglar", checked: false },
        { text: "Diabetes Branded Portfoliio", checked: false },
        { text: "Glucagon", checked: false },
        { text: "Humalog", checked: false },
        { text: "Humalog H100", checked: false },
        { text: "Abasaglar", checked: false }
      ],
      checked: false
    }
  ];
  return (dispatch) => {
    dispatch({
      type: product_actionType.GET_PRODUCT_SUCCESS,
      data: dummyData
    });
  };
}

export function setProductIsMandatoryTrue() {
  return (dispatch) => {
    dispatch({
      type: product_actionType.SET_IS_PRODUCT_MANDATORY_TRUE
    });
  };
}

export function setUserAccessProductModified(data) {
  return (dispatch) => {
    dispatch({
      type: product_actionType.SET_USER_ACCESS_PRODUCT_MODIFIED,
      payload: data
    });
  };
}

export function setProductIsMandatoryFalse() {
  return (dispatch) => {
    dispatch({
      type: product_actionType.SET_IS_PRODUCT_MANDATORY_FALSE
    });
  };
}

export function setProductFromDB(productCategory, selectedProducts) {
  // metadata setup
  let isRequiredFieldAvailable = false;
  let actualData = productCategory.map((productCategoryObj) => {
    return {
      productName: productCategoryObj.name,
      productList: productCategoryObj.products.map((productObj) => {
        return {
          text: productObj.product_name,
          checked: false,
          isFilteredProduct: true,
          product_abbreviation: productObj.product_abbreviation,
          product_active_flg: productObj.product_active_flg,
          product_id: productObj.product_id,
          product_name: productObj.product_name,
          vamProductGroupId: productObj.vamProductGroupId,
          applicableAffiliates: productObj.applicableAffiliates
        };
      }),
      checked: false,
      description: productCategoryObj.description,
      id: productCategoryObj.id,
      name: productCategoryObj.name,
      vamhubs_vamproductgroup_junction: productCategoryObj.vamProductGroupId
    };
  });
  console.log("actualData***", actualData);
  console.log("selectedProducts***", selectedProducts);
  if (selectedProducts) {
    if (selectedProducts.length > 0) isRequiredFieldAvailable = true;
    selectedProducts.forEach((selectedProductObject) => {
      actualData.forEach((actualDataObject) => {
        if (actualDataObject.id === selectedProductObject.vamProductGroupId) {
          // Update checked product
          actualDataObject.productList.forEach((element) => {
            if (element.product_id === selectedProductObject.product_id)
              element.checked = true;
          });
        }
      });
    });
  }
  return (dispatch) => {
    dispatch({
      type: product_actionType.SET_PRODUCT_SUCCESS,
      data: actualData,
      isRequiredFieldAvailable: isRequiredFieldAvailable
    });
  };
}
